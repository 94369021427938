import React, { useContext, useRef, useState } from "react";
import { Form, Input, Button, message, notification } from "antd";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import decode from "jwt-decode";
import { graphql, useMutation } from "react-apollo";
import PublicContent from "../../../layouts/public";
import { FormWrapper } from "../../../components/form";
import { useEffect } from "react";
import { AccountContext } from "../../../context/AccountContext";

const Login2fa = (props) => {
  const { form, mutate, history, location, data, user } = props;
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const userContext = useContext(AccountContext);
  const email = location && new URLSearchParams(location.search).get("email");
  const [state, setState] = useState({
    loading: false,
  });

  const [timer, setTimer] = useState(59);
  const [running, setRunning] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isError, setError] = useState(null);
  const otpInputs = useRef([]);

  const [resendCode, { data: codeData, loading: codeLoading, error: codeError }] = useMutation(gql`
    mutation($email: String) {
      resendVerifyCode(email: $email) {
        ok
        message
      }
    }
  `);

  useEffect(() => {
    if (running) {
      if (timer == 0) {
        setRunning(false);
        setTimer(59);
        return;
      }
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [running, timer]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value !== "" && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }

    setOtp(newOtp);
    if (isError) setError(null);
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputs.current[index - 1].focus();
    }
    const isEmpty = otp.every((digit) => digit === "");
    if (isEmpty) {
      // setErrorShow("");
    }
    if (event.key == "Enter") handleSubmit(event);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedDigits = pastedData.split("").slice(0, otp.length);
    const newOtp = [...otp];
    pastedDigits.forEach((digit, index) => {
      if (index < otp.length) {
        if (/[0-9]/.test(digit)) {
          newOtp[index] = digit;
        }
      }
    });
    setOtp(newOtp);
    if (!newOtp.includes("")) {
      const nextIndex = Math.min(otp.length - 1, pastedDigits.length - 1);
      if (otpInputs.current[nextIndex]) {
        otpInputs.current[nextIndex].focus();
      }
      if (isError) setError(null);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const { form, history, mutate, location } = props;
    let goto = null;

    if (location.search) {
      let splitGo = location.search.split("?goto=");
      if (splitGo.length > 1) goto = splitGo[1];
      if (goto && goto.includes("/clients/view")) {
        goto = goto.split("&");
        if (goto.length > 0) goto = goto[0];
      }
    }

    form.validateFields(async (err, values) => {
      if (otp.includes("")) {
        setError("OTP is required");
        return;
      }

      if (!err) {
        setState({ loading: true });

        // let code = `${values.p1}${values.p2}${values.p3}${values.p4}`;
        const response = await mutate({
          variables: { email: email.trim(), code: otp.join("") },
        });

        const { ok, token, refreshToken, errors, userPermissions, defaultLandingPage, practiceId } = response.data.loginUser;
        if (ok) {
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          const { user } = decode(token);
          const { isGlobalAdmin, isPortalAdmin, practices, portals, role } = user;
          const perm = userPermissions.map((per) => per.slug);

          userContext.setUserPermission(perm);

          if (user) {
            if (portals && portals.length > 0) {
              localStorage.setItem("current_company_id", portals[0].id);
              localStorage.setItem("current_company", portals[0].slug);
              if (practices && practices.length > 0) {
                localStorage.setItem("current_practice_id", practices[0].id);
                localStorage.setItem("current_practice", practices[0].practiceId);
              }
              if (!user.subscriptions && user.accountType == "1") history.push(`/collect-payment`);
              else {
                if (goto) history.push(goto);
                else if (defaultLandingPage == "overview") history.push(`/`);
                else if (defaultLandingPage == "client") history.push(`/${practiceId}/clients`);
                else history.push(defaultLandingPage);
              }
            } else {
                if (user.status.toLowerCase() == "active" && user.role.length > 0) {
                  history.push("/settings/account");
                }
            }
          }
          setState({ loading: false });
        } else {
          setState({ loading: false });
          if (errors && errors.length > 0) {
            setError(errors[0].message);
          }

          userContext.setAccount(null);
          userContext.setProfile(null);
          userContext.setLogoUpdate(null);
          userContext.setUserPermission([]);

          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("currentPractice");
        }
      }
    });
  };

  const resendCodeHandler = async () => {
    setRunning(true);
    const result = await resendCode({ variables: { email } });
  };

  const cancelHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("current_company");
    localStorage.removeItem("current_practice");
    localStorage.removeItem("current_practice_id");
    localStorage.removeItem("current_company_id");
    userContext.setAccount(null);
    userContext.setProfile(null);
    userContext.setLogoUpdate(null);
    userContext.setUserPermission([]);
    history.push("/login");
  };

  return (
    <PublicContent className="stretch-content unitifi-login-wrapper unitifi-signup-wrapper">
      <div className="public-form-wrapper">
        <FormWrapper className="public-form">
          <div className="unitifi-login-header-bar">
            <h5 className="unitifi-2fa-heading">Two-Factor Authentication</h5>
            <span className="unitifi-code-notification-text">{email && `We have sent the code to ${email}`}</span>
          </div>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <div className="code-number-input-box">
              {otp.map((digit, index) => (
                <div className="input-box-wrapper" key={index}>
                  <Input
                    className={`unitifi-code-input-box ${isError && !otp.includes("") ? "error-code-text" : ""}`}
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "0")}
                    value={otp[index]}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(e)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                </div>
              ))}

              {/* <div className="input-box-wrapper">
                {getFieldDecorator("p2", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p1", "p2", "p3");
                    }}
                  />
                )}
              </div>

              <div className="input-box-wrapper">
                {getFieldDecorator("p3", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p2", "p3", "p4");
                    }}
                  />
                )}
              </div>

              <div className="input-box-wrapper">
                {getFieldDecorator("p4", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p2", "p3", "p4");
                    }}
                  />
                )}
              </div> */}
            </div>
            {isError && <p className="error-code-text text-center mt-2 text-danger"> {isError}</p>}
            <div className="code-footer-text">
              {running && (
                <p className="code-success-text-wrapper">
                  <img src="/img/code-check-icon.svg" className="me-2" />
                  Code resent successfully! You can resend again in {timer}s.
                </p>
              )}
              {!running && (
                <p className="mb-0">
                  Did not receive code? <span onClick={() => resendCodeHandler()}>Resend code</span>
                </p>
              )}
            </div>
            <div className="unitifi-footer-wrapper code-footer-btn-wrapper">
              <Button htmlType="button" type="primary" className="unitifi-login-btn-border" onClick={() => cancelHandler()}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" className="unitifi-login-btn">
                Continue
              </Button>
            </div>
          </Form>
        </FormWrapper>
      </div>
    </PublicContent>
  );
};

const loginUserMutation = gql`
  mutation($email: String, $code: String) {
    loginUser(email: $email, code: $code) {
      ok
      token
      defaultLandingPage
      practiceId
      userPermissions {
        id
        slug
      }
      refreshToken
      errors {
        path
        message
      }
    }
  }
`;

export default compose(graphql(loginUserMutation))(Form.create()(Login2fa));
