import React from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import TitlebarWrapper from "./index.style";
import moment from "moment";

const menuKeys = ["advisors", "assessments", "clients", "practices", "users", "portals"];

const getPracticeDetailsQuery = gql`
  query($practiceId: String!) {
    getPracticeDetails(practiceId: $practiceId) {
      name
    }
  }
`;

const Subtitle = ({ forPrint, path, practiceId, viewClient, viewMode }) => (
  <Query query={getPracticeDetailsQuery} variables={{ practiceId }}>
    {({ loading, error, data }) => {
      if (loading || error) {
        return null;
      }

      if (!menuKeys.includes(practiceId) || viewClient) {
        return (
          <div className="sub-title-text">
            {data.getPracticeDetails && data.getPracticeDetails.name ? data.getPracticeDetails.name : null}
            {forPrint && <div style={{ textAlign: "right" }}>{moment(viewClient.dateCollected).format("M/D/YYYY")}</div>}
          </div>
        );
      }

      if (!menuKeys.includes(practiceId) && viewMode) {
        return (
          <div className="sub-title-text">
            <span className="capitalize">{path[2]}</span>
          </div>
        );
      }

      return null;
    }}
  </Query>
);

const Titlebar = (props) =>
  props.forPrint ? (
    <TitlebarWrapper>
      <div className="title-bar-wrapper">
        <div className="title-wrapper for-print">
          <div className="title-text">{props.displayText}</div>
          {props.subtitle ? <div className="sub-title-text">{props.subtitle}</div> : <Subtitle forPrint path={props.path} practiceId={props.currentPractice} viewClient={props.viewClient} viewMode={props.viewMode} />}
        </div>
      </div>
    </TitlebarWrapper>
  ) : (
    <TitlebarWrapper>
      <div className="title-bar-wrapper">
        <div className="title-wrapper">
          <div className="title-text">{props.displayText}</div>
          {props.subtitle ? <div className="sub-title-text">{props.subtitle}</div> : <Subtitle path={props.path} practiceId={props.currentPractice} viewClient={props.viewClient} viewMode={props.viewMode} />}
        </div>
      </div>
    </TitlebarWrapper>
  );

export default Titlebar;
