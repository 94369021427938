import React from "react";
import { Icon, Popover } from "antd";
import { FlexibleXYPlot, MarkSeries, VerticalGridLines, XAxis } from "react-vis";
import "../../../node_modules/react-vis/dist/style.css";
import infoIcon from "../../assets/img/chart-info-icon.svg";
import MapDecisionMakingStyle from "./mapDecisionMaking";
import PassionLogicMapWrapper from "./index.style";

class PassionLogicMapMultiple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let chartData = [];
    const { data: insights, firstName, forPrint, firstNameClient } = this.props;
    const {
      personality: { decisionMakingStyle },
      score: { plScore },
    } = insights[0];

    chartData.push(MapDecisionMakingStyle(decisionMakingStyle, plScore));
    chartData.push(MapDecisionMakingStyle(insights[1].personality.decisionMakingStyle, insights[1].score.plScore));
    chartData[1].fill = "#CB9120";
    chartData[1].stroke = "#CB9120";
    chartData[1].color = "#CB9120";

    const margin = { bottom: 12, left: 12, right: 12 };

    return (
      <PassionLogicMapWrapper>
        <div className="top-wrapper justify-content-around">
          <span className="chartTitle">
            {decisionMakingStyle}
            {!forPrint && (
              <Popover content={`This measurement represents how financial decisions are made and how best to communicate with ${firstName}.`} placement="top" trigger="hover">
                <i className="ms-1">
                  <img src={infoIcon} />
                </i>
              </Popover>
            )}
            {/* <i className="ms-1">
              <img src={infoIcon} />
            </i> */}
          </span>

          <span className={`chartTitle compareTitle ${forPrint ? "ms-1" : ""}`}>
            {insights[1].personality.decisionMakingStyle}
            {!forPrint && (
              <Popover content={`This measurement represents how financial decisions are made and how best to communicate with ${firstNameClient}.`} placement="top" trigger="hover">
                <i className="ms-1">
                  <img src={infoIcon} />
                </i>
              </Popover>
            )}
            {/* <i className="ms-1">
              <img src={infoIcon} />
            </i> */}
          </span>
        </div>
        <div className="chart-wrapper">
          <FlexibleXYPlot height={100} margin={margin} {...(forPrint && { width: 255 })} xDomain={[0, 200]} yDomain={[0, 10]}>
            <VerticalGridLines />
            <XAxis hideLine hideTicks on0 />
            <MarkSeries animation data={[chartData[0]]} stroke={chartData[0].stroke} fill={chartData[0].fill} />
            <MarkSeries animation data={[chartData[1]]} stroke={chartData[1].stroke} fill={chartData[1].fill} />
          </FlexibleXYPlot>
          <div className="label-wrapper gradientBg">
            <div>Logical</div>
            <div>Passionate</div>
          </div>
        </div>
      </PassionLogicMapWrapper>
    );
  }
}

export default PassionLogicMapMultiple;
