import { Button, notification } from "antd";

import { Input, Menu, Checkbox, Dropdown, Icon } from "antd";
import gql from "graphql-tag";
import moment from "moment";

import { Modal } from "react-bootstrap";
import React, { useCallback, useEffect, forwardRef, useRef, useState, useImperativeHandle } from "react";
import tagCloseIcon from "../../../assets/img/tag-close-icon.svg";
import TimeAgo from "react-timeago";
import { useApolloClient } from "react-apollo";
import { assessmentUrl } from "../../../config";
import { Tabs, Radio, Table } from "antd";
import { useLazyQuery } from "react-apollo";

import _, { forEach } from "lodash";

const CompareClient = (props, ref) => {
  const { setAssessmentModal, currentPortalId, currentPractice, gblUserId, user, setTotalAssessment, me, assessmentHandler, history, id, match } = props;
  const client = useApolloClient();
  const [tags, selectedTags] = useState([]);
  const [menuKey, setMenuKey] = useState([]);

  const [dateRange, setDateRange] = useState({
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(null);
  const selectRef = useRef();
  const [selectedOptions, setSelectedOptions] = useState([]);
  // useImperativeHandle(ref, () => ({
  //   selectAll(inputs) {
  //     searchClientHandler(inputs);
  //   },
  // }));
  useEffect(() => {
    clientListHandler();
  }, []);
  const searchHandler = (event) => {
    setSearch(event.target.value);

    // setSelectedRowKeys([]);
    clientListHandler();
  };
  const [clientListHandler, { loading: sentViewLoading, data: sentViewCompleteData }] = useLazyQuery(
    gql`
      query(
        $limit: Int
        $offset: Int
        $order: String
        $orderColumn: String
        $practiceId: Int
        $search: String
        $portalId: Int
        $roleCode: String
        $accountType: Int
        $advisorId: String
        $practiceAssignId: String
        $advisorType: [Int]
        $clientId: String
        $supportAdvisorId: String
      ) {
        getAllClientsCompare(
          limit: $limit
          offset: $offset
          order: $order
          orderColumn: $orderColumn
          practiceId: $practiceId
          search: $search
          portalId: $portalId
          roleCode: $roleCode
          accountType: $accountType
          advisorId: $advisorId
          advisorType: $advisorType
          practiceAssignId: $practiceAssignId
          clientId: $clientId
          supportAdvisorId: $supportAdvisorId
        ) {
          clients {
            id
            email
            firstName
            lastName
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        limit: 20,
        practiceId: me.practiceid,
        accountType: me.accountType,
        roleCode: me.rolecode,
        portalId: currentPortalId,
        advisorType: [],
        search: search,
        supportAdvisorId: new URLSearchParams(match.params).get("srAdvisorId"),
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  let sentAssessment = _.get(sentViewCompleteData, "getAllClientsCompare.clients", []);
  sentAssessment = sentAssessment.map((item) => {
    item.key = item.id;
    return item;
  });
  // console.log("sentAssessment", sentAssessment);
  // let totalCount =  _.get(sentViewCompleteData, "getIncompleteAssessment.pagination.totalCount", []);
  useEffect(() => {
    if (totalCount == 0 && sentAssessment.length > 0) {
      setTotalCount(sentAssessment.length);
      if (id) {
        setSelectedRowKeys([parseInt(id)]);
        const clients = sentAssessment.filter((item) => [parseInt(id)].includes(item.id));
        setSelectedClient([...clients]);
      }
    }
  }, [sentAssessment, id]);
  const recentlySentColumns = [
    {
      dataIndex: "firstName",
      title: "NAME",
      className: "tblNameTitle",
      sorter: false,
      render: (data, i) => i.firstName + " " + i.lastName,
    },
    {
      dataIndex: "email",
      title: "EMAIL",
      className: "tblEmailTitle",
      width: 250,
      sorter: false,
    },
  ];

  const inviteHandler = async () => {
    if (selectedRowKeys.length <= 1) {
      return;
    }
    let supportAdvisorId = new URLSearchParams(match.params).get("srAdvisorId");
    let url = `/${currentPractice}/clients/view-compare/${selectedRowKeys[0]}/${selectedRowKeys[1]}`;
    if (me.rolecode == "SPR" && supportAdvisorId) url = `/${currentPractice}/clients/advisors/${supportAdvisorId}/view-compare/${selectedRowKeys[0]}/${selectedRowKeys[1]}`;
    history.push(url);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length <= 2) setSelectedRowKeys(newSelectedRowKeys);

    const clients = sentAssessment.filter((item) => newSelectedRowKeys.includes(item.id));
    if (!selectedClient.length) {
      setSelectedClient([...clients]);
    } else {
      var arr = [...selectedClient, ...clients];
      var filteredArray = arr.filter((item, pos) => arr.findIndex((obj) => obj.id === item.id) == pos);
      filteredArray = filteredArray.filter((item) => newSelectedRowKeys.includes(item.id));
      if (filteredArray.length <= 2) {
        setSelectedClient(filteredArray);
      }
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const removeFromSelection = (id) => {
    setSelectedRowKeys(selectedRowKeys.filter((item) => item != id));
    setSelectedClient(selectedClient.filter((item) => item.id != id));
  };

  return (
    <div>
      <Modal.Body>
        <div className="unitifi-invite-practice-modal-wrapper">
          {/* <span class="d-flex justify-content-end">
            <button type="button" class="btn-close" aria-label="Close" onClick={() => assessmentHandler()}></button>
          </span> */}
          <div className="d-flex align-items-start align-items-sm-start justify-content-between flex-column flex-sm-row">
            <h6 className="modalHeadingTitle">Client Compare</h6>
            <div className="topBtnWrapper ms-auto">
              <Button type="primary" htmlType="button" className="unitifi-login-btn unitifi-cancel-btn" onClick={() => assessmentHandler()}>
                Cancel
              </Button>
              <Button type="primary" htmlType="button" className="unitifi-login-btn" onClick={() => inviteHandler()} disabled={loading} loading={loading}>
                Compare
              </Button>
            </div>
          </div>
          {selectedRowKeys.length > 0 && (
            <div className="tagboxWrapper">
              <ul id="tags" className="list-unstyled">
                {selectedClient.length != totalCount &&
                  selectedClient.length > 0 &&
                  selectedClient.map((tag, index) => {
                    return (
                      <li key={tag.id} className="tag">
                        <span className="tag-title">{tag.firstName + " " + tag.lastName}</span>
                        <span className="tag-close-icon" onClick={() => removeFromSelection(tag.id)}>
                          <i>
                            <img src={tagCloseIcon} />
                          </i>
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          )}
          <input type="text" onChange={(e) => searchHandler(e)} placeholder="Search Clients To Compare (Max 2 Clients)" className="clientSearchInput mt-2" />
        </div>
        {sentAssessment.length ? (
          <Table
            className="assessmentTblWrapper clientCompare"
            rowSelection={rowSelection}
            columns={recentlySentColumns}
            loading={sentViewLoading}
            dataSource={sentAssessment}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ y: selectedRowKeys.length > 0 ? 370 : 450 }}
          />
        ) : (
          ""
        )}
      </Modal.Body>
      {/* <Modal.Footer>
        <div className="modal-footer-bnts-wrapper mt-3">
          <Button type="primary" htmlType="button" className="unitifi-login-btn unitifi-cancel-btn" onClick={() => assessmentHandler()}>
            Cancel
          </Button>
          <Button type="primary" htmlType="button" className="unitifi-login-btn" onClick={() => inviteHandler()} disabled={loading} loading={loading}>
            Compare
          </Button>
        </div>
      </Modal.Footer> */}
    </div>
  );
};

export default forwardRef(CompareClient);
