import React, { useCallback, useEffect, useRef, useState } from "react";
import { Titlebar } from "../../components";
import { Link } from "react-router-dom";
import { Form, Modal } from "react-bootstrap";
import { Row, Col } from "antd";
import Search from "../../assets/img/header-search-icon.svg";
import Protector from "../../assets/img/Protector.svg";
import { personalityTypeImg } from "../../helpers/formatters";
import { Query, useLazyQuery, useQuery } from "react-apollo";
import _, { debounce } from "lodash";
import gql from "graphql-tag";

const Behavior = () => {
  const [behaviorModal, setBehaviorModal] = useState(false);
  const [personalityModal, setPersonalityModal] = useState(false);
  const [behaviorModalData, setBehaviorModalData] = useState(null);
  const [personalityModalData, setPersonalityModalData] = useState(null);
  const searchRef = useRef();
  const [personalityHandler, { loading: dataLoading, data: personalityData, error: dataError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getPersonalityBehavior(filters: $filters) {
          personalityResult
          behaviorResult
        }
      }
    `,
    {
      variables: {
        filters: {},
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    personalityHandler();
  }, []);

  const searchDebouncedHandler = useCallback(
    debounce((searchText) => personalityHandler({ variables: { filters: { search: searchText } } }), 700),
    []
  );

  const searchHandler = (event) => {
    searchDebouncedHandler(event.target.value);
  };

  const behaviorModalHandler = () => {
    setBehaviorModal(false);
    setBehaviorModalData(null);
  };

  const personalityModalHandler = () => {
    setPersonalityModal(false);
    setPersonalityModalData(null);
  };

  const behaviorResultHandler = (result) => {
    setBehaviorModalData(result);
    setBehaviorModal(true);
  };

  const personalityResultHandler = (result) => {
    setPersonalityModalData(result);
    setPersonalityModal(true);
  };

  const searchBtnHandler = (e) => {
    if (e) e.preventDefault();
    personalityHandler({ variables: { filters: { search: searchRef.current.value } } });
  };
  const personalityResult = _.get(personalityData, "getPersonalityBehavior.personalityResult", []);
  const behaviorResult = _.get(personalityData, "getPersonalityBehavior.behaviorResult", []);

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-learning-center-heading">
        <Titlebar displayText="Learning Center" />
        <Link to="/learning-center" className="unitifi-headLinks">
          Self-Help Tutorials
        </Link>
        <Link to="/personality-behavior" className="unitifi-headLinks active ms-2 ms-sm-5">
          Personality & Behavior Attributes
        </Link>
        <div className="unitifi-videoBlock-wrapper">
          <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-end">
            <Form className="searchbox-form-wrapper" onSubmit={searchBtnHandler}>
              <div className="search-input-wrapper input-group">
                <i onClick={() => searchBtnHandler()}>
                  <img src={Search} />
                </i>
                <svg xmlns="http://www.w3.org/2000/svg" className="" width="17" height="17" viewBox="0 0 384 512" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                    fill="#ffffff"
                  />
                </svg>
                <Form.Control type="search" placeholder="Search Tutorials" ref={searchRef} aria-label="Search" />
                <div class="input-group-append" onClick={() => searchBtnHandler()}>
                  <span class="input-group-text"></span>
                </div>
              </div>
            </Form>
          </div>

          <h2 className="videoBlock-title mb-4">Personality Types</h2>

          <div className="personalityBlock-wrapper">
            <Row gutter={40}>
              {personalityResult.length > 0 &&
                personalityResult.map((personality) => (
                  <Col md={6} key={personality.id} onClick={() => personalityResultHandler(personality)}>
                    <div className={`personality-cmn-block ${personality.className}`}>
                      <i>
                        <img src={personalityTypeImg[personality.name]} />
                      </i>
                      <h4>{personality.name}</h4>
                    </div>
                  </Col>
                ))}
              {!dataLoading && personalityResult.length == 0 && (
                <Col lg={8}>
                  <p>No matches found</p>
                </Col>
              )}
              {/* <Col md={6}>
                <div className="personality-cmn-block observer">
                  <h4>Observer</h4>
                </div>
              </Col>
              <Col md={6}>
                <div className="personality-cmn-block liberator">
                  <h4>Liberator</h4>
                </div>
              </Col>
              <Col md={6}>
                <div className="personality-cmn-block energizer">
                  <h4>Energizer</h4>
                </div>
              </Col> */}
            </Row>
          </div>

          <h2 className="videoBlock-title mb-4">Behavior Attributes</h2>

          <div className="behaviourBlock-wrapper">
            <Row gutter={40}>
              {behaviorResult.length > 0 &&
                behaviorResult.map((behavior) => (
                  <Col lg={8} key={behavior.id} onClick={() => behaviorResultHandler(behavior)}>
                    <div className="behaviour-cmn-block blue-box">
                      <div className="behaviour-icon-detail">
                        <i>
                          <img src={behavior.white_icon} />
                        </i>
                        <p>{behavior.name}</p>
                      </div>
                    </div>
                  </Col>
                ))}

              {!dataLoading && behaviorResult.length == 0 && (
                <Col lg={8}>
                  <p>No matches found</p>
                </Col>
              )}

              <Modal show={behaviorModal} onHide={() => behaviorModalHandler()} centered className="unitifi-modal-wrapper" size="lg">
                {behaviorModalData && (
                  <>
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-sm">{behaviorModalData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="unitifi-behavior-recognized-modal">
                        <div className="unitifi-behavior-recognized-detail">
                          {behaviorModalData.characteristics.length > 0 && (
                            <>
                              <h5>Characteristics</h5>
                              <ul>
                                {behaviorModalData.characteristics.map((c) => (
                                  <li key={c}>{c}</li>
                                ))}
                              </ul>
                            </>
                          )}
                          {behaviorModalData.results.length > 0 && (
                            <>
                              <h5 className="mt-4">Result</h5>
                              <ul>
                                {behaviorModalData.results.map((res) => (
                                  <li key={res}>{res}</li>
                                ))}
                              </ul>
                            </>
                          )}
                          {behaviorModalData.recommendations.length > 0 && (
                            <>
                              <h5 className="mt-4">Tips for the Financial Professional</h5>
                              <ul>
                                {behaviorModalData.recommendations.map((res) => (
                                  <li key={res}>{res}</li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                        <div>
                          <div className="unitifi-behavior-recognized-icon">
                            <img src={behaviorModalData.blue_icon} />
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </>
                )}
              </Modal>

              <Modal show={personalityModal} onHide={() => personalityModalHandler()} centered className="unitifi-modal-wrapper" size="lg">
                {personalityModalData && (
                  <>
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-sm">{personalityModalData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="unitifi-behavior-recognized-modal">
                        <div className="unitifi-behavior-recognized-detail">
                          {personalityModalData.attributes && (
                            <>
                              <h5>Tips for the Financial Professional</h5>
                              <ul>
                                {personalityModalData.attributes.advisorTips.map((c) => (
                                  <li key={c}>{c}</li>
                                ))}
                              </ul>
                            </>
                          )}
                          {personalityModalData.attributes && (
                            <>
                              <h5 className="mt-4">Behaviors of this Investor</h5>
                              <ul>
                                {personalityModalData.attributes.investorBehaviors.map((res) => (
                                  <li key={res}>{res}</li>
                                ))}
                              </ul>
                            </>
                          )}
                        </div>
                      </div>
                    </Modal.Body>
                  </>
                )}
              </Modal>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Behavior;
