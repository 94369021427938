import { Button, notification } from "antd";
import gql from "graphql-tag";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import infoIcon from "../../assets/img/info-blue.svg";
import { useApolloClient, useQuery, graphql } from "react-apollo";
import TagsInput from "../TagsInput";
import _, { flowRight as compose } from "lodash";
import Select from "react-select";
import { convertCurrency } from "../../helpers/formatters";

const InviteAdvisor = (props) => {
  const { setAdvisorModal, currentPortalId, gblUserId, me, setExceedalert, exceedalert, setInviteModal, isInviteModal } = props;
  //const { setFields } = props.form;
  const client = useApolloClient();
  const [tags, selectedTags] = useState([]);
  const [choosepractice, setChoosepractice] = useState();
  const [loading, setLoading] = useState(false);
  const [practiceList, setPracticeList] = useState([]);
  const [ExceedTeam, setExceedTeam] = useState([]);
  const [exceedalertmsg, setExceedalertmsg] = useState();

  useEffect(() => {
    if (!me) return;
    if (["CMPYO", "CMPYM"].includes(me.rolecode) == false) setChoosepractice(me.practiceid);
  }, [me]);

  useEffect(() => {
    if (!me) return;
    if (!isInviteModal) return;
    if (isInviteModal == "advisoreMember") {
      setChoosepractice(me.practiceid);
    }
  }, [me, isInviteModal]);

  const inviteHandler = async () => {
    if (tags.length == 0) {
      return;
    }
    if (!choosepractice) {
      return;
    }
    if (ExceedTeam.totalUserAdded + tags.length > ExceedTeam.totalUser) {
      // var msg =
      // "You are currently at your Team Member limit (" + ExceedTeam.totalUser + "). If you would like to invite this member your subscription cost will increase by $" + ExceedTeam.price + "/new member per " + ExceedTeam.subscriptionInterval + ".";
      if (exceedalert == false) {
        setExceedalert(true);
        // setExceedalertmsg(msg);
        return;
      }
    } else {
      setExceedalert(false);
    }
    setLoading(true);
    let variables = {
      toEmail: tags.join(","),
      portalId: currentPortalId,
      userId: gblUserId,
      practiceId: choosepractice,
    };
    let result = await client.mutate({
      mutation: gql`
        mutation($userId: Int!, $portalId: Int!, $practiceId: Int!, $toEmail: String) {
          inviteAdvisor(userId: $userId, portalId: $portalId, toEmail: $toEmail, practiceId: $practiceId) {
            ok
            message
            errors {
              path
              message
            }
          }
        }
      `,
      variables: variables,
    });
    setLoading(false);

    if (result.data.inviteAdvisor.ok) {
      notification.success({ message: result.data.inviteAdvisor.message });
      setAdvisorModal(false);
    } else notification.error({ message: result.data.inviteAdvisor.errors[0].message });
  };
  const { data: practicedata } = useQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $search: String, $portalId: Int, $practiceId: Int, $roleCode: String, $accountType: Int) {
        getAdminPractices(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, search: $search, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
          practices {
            id
            name
            practiceId
            portalId
            company
            cslug
            primaryContactAddress
            status
            primaryContactEmail
          }
          pagination {
            offset
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        page: 1,
        offset: 0,
        limit: 100000,
        order: "ASC",
        orderColumn: "",
        search: "",
        portalId: currentPortalId,
        practiceId: props.me.practiceid,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
      },
      fetchPolicy: "network-only",
    }
  );
  const { data: exceedteam } = useQuery(
    gql`
      query($portalId: Int!) {
        getExceedTeam(portalId: $portalId) {
          exceedteam {
            id
            totalUser
            totalUserAdded
            name
            subscriptions
            price
            subscriptionInterval
          }
        }
      }
    `,
    {
      variables: {
        portalId: currentPortalId,
      },
      fetchPolicy: "network-only",
    }
  );
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Team Discounts</Popover.Header>
      <Popover.Body>
        <ul>
          <li>
            <span>25%</span> off for <span>11-50</span> team members
          </li>
          <li>
            <span>30%</span> off for <span>51-100</span> team members
          </li>
          <li>
            <span>40%</span> off for <span>101-250</span> team members
          </li>
          <li>
            <span>60%</span> off for <span>250+</span> team members
          </li>
        </ul>
      </Popover.Body>
    </Popover>
  );
  //start for practices
  useEffect(() => {
    if (!practicedata) return;
    const practice = _.get(practicedata, "getAdminPractices.practices", []).map((practice) => {
      return {
        label: practice.name,
        value: practice.id,
      };
    });

    setPracticeList(practice);
  }, [practicedata]);

  useEffect(() => {
    if (!exceedteam) return;
    const e_team = _.get(exceedteam, "getExceedTeam.exceedteam", []);

    setExceedTeam(e_team);
  }, [exceedteam]);
  return (
    <div>
      <p className={` ${exceedalert ? "section_hide" : "section_show"}`}>
        {isInviteModal
          ? `This advisor will be invited to the default practice, and once they accept, you can reassign them to other new or available practices.`
          : `If you're unsure which practice to invite the advisor to, please choose the default practice named "${me.practicename}."`}
      </p>
      <div className={`unitifi-invite-practice-modal-wrapper inviteModalBox ${exceedalert ? "section_hide" : "section_show"}`}>
        <TagsInput selectedTags={selectedTags} tags={tags} placeholder="Input email (hit return to add multiple)" />
      </div>
      {["CMPYO", "CMPYM"].includes(me.rolecode) && !isInviteModal && (
        <div className={`${exceedalert ? "section_hide" : "section_show"}`}>
          <Select
            options={practiceList}
            className="unitifi-select-container selectBox mb-3"
            classNamePrefix="unitifi-react-select"
            name="choosepractice"
            onChange={(e) => setChoosepractice(e.value)}
            placeholder="Select Practice"
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </div>
      )}

      <div className={`inviteMember-section ${exceedalert ? "section_show" : "section_hide"}`}>
        {/*<h4>
            Invite Advisor
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <i className="ms-2">
              <img src={infoIcon} />
            </i>
            </OverlayTrigger>
        </h4>*/}
        {ExceedTeam && (
          <p>
            You are currently at your Team Member limit <span>({ExceedTeam.totalUser})</span>. If you would like to invite this member your subscription cost will increase by <span>${convertCurrency(ExceedTeam.price)}</span>
            /new member per <span>{ExceedTeam.subscriptionInterval}</span>.{" "}
          </p>
        )}

        {/* <p>{exceedalertmsg}</p> */}
        <span className="inviteInfo">*By inviting, your next billing cycle will automatically be updated to reflect this change.</span>
      </div>

      <div className="modal-footer-bnts-wrapper">
        <Button type="primary" htmlType="button" className="unitifi-login-btn" onClick={() => inviteHandler()} disabled={loading} loading={loading}>
          Invite
        </Button>
        <Button type="primary" htmlType="button" className="unitifi-login-btn unitifi-cancel-btn" onClick={() => setAdvisorModal(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InviteAdvisor;
