import React from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { Form, Icon, Input, Select, Spin, AutoComplete, Badge } from "antd";
import PropTypes from "prop-types";
import { AddButtons, EditButtons, FormWrapper } from "../../../components/form";
import StateList from "../../../helpers/statesDataList";
import { AccountContext } from "../../../context/AccountContext";
import _ from "lodash";
import { throwServerError } from "@apollo/client";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

class UserForm extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      showPractice: this.props.user ? ([5, 3, 6].includes(this.props.user.roleId) ? true : false) : false,
      // this.props.user? this.props.user.isPortalAdmin ? true : this.props.user.isPracticeAdmin ? true : false : false,
      showPortal: [1].includes(this.props.gblUserRole) ? true : false,
      // just for checking, may remove
      selectedPortals: this.props.user ? this.props.user.portalid : this.props.userPortals.id,
      selectedPractices: [],
      portalId: this.props.user ? this.props.user.portalid : this.props.userPortals.id,
      roleId: this.props.user ? this.props.user.roleId : this.props.gblUserRole ? this.props.gblUserRole : null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    // just for checking, may remove
    this.handleSelectedPortals = this.handleSelectedPortals.bind(this);
    this.handleSelectedPractices = this.handleSelectedPractices.bind(this);
  }

  // componentDidMount() {
  //   const userContext = this.context;
  //   console.log("context",userContext);
  //   let portalId = _.get(userContext.account,'portalId',[])
  //
  //   this.fetchData(portalId[0])
  // }

  // componentDidUpdate(prevProps){
  //   const { getFieldValue } = this.props.form;
  //  if(this.props !== prevProps && getFieldValue('roleId') !== 5){
  //
  //    const userContext = this.context;
  //    let portalId = _.get(userContext.account,'portalid',[]);
  //     if (!_.isEmpty(portalId) ){
  //       let pid = portalId.toString()
  //       this.setState({portalId:pid},()=>{this.fetchData()})
  //       //this.fetchData(pid)
  //     }
  //  }
  //
  // }

  fetchData = () => {
    this.setState({ loading: true });
    this.props.data.fetchMore({
      variables: {
        filter: this.state.portalId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        // console.log('prev', previousResult)
        // console.log('new: ', fetchMoreResult)

        if (!fetchMoreResult) {
          // console.log('No fetch more result, show prev: ', previousResult)
          return previousResult;
        }

        if ([5].includes(this.state.roleId) && this.state.selectedPortals !== undefined) {
          const { setFieldsValue } = this.props.form;
          setFieldsValue({
            practiceId: fetchMoreResult.getAllPracticesForForm.practices.map((item, i) => {
              return item.id;
            }),
          });
        }
        // console.log('show fetchmore: ', previousResult)
        return fetchMoreResult;
      },
    });
  };

  onRoleChange(roleId) {
    this.setState({
      showPractice: [2, 3, 6, 5].includes(roleId),
    });

    if ([5, 2, 6, 3].includes(roleId) && this.state.selectedPortals !== "") {
      let pid;
      if (this.state.selectedPortals === undefined) {
        pid = [this.state.selectedPortals];
      } else {
        pid = this.state.selectedPortals;
      }
      this.setState({ portalId: pid, roleId }, () => {
        this.fetchData();
      });
    }
  }

  // just for checking, may remove
  handleSelectedPortals(selectedPortals, practice, roleId) {
    this.setState({ selectedPortals });

    const { getFieldValue } = this.props.form;

    if ([5, 2, 6, 3].includes(getFieldValue("roleId"))) {
      let pid;
      if (selectedPortals.length === undefined) {
        pid = [selectedPortals];
      } else {
        pid = selectedPortals;
      }
      this.setState({ portalId: pid }, () => {
        this.fetchData();
      });
    }
  }

  // just for checking, may remove
  handleSelectedPractices(selectedPractices) {
    this.setState({ selectedPractices });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  }

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const {
      data: { loading, error, getAllPracticesForForm },
      rolesData: { loading: rolesDataLoading, error: rolesDataError, getAllRolesForForm },
      portalsData: { getPortalByAccess },
    } = this.props;

    if (loading || rolesDataLoading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error || rolesDataError) {
      return <div className="loading-wrapper">{/* <p>Whoopsie - users didn&apos;t load.</p> */}</div>;
    }

    const portals = getPortalByAccess;
    const practices = _.get(getAllPracticesForForm, "practices", []);
    const { roles } = getAllRolesForForm;
    const { portalId, roleId } = this.state;

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem label="Role">
            {getFieldDecorator("roleId", {
              initialValue: this.props.user ? roleId : undefined,
              rules: [
                {
                  message: "A user role is required.",
                  required: true,
                },
              ],
            })(
              <Select filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} onChange={(roleId) => this.onRoleChange(roleId)} optionFilterProp="children" placeholder="Role" showSearch>
                {(roles || [])
                  .filter((r) => (this.props.user ? (this.props.user.id == this.props.gblUserId ? ["GADM", "PoADM", "PADM", "PoSUP", "PSUP", "ADV"].includes(r.code) : r.id != this.props.gblUserRole) : r.id != this.props.gblUserRole))
                  .map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.name}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>
          {this.state.showPortal ? (
            <FormItem label="Company">
              {getFieldDecorator("portalId", {
                initialValue: this.props.user ? portalId : this.props.userPortals.id,
                rules: [
                  {
                    message: "A user Company is required.",
                    required: true,
                  },
                ],
              })(
                <Select
                  mode={getFieldValue("roleId") === 1 ? "multiple" : "default"}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  optionFilterProp="children"
                  placeholder="Company"
                  onChange={(e) => this.handleSelectedPortals(e, practices, roleId)}
                  showSearch>
                  {(portals || []).map((portal, x) => (
                    <Option key={x} value={portal.id}>
                      {portal.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          ) : null}
          {(this.state.showPractice && this.props.user) || [5, 2, 6, 3].includes(getFieldValue("roleId")) ? (
            // ( this.props.user && portalId !== "" && [5,2,6,3].includes(getFieldValue('roleId') ? getFieldValue('roleId') : roleId))) ?
            <FormItem label="Practice">
              {getFieldDecorator("practiceId", {
                initialValue: this.props.user
                  ? this.props.user.practiceId
                  : [5].includes(roleId) && this.state.selectedPortals !== undefined
                  ? practices.map((prac, i) => {
                      return prac.id;
                    })
                  : [],
                rules: [
                  {
                    message: "A user practice is required.",
                    required: true,
                  },
                ],
              })(
                <Select
                  mode={[1, 4, 5].includes(getFieldValue("roleId")) ? "multiple" : "default"}
                  filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  optionFilterProp="children"
                  placeholder="Practice"
                  onChange={this.handleSelectedPractices}
                  showSearch>
                  {(practices || []).map((practice) => (
                    <Option key={practice.id} value={practice.id}>
                      {practice.name}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          ) : null}
          <FormItem label="Email">
            {getFieldDecorator("email", {
              initialValue: this.props.user ? this.props.user.email : null,
              rules: [
                {
                  message: "A user email is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="Email" />)}
          </FormItem>
          <FormItem className="input-small" label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: this.props.user ? this.props.user.firstName : null,
              rules: [
                {
                  message: "A user first name is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="First Name" />)}
          </FormItem>
          <FormItem className="input-small" label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: this.props.user ? this.props.user.lastName : null,
              rules: [
                {
                  message: "A user last name is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="Last Name" />)}
          </FormItem>
          {this.props.user && this.props.user.status.toUpperCase() !== "PENDING" ? (
            <FormItem className="input-small" label="Status">
              {getFieldDecorator("status", { initialValue: this.props.user.status })(
                <Select placeholder="Status">
                  <Option value="Active">
                    <Badge status="success" text="Active" />
                  </Option>
                  <Option value="Inactive">
                    <Badge status="error" text="Inactive" />
                  </Option>
                </Select>
              )}
            </FormItem>
          ) : null}
          <FormItem className="input-small" label="Address">
            {getFieldDecorator("address", {
              initialValue: this.props.user ? this.props.user.address : null,
            })(<Input placeholder="Address" />)}
          </FormItem>
          <FormItem className="input-small" label="City">
            {getFieldDecorator("city", {
              initialValue: this.props.user ? this.props.user.city : null,
            })(<Input placeholder="City" />)}
          </FormItem>
          <FormItem className="input-xsmall" label="State">
            {getFieldDecorator("state", {
              initialValue: this.props.user && this.props.user.state ? this.props.user.state : undefined,
            })(
              <AutoComplete placeholder="Select a State" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {(StateList || []).map((state) => (
                  <AutoCompleteOption key={state.abbr} value={state.abbr}>
                    {state.name}
                  </AutoCompleteOption>
                ))}
              </AutoComplete>
            )}
          </FormItem>
          <FormItem className="input-xsmall" label="Zip Code">
            {getFieldDecorator("zipCode", {
              initialValue: this.props.user ? this.props.user.zipCode : null,
            })(<Input placeholder="Zip Code" />)}
          </FormItem>
          <FormItem className="input-phone__wrapper" label="Phone Number">
            <InputGroup>
              {getFieldDecorator("phoneNumber", {
                initialValue: this.props.user ? this.props.user.phoneNumber : null,
              })(
                <div className="input-phone__input">
                  {getFieldDecorator("p1", {
                    initialValue: this.props.user && this.props.user.phoneNumber ? this.props.user.phoneNumber.substring(0, 3) : null,
                  })(<Input maxLength={3} placeholder="867" />)}
                  <span>&#8211;</span>
                  {getFieldDecorator("p2", {
                    initialValue: this.props.user && this.props.user.phoneNumber ? this.props.user.phoneNumber.substring(3, 6) : null,
                  })(<Input maxLength={3} placeholder="530" />)}
                  <span>&#8211;</span>
                  {getFieldDecorator("p3", {
                    initialValue: this.props.user && this.props.user.phoneNumber ? this.props.user.phoneNumber.substring(6, 10) : null,
                  })(<Input maxLength={4} placeholder="9999" />)}
                </div>
              )}
            </InputGroup>
          </FormItem>
          <div className="form-toolbar">
            {this.props.user ? (
              <EditButtons cancelUrl={this.props.cancelUrl} loading={this.props.loading} onDelete={this.props.onDelete} onSubmit={this.handleSubmit} rights={["superAdmin", "EditUser"]} deleteRights={"DeleteUser"} />
            ) : (
              <AddButtons
                cancelUrl={this.props.currentPractice && this.props.currentPractice !== "users" ? `/${this.props.currentPractice}/users` : "/users"}
                createAnother={this.props.createAnother}
                loading={this.props.loadingButton}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={["superAdmin", "createpracticeAdm", "createpracticeSup", "createPortalAdm", "createPortalSup", "createAdviser"]}
              />
            )}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

UserForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

UserForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
};

const getAllPortalsForFormQuery = gql`
  query {
    getPortalByAccess {
      id
      name
      slug
    }
  }
`;

const getAllPracticesForFormQuery = gql`
  query($filter: [Int]) {
    getAllPracticesForForm(filter: $filter) {
      practices {
        id
        name
        practiceId
      }
    }
  }
`;

const getAllRolesForFormQuery = gql`
  query {
    getAllRolesForForm {
      roles {
        id
        name
        code
      }
    }
  }
`;

export default compose(
  graphql(getAllPortalsForFormQuery, {
    name: "portalsData",
    options: {
      fetchPolicy: "network-only",
    },
  }),
  graphql(getAllPracticesForFormQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        filter: props.user ? props.user.portalid : null,
      },
    }),
  }),
  graphql(getAllRolesForFormQuery, {
    name: "rolesData",
    options: {
      fetchPolicy: "network-only",
    },
  })
)(Form.create()(UserForm));
