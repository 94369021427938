import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { notification } from "antd";
import { Redirect } from "react-router";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import PracticeForm from "../form";
import _ from "lodash";
import TitlebarWrapper from "../../../components/titlebar/index.style";
import TitleBar from "../../../components/titlebar/titlebar";
import { AccountContext } from "../../../context/AccountContext";

class PracticesAdd extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      // status: "Active",
      portalId: parseInt(this.props.currentPortalId),
    };

    this.props
      .mutate({ variables })
      .then(({ data }) => {
        if (data.createPractice.ok) {
          // localStorage.setItem("current_practice", variables.practiceId);
          this.handleSuccess(data.createPractice.practice.name, variables.practiceId);
          return;
        }

        this.handleError(data.createPractice.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(practiceName, practiceId) {
    notification.success({
      message: "Practice Created Successfully",
      //description: `${practiceName} has been successfully created.`,
    });

    this.props.history.push("/practices");
    return;
    if (this.state.reset === false) {
      this.setState({ redirectTo: `/${practiceId}` });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });
    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    const userContext = this.context;
    const { account } = userContext;
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <TitleBar title="Add Practice" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />

          {/* <Titlebar currentPractice={this.props.currentPractice} displayText="Add Practice" /> */}
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <PracticeForm
              createAnother={this.state.reset}
              companyId={_.get(this.props.match.params, "poid")}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
              toggleLoading={this.toggleLoading}
              gblUserRole={this.props.gblUserRole}
              portalId={parseInt(this.props.match.params.poid)}
              {...this.props}
            />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addPracticeMutation = gql`
  mutation createPractice(
    $name: String
    $notes: String
    $practiceId: String
    $siteLogo: String
    $siteLogoS3Token: String
    $status: String!
    $primaryContactAddress: String
    $primaryContactZipCode: String
    $primaryContactCity: String
    $primaryContactCountry: String
    $primaryContactState: String
    $primaryContactSuite: String
    $primaryContactEmail: String
    $primaryContactFirstName: String
    $primaryContactLastName: String
    $newPracticeEmailTitle: String
    $newPracticeEmailBody: String
    $portalId: Int!
    $adminId: Int
    $assessmentNotification: Boolean
  ) {
    createPractice(
      name: $name
      notes: $notes
      practiceId: $practiceId
      siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      status: $status
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
      newPracticeEmailTitle: $newPracticeEmailTitle
      newPracticeEmailBody: $newPracticeEmailBody
      portalId: $portalId
      adminId: $adminId
      assessmentNotification: $assessmentNotification
    ) {
      ok
      practice {
        name
      }
      errors {
        message
        path
      }
    }
  }
`;

export default graphql(addPracticeMutation)(PracticesAdd);
