import styled from "styled-components";
import { palette } from "styled-theme";
import { mediaQueries } from "../../../utils/mediaQuery";
const CollectPaymentWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  background: white;
  .unitifi-collect-payment-wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    ${mediaQueries("lg")`
      flex-direction: column;
    `}
    .unitifi-annual-monthly-wrapper{
      flex: 1;
      .unitifi-logo-wrapper{
        padding: 45px 20px;
        background: ${palette("primary", 0)};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .unitifi-logo{
          display: inline-flex;
          width: 190px;
          overflow: hidden;
          margin-bottom: 20px;
          ${mediaQueries("lg")`
            width: 170px;
          `}
          ${mediaQueries("md")`
            width: 160px;
          `}
          img{
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        h6{
          font-weight: 600;
          font-size: 22px;
          line-height: 32px;
          color: white;
          margin-bottom: 0;
          text-align: center;
          ${mediaQueries("lg")`
            font-size: 20px;
          `}
          ${mediaQueries("md")`
            font-size: 18px;
          `}
        }
      }
      .unitifi-annual-monthly-tab-wrapper{
        padding: 60px 15px;
        display: flex;
        flex-direction: column;
        height: auto;
        border-right: 1px solid #F2F1F1;
        ${mediaQueries("lg")`
            border-bottom: 1px solid #F2F1F1;
        `}
        .unitifi-max-width-box{
          max-width: 550px;
          width: 100%;
          margin: 0 auto;
          .nav-tabs{
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            margin-bottom: 30px;
            border: none;
            .nav-item{
              width: 50%;
              display: flex;
              justify-content: center;
              button{
                border: none;
                border-bottom: 2px solid transparent;
                padding: 0;
                line-height: normal;
                color: #969696;
                font-size: 16px;
                font-weight: 600;
                &.active{
                  border-color: ${palette("primary", 0)};
                  color: ${palette("primary", 0)};
                }
              }
            }
          }
          .tab-content{
            .tab-pane{
              .annual-monthly-tab-section{
                .annual-header-bar{
                  text-align: center;
                  margin-bottom: 40px;
                  h5{
                    font-size: 26px;
                    font-weight: 600;
                    text-align: center;
                    color: ${palette("base", 8)};
                    ${mediaQueries("xs")`
                      font-size: 23px;
                    `}
                  }
                  p{
                    font-size: 17px;
                    font-weight: 600;
                    text-align: center;
                    color: ${palette("base", 8)};
                  }
                  button{
                    max-width: 300px;
                    margin: auto;
                    width: 100%;
                    padding: 10px;
                    height: auto;
                    font-weight: 600;
                    margin-top: 18px;
                  }
                }
                .annual-monthly-details{
                  text-align: center;
                  button{
                    max-width: 300px;
                    margin: auto;
                    width: 100%;
                    padding: 10px;
                    height: auto;
                    font-weight: 600;
                    margin-top: 18px;
                    &.returnBtn{
                      border-color: ${palette("primary", 0)};
                      color: ${palette("primary", 0)};
                      &:hover{
                        background:${palette("primary", 0)};
                        color: ${palette("base", 0)};
                      }
                    }
                    &.accountType{
                        background:${palette("primary", 0)};
                        color: ${palette("base", 0)};
                    }
                  }
                  .or-text{
                    font-size: 26px;
                    font-weight: 600;
                    text-align: center;
                    color: ${palette("primary", 0)};
                    display: flex;
                    justify-content: center;
                    margin-bottom: 18px;
                  }
                  h4{
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    color: ${palette("base", 8)};
                    display: inline-flex;
                    ${mediaQueries("md")`
                      font-size: 18px;
                    `}
                    button{
                      padding: 0;
                      border: none;
                      outline: none;
                      box-shadow: none;
                      width: 15px;
                      height: 15px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-left: 10px;
                      margin-top: 5px;
                      img{
                        width: 15px;
                        height: 15px;
                      }
                    }
                  }
                  .contact-us-estimate-block{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    h4{
                      margin-top: 25px;
                      font-size: 18px;
                      .unitifi-link-text{
                        margin-right: 10px;
                        color: ${palette("primary", 0)};
                        text-decoration: underline;
                      }
                    }
                  }
                  .checkmark-wrapper{
                    padding:  38px 0 0;
                    max-width: 470px;
                    margin: auto;
                    width: 100%;
                    .checkbox-cmn-label {
                      display: flex;
                      position: relative;
                      padding-left:45px;
                      margin-bottom: 12px;
                      cursor: pointer;
                      font-size: 19px;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      ${mediaQueries("lg")`
                        font-size: 18px;
                      `}
                      ${mediaQueries("md")`
                        font-size: 17px;
                      `}
                      ${mediaQueries("sm")`
                        font-size: 16px;
                      `}
                      input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                        &:checked {
                          ~ {
                            .checkmark {
                              background-color: #006FBA;
                              &:after {
                                display: block;
                              }
                            }
                          }
                        }
                      }
                      // &:hover {
                      //   input {
                      //     ~ {
                      //       .checkmark {
                      //         background-color: #ccc;
                      //       }
                      //     }
                      //   }
                      // }
                      .checkmark {
                        &:after {
                          left: 11px;
                          top: 6px;
                          width: 8px;
                          height: 14px;
                          border: solid white;
                          border-width: 0 3px 3px 0;
                          -webkit-transform: rotate(45deg);
                          -ms-transform: rotate(45deg);
                          transform: rotate(45deg);
                        }
                      }
                      .checkbox-cmn-title{
                        color: #181818;
                        font-size:20px;
                        font-weight:600;
                        line-height:32px;
                        text-align:left;
                        ${mediaQueries("sm")`
                          font-size:18px;
                        `}
                      }
                    }
                    .checkmark {
                      position: absolute;
                      top: 0;
                      left: 0;
                      height: 30px;
                      width: 30px;
                      border-radius: 50%;
                      background-color: #eee;
                      &:after {
                        content: "";
                        position: absolute;
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .unitifi-payment-method-wrapper{
      width: 600px;
      max-width: 100%;
      padding: 100px 20px 60px;
      ${mediaQueries("xxl")`
        width: 550px;
      `}
      ${mediaQueries("xl")`
        width: 420px;
      `}
      ${mediaQueries("lg")`
        width: 100%;
      `}
      ${mediaQueries("md")`
        width: 100%;
        padding: 40px 15px;
      `}
      .unitifi-payment-method-content{
        max-width: 400px;
        margin: 0 auto;
        width: 100%;
        .uni-payment-method-subheader{
          padding-bottom:17px;
          margin-bottom:14px;
          border-bottom: 1px solid rgba(150, 150, 150, 0.35);
          .unitifi-payment-title{
            color: ${palette("base", 8)};
            font-weight:600;
            line-height:30px;
            margin-bottom:6px;
            font-size: 22px;
          }
          p{
            font-size:18px;
            line-height:28px;
            color: ${palette("base", 8)};
            margin: 0;
          }
        }
        ul{
          padding-left:1rem;
          li{
             font-size:18px;
            .text-highlight{
              font-weight:700;
              color:#006FBA;
              &.strikeAmt{
                color:rgba(234,62,62,53%);
                padding:0 5px;
                text-decoration: line-through;
              }
            }
          }
        }
        .unitifi-payment-card-wrapper{
          padding-top: 15px;  
          form{
            .ant-row{
              margin-bottom: 18px;
              padding-bottom: 0;
              label[for=discountcode]{
                &::after{
                  color:unset;
                  content:"";
                }
              }
              label{
                font-size: 15px;
                font-weight: 400;
                position: relative;
                //white-space: nowrap;
                //overflow: hidden;
                //text-overflow: ellipsis;
                width: 100%;
                min-height:46px;
                height:auto;
                &::after {
                  color: #f5222d;
                  content: "*";
                  display: inline-block;
                  font-family: SimSun;
                  font-size: 12px;
                  line-height: 1;
                  margin-left: 4px;
                  top: 2px;
                  vertical-align: super;
              }
              }
              .ant-form-item-control{
                .ant-input{
                  padding: 12px;
                  height: auto;
                }
              }
            }
            .unitifi-login-btn{
              padding: 12px;
              height: auto;
              font-size: 16px;
              font-weight: 500;
              width: 240px;
            }
            .ant-form-item-control-wrapper{
              .StripeElement{
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                color: ${palette("base", 9)};
                padding: 15px;
                min-height: auto;
                height: auto;
                background: ${palette("base", 10)};
                outline: none;
                box-shadow: none;
                border: 1px solid ${palette("base", 10)};
                appearance:none;
                border-radius: 5px;
                .InputElement{
                  font-size: 12px;
                  font-weight: 400;
                }
                &.StripeElement--focus{
                  color: ${palette("base", 9)};
                  border-color: ${palette("primary", 12)};
                  background: ${palette("base", 10)};
                }
                &:hover{
                  color: ${palette("base", 9)};
                  border-color: ${palette("primary", 12)};
                  background: ${palette("base", 10)};
                }
              }
            }
            p{
              font-size:14px;
              line-height:32px;
              color:#181818;
            }
          }
          .ant-form-item-control-wrapper{
            InputElement{
              font-weight: 400;
              font-size: 12px;
              line-height: normal;
              color: ${palette("base", 9)};
              padding: 15px;
              min-height: auto;
              height: auto;
              background: ${palette("base", 10)};
              outline: none;
              box-shadow: none;
              border: 1px solid ${palette("base", 10)};
              appearance:none;
            }
            .StripeElement{
              font-weight: 400;
              font-size: 12px;
              line-height: normal;
              color: ${palette("base", 9)};
              padding: 15px;
              min-height: auto;
              height: auto;
              background: ${palette("base", 10)};
              outline: none;
              box-shadow: none;
              border: 1px solid ${palette("base", 10)};
              appearance:none;
              border-radius: 5px;
              .InputElement{
                font-size: 12px;
                font-weight: 400;
              }
              &.StripeElement--focus{
                color: ${palette("base", 9)};
                border-color: ${palette("primary", 12)};
                background: ${palette("base", 10)};
              }
              &:hover{
                color: ${palette("base", 9)};
                border-color: ${palette("primary", 12)};
                background: ${palette("base", 10)};
              }
            }
          }     
        }
      }
    }
    &.payment-start-today{
      .unitifi-payment-method-wrapper{
        display: none;
      }
    }
  }
`;

export default CollectPaymentWrapper;
