import React, { useContext, useEffect, useState } from "react";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import _ from "lodash";
import gql from "graphql-tag";
import { Button, Modal, notification, Table, Spin, Icon } from "antd";
import { AccountContext } from "../../context/AccountContext";
import { useLazyQuery, useApolloClient } from "react-apollo";
import TitleBar from "../../components/titlebar/titlebar";
import CustomButton from "../../components/commons/CustomButton";
const { confirm } = Modal;

const SupportRole = (props) => {
  const { location, currentPortalId, me } = props;
  const [supportListData, setSupportListData] = useState([]);
  const accountContext = useContext(AccountContext);
  const { ownerPractice } = accountContext;
  const client = useApolloClient();
  const permissions = _.get(accountContext.account, "permissions", []);
  const account = _.get(accountContext, "account", null);

  const [state, setState] = useState({ limit: 10, loading: false, order: "ASC", orderColumn: "", page: 1, search: "", totalCount: null, sortDirection: "" });

  useEffect(() => {
    supportRoleHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
    });
  }, [state.page]);

  const [supportRoleHandler, { loading, data }] = useLazyQuery(
    gql`
      query($limit: Int, $offset: Int, $order: String, $orderColumn: String) {
        supportList(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn) {
          result
          pagination
        }
      }
    `,
    {
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
      fetchPolicy: "network-only",
    }
  );

  function reCall() {
    supportRoleHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;
    let x = (pagination.current - 1) * state.limit;
    setState({
      ...state,
      offset: x,
      limit: pagination.pageSize || 10,
      page: 1,
      order: sorterOrder,
      orderColumn: sorter.field,
      [sorter.columnKey]: sorter.order,
    });

    supportRoleHandler({
      variables: {
        page: 1,
        offset: x,
        limit: pagination.pageSize,
        order: sorterOrder,
        orderColumn: sorter.field,
      },
    });
  }

  const columns = () => {
    const column = [
      {
        sorter: true,
        title: "Name",
        className: "name-column",
        width: 100,
        render: (data) => {
          return (
            <>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  return props.history.push(`/support-roles/view/${data.id}`);
                }}
                className="practicelink-text">
                {data.username}
              </a>
            </>
          );
        },
      },
      {
        dataIndex: "email",
        className: "location-column",
        title: "Email",
        width: 100,
        sorter: true,
      },
  
      {
        dataIndex: "accessType",
        className: "access-column",
        title: "Access",
        width: 50,
        sorter: true,
        render: (text, row) => {
          return <>{text.charAt(0).toUpperCase() + text.slice(1)}</>;
        },
      },
      {
        dataIndex: "groupName",
        className: "group-column",
        title: "Group Name",
        width: 100,
        sorter: true,
        render: (text, row) => {
          return <>{text ? text : "N/A"}</>;
        },
      },
      {
        className: "action-column",
        title: "View",
        //width:40,
        render: (data) => {
          return <Button type={"link"} icon={"eye"} onClick={() => props.history.push(`/support-roles/view/${data.id}`)} />;
        },
      },
  
      {
        className: "action-column",
        title: "Edit",
        //width:40,
        render: (data) => {
          return <Button type={"link"} icon={"edit"} onClick={() => props.history.push(`/support-roles/edit/${data.id}`)} />;
        },
      },
      {
        className: "action-column",
        title: "Delete",
        //width:40,
        render: (data) => {
          return <Button type={"link"} icon={"delete"} onClick={() => deleteHandler(data.id)} />;
        },
      },
    ];
    if(me && (me.rolecode == 'ADVSO' || me.rolecode=='ADVSM')){
      const index = 3;
      if (index > -1) {
        column.splice(index, 1);
      }
    }
    return column;
  }


  const [tableCount,setTableCount] = useState(0);
  let tempTableCount = _.get(data, "supportList.pagination.totalCount", 0);
  useEffect(() => {
    if (!data) return;
  setTableCount(tempTableCount>tableCount || tableCount==undefined?tempTableCount:tableCount);

    setSupportListData(data.supportList.result);
  }, [data]);

  useEffect(() => {
    localStorage.setItem("totalSupportRole", supportListData.length);
  }, [supportListData]);

  let tablePagination = {
    current: state.page,
    defaultPageSize: 10,
    pageSize: state.limit,
    pageSizeOptions: ["10", "20", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    total: tableCount,
  };

  const countStart = (state.page - 1) * state.limit + 1;
  const countEnd = state.page * state.limit > tableCount ? tableCount : state.page * state.limit;
  const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

  const deleteHandler = (id) => {
    confirm({
      title: "Are you sure you want to delete this support role?",
      content: `Once deleted, this support role will no longer have access, and this action cannot be undone.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",

      onOk() {
        return new Promise(async (resolve, reject) => {
          let data = await deleteHandlerSubmit(id);
          resolve();
        }).catch(() => console.log("error"));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteHandlerSubmit = async (id) => {
    let result = await client.mutate({
      mutation: gql`
        mutation($id: Int) {
          deleteSupportUser(id: $id) {
            ok
            message
          }
        }
      `,
      variables: { id: id },
    });
    if (result && result.data.deleteSupportUser.ok) {
      notification.success({
        message: result.data.deleteSupportUser.message,
      });
      reCall();
    }
    return true;
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <TitleBar title="Support Roles" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />

        {/* {accountContext.account && accountContext.account.permissions.includes("createpractice") && ( */}
        <CustomButton
          rights={["superAdmin", "createpractice"]}
          icon="plus"
          href={`/support-roles/create`}
          text="Add New"
          type={"primary"}
          className="addnew-link-btn-wrapper"
          disabled={account && account.rolecode != "ADVSO" && account.rolecode != "ADVSM" ? supportListData.length == 4 : supportListData.length == 1}>
          Add Support Role
        </CustomButton>
        {/* )} */}
      </div>
      {account && account.rolecode != "ADVSO" && account.rolecode != "ADVSM" && (
        <LayoutContentWrapper className="support-roles-note-section">
          <div className="">
            <h5>Support roles are divided into two types: Individual and Group.</h5>
            <ul>
              <li>
                <span>Individual Support Role:</span> If you have a personal assistant or someone who needs access solely for assisting you, add them as an individual support role.
              </li>
              <li>
                <span>Group Support Role:</span> If you need to provide access to a group of advisors, select the group option and checkmark all the advisors you want to give access to.
              </li>
            </ul>
            <h6>Note: You are allotted 1 Individual Support Role and 3 Group Support Roles.</h6>
          </div>
        </LayoutContentWrapper>
      )}
      {account && (account.rolecode == "ADVSO" || account.rolecode == "ADVSM") && (
        <LayoutContentWrapper className="support-roles-note-section">
          <div className="">
            <h5 className="support-text">
              <span>Individual Support Role:</span> If you have a personal assistant or someone who needs access solely for assisting you, add them as an individual support role.
            </h5>
            <h6>Note: You are allotted 1 Individual Support Role</h6>
          </div>
        </LayoutContentWrapper>
      )}
      <LayoutContentWrapper>
        <div className="display-bar">{display}</div>
        <LayoutContent className="practiceTblWrapper">
          <Table
            columns={columns()}
            loading={
              loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }
            }
            locale={{ emptyText: "No support role found." }}
            onChange={handleTableChange}
            dataSource={supportListData}
            rowKey="id"
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
};

export default SupportRole;
