import React, { useEffect, useState } from "react";
import { Result, Button, Spin, Icon, Skeleton } from "antd";
import axios from "axios";
import { apiUrl, assessmentUrl } from "../../config";

const SendAssessment = (props) => {
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (props.match.params.token) sendAssessmentHandler();
  }, [props.match]);

  const sendAssessmentHandler = async () => {
    let result = await axios.get(`${apiUrl}/salesforce-assessment/${props.match.params.token}`, { headers: { "assessment-url": assessmentUrl } });
    if (result && result.data && result.data.ok) setLoading(result.data.message);
    else setLoading("Client not found");
  };
  return (
    <div style={{ marginTop: "5%" }}>
      {/* <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper"></div>
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      </div> */}
      {!loading && (
        <center>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />
        </center>
      )}
      {loading && (
        <Result
          status="success"
          title={loading}
          subTitle=""
          extra={[
            <Button type="primary" key="console" onClick={() => window.close()}>
              CLOSE
            </Button>,
          ]}
        />
      )}
    </div>
  );
};
export default SendAssessment;
