import { Button, notification } from "antd";

import { Input, Menu, Checkbox, Dropdown, Icon } from 'antd';
import gql from "graphql-tag";
import moment from "moment";

import {  Modal } from "react-bootstrap";
import React, { useCallback, useEffect, forwardRef, useRef, useState, useImperativeHandle } from "react";
import tagCloseIcon from "../../assets/img/tag-close-icon.svg";
import TimeAgo from "react-timeago";
import { useApolloClient } from "react-apollo";
import { assessmentUrl } from "../../config";
import { Tabs, Radio, Table } from "antd";
import { useLazyQuery } from "react-apollo";

import dateAssessment from "./dateAssessment";
import _, { forEach } from "lodash";

const InviteAssessment = (props, ref) => {
  const { setAssessmentModal, currentPortalId, gblUserId, user, setTotalAssessment, me } = props;
  const client = useApolloClient();
  const [tags, selectedTags] = useState([]);
  const [menuKey, setMenuKey] = useState([]);

  const [dateRange, setDateRange] = useState( {
    endDate: moment().format("YYYY-MM-DD"),
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
  });
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState(null);
  const selectRef = useRef();
  const [selectedOptions, setSelectedOptions] = useState([]);
  // useImperativeHandle(ref, () => ({
  //   selectAll(inputs) {
  //     searchClientHandler(inputs);
  //   },
  // }));
  useEffect(() => {
    sentViewHandler();
  }, []);
  const searchHandler = (event) => {
    setSearch(event.target.value);

    // setSelectedRowKeys([]);
    sentViewHandler();
  };
  const [sentViewHandler, { loading: sentViewLoading, data: sentViewCompleteData }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getIncompleteAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          advisorId: "",
          // type: assessmentViewType,
          // order: assessmentOrder,
          search: search,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          accountType: me.accountType,
          roleCode: me.rolecode,
          dateRange: dateAssessment.filter((p) => menuKey.includes(p.key)).map(item=>item.dateRange)
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  let sentAssessment = _.get(sentViewCompleteData, "getIncompleteAssessment.clients", []);
  sentAssessment = sentAssessment.map(item => {item.key = item.id; return item;});
  // let totalCount =  _.get(sentViewCompleteData, "getIncompleteAssessment.pagination.totalCount", []);
  useEffect(() => {
    if(totalCount == 0) {
      setTotalCount(sentAssessment.length);
    }
  }, [sentAssessment]);
  const recentlySentColumns = [
    {
      dataIndex: "name",
      title: "NAME",
      className:"tblNameTitle",
      sorter: false,
    },
    // {
    //   dataIndex: "location",
    //   title: "LOCATION",
    //   sorter: false,
    //   render: (data) => (!data.includes("null") ? data : ""),
    // },
    {
      dataIndex: "email",
      title: "EMAIL",
      className:"tblEmailTitle",
      width:250,
      sorter: false,
    },
    // {
    //   dataIndex: "phone",
    //   title: "PHONE",
    //   sorter: false,
    //   render: (data) => (!data.includes("null") ? data : ""),
    // },
    {
      dataIndex: "" ,
      title: "Days Old",
      className:"tblDayOld",
      sorter: false,
      render: (data) =>   <TimeAgo date={data.viewbyClient ? data.viewbyClient : data.dateSent} />,
    },
    // {
    //   render: (data) => <p>Cancel Assessment</p>,
    // },
  ];

  const inviteHandler = async () => {
    if (selectedRowKeys.length == 0) {
      return;
    }
    setLoading(true);
    let variables = {
      clientId: selectedRowKeys.join(","),
      portalId: currentPortalId,
      advisorId: gblUserId,
      practiceId: me.practiceid,
      assessmentUrl: assessmentUrl,
      accountType: me.accountType,
      roleCode: me.rolecode,
    };
    let result = await client.mutate({
      mutation: gql`
        mutation($practiceId: Int!, $portalId: Int!, $clientId: String!, $advisorId: Int!, $assessmentUrl: String!, $accountType: Int, $roleCode: String) {
          sendGenerateAssessment(practiceId: $practiceId, portalId: $portalId, clientId: $clientId, advisorId: $advisorId, assessmentUrl: $assessmentUrl, accountType: $accountType, roleCode: $roleCode) {
            ok
            message
            errors {
              path
              message
            }
          }
        }
      `,
      variables: variables,
    });
    setLoading(false);

    if (result.data.sendGenerateAssessment.ok) {
      notification.success({ message: result.data.sendGenerateAssessment.message });
      setAssessmentModal(false);
    } else notification.error({ message: result.data.sendGenerateAssessment.errors[0].message });
  };

  
  // const searchClientHandler = async (input) => {
  //   if (typeof input != "string") {
  //     selectRef.current.setValue([input]);
  //     return;
  //   }
  //   let result = await searchClientListHandler(input);
  //   return result;
  // };

  // const searchClientListHandler = async (input) => {
  //   if (input.trim().length <= 1) return;

  //   let result = await client.query({
  //     query: gql`
  //       query($practiceId: String, $search: String, $roleCode: String, $accountType: Int, $portalId: Int) {
  //         searchClient(practiceId: $practiceId, search: $search, roleCode: $roleCode, accountType: $accountType, portalId: $portalId) {
  //           clients
  //           total
  //         }
  //       }
  //     `,
  //     variables: {
  //       search: input.trim(),
  //       practiceId: me.practiceid.toString(),
  //       portalId: currentPortalId,
  //       accountType: me.accountType,
  //       roleCode: me.rolecode,
  //     },
  //   });
  //   let total = result.data.searchClient.total;
  //   setTotalAssessment(total);
  //   result = result.data.searchClient.clients;

  //   return result.map((data) => ({
  //     label: data.firstName + " " + data.lastName,
  //     value: data.id,
  //   }));
  // };

  // const searchClientSelectHandler = (data) => {
    // data = data.map((c) => c.value);
    // selectedTags(data);
  // };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const clients = sentAssessment.filter(item => newSelectedRowKeys.includes(item.id));
    if(!selectedClient.length) {
      setSelectedClient([...clients]);
    } else {
      var arr = [...selectedClient,...clients];
      var filteredArray = arr.filter((item, pos) => 
         arr.findIndex(obj => obj.id === item.id )== pos 
      );
      filteredArray = filteredArray.filter(item => newSelectedRowKeys.includes(item.id));
      
      setSelectedClient(filteredArray);
    }
   
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const removeFromSelection = (id) => {
    setSelectedRowKeys(selectedRowKeys.filter(item => item != id));
    setSelectedClient(selectedClient.filter(item => item.id != id));
  }
  const onDateMenuClick = ( key ) => {

    const dates = dateAssessment.find((p) => p.key === key).dateRange;
    const label = dateAssessment.find((p) => p.key === key).label;

    
    if(label == "Select All") {
      
      if(menuKey.includes(key)) {
        setMenuKey([]);        
      } else {
        setMenuKey([ ...dateAssessment.map(item => item.key)]);
      }
    } else {
      if(menuKey.includes(key)) {
        const temp = menuKey.filter(item => item != key );
        setMenuKey(temp.filter(item => item != 1));
      } else {
        
        setMenuKey([...menuKey, key]);
      }
      
    }
    // setSelectedRowKeys([]);
    sentViewHandler();
    

    // this.fetchData({
    //   filters: {
    //     ...filters,
    //     ...{ dateRange: datePresets.find((p) => p.key === key).dateRange },
    //   },
    // });
  }
  return (
    <div>
      <Modal.Body>
      <div className="unitifi-invite-practice-modal-wrapper">
      <div className="d-flex align-items-center justify-content-between">
      <h6 className="modalHeadingTitle mb-0">Assessment Reminder</h6>
      <Dropdown overlayClassName="reminderBox" className="reminderDropdown" overlay={   <Menu className="menuOption" selectedKeys={[menuKey]}>
                      {dateAssessment.map(({ key, label }) => (
                        <Menu.Item key={key} ><Checkbox onClick={(e) => {e.stopPropagation();onDateMenuClick(key);}} checked={menuKey.includes(key)}>{label}</Checkbox></Menu.Item>
                      ))}
                    </Menu>} open={true} >
                    <Button size="large">
                    {menuKey.length > 0 ? menuKey.length>1 ? "Multiple" : dateAssessment.find((p) => p.key === menuKey[0]).label :"Date Sent"} <Icon type="down" />
      </Button>

      </Dropdown>
   
      </div>
      {selectedRowKeys.length > 0 && (
    <div className="tagboxWrapper">
      <ul id="tags" className="list-unstyled">
        {selectedClient.length == totalCount && selectedClient.length > 0 && (
          <li key="all" className="tag">
          <span className="tag-title">Send assessment to all clients in date range ({selectedClient.length}) </span>
          <span className="tag-close-icon" onClick={() =>setSelectedRowKeys([]) }>
              <i>
                  <img src={tagCloseIcon} />
              </i>
          </span>
        </li>
        ) }
        {selectedClient.length != totalCount && selectedClient.length > 0 &&
          selectedClient.map((tag, index) => {
            return(
            <li key={tag.id} className="tag">
              <span className="tag-title">{tag.name}</span>
              <span className="tag-close-icon" onClick={() => removeFromSelection(tag.id)}>
                  <i>
                      <img src={tagCloseIcon} />
                  </i>
              </span>
            </li>
          )})}
      </ul>
    </div>)}
      <input type="text" onChange={(e) => searchHandler(e)} placeholder="Search Clients With Incomplete Assessments" className="clientSearchInput mt-2" />
        {/* <AsyncSelect ref={selectRef} placeholder="Search Client" isMulti={true} loadOptions={searchClientHandler} onChange={(data) => searchClientSelectHandler(data)} className="unitifi-select-container" classNamePrefix="unitifi-react-select" /> */}
       
      </div>
     { sentAssessment.length ? (
      <Table className="assessmentTblWrapper" rowSelection={rowSelection}  columns={recentlySentColumns} loading={sentViewLoading} dataSource={sentAssessment} rowKey={(record) => record.id} pagination={false} scroll={{ y:selectedRowKeys.length > 0 ? 370 :450}} />) : ""}
      </Modal.Body>
      <Modal.Footer>
      <div className="modal-footer-bnts-wrapper">
        <Button type="primary" htmlType="button" className="unitifi-login-btn me-2" onClick={() => inviteHandler()} disabled={loading} loading={loading}>
          Send Reminder
        </Button>
        <Button type="primary" htmlType="button" className="unitifi-login-btn unitifi-cancel-btn" onClick={() => setAssessmentModal(false)}>
          Cancel
        </Button>
      </div>
      </Modal.Footer>
    </div>
  );
};

export default forwardRef(InviteAssessment);
