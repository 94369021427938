import styled from "styled-components";
import { palette } from "styled-theme";

export const SelectWrapper = styled.span`
  color: grey;
`;

export const SelectMainWrapper =styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
`
