import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssessmentsList from '../../../containers/assessment/index';

class AssessmentRoutes extends React.Component {
  render() {
    const {
      gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice,
      gblUserIsPortalAdmin,
    } = this.props;

    return (
      <Switch>
        <Redirect
          exact
          from="/assessments/"
          to="/assessments"
          strict
        />
        <Route
          exact
          path="/assessments"
          render={props => (
            <AssessmentsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
      </Switch>
    );
  }
}

export default AssessmentRoutes;
