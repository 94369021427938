import styled from "styled-components";
import { palette } from "styled-theme";

const ClientViewWrapper = styled.div`
  padding: 24px 24px 0;
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }

  .main-chart-wrapper {
    display: flex;
    @media only screen and (max-width: 1499px) {
      margin-bottom:-10px;
    }
    @media only screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      margin-bottom:0;
    }

    .left-col {
      padding-right: 12px;
      width: calc(100% / 3 * 1);

      @media only screen and (max-width: 1024px) {
        padding-right: 0;
        width: 100%;
      }

      .item {
        background: ${palette("base", 0)};
        border: 1px solid rgb(245, 245, 245);
        border-radius: 4px;
        margin: 24px 0;
        /* padding: 24px; */

        &:first-child {
          margin-top: 0;

          @media only screen and (max-width: 1024px) {
            margin-top: 24px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .calloutLbl {
          font-size: 26px;
          font-weight: 600;
          color: #006fba;
          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
          @media only screen and (max-width: 1399px) {
            font-size: 20px;
          }
          &.compareLbl {
            color: #cb9120;
          }
        }

        .details-wrapper {
          padding: 24px;
          @media only screen and (max-width: 1199px) {
            padding: 18px;
          }
          &.chartWrapper {
            @media only screen and (max-width: 1499px) {
              padding: 12px;
            }
            @media only screen and (max-width: 1024px) {
              padding: 24px;
            }
            @media only screen and (max-width: 575px) {
              padding: 10px;
            }
          }
          &.behavior-defined-box-wrapper{
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            .score-item-wrapper{
              margin: 0;
              border-right: 1px solid #dee4e7;
              &:last-child{
                border-right: 0;
              }
              .score-item{
                padding: 9px 18px;
                text-align: flex-start;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-bottom: 1px solid #dee4e7;
                max-width: 100%;
                width: 100%;
                &:last-child{
                  border-bottom: 0;
                }
                .risk-behavior-score{
                  line-height: normal;
                  width: 55px;
                }
                .scoreLabel{
                  flex: unset;
                  text-align: start;
                  width: 132px;
                }
              }
            }
          }
        }
        .behavior-defined-box-wrapper{
          display: flex;
          .behavior-defined-text{
            flex: 0 0 50%;
            border-right: 1px solid #efefef;
            &:last-child{
              border-right: 0;
            }
            .behavior-text{
              padding: 12px 18px;
              border-bottom: 1px solid #efefef;
              &:last-child{
                border-bottom: 0;
              }
              h4{
                font-size: 16px;
                color: #6c6c6c;
                margin: 0;
                align-items: center;
                display: flex;
                flex: 1;
                @media only screen and (max-width: 1400px) {
                  flex-direction: column;
                  text-align: center;
                }
                div{
                  text-align: end;
                  display: flex;
                  justify-content: flex-end;
                  @media only screen and (max-width: 1400px) {
                    text-align: center;
                    justify-content: center;
                  }
                }
                span{
                  color: rgba(0, 111, 186, 1);
                  font-size: 40px;
                  font-weight: 600;
                  line-height: 44px;
                  width: 55px;
                  text-align: end;
                  margin-right: 10px;
                  text-align: end;
                  display: flex;
                  justify-content: flex-end;
                  @media only screen and (max-width: 1561px) {
                    font-size: 30px;
                    line-height: 30px;
                  }
                  @media only screen and (max-width: 1400px) {
                    width: auto;
                    text-align: center;
                    margin-right: 0px;
                  }
                  @media only screen and (max-width: 1475px) {
                    text-align: end;
                  }
                  @media only screen and (max-width: 1199px) {
                    font-size: 25px;
                    line-height: 25px;
                  }
                  @media only screen and (max-width: 991px) {
                    font-size: 35px;
                    line-height: 35px;
                  }
                }
              }
            }
            &.behavior-yellow-text{
              .behavior-text{
                h4{
                  span{
                    color: #cb9120;
                  }
                }
              }
            }
          }
        }
       
        .heading {
          border-bottom: 1px solid rgba(238, 242, 244, 1);
          font-size: 16px;
          font-weight: 600;
          padding: 12px 18px 12px;
          color: #181818;
        }

        .label {
          /* font-size: 16px; */
        }

        .score-item-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          flex: 0 0 50%;
          margin-bottom: 10px;
          // position:relative;
          &::before {
            // content:'';
            // position:absolute;
            height: 120px;
            border: 1px solid #ececec;
            left: -15px;
            @media only screen and (max-width: 1699px) {
              left: 0;
            }
            @media only screen and (max-width: 1599px) {
              left: 10px;
            }
            @media only screen and (max-width: 1099px) {
              left: 5px;
            }
            @media only screen and (max-width: 575px) {
              left: 0;
            }
          }
          &:first-child {
            &::before {
              content: unset;
            }
          }
          .slash {
            color: rgba(238, 242, 244, 1);
            font-size: 80px;
            font-weight: 200;
            line-height: 80px;
            margin-top: -15px;
          }

          .score-item {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1475px) {
              flex-direction: column;
            }
            &.leftScore {
              //max-width:190px;
              width: 100%;
              .scoreLabel {
                font-size: 18px;
                color: #6c6c6c;
                margin-left: 10px;
                flex: 1;
                @media only screen and (max-width: 1561px) {
                  font-size: 16px;
                }
                @media only screen and (max-width: 1475px) {
                  margin-left:0;
                }
              }
            }
            &.rightScore {
              //max-width:190px;
              width: 100%;
              @media only screen and (max-width: 1475px) {
                max-width: 180px;
              }
              .scoreLabel {
                font-size: 18px;
                color: #6c6c6c;
                margin-left: 10px;
                flex: 1;
                @media only screen and (max-width: 1561px) {
                  font-size: 16px;
                }
                @media only screen and (max-width: 1475px) {
                  margin-left:0;
                }
              }
            }
            &:first-child {
              /* margin-right: 16px; */
            }

            .risk-behavior-score {
              color: rgba(0, 111, 186, 1);
              font-size: 40px;
              font-weight: 600;
              line-height: 44px;
              width: 80px;
              text-align: end;
              @media only screen and (max-width: 1561px) {
                font-size: 30px;
                line-height: 30px;
              }
              @media only screen and (max-width: 1475px) {
                text-align: center;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 25px;
                line-height: 25px;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 35px;
                line-height: 35px;
              }
              &.compareScore {
                color: #cb9120;
                text-align: end;
                width: 80px;
                @media only screen and (max-width: 1475px) {
                  text-align: center;
                }
              }
            }

            .self-reported-score {
              font-size: 40px;
              /* font-weight: 600; */
              line-height: 44px;
            }

            .selfReportedScore {
              font-size: 40px;
              font-weight: 600;
              line-height: 44px;
              color: #006fba;
              width: 80px;
              text-align: end;
              @media only screen and (max-width: 1561px) {
                font-size: 30px;
                line-height: 30px;
              }
              @media only screen and (max-width: 1475px) {
                text-align: center;
              }
              &.compareReport {
                color: #cb9120;
                text-align: end;
                width: 80px;
                @media only screen and (max-width: 1475px) {
                  text-align: center;
                }
              }
              @media only screen and (max-width: 1399px) {
                font-size: 30px;
                line-height: 30px;
              }
              @media only screen and (max-width: 1199px) {
                font-size: 25px;
                line-height: 25px;
              }
              @media only screen and (max-width: 1024px) {
                font-size: 35px;
                line-height: 35px;
              }
            }

            .score-label {
              font-size: 14px;
            }
          }
          .item-divider {
            border: 1px solid rgba(237, 237, 237, 0.5);
            width: 100%;
            margin: 10px 0;
            @media only screen and (max-width: 1399px) {
              //margin-bottom:5px;
            }
            @media only screen and (max-width: 575px) {
              width: 150px;
            }
          }
        }
        .seperator {
          border: 1px solid rgba(238, 242, 244, 1);
          width: 1px;
          height: 120px;
        }
      }
    }

    .right-col {
      padding-left: 12px;
      width: calc(100% / 3 * 2);

      @media only screen and (max-width: 1024px) {
        padding-left: 0;
        width: 100%;
      }

      .chart-legend {
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgb(245, 245, 245);
        border-bottom: none;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        display: flex;
        justify-content: center;
        padding: 16px 0 8px;
        @media only screen and (max-width: 1499px) {
          padding: 10px 0 8px;
        }
      }

      .chart-wrapper {
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgb(245, 245, 245);
        border-radius: 2px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 8px 24px 24px;
        height: calc(100% - 50px);
        @media only screen and (max-width: 1499px) {
          height: calc(100% - 54px);
        }
        @media only screen and (max-width: 1399px) {
          height: calc(100% - 55px);
        }
        @media only screen and (max-width: 1199px) {
          height: calc(100% - 57px);
        }
        @media only screen and (max-width: 1057px) {
          height: calc(100% - 55px);
        }
        @media only screen and (max-width: 575px) {
          padding: 8px 12px 12px;
        }
        > div {
          margin-top: 20px;
          @media only screen and (max-width: 1561px) {
            margin-top: -10px;
          }
          @media only screen and (max-width: 1499px) {
            margin-top: -16px;
          }
          @media only screen and (max-width: 1401px) {
            margin-top: 20px;
          }
          @media only screen and (max-width: 1199px) {
            margin-top: 0;
          }
          @media only screen and (max-width: 1057px) {
            margin-top: 28px;
          }
          @media only screen and (max-width: 1024px) {
           margin-top: 80px;
          }
        }
      }
    }
  }

  .main-wrapper {
    background: ${palette("base", 0)};
    border: 1px solid rgb(245, 245, 245);
    border-radius: 4px;
    margin-top: 24px;
    /* padding: 24px; */

    .heading {
      border-bottom: 1px solid rgba(238, 242, 244, 1);
      font-size: 16px;
      font-weight: 600;
      padding: 12px 20px 12px;
      color: #181818;
    }

    .wrapper {
      &.has-below {
        margin-bottom: 24px;
      }
    }

    .item-wrapper {
      padding: 24px;
      @media only screen and (max-width: 575px) {
        padding: 15px;
      }
      &.firstCol {
        max-width: 860px;
        width: 100%;
      }
      &.secondCol {
        position: relative;
        max-width: 860px;
        width: 100%;
        &::before {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          border-left: 1px solid #f0f2f5;
          width: 1px;
          height: 95%;
          margin: 15px -20px;
          @media only screen and (max-width: 767px) {
            border-left: none;
            border-top: 1px solid #f0f2f5;
            width: 95%;
            margin: 0;
          }
        }
      }

      .clientTitle {
        font-size: 20px;
        font-weight: 600;
        color: #006fba;
        &.secondClient {
          color: #cb9120;
        }
      }
    }

    .item-wrapper-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-flex {
        flex-direction: column;
        display: flex;
        text-align: center;
        width: 20%;
        background: rgb(255, 255, 255);
        border-right: 1px solid rgb(238, 242, 244);
        border-bottom: 1px solid rgb(238, 242, 244);
        padding: 24px;
        @media only screen and (max-width: 1125px) {
          width: 50%;
          border-top: 1px solid rgb(238, 242, 244);
          &:nth-child(-n + 2) {
            border-bottom: 1px solid rgba(238, 242, 244, 1);
          }
          &:nth-child(2) {
            border-right: none;
          }
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          &:nth-child(-n + 3) {
            border-bottom: 1px solid rgba(238, 242, 244, 1);
            border-right: none;
          }
        }
        &:last-child {
          border-right: none;
        }
        .item-flex-heading {
          font-weight: 600;
          padding-bottom: 4px;
          color: #181818;
          &.clientTitle {
            font-size: 20px;
            font-weight: 600;
            color: #006fba;
          }
          &.secondClient {
            font-size: 20px;
            font-weight: 600;
            color: #cb9120;
          }
        }
      }
    }

    .itemDivider {
      border: 1px solid #ededed;
      width: 1px;
      height: 500px;
      margin: 30px 0 auto;

      @media only screen and (max-width: 991px) {
        height: 750px;
      }
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    .item {
      &.has-below {
        margin-bottom: 24px;
      }

      .item-heading {
        font-weight: 600;
        padding-bottom: 4px;
        color: #181818;
      }
    }

    .attributeBtnWrapper {
      display:flex;
      flex-wrap:wrap;
      margin-bottom: 14px;
      .attributeData {
        cursor: pointer;
        border: 1px solid #006fba;
        border-radius: 80px;
        padding: 5px 10px;
        //height: 30px;
        color: #006fba;
        font-size: 16px;
        background: #fff;
        margin-left: 20px;
        @media only screen and (max-width: 480px) {
          margin-left:5px;
          margin-bottom:10px;
        }
        &.active {
          background: #006fba;
          color: #fff;
          font-weight: 600;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .attribute-text {
      margin-bottom: 21px;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      .circle {
        background: rgba(0, 111, 186, 1);
        border-radius: 4px;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-left: 4px;
        margin-right: 4px;
        &.min {
          background: #6fb6e0;
        }
      }
    }
  }

  .ant-collapse {
    > .ant-collapse-item {
      > .ant-collapse-header {
        color: ${palette("text", 1)};

        .arrow {
          color: ${palette("text", 2)};
        }
      }
    }
  }
  .unitifi-behavior-attributes-section {
    .unitifi-behavior-attributes-box {
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 180px;
      background: #054471;
      text-align: center;
      text-decoration: none;
      border-radius: 3px;
      margin-bottom: 26px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &.blue-box {
        background: #589bcb;
      }
      .icons-and-details {
        display: flex;
        align-items: center;
        flex-direction: column;
        i {
          display: block;
          width: 75px;
          height: 75px;
          overflow: hidden;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        p {
          font-size: 15px;
          font-weight: 600;
          color: white;
          margin-bottom: 8px;
        }
      }
    }
  }
`;

export default ClientViewWrapper;
