import { Button, Col, Form, Modal, Row } from "antd";
import React from "react";
import Select from "react-select";
import FilterDelImg from "../../assets/img/delete-filter.png";

import { FormWrapper } from "../form";
const FormItem = Form.Item;

const FilterClient = ({ advisors, form, setFilterType, setFilterModal, filterType, me, onAdvisorChange }) => {
  const { getFieldProps, getFieldDecorator, getFieldValue, resetFields } = form;

  advisors = [...advisors.map((data) => ({ label: `${data.firstName} ${data.lastName}`, value: data.id })), {label: 'Unassigned', value: 0}];

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let variable = {};
        if (values.advisorType.length > 0) variable.advisorType = values.advisorType.map((data) => data.value);
        setFilterType(variable);
        setFilterModal(false);
        onAdvisorChange(variable);
      }
    });
  };

  const resetFormHandler = (type = "cancel") => {
    resetFields();
    setFilterType(null);
    if (type == "cancel") setFilterModal(false);
  };

  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={10}>
          <Col span={24}>
            <FormItem label="Advisor">
              {getFieldDecorator("advisorType", { initialValue: (filterType.length > 0 && advisors.filter((data) => filterType.includes(data.value))) || "" })(
                <Select isMulti options={advisors} placeholder="Select Advisor" className="modal-filter-multi-select" classNamePrefix="unitifi-react-select" />
              )}
            </FormItem>
          </Col>
        </Row>
        {filterType.length > 0 && (
          <div class="mb-3 d-flex justify-content-end">
            <span className="filteText">
              <a href="#" onClick={() => resetFormHandler("clear")}>
                Clear Filters
                <img src={FilterDelImg} />
              </a>
            </span>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Apply Filter
          </Button>
          <Button className="ms-3" onClick={() => resetFormHandler()}>
            Cancel
          </Button>
        </div>
      </Form>
    </FormWrapper>
  );
};

export default Form.create()(FilterClient);
