import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Form, Icon, Input, notification } from 'antd';
import { Redirect } from 'react-router';
import IntlMessages from '../../../../helpers/intlMessages';
import { FormWrapper } from '../../../../components/form';
import PublicContent from '../../../../layouts/public';
/**
 * handles sending of a password reset email &
 * redirects user to login page
 */
const FormItem = Form.Item;

class UsersPasswordForgot extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
      submitting: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { form } = this.props;

    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        const { email } = values;

        const response = await this.props.mutate({
          variables: { email },
        });

        const {
          ok, errors,
        } = response.data.sendPassworReset;

        /**
         * if the user exists store a token & refreshToken
         * in the user's localStorage, send to dashboard
         * if user does not exist send errors to form based
         * on type
         */
        if (ok) {
          this.handleSuccess(email);
        } else {
          this.setState({ submitting: false });
          this.handleError(errors);
        }
      }
    });
  }

  /**
   * Display success notification and redirect.
   */
  handleSuccess(userEmail) {
    notification.success({
      message: 'Password Reset Sent Successfully',
      //description: `A password reset email has been sent to ${userEmail}.`,
    });

    this.setState({ redirectTo: '/login' });
  }

  /**
   * Display error message.
   * @param {Array} errors
   */
  handleError(errors) {
    const errorMessages = errors.map(err => err.message);

    if (errorMessages.includes('Email not found')) {
      // notification.error({
      //   message: 'Email Not Found',
      //   description: 'There’s no account associated with this email. Please contact your practice administrator for assistance.',
      // });
      
      const { form } = this.props;
      form.setFields({
        email: {
          value: form.getFieldValue('email'),
          errors: [new Error('There’s no account associated with this email.')],
        },
      });
    } else {
      notification.error({
        message: 'An Error Has Occurred',
        description: errorMessages.join(', '),
      });
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <PublicContent className="stretch-content unitifi-login-wrapper">
        <div className="public-form-wrapper">
          <FormWrapper className="public-form">
            <div className='unitifi-login-header-bar'>
              <Link to="/login" className='unitifi-back-btn-link'>
                <Icon className="uni-back-icon" type="arrow-left" />
                <IntlMessages id="login.backToLogin" />
              </Link>
              <p className='login-detail-text'>Please enter your email address and a link will be sent to reset your password.</p>
            </div>
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <FormItem label="Email Address">
                {getFieldDecorator('email', {
                  rules: [{
                    message: 'Email is required',
                    required: true,
                  }],
                })(<Input />)}
              </FormItem>
              <div className='unitifi-footer-wrapper unitifi-resetpw-btn-wrapper'>
                <Button
                  htmlType="submit"
                  loading={this.state.submitting}
                  onClick={this.handleSubmit}
                  type="primary"
                  className='unitifi-login-btn'
                >
                  <IntlMessages id="login.sendPasswordResetEmail" />
                </Button>
              </div>
            </Form>
           
            {
              this.state.redirectTo !== null && (
                <Redirect to={this.state.redirectTo} />
              )
            }
          </FormWrapper>
        </div>
      </PublicContent>
    );
  }
}

UsersPasswordForgot.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

const sendPassworResetMutation = gql`
  mutation sendPassworReset($email: String!) {
    sendPassworReset(email: $email) {
      ok
      errors {
        message
      }
    }
  }
`;

export default graphql(sendPassworResetMutation)(Form.create()(UsersPasswordForgot));
