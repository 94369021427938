import React, { useContext, useRef, useState } from "react";
import { Form, Input, Button, message, notification } from "antd";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import decode from "jwt-decode";
import { graphql, useMutation } from "react-apollo";
import PublicContent from "../../../layouts/public";
import { FormWrapper } from "../../../components/form";
import { useEffect } from "react";
import { AccountContext } from "../../../context/AccountContext";

const InputGroup = Input.Group;

const Signup2fa = (props) => {
  const { form, mutate, history, location, data, user } = props;
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const userContext = useContext(AccountContext);
  const email = location && new URLSearchParams(location.search).get("email");
  const [queryParam, setQueryParam] = useState({ account_type: null, role_id: null, practice_id: null, company_id: null, advisor_id: null, email: email, memberCompanyName: null, utype: null });
  const [state, setState] = useState({
    visible: false,
    accept: false,
    verify: false,
    loading: false,
  });
  const [timer, setTimer] = useState(59);
  const [running, setRunning] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isError, setError] = useState(null);
  const otpInputs = useRef([]);

  useEffect(() => {
    if (!location) return;

    let accountType = new URLSearchParams(location.search).get("account_type");
    if (!accountType || (accountType != 1 && accountType != 2)) {
      history.push("/login");
      return;
    }

    let obj = queryParam;
    let roleId = new URLSearchParams(location.search).get("role_id");
    let practiceId = new URLSearchParams(location.search).get("practice_id");
    let companyId = new URLSearchParams(location.search).get("company_id");
    let advisorId = new URLSearchParams(location.search).get("advisor_id");
    let memberCompanyName = new URLSearchParams(location.search).get("memberCompanyName");
    let utype = new URLSearchParams(location.search).get("utype");
    if (roleId) obj.role_id = roleId;
    if (roleId) obj.practice_id = practiceId;
    if (companyId) obj.company_id = companyId;
    if (advisorId) obj.advisor_id = advisorId;
    if (memberCompanyName) obj.memberCompanyName = memberCompanyName;
    if (utype) obj.utype = utype;

    obj.account_type = accountType;
    setQueryParam(obj);
  }, [location.search]);

  useEffect(() => {
    if (running) {
      if (timer == 0) {
        setRunning(false);
        setTimer(59);
        return;
      }
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [running, timer]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    if (value !== "" && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }

    setOtp(newOtp);
    if (isError) setError(null);
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputs.current[index - 1].focus();
    }
    const isEmpty = otp.every((digit) => digit === "");
    if (isEmpty) {
      // setErrorShow("");
    }
    if (event.key == "Enter") handleSubmit();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const pastedDigits = pastedData.split("").slice(0, otp.length);
    const newOtp = [...otp];
    pastedDigits.forEach((digit, index) => {
      if (index < otp.length) {
        if (/[0-9]/.test(digit)) {
          newOtp[index] = digit;
        }
      }
    });
    setOtp(newOtp);
    if (!newOtp.includes("")) {
      const nextIndex = Math.min(otp.length - 1, pastedDigits.length - 1);
      if (otpInputs.current[nextIndex]) {
        otpInputs.current[nextIndex].focus();
      }
      if (isError) setError(null);
    }
  };

  const [organizationSave, { data: orgData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($id: Int, $utype: String) {
      organizationJoinRequestByUser(id: $id, utype: $utype) {
        ok
        error
        message
      }
    }
  `);

  const [resendCode, { data: codeData, loading: codeLoading, error: codeError }] = useMutation(gql`
    mutation($email: String) {
      resendVerifyCode(email: $email) {
        ok
        message
      }
    }
  `);

  useEffect(() => {
    if (!orgData) return;
    let data = orgData.organizationJoinRequestByUser;
    if (data.ok) {
      notification.success({ message: data.message });
      history.push("/settings/account");
    } else {
      notification.error({ message: data.error });
    }
  }, [orgData]);

  const handleSubmit = async (e) => {
    if(e)
      e.preventDefault();
    form.validateFields(async (err, values) => {
      if (otp.includes("")) {
        setError("OTP is required");
        return;
      }
      // if (values.otp == undefined || values.p2 == undefined || values.p3 == undefined) {
      //   form.setFields({
      //     otp: {
      //       value: values.otp,
      //       errors: [new Error("Phone is required")],
      //     },
      //   });
      //   return;
      // }
      // let code = `${values.p1}${values.p2}${values.p3}${values.p4}`;
      setState({ ...state, loading: true });
      if (!err) {
        await mutate({
          variables: { email: email, code: otp.join("") },
        }).then(function(result) {
          if (result && result.data && result.data.checkVerifyCode && result.data.checkVerifyCode.errors) {
            result.data.checkVerifyCode.errors.forEach((element) => {
              message.error(element.message);
            });
            setState({ ...state, loading: false });
            return;
          }

          const {
            checkVerifyCode: { id, userExisted, token, email, refreshToken, userPermissions, portalExisted, message },
          } = result.data || {
            checkVerifyCode: {
              id: null,
              userExisted: false,
              token: "",
              refreshToken: "",
              portalExisted: false,
            },
          };

          if (id) {
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            userContext.setUserPermission(userPermissions);
            const { user } = decode(token);
            if (user.portals && user.portals.length > 0) {
              localStorage.setItem("current_company_id", user.portals[0].id);
              localStorage.setItem("current_company", user.portals[0].slug);
            }
            if (user.practices && user.practices.length > 0) {
              localStorage.setItem("current_practice_id", user.practices[0].id);
              localStorage.setItem("current_practice", user.practices[0].practiceId);
            }
            if (queryParam.account_type == "1") history.push(`/collect-payment`);
            else if (queryParam.account_type == "2") {
              if (queryParam.practice_id || queryParam.company_id) {
                history.push("/news-center");
                return;
              }
              setTimeout(() => {
                if (queryParam.memberCompanyName && queryParam.utype) organizationSave({ variables: { id: parseInt(queryParam.memberCompanyName), utype: queryParam.utype } });
              }, 200);
            } else history.push("/login");
            return;
          } else {
            if (message) {
              setError(message);
            }
          }
          setState({ ...state, loading: false });
        });
      }
    });
  };

  const resendCodeHandler = async () => {
    setRunning(true);
    const result = await resendCode({ variables: { email } });
  };

  const cancelHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("current_company");
    localStorage.removeItem("current_practice");
    localStorage.removeItem("current_practice_id");
    localStorage.removeItem("current_company_id");
    userContext.setAccount(null);
    userContext.setProfile(null);
    userContext.setLogoUpdate(null);
    userContext.setUserPermission([]);
    history.push("/signup_new");
  };

  return (
    <PublicContent className="stretch-content unitifi-login-wrapper unitifi-signup-wrapper">
      <div className="public-form-wrapper">
        <FormWrapper className="public-form">
          <div className="unitifi-login-header-bar">
            <h5 className="unitifi-2fa-heading">Two-Factor Authentication</h5>
            <span className="unitifi-code-notification-text">{email && `We have sent the code to ${email}`}</span>
          </div>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <div className="code-number-input-box">
              {otp.map((digit, index) => (
                <div className="input-box-wrapper" key={index}>
                  <Input
                    className={`unitifi-code-input-box ${isError && !otp.includes("") ? "error-code-text" : ""}`}
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "0")}
                    value={otp[index]}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(e)}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    ref={(input) => (otpInputs.current[index] = input)}
                  />
                </div>
              ))}

              {/* <div className="input-box-wrapper">
                {getFieldDecorator("p2", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p1", "p2", "p3");
                    }}
                  />
                )}
              </div>

              <div className="input-box-wrapper">
                {getFieldDecorator("p3", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p2", "p3", "p4");
                    }}
                  />
                )}
              </div>

              <div className="input-box-wrapper">
                {getFieldDecorator("p4", {
                  rules: [
                    {
                      required: true,
                      message: "Phone is required",
                    },
                  ],
                })(
                  <Input
                    className="unitifi-code-input-box error-code-text"
                    size={"large"}
                    maxLength={1}
                    placeholder="0"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onKeyUp={(event) => {
                      autoFocusPhone(event, "p2", "p3", "p4");
                    }}
                  />
                )}
              </div> */}
            </div>
            {isError && <p className="error-code-text text-center mt-2 text-danger"> {isError}</p>}
            <div className="code-footer-text">
              {running && (
                <p className="code-success-text-wrapper">
                  <img src="/img/code-check-icon.svg" className="me-2" />
                  Code resent successfully! You can resend again in {timer}s.
                </p>
              )}
              {!running && (
                <p className="mb-0">
                  Did not receive code? <span onClick={() => resendCodeHandler()}>Resend code</span>
                </p>
              )}
            </div>
            <div className="unitifi-footer-wrapper code-footer-btn-wrapper">
              <Button htmlType="button" type="primary" className="unitifi-login-btn-border" onClick={() => cancelHandler()}>
                Cancel
              </Button>
              <Button htmlType="submit" type="primary" className="unitifi-login-btn" disabled={state.loading} loading={state.loading}>
                Continue
              </Button>
            </div>
          </Form>
        </FormWrapper>
      </div>
    </PublicContent>
  );
};

const loginUserMutation = gql`
  mutation($code: String, $email: String) {
    checkVerifyCode(code: $code, email: $email) {
      ok
      message
      id
      token
      refreshToken
      userExisted
      portalExisted
      userPermissions {
        id
        slug
      }
      errors {
        path
        message
      }
    }
  }
`;

export default compose(graphql(loginUserMutation))(Form.create()(Signup2fa));
