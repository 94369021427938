import styled from 'styled-components';

const ButtonBarWrapper = styled.div`
  button {
    text-transform: capitalize;
    &.has-sibling {
      margin-right: 16px;
    }
  }
`;

export default ButtonBarWrapper;
