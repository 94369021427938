import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, Modal, message } from "antd";
import React, { useState } from "react";
import logo from "../../../assets/img/unitifi.svg";
import PublicContent from "../../../layouts/public";
import SignupWrapper from "./index.style";
import { FormWrapper } from "../../../components/form";
import { NavLink, Link } from "react-router-dom";
// images
import signupbg from "../../../assets/img/sign-up-bg.png";
import logoimg from "../../../assets/img/unitifi.svg";
import { Container } from "react-bootstrap";
const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;

const slugGenerator = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/_+$/, "")
    .toLowerCase();

const Signup_new = (props) => {
  const recaptchaRef = React.createRef();
  const [state, setState] = useState({
    visible: false,
    accept: false,
    verify: false,
  });

  const [error, setError] = useState({
    email: false,
  });
  const [accountType, setAccountType] = useState(null);
  const { form, mutate, history } = props;
  const { getFieldProps, getFieldDecorator } = form;

  const handleOk = () => {
    setState({ ...state, visible: false, accept: true });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false, accept: false });
  };

  const onChecked = () => {
    setState({ ...state, visible: true });
  };

  const submitHandler = () => {
    if (accountType == null) setAccountType("error");
    if (accountType == "1" || accountType == "2") history.push({ pathname: "/signup", search: `?account_type=${accountType}` });
  };
  const accountHandler = (value) => {
    setAccountType(value);
  };

  return (
    <SignupWrapper>
      <div className="unitfi-owner-member-main">
        <div className="unitfi-owner-member-wrapper">
          <NavLink to="/" className="logo-img-link">
            <img src={logoimg} />
          </NavLink>
          <Container>
            <Row gutter={8}>
              <Col lg={{ span: 20, offset: 2 }}>
                <Row gutter={12} className="owner-member-account-detals-wrapper">
                  <Col span={24} md={12}>
                    <div className="box-wrapper">
                      <div className={`unitfi-owner-member-box ${accountType == "1" ? "active" : ""}`} onClick={() => accountHandler("1")}>
                        <h5>Account Owner</h5>
                        <p>For those creating their company, practice, or advisory account. The account holder will serve as the administrator for their organization and handle company information, employee access, payment, and more.</p>
                      </div>
                    </div>
                  </Col>
                  <Col span={24} md={12}>
                    <div className="box-wrapper">
                      <div className={`unitfi-owner-member-box member-box ${accountType == "2" ? "active" : ""}`} onClick={() => accountHandler("2")}>
                        <h5>Team Member</h5>
                        <p>
                          For employees looking to join their company, practice, or advisory’s Unitifi account. This is a free account that will allow you to send investor assessments as well access all relevant investor information and preferences.
                        </p>
                      </div>
                      {/* <div className="unitifi-copy-link-text">
                        <p>Already have an account?</p>
                        <Link to="/login" className="link-text">
                          Click here to login
                        </Link>
                      </div>
                      <div className="box-btns-wrapper">
                        {accountType && accountType == "error" && <p>Please select account type</p>}
                        <Button onClick={() => submitHandler()} className={`unitfi-btn-wrapper  ${accountType && accountType != "error" ? "active" : ""}`}>
                          Continue
                        </Button>
                      </div> */}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <div className="box-btns-wrapper">
                      <div className="unitifi-copy-link-text">
                        <p class="mb-0">Already have an account?</p>
                        <Link to="/login" className="link-text ms-1">
                          Click here to login
                        </Link>
                      </div>
                      {accountType && accountType == "error" && <p>Please select account type</p>}
                      <Button onClick={() => submitHandler()} className={`unitfi-btn-wrapper mt-0 ${accountType && accountType != "error" ? "active" : ""}`}>
                        Continue
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="unitfi-owner-member-select-wrapper">
              {/* <div className="unitfi-owner-member-select-div">
                <div className={`unitfi-owner-member-box ${accountType == "1" ? "active" : ""}`} onClick={() => accountHandler("1")}>
                  <h5>Owner Account</h5>
                  <p>For those creating their company, practice, or advisory account. The account holder will serve as the administrator for their organization and handle company information, employee access, payment, and more.</p>
                </div>
                <div className={`unitfi-owner-member-box member-box ${accountType == "2" ? "active" : ""}`} onClick={() => accountHandler("2")}>
                  <h5>Member Account</h5>
                  <p>For employees looking to join their company, practice, or advisory’s Unitifi account. This is a free account that will allow you to send investor assessments as well access all relevant investor information and preferences.</p>
                </div>
              </div> */}

              {/* <div className="unitfi-signup-member-btn-div">
                
               
              </div> */}
            </div>
          </Container>
        </div>
      </div>
      {/* </Content> */}
      {/* </Layout> */}
    </SignupWrapper>
  );
};
export default Form.create()(Signup_new);
