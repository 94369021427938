import { AddButtons, EditButtons, FormWrapper } from "../../../../components/form";
import StateList from "../../../../helpers/statesDataList";
import { Typography, Row, Col, AutoComplete, Progress, Form, Input, Button } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useLazyQuery, useQuery } from "react-apollo";
import gql from "graphql-tag";
import _, { round } from "lodash";
import StepsQuestions from "./component/questions";

const { Title, Paragraph, Text } = Typography;

function Assessment(props) {
  const [question, setQuestion] = useState({
    list: [],
    totalpage: 0,
  });
  const [current, setCurrent] = useState(-1);
  const [percent, setPercent] = useState(0);
  const [pageable, setPageable] = useState([]);

  const { state, form, setState, pages, practiceId, userId, clientAssessmentBody } = props;

  const [getAssessment, { loading, error, data }] = useLazyQuery(
    gql`
      query($practiceId: String!) {
        getClientAssessmentPerPractice(practiceId: $practiceId) {
          practice {
            id
            userInviteDomain
            clientAssessmentBody
          }
          assessment {
            name
            questions
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data.getClientAssessmentPerPractice) {
          const { getClientAssessmentPerPractice } = data;
          const { practice, assessment } = getClientAssessmentPerPractice;
          // setState({ ...state, practice: { ...practice } });
          setQuestion({
            list: assessment.questions,
            totalpage: Math.ceil(assessment.questions.length / 4),
          });
          setCurrent(0);
        }
      },
    }
  );

  const [submitClientAssessment, { data: mutData, loading: loadingMutation }] = useMutation(
    gql`
      mutation submitClientAssessment($advisorId: Int!, $clientId: Int!, $practiceId: Int!, $assessmentToken: String, $responses: JSON!) {
        submitClientAssessment(advisorId: $advisorId, clientId: $clientId, practiceId: $practiceId, assessmentToken: $assessmentToken, responses: $responses) {
          practice
          ok
          client {
            id
            firstName
            lastName
          }
          errors {
            message
            path
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (data.submitClientAssessment.ok && !data.submitClientAssessment.errors) {
          var thisPage = pages;
          thisPage[1].status = true;
          props.setPages(thisPage);
          //props.userInviteDomain = userInviteDomain;
          if (data.submitClientAssessment.practice !== null) {
            props.setuserInviteDomain(data.submitClientAssessment.practice);
          } else {
            props.setuserInviteDomain("https://unitifi.com");
          }
          console.log("data=>>", data);
          props.setActivePage("done");
        }
      },
    }
  );

  const begin = () => {
    getAssessment({
      variables: {
        practiceId: practiceId,
      },
    });
  };

  useEffect(() => {
    paging(current);
    getPercentage();
  }, [current, question]);
  //getpracticedetails
  const { data: practiceData } = useQuery(
    gql`
      query($practiceId: String!) {
        getPracticeDetails(practiceId: $practiceId) {
          id
          name
          userInviteDomain
          clientAssessmentBody
        }
      }
    `,
    {
      variables: {
        practiceId: props.practiceId,
      },
      fetchPolicy: "network-only",
    }
  );
  //end practice details

  function paging(current) {
    var data_c = _.clone(question.list);
    const last_p = setPageable(
      _.remove(data_c, function(o) {
        return o.page == current;
      })
    );
  }

  const getPercentage = () => {
    if (question.list.length > 0) {
      const totalPages = question.list[question.list.length - 1].page;
      const currentPages = current;
      var percentage = 0;
      percentage = currentPages / (totalPages + 1);
      percentage = round(percentage * 100);
      setPercent(percentage);
    }
  };

  const handleSubmitNext = (event) => {
    event.preventDefault();
    const newList = _.clone(question.list);
    props.form.validateFields((err, values) => {
      if (!err) {
        var result = Object.keys(values).map((key) => [Number(key), values[key]]);
        result.map((items, i) => {
          const ind = _.findIndex(question.list, function(o) {
            return o.id == items[0];
          });
          const toUpd = newList[ind];
          toUpd.answer = items[1];
        });
        setCurrent(current + 1);
      }
    });
  };

  const handleSubmitEnd = (event) => {
    event.preventDefault();
    var responses = [];
    const newList = _.clone(question.list);

    props.form.validateFields((err, values) => {
      if (!err) {
        var result = Object.keys(values).map((key) => [Number(key), values[key]]);
        result.map((items, i) => {
          const ind = _.findIndex(question.list, function(o) {
            return o.id == items[0];
          });
          const toUpd = newList[ind];
          toUpd.answer = items[1];
        });

        question.list.map((qa, i) => {
          if (qa.type !== "Page Title") {
            responses.push({
              id: qa.id,
              answer: qa.answer,
            });
          }
        });
        const variables = {
          advisorId: parseInt(userId),
          clientId: state.client.id,
          practiceId: state.practice.id,
          assessmentToken: state.client.assessmentToken,
          responses,
        };
        submitClientAssessment({
          variables,
        });
      }
    });
  };
  /*function strParser(origintalString, parameters) {
    let newString = origintalString;
    // loop through the parameters and replace them
    for (let i = 0; i < parameters.length; i += 1) {
      const param = parameters[i];
      // replace all occurance of the pattern
      newString = newString.replace(param.pattern, param.replacement);
    }
    return newString.split("\n").reduce((merged, curr) => {
      const current = `<p>${curr}</p>`;
      return `${merged}${current}`;
    }, "");
  }
  const params = [
    { pattern: /\[\[CLIENT_FIRST_NAME\]\]/gi, replacement: props.state.client.firstName },
    { pattern: /\[\[CLIENT_LAST_NAME\]\]/gi, replacement: props.state.client.lastName },
    { pattern: /\[\[ADVISOR_FIRST_NAME\]\]/gi, replacement: props.state.client.advisorfirstname },
    { pattern: /\[\[ADVISOR_LAST_NAME\]\]/gi, replacement: props.state.client.advisorlastname },
    { pattern: /\[\[PRACTICE_NAME\]\]/gi, replacement: props.state.client.practiceName || "Team Unitifi" },
    { pattern: /\[\[SYS_GENERATED_LINK\]\]/gi, replacement: `<a onClick={begin}>Click here</a>` },
  ];
  let assessmentBody = strParser(props.state.client.clientAssessmentBody, params);*/

  return (
    <>
      <Form>
        {current < 0 && (
          <>
            <Row>
              <Col span={24}></Col>
              <Col span={24} className="mt-3">
                <Typography>
                  <Paragraph>Greetings {props.state.client.firstName},</Paragraph>
                  <Paragraph>The assessment you have been invited to complete is powered by Unitifi – a subject matter expert in Behavioral Finance.</Paragraph>
                  <Paragraph>Typically, this assessment takes less than 10 minutes to complete. Your responses are confidential, and results are available immediately for your financial professional to review/share with you.</Paragraph>
                  <Paragraph>Insights from your unique report help provide a clear understanding of your financial behavior as it relates to your finances, communication preferences, and decision-making style.</Paragraph>
                  <Paragraph>Peace!</Paragraph>
                  <Paragraph>
                    Jerry Szatko
                    <br />
                    CEO Unitifi
                  </Paragraph>
                  {/*<Paragraph>
                    Unitifi fully understands that as human beings, we each have unique values, goals and perspectives. We know that you are more than just a set of numbers based on your age, appetite for risk and economic status.
                  </Paragraph>
                  <Paragraph>
                    The assessment which you are about to take will not only help us understand your unique tolerance for risk, but it will also help us to see <Text strong>HOW</Text> you go about making financial decisions. This will aid us in
                    understanding your desired communication style as well as guiding us toward portfolios that will be personalized for your goals and comfort level.
                  </Paragraph>
                  <Paragraph>Thank you in advance for taking the time to help us understand who you are as an individual. We hope you find the assessment beneficial!</Paragraph>
                  <Paragraph>Sincerely,</Paragraph>
                  <Paragraph>Unitifi</Paragraph>*/}
                  {/*<Paragraph><div dangerouslySetInnerHTML={{__html: assessmentBody}}></div></Paragraph>*/}
                </Typography>
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }} style={{ marginTop: 20 }}>
              <Button type="primary" onClick={begin}>
                Begin
              </Button>
            </Row>
            <Row style={{ marginTop: 12 }}>
              <Paragraph>By clicking on Begin, you are agreeing to share the results with your financial professional.</Paragraph>
            </Row>
          </>
        )}

        {question.list.length > 0 && current >= 0 && (
          <>
            <Row>
              <Progress strokeLinecap="square" percent={percent} style={{ marginBottom: 18, marginTop: 18 }} />

              {pageable.map((items, i) => {
                return <StepsQuestions setQuestion={setQuestion} items={items} {...props} question={question} />;
              })}
            </Row>
            <Row style={{ marginTop: 10 }} style={{ marginTop: 20 }}>
              <Button type="default" style={{ marginRight: 10 }} onClick={() => setCurrent(current - 1)} loading={loadingMutation}>
                Previous
              </Button>
              {question.list[question.list.length - 1].page === current && (
                <Button type="primary" onClick={handleSubmitEnd} loading={loadingMutation}>
                  Finish
                </Button>
              )}

              {question.list[question.list.length - 1].page !== current && (
                <Button type="primary" onClick={handleSubmitNext}>
                  Next
                </Button>
              )}
            </Row>
          </>
        )}
      </Form>
    </>
  );
}

export default Form.create()(Assessment);
