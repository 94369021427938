import { Badge, Button, Dropdown, Icon, Menu, notification } from "antd";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import { format } from "date-fns";
import { practiceConfig } from "../../config";
import { Modal } from "react-bootstrap";
import NotificationModal from "./notificationModal";
import moment from "moment";

const Notification = (props) => {
  const {
    notificationList,
    requestJoinQuery,
    history,
    updateNotificationHandler,
    currentPortalId,
    setNotificationShowHandler,
    clearNotification,
    currentPractice,
    data: { me },
  } = props;
  const [notifications, setNotifications] = useState(null);
  const [visible, setvisible] = useState(false);

  useEffect(() => {
    if (!notificationList) return;

    if (notificationList && notificationList.notificationList) {
      let data = notificationList.notificationList;
      setNotifications(data);
    }
  }, [notificationList]);

  const notificationListHandler = async (limit, offset) => {
    let result = await notificationList.fetchMore({
      variables: { limit, offset, type: "all" },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setNotifications(fetchMoreResult.notificationList);
        // this.setState({ loading: false });
        // if (!fetchMoreResult) {
        //   return previousResult;
        // }

        // this.setState({
        //   totalCount: fetchMoreResult.getAllAdvisors.pagination.totalCount,
        // });

        return fetchMoreResult;
      },
    });
  };

  const handleVisibleChange = async () => {
    setvisible(!visible);
    if (notifications && notifications.notificationTotal > 0) {
      let result = await updateNotificationHandler({ variables: { portalId: currentPortalId } });
      if (result && result.data && result.data.updateNotificationHandler && result.data.updateNotificationHandler.ok) notificationListHandler(10, 0);
    }
  };

  const clearNotificationHandler = async () => {
    // setvisible(!visible);
    if (notifications) {
      let result = await clearNotification({ variables: { portalId: currentPortalId } });
      if (result && result.data && result.data.clearNotification && result.data.clearNotification.ok) notificationListHandler(10, 0);
    }
  };

  const accpetRejectHandler = async (type, data) => {
    let result = await requestJoinQuery({ variables: { id: data.id, status: type } });
    if (result && result.data && result.data.requestJoinAction && result.data.requestJoinAction.ok) {
      notification.success({
        message: result.data.requestJoinAction.message,
        style: { zIndex: 9999, position: "relative" },
      });
      notificationListHandler(10, 0);
    }
  };

  const notificationShowHandler = () => {
    setvisible(false);
    setTimeout(() => {
      setNotificationShowHandler(true);
    }, 100);
  };

  const notificationMenuContent = () => (
    <Menu className="notify-menu-main-wrapper p-0">
      <div className="user-notification-menu">
        {notifications &&
          notifications.data.length > 0 &&
          notifications.data.map(
            (client) =>
              client.userdisplayname != "" && (
                <Menu.Item key={client.id} className="notify-dropdown">
                  <div className="menu-item-wrapper">
                    <div className="user-detail-header-bar">
                      {client.notificationType == "assessment" ? (
                        <Link
                          to={me.rolecode == "SPR" ? `/${client.practiceId}/clients/advisors/${client.advisorId}/view/${client.userId}` : `/${client.practiceId}/clients/view/${client.userId}`}
                          onClick={() => {
                            localStorage.setItem("current_practice", client.userId);
                            this.setState({ visible: false });
                          }}>
                          <h6>{client.userdisplayname}</h6>
                        </Link>
                      ) : client.notificationType == "bounceAssessment" ? (
                        <Link to="#">
                          <h6>{client.userdisplayname}</h6>
                        </Link>
                      ) : client.notificationType != "clientAssign" ? (
                        <Link to={`/${me.currentpracticeid}/advisors/view/${client.userId}`}>
                          <h6>{client.userdisplayname}</h6>
                        </Link>
                      ) : (
                        <Link
                          to={`/${currentPractice}/clients?${client.advisorId ? `assign_id=${client.advisorId}` : `practice_id=${client.practiceId}`}`}
                          onClick={() => {
                            this.setState({ visible: false });
                          }}>
                          <h6>{client.message}</h6>
                        </Link>
                      )}

                      {client.status == "pending" && client.notificationType != "assessment" && client.notificationType != "bounceAssessment" && client.notificationType != "clientAssign" && (
                        <div className="notification-btns-wrapper">
                          <Button className="btnCancel me-2" onClick={() => accpetRejectHandler("cancel", client)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                fillRule="evenodd"
                                d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Button>
                          <Button className="btnSuccess" onClick={() => accpetRejectHandler("accept", client)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                              <path fill="currentColor" d="M8.6 20 .8 12.2l1.4-1.4 6.4 6.5L21.8 4l1.4 1.4z" />
                            </svg>
                          </Button>
                        </div>
                      )}
                    </div>

                    <div className="date-note-text" id={client.id}>
                      <h5 className={`${client.notificationType == "bounceAssessment" ? "bounce-mail" : "green-text"}`}>
                        {client.notificationType == "clientAssign" ? "Advisor Deleted" : client.message} <span>- {moment(client.createdAt).format("MM/DD/YYYY")}</span>
                      </h5>
                      {/* <span style={{ color: client.transferredClient ? "#faad14" : "#008000" }}>{client.transferredClient ? "Transferred Client" : "New Assessment"}</span> - {format(client.dateCollected, "M/D/YYYY")} */}
                    </div>
                    {/*</Link>*/}
                    {/* <div>
                    <Icon type="close" />
                  </div> */}
                  </div>
                </Menu.Item>
              )
          )}
      </div>

      {notifications && notifications.data.length > 0 ? (
        <>
          <div onClick={() => clearNotificationHandler()} className="notification-text d-flex justify-content-center">
            <span>Clear Assessment Notifcations</span>
          </div>
          <div onClick={() => notificationShowHandler()} className="view-text d-flex justify-content-center">
            <span>View All</span>
          </div>
        </>
      ) : null}
    </Menu>
  );
  // notification.success({
  //   message: "Test MEssage",
  //   duration:0,
  //   style:{zIndex:9999,position:"relative"}
  // });
  return (
    <>
      {notifications && notifications.total > 0 ? (
        <Dropdown
          overlay={notificationMenuContent}
          overlayClassName="dropdown-sticky"
          placement="bottomRight"
          onVisibleChange={handleVisibleChange}
          visible={visible}
          trigger={["click"]}
          overlayStyle={{ zIndex: 100, position: "fixed", right: 0, width: "232px" }}>
          <div className="user-menu notification">
            {notifications && notifications.notificationTotal > 0 ? (
              <Badge dot={true} color="green">
                {/* <Icon type="bell" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" fill="none">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M16.334 17.59c-1.484-2.564-.619-3.982-1.202-8.725-.523-4.25-3.777-5.467-5.444-5.814V0H7.99v3.05c-1.67.348-4.924 1.565-5.445 5.815-.584 4.741.282 6.161-1.202 8.724A7.093 7.093 0 0 1 0 19.23v.989h17.676v-.99a7.063 7.063 0 0 1-1.342-1.639ZM8.838 24.976c1.145 0 2.073-.91 2.073-2.03 0-1.122-.928-2.031-2.073-2.031s-2.073.909-2.073 2.03c0 1.122.928 2.03 2.073 2.03Z"
                    clipRule="evenodd"
                  />
                </svg>
              </Badge>
            ) : (
              <i className="green-dost">
                {/* <Icon type="bell" /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" fill="none">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M16.334 17.59c-1.484-2.564-.619-3.982-1.202-8.725-.523-4.25-3.777-5.467-5.444-5.814V0H7.99v3.05c-1.67.348-4.924 1.565-5.445 5.815-.584 4.741.282 6.161-1.202 8.724A7.093 7.093 0 0 1 0 19.23v.989h17.676v-.99a7.063 7.063 0 0 1-1.342-1.639ZM8.838 24.976c1.145 0 2.073-.91 2.073-2.03 0-1.122-.928-2.031-2.073-2.031s-2.073.909-2.073 2.03c0 1.122.928 2.03 2.073 2.03Z"
                    clipRule="evenodd"
                  />
                </svg>
              </i>
            )}
          </div>
        </Dropdown>
      ) : (
        <Dropdown
          overlay={
            <Menu className="notify-menu-main-wrapper emptyData p-0">
              <div className="notify">
                <div className="menu-item-wrapper" style={{ cursor: "default", fontSize: "14px", display: "flex", justifyContent: "center" }}>
                  No New Notifications
                </div>
              </div>
              <div onClick={() => notificationShowHandler()} className="view-text d-flex justify-content-center">
                <span>View All</span>
              </div>
            </Menu>
          }
          onVisibleChange={handleVisibleChange}
          visible={visible}
          placement="bottomRight"
          trigger={["click"]}
          overlayStyle={{ position: "fixed" }}>
          <div className="user-menu">
            {/* <Icon type="bell" /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="25" fill="none">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M16.334 17.59c-1.484-2.564-.619-3.982-1.202-8.725-.523-4.25-3.777-5.467-5.444-5.814V0H7.99v3.05c-1.67.348-4.924 1.565-5.445 5.815-.584 4.741.282 6.161-1.202 8.724A7.093 7.093 0 0 1 0 19.23v.989h17.676v-.99a7.063 7.063 0 0 1-1.342-1.639ZM8.838 24.976c1.145 0 2.073-.91 2.073-2.03 0-1.122-.928-2.031-2.073-2.031s-2.073.909-2.073 2.03c0 1.122.928 2.03 2.073 2.03Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </Dropdown>
      )}
    </>
  );
};

const notificationQuery = gql`
  query($limit: Int!, $offset: Int!, $type: String, $isAll: Int, $roleCode: String, $accountType: Int, $portalId: Int, $practiceId: Int) {
    notificationList(limit: $limit, offset: $offset, type: $type, isAll: $isAll, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
      data {
        id
        userId
        acceptByUserId
        portalId
        practiceId
        advisorId
        notificationType
        message
        status
        isRead
        isReadAll
        userdisplayname
        createdAt
      }
      total
      notificationTotal
      error
    }
  }
`;

const requestJoinQuery = gql`
  mutation($id: Int, $status: String) {
    requestJoinAction(id: $id, status: $status) {
      ok
      message
      error
    }
  }
`;
const updateNotificationHandler = gql`
  mutation($portalId: Int) {
    updateNotificationHandler(portalId: $portalId) {
      ok
      message
      error
    }
  }
`;

const clearNotification = gql`
  mutation($portalId: Int) {
    clearNotification(portalId: $portalId) {
      ok
      message
      error
    }
  }
`;
export default compose(
  graphql(notificationQuery, {
    name: "notificationList",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        limit: props.limit || practiceConfig.rowsPerPage,
        offset: props.offset || 0,
        type: "all",
        isAll: 0,
        roleCode: props.data.me ? props.data.me.rolecode : "",
        accountType: props.data.me ? props.data.me.accountType : 0,
        portalId: props.currentPortalId,
        practiceId: props.data.me ? props.data.me.practiceid : 0,
      },
    }),
  }),
  graphql(requestJoinQuery, { name: "requestJoinQuery" }),
  graphql(updateNotificationHandler, { name: "updateNotificationHandler" }),
  graphql(clearNotification, { name: "clearNotification" })
)(Notification);
