import styled from "styled-components";

const DashboardWrapper = styled.div`
  .dashboard-filter-bar {
    max-width: 220px;
    align-items: center;
    background: rgba(0,111,186,1);
    color: rgba(255,255,255,1);
    display: flex;
    justify-content: space-between;
    padding: 24px;
    transition: all .2s;
    .date-label {
      font-size: 12px;
    }
    .date-select-wrapper {
      display: flex;
      font-size: 16px;
      line-height: 24px;
      .ant-dropdown-link {
        border-bottom: solid 1px rgba(255,255,255,1);
        color: rgba(255,255,255,1);
        cursor: pointer;
        padding-bottom: 2px;
      }
      .select-label {
        margin-right: 8px;
      }
    }
  }
  
  .dashboard-wrapper {
    display: flex;
    margin-bottom: 16px;
    &.tableData{
      height:auto;
      overflow-y:hidden;
      padding:0 5px;
    }
    &.chart-box{
      background: #fff;
      border-radius:4px;
      width:100%;
      height:auto;
      h5{
        font-size:16px;
        font-weight:600;
        line-height:15px;
        color:#181818;
        margin-bottom:0;
      }
      .rv-radial-chart{
        width: 100% !important;
        height: 100% !important;
        svg{
          margin: auto;
        }
      }
    }
    .main-wrapper {
      padding: 24px 24px 0;
      transition: all .2s;
      width: 100%;
      @media only screen and (max-width: 767px) {
        padding: 24px 0;
      }
      .d-flex{
        flex-wrap: wrap;
      }
      .square-box{
        display: flex;
        align-items: center;
        position: relative;
        padding:5px 26px;
        font-size: 14px;
        font-weight:600;
        &:before{
          position: absolute;
          content: '';
          width: 12px;
          height: 12px;
          border-radius:2px;
          left: 0;
        }
        &.box1{
          &:before{
            background:#D230AF;
          }
        }
        &.box2{
          &:before{
            background:#30ABD2;
          }
        }
        &.box3{
          &:before{
            background:#9B4BEB;
          }
        }
        &.box4{
          &:before{
            background:#D2A530;
          }
        }
        /* &.box5{
          &:before{
            background:#054471;
          }
        } */
      }
      .unitify-cmn-title{
        font-size:14px;
        font-weight:600;
        line-height:17px;
        letter-spacing:1px;
        color: #1E2022;
        &.percentage{
          font-weight:400;
          color:#657A85;
        }
      }    
    }
    .sidebar-wrapper {
      background: rgba(240,242,245,1);
      height: 100vh;
      margin-top: -6px;
      min-width: 200px;
      padding-left: 24px;
      position: fixed;
      right: 24px;
      transition: all .2s;
      width: 200px;
      z-index: 2;
      .sidebar-inner-wrapper {
        height: 100%;
        overflow-x: scroll;
        padding-bottom: 132px;
        .button-wrapper {
          display: flex;
          justify-content: flex-end;
          button {
            &:first-child {
              margin-right: 8px;
            }
          }
        }
      }
      &.collapsed {
        right: -200px;
        transition: all .2s;
      }
    }
    .ant-skeleton-content {
      ul {
        padding-left: 0;
      }
    }
    .dashboard-info-wrapper {
      border-left: none;
      border-right: none;
      display: flex;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      padding:24px;
      margin-left: -6px;
      margin-right: -6px;
      .dashboard-info-item {
        background: rgba(255,255,255,1);
        flex-direction: column;
        display: flex;
        flex: 0 0 25%;
        padding: 0 6px;
        @media only screen and (max-width: 575px) {
        
              #Observer{
                order:3 !important;
              }
            
        }
        
        @media only screen and (max-width: 1199px) {
          //flex: 0 0 33.33%;
          padding: 6px;
        }
        @media only screen and (max-width: 991px) {
          flex: 0 0 50%;
        }
        &.protector{
          > div{
            border-left:11px solid #D230AF;
            &.activeProtector{
              border-top: 1px solid #D230AF;
              border-right: 1px solid #D230AF;
              border-bottom: 1px solid #D230AF;
            }
          }
        }
        &.observer{
          @media only screen and (max-width: 550px) {
              &:nth-child(-n+3) {
              border-bottom: none;
            } 
          }
          @media only screen and (max-width: 991px) {
            order:3;
          }
          > div{
            border-left:11px solid #30ABD2;
            &.activeObserver{
              border-top: 1px solid #30ABD2;
              border-right: 1px solid #30ABD2;
              border-bottom: 1px solid #30ABD2;
            }
          }
        }
        &.liberator{
          > div{
            border-left:11px solid #9B4BEB;
            &.activeLiberator{
              border-top: 1px solid #9B4BEB;
              border-right: 1px solid #9B4BEB;
              border-bottom: 1px solid #9B4BEB;
            }
          }
        }
        &.energizer{
          @media only screen and (max-width: 991px) {
            order:4;
          }
          > div{
            border-left:11px solid #D2A530;
            &.activeEnergizer{
              border-top: 1px solid #D2A530;
              border-right: 1px solid #D2A530;
              border-bottom: 1px solid #D2A530;
            }
          }
        }
        > div{
          background:#ECECEC;
          border-radius:8px;
          width: 100%;
          height:66px;
          padding:10px;
        }
        @media only screen and (max-width: 550px) {
          flex: 0 0 50%;
          &:nth-child(-n+3) {
            border-bottom: 1px solid rgba(238,242,244,1);
          }
          &:nth-child(2) {
            border-right: none;
          }
        }
        &:last-child {
          border-right: none;
        }
        .dashboard-info-item-inner {
          padding: 24px;
        }
        .dashboard-info-title {
          //color: rgba(0,111,186,1);
          color: #181818;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
        }
        .dashboard-info-detail {
          color: rgba(0,0,0,.65);
          font-size: 22px;
          font-weight: 600;
          line-height: 40px;
          cursor: pointer;
        }
        .dashboard-info-average {
          color: rgba(0,0,0,.65);
          font-weight: 600;
          &.dimmed {
            color: rgba(0,0,0,.2);
          }
        }
        &.dimmed {
          .dashboard-info-title {
            //color: rgba(0,0,0,.2);
            color: #181818;
          }
          .dashboard-info-detail {
            color: rgba(0,0,0,.2);
          }
          .dashboard-info-average {
            color: rgba(0,0,0,.2);
          }
        }
        .ant-skeleton-content {
          .ant-skeleton-title {
            margin-bottom: 2px;
            margin-top: 14px;
          }
          .ant-skeleton-paragraph {
            margin-top: 0;
            li {
              margin-bottom: 4px;
              &:first-child {
                height: 22px;
              }
            }
            > li + li {
              margin-top: 0;
            }
          }
        }
      }
    }
    .dashboard-chart-wrapper {
      background: rgba(255,255,255,1);
      border: 1px solid rgba(238,242,244,1);
      border-radius: 2px;
      //padding: 0 0 24px;
      &.no-clients {
        .no-client-message-wrapper {
          border: 1px solid rgba(238,242,244,1);
          border-left: none;
          border-right: none;
          padding: 24px;
          p {
            &:first-child {
              padding-bottom: 8px;
            }
            span {
              color: rgba(0,0,0,.85);
            }
          }
        }
      }

      .filterTitle{
        margin-right:18px;
      }

      .dashboard-chart-title {
        color: rgba(0,0,0,.85);
        font-size: 20px;
        line-height: 22px;
        padding: 24px;
        @media only screen and (max-width: 767px) {
          padding: 24px 12px;
        }
        p{
          font-size:14px;
          font-weight:400;
          margin-bottom:0;
          color:#969696;
        }
      }

      .filteText{
        a{
          font-size:12px;
          font-weight:400;
          line-height:15px;
          color:#EA3E3E;
          text-decoration:none;
          vertical-align:middle;
        }
        img{
          width:18px;
          height:18px;
        }
      }

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
      span{
        font-size:14px;
        font-weight:700;
        line-height:17px;
        color:#181818;
        .arrowIcon{
          padding:0 20px;
        }
      }
      .topbar-wrapper{
        width:100%;
        *{
          color: #181818;
        }
        .top-bar{
          display:flex;
          justify-content:space-around;
        }
      }
      .graph-bottom-bar{
        padding:10px 15px;
        // max-width:1170px;
        max-width: unset;
        width: calc(100% - 60px);
        margin: 15px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        &.gradientBg{
          background-image: linear-gradient(to right, rgba(85,155,203,1), rgba(5,68,113,1));
        }
        @media only screen and (max-width: 575px) {
          padding:5px 10px;
          //margin:15px auto;
        }
        span{
          display:block;
          //max-width:103px;
          width:100%;
          font-size:12px;
          line-height:15px;
          color:#fff;
          text-align:center;
          @media only screen and (max-width: 575px) {
            font-size:10px;
          }
        }
      }
    }


    .client-table-data-wrapper{
      background:#fff;
      border-radius:4px;
      //padding:10px;
      //margin-bottom:10px;
      @media only screen and (max-width: 1399px) {
           max-width:1400px;
           width:100%;
           flex:0 0 1400px;
           //overflow:hidden; 
      }
      
      .ant-table-wrapper{
        table{
          background:#fff;
          thead{
            th
              {
                  background:none;
                .ant-table-header-column{
                  span{
                    font-size:14px;
                    font-weight:600;
                    line-height:17px;
                    color:#181818;
                    text-transform: uppercase;
                  }
                }
                &:nth-child(2){
                  width:180px;
                }
                &:nth-child(3){
                  width:150px;
                }
                &:last-child{
                  width:70px;
                  text-align:right;
                }
              }
          }

          tbody{
                  tr{
                          td{
                            background:none;
                            font-weight:600;
                            line-height:17px;
                           a{
                             &:first-child{
                                color:#006FBA;
                                text-decoration:underline;
                                cursor:pointer;
                             } 
                           } 
                           &:nth-child(2){
                            width:180px;
                           }
                           &:nth-child(3){
                              color:#006FBA;
                              cursor:pointer;
                              width:150px;
                            } 
                           &:last-child{
                            width:70px;
                           } 
                          }

                          &:hover{
                              background: #006FBA;
                              color:#fff !important;
                              transition: all 0.3s ease;
                              td{
                                a{
                                  &:first-child {
                                  color: #fff;
                                  }
                                }
                                &:nth-child(3){
                                  color: #fff;
                                }
                              }
                          }
                    }
              }
        }
      }
    }

  }
  .modal-status-tab-wrapper{
    margin-top:15px;
    position:relative;
    /* max-width:452px;
    width:100%; */
    @media only screen and (max-width: 991px) {
      max-width:100%;     
    }

    h6{
      position:absolute;
      z-index: 1;
      font-size:16px;
      font-weight:600;
      color:#181818;
      padding:8px 18px;
      text-transform:uppercase;
    }

    .expand-icon{
      position:absolute;
      top:5px;
      right:10px;
      z-index:1;
    }
    .ant-tabs{
      .ant-tabs-bar{
        background:#fff;
        border-radius:4px;
        padding:15px 12px;
        .ant-tabs-nav-container{
          height: auto;
          padding: 0;
          .ant-tabs-tab-prev, 
          .ant-tabs-tab-next{
            display: none;
          }
          .ant-tabs-nav-wrap{
            .ant-tabs-tab{
              font-weight:600;
              line-height:17px;
              color: #181818;
              border:0;
              background:transparent;
              &.ant-tabs-tab-active{
                background: #006FBA;
                border-radius:70px;
                color:#fff;
                padding:10px 20px;
                width:auto;
                text-align:center;
              }
            }
            .ant-tabs-nav{
              .ant-tabs-ink-bar{
                display:none !important;
              }
            }
            .ant-tabs-nav-scroll{
              overflow-x: auto;
              margin-top: 16px;
              scrollbar-width: thin;
              scrollbar-width: 0px;
              scrollbar-width: none;}
              &::-webkit-scrollbar {
                height: 0;
                width: 0;
              }
              &::-webkit-scrollbar-track {
                background: #f1f1f1;
              }
              &::-webkit-scrollbar-thumb {
                background: #888;
              }
              .ant-tabs-tab{
                padding: 12px;
                height: auto;
                font-size: 14px;
                margin-right:20px;
              }
            }
          }
        }
      }
      .ant-tabs-content{
        height: 1155px;
        overflow-y: auto;
        padding:0 4px;
                  .ant-tabs-tabpane{
                    .tab-data-content{
                       background:#fff;
                       padding:15px;
                       border-radius:4px;
                       margin-bottom: 9px;
                        &.recent-data{
                          display:flex;
                          align-items: flex-start;
                          justify-content:space-between;
                        }
                        &.recently-tab-wrapper{
                          padding:9px 15px;
                          div{
                            width: calc(100% - 90px);
                            display: flex;
                            .score-data{
                              //margin-right: 5px;
                              font-size: 15px;
                              margin-bottom: 10px;
                              &.client-name-data{
                                margin-right:5px;
                              }
                            }
                            .client-title{
                              font-size: 15px;
                            }
                            p{
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }
                            .retirement-data{
                              font-size: 14px;
                              font-weight:400;
                            }
                          }
                        }
                        .time-detail{
                          display:flex;
                          justify-content:flex-end;
                          color:#A2A2A3;
                          &.recentData{
                            display:unset;
                          }
                        }
                        .view-data{
                            a{
                              display:flex;
                              justify-content:flex-end;
                              font-size:18px;
                              text-decoration:none;
                              color:#A2A2A3;
                            }
                          }
                          .client-title{
                            font-size:18px;
                            font-weight:600;
                            line-height:normal;
                            color:#181818;
                            text-transform:capitalize;
                            margin-bottom: 5px;
                            &.style-data{
                              font-weight:400;
                              margin-bottom:12px;
                            }
                            &.retirement-data{
                              font-size: 16px;
                              font-weight:400;
                            }
                          }
                          .score-data{
                            font-size: 24px;
                            font-weight:600;
                            line-height: normal;
                            color: #006FBA;
                            margin-bottom: 10px;
                          }
                      }
                  }
         }
    }
  }
  .cursor-pointer{
    cursor:pointer;
  }
`;

export default DashboardWrapper;
