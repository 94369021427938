import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { notification } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import TeamsForm from '../form';

class TeamsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect
        ? this.props.location.state.redirect
        : `/${this.props.currentPractice}/clients`,
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      practiceId: this.props.currentPractice,
    };
    // console.log('to be submittted variables: ', variables);

    this.props.mutate({ variables })
      .then(({ data }) => {
        if (data.createTeam.ok && !data.createTeam.errors) {
          this.handleSuccess(data.createTeam.team.name, data.createTeam.team.id);
          return;
        }

        this.handleError(data.createTeam.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(teamName, teamId) {
    notification.success({
      message: 'Team Created Successfully',
      //description: `${teamName} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: `/${this.props.currentPractice}/teams/view/${teamId}` });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    return (
      <div className='unirifi-costomer-layout-wrapper'>
        <div className='unitifi-subheader-bar-wrapper'>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Add Team"
          />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <TeamsForm
              cancelUrl={this.state.cancelUrl}
              createAnother={this.state.reset}
              currentPractice={this.props.currentPractice}
              gblUserRole={this.props.gblUserRole}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={{
                pathname: this.state.redirectTo,
                state: { newClient: true },
              }}
              />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addTeamMutation = gql`
mutation createTeam($name: String!, $practiceId: String!, $advisors: [ID]){
  createTeam(name: $name, practiceId: $practiceId, advisors: $advisors ){
    ok
    team {
      id
      name
      practice{
        id
        practiceId
      }
      advisors{
        id
        email
        firstName
        lastName
      }
    }
  }
}
`;

export default graphql(addTeamMutation)(TeamsAdd);
