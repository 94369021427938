import { Button, notification } from "antd";
import gql from "graphql-tag";
import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import infoIcon from "../../assets/img/info-blue.svg";
import { useApolloClient, useQuery, graphql } from "react-apollo";
import TagsInput from "../TagsInput";
import _, { flowRight as compose } from "lodash";
import Select from "react-select";
import { convertCurrency } from "../../helpers/formatters";

const InviteCompanyMember = (props) => {
  const { setCompanyModal, currentPortalId, gblUserId, me, setExceedalert, exceedalert } = props;
  //const { setFields } = props.form;
  const client = useApolloClient();
  const [tags, selectedTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ExceedTeam, setExceedTeam] = useState([]);
  const [exceedalertmsg, setExceedalertmsg] = useState();

  const inviteHandler = async () => {
    if (tags.length == 0) {
      return;
    }
    if (ExceedTeam.totalUserAdded + tags.length > ExceedTeam.totalUser) {
      // var msg =
      // "You are currently at your Team Member limit (" + ExceedTeam.totalUser + "). If you would like to invite this member your subscription cost will increase by $" + ExceedTeam.price + "/new member per " + ExceedTeam.subscriptionInterval + ".";
      if (exceedalert == false) {
        setExceedalert(true);
        // setExceedalertmsg(msg);
        return;
      }
    } else {
      setExceedalert(false);
    }
    setLoading(true);
    let variables = {
      toEmail: tags.join(","),
      portalId: currentPortalId,
      userId: gblUserId,
      practiceId: me.practiceid,
    };
    let result = await client.mutate({
      mutation: gql`
        mutation($userId: Int!, $portalId: Int!, $practiceId: Int!, $toEmail: String) {
          inviteCompanyMember(userId: $userId, portalId: $portalId, toEmail: $toEmail, practiceId: $practiceId) {
            ok
            message
            errors {
              path
              message
            }
          }
        }
      `,
      variables: variables,
    });
    setLoading(false);

    if (result.data.inviteCompanyMember.ok) {
      notification.success({ message: result.data.inviteCompanyMember.message });
      setCompanyModal(false);
    } else notification.error({ message: result.data.inviteCompanyMember.errors[0].message });
  };

  const { data: exceedteam } = useQuery(
    gql`
      query($portalId: Int!) {
        getExceedTeam(portalId: $portalId) {
          exceedteam {
            id
            totalUser
            totalUserAdded
            name
            subscriptions
            price
            subscriptionInterval
          }
        }
      }
    `,
    {
      variables: {
        portalId: currentPortalId,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!exceedteam) return;
    const e_team = _.get(exceedteam, "getExceedTeam.exceedteam", []);

    setExceedTeam(e_team);
  }, [exceedteam]);
  return (
    <div>
      <div className={`unitifi-invite-practice-modal-wrapper inviteModalBox ${exceedalert ? "section_hide" : "section_show"}`}>
        <TagsInput selectedTags={selectedTags} tags={tags} placeholder="Input email (hit return to add multiple)" />
      </div>

      <div className={`inviteMember-section ${exceedalert ? "section_show" : "section_hide"}`}>
        {/*<h4>
            Invite Advisor
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <i className="ms-2">
              <img src={infoIcon} />
            </i>
            </OverlayTrigger>
        </h4>*/}
        {ExceedTeam && (
          <p>
            You are currently at your Team Member limit <span>({ExceedTeam.totalUser})</span>. If you would like to invite this member your subscription cost will increase by <span>${convertCurrency(ExceedTeam.price)}</span>
            /new member per <span>{ExceedTeam.subscriptionInterval}</span>.{" "}
          </p>
        )}

        {/* <p>{exceedalertmsg}</p> */}
        <span className="inviteInfo">*By inviting, your next billing cycle will automatically be updated to reflect this change.</span>
      </div>

      <div className="modal-footer-bnts-wrapper">
        <Button type="primary" htmlType="button" className="unitifi-login-btn" onClick={() => inviteHandler()} disabled={loading} loading={loading}>
          Invite
        </Button>
        <Button type="primary" htmlType="button" className="unitifi-login-btn unitifi-cancel-btn" onClick={() => setCompanyModal(false)}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InviteCompanyMember;
