import styled from 'styled-components';
import { palette } from 'styled-theme';

const ClientViewWrapper = styled.div`
  padding: 24px 24px 0;
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }

  .main-chart-wrapper {
    display: flex;

    @media only screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }

    .left-col {
      padding-right: 12px;
      width: calc(100% / 3 * 1);

      @media only screen and (max-width: 1024px) {
        padding-right: 0;
        width: 100%;
      }

      .item {
        background: ${palette('base', 0)};
        border: 1px solid rgb(245, 245, 245);
        border-radius: 4px;
        margin: 24px 0;
        /* padding: 24px; */

        &:first-child {
          margin-top: 0;

          @media only screen and (max-width: 1024px) {
            margin-top: 24px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .callout {
          font-size: 24px;
          font-weight: 600;
          /* line-height: 28px; */
        }

        .details-wrapper {
          padding: 24px;
        }

        .heading {
          border-bottom: 1px solid rgba(238,242,244,1);
          font-size: 16px;
          font-weight: 600;
          padding: 12px 24px 12px;
          color: #181818;
        }

        .label {
          /* font-size: 16px; */
        }

        .score-item-wrapper {
          align-items: center;
          display: flex;
          justify-content: space-evenly;
          .slash {
            color: rgba(238,242,244,1);
            font-size: 80px;
            font-weight: 200;
            line-height: 80px;
            margin-top: -15px;
          }
          .score-item {
            align-items: center;
            display: flex;
            flex-direction: column;

            &:first-child {
              /* margin-right: 16px; */
            }

            .risk-behavior-score {
              color: rgba(0,111,186,1);
              font-size: 40px;
              font-weight: 600;
              line-height: 44px;
            }

            .self-reported-score {
              font-size: 40px;
              /* font-weight: 600; */
              line-height: 44px;
            }

            .score-label {
              font-size: 14px;
            }
          }
        }
      }
    }

    .right-col {
      padding-left: 12px;
      width: calc(100% / 3 * 2);

      @media only screen and (max-width: 1024px) {
        padding-left: 0;
        width: 100%;
      }

      .chart-legend {
        background: rgba(255,255,255,1);
        border: 1px solid rgb(245,245,245);
        border-bottom: none;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        display: flex;
        justify-content: center;
        padding: 16px 0 8px;
      }

      .chart-wrapper {
        background: rgba(255,255,255,1);
        border: 1px solid rgb(245, 245, 245);
        border-radius: 2px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: 8px 24px 24px;
        height: calc(100% - 54px);
      }
    }
  }

  .main-wrapper {
    background: ${palette('base', 0)};
    border: 1px solid rgb(245, 245, 245);
    border-radius: 4px;
    margin-top: 24px;
    /* padding: 24px; */

    .heading {
      border-bottom: 1px solid rgba(238,242,244,1);
      font-size: 16px;
      font-weight: 600;
      padding: 12px 24px 12px;
      color: #181818;
    }

    .wrapper {
      &.has-below {
        margin-bottom: 24px;
      }
    }

    .item-wrapper {
      padding: 24px;
    }

    .item-wrapper-flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item-flex {
        flex-direction: column;
        display: flex;
        text-align: center;
        width: 25%;
        background: rgb(255, 255, 255);
        border-right: 1px solid rgb(238, 242, 244);
        padding: 24px;
        @media only screen and (max-width: 1125px) {
          width: 50%;
          &:nth-child(-n+2) {
            border-bottom: 1px solid rgba(238,242,244,1);
          }
          &:nth-child(2) {
            border-right: none;
          }
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
          &:nth-child(-n+3) {
            border-bottom: 1px solid rgba(238,242,244,1);
            border-right: none;
          }
        }
        &:last-child {
          border-right: none;
        }
        .item-flex-heading {
          font-weight: 600;
          padding-bottom: 4px;
          color: #181818;
        }
      }
    }

    .item {
      &.has-below {
        margin-bottom: 24px;
      }

      .item-heading {
        font-weight: 600;
        padding-bottom: 4px;
        color: #181818;
      }
    }

    .attribute-text {
      margin-bottom: 21px;
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      .circle {
        background: rgba(0,111,186,1);
        border-radius: 4px;
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-left: 4px;
        margin-right: 4px;
        &.min {
          background: #6fb6e0;
        }
      }
    }
  }

  .ant-collapse {
    >.ant-collapse-item {
      >.ant-collapse-header {
        color: ${palette('text', 1)};

        .arrow {
          color: ${palette('text', 2)};
        }
      }
    }
  }
`;

export default ClientViewWrapper;
