import React from "react";
import gql from "graphql-tag";
import decode from "jwt-decode";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Button, Form, Icon, Input, Spin } from "antd";
import { FormWrapper, PasswordFeedbackWrapper, PasswordInputWrapper } from "../../../../components/form";
import PublicContent from "../../../../layouts/public";

const FormItem = Form.Item;

class UsersAccountConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: false,
      loading: false,
      value: null,
      isShowPass: false,
      isConfirmShowPass: false,
    };

    this.handleConfirmNewPassword = this.handleConfirmNewPassword.bind(this);
    this.handlePasswordFeedback = this.handlePasswordFeedback.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { form } = this.props;

    form.validateFieldsAndScroll({ scroll: { offsetTop: 128 } }, async (err, values) => {
      if (!err) {
        this.setState({ loading: true });

        const { id, email } = this.props.data.checkToken.user;
        /**
         * check password for minimum 8 characters,
         * 1 uppercase, 1 lowercase, 1 special character & 1 number
         * if password does not meet requirements,
         * send back an error and empty the confirm
         * password input
         */
        const check = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9@$!%*#?&]{8,}$");
        const password = form.getFieldValue("password");

        if (check.test(password)) {
          /**
           * if password meets requirements, check that
           * the password has been confirmed
           * if no password cofirm, ask user to confirm
           */
          if (form.getFieldValue("confirmPassword")) {
            /**
             * if all checks have passed,
             * update the user
             */
            const updatePasswordResponse = await this.props.updatePassword({
              variables: {
                id,
                accountConfirmToken: null,
                accountConfirmTokenExpires: null,
                email,
                password,
                status: "Active",
              },
            });

            console.log(updatePasswordResponse, "updatePasswordResponse");

            if (updatePasswordResponse.data.updatePassword.ok) {
              /**
               * if all created successfully,
               * log the user in
               * fallback to sending user to the login page
               */
              const loginUserResponse = await this.props.loginUser({
                variables: { email, password },
              });

              if (loginUserResponse.data.loginUser.ok) {
                localStorage.setItem("token", loginUserResponse.data.loginUser.token);
                localStorage.setItem("refreshToken", loginUserResponse.data.loginUser.refreshToken);

                const {
                  user: { isGlobalAdmin, isPortalAdmin, practices },
                } = decode(loginUserResponse.data.loginUser.token);

                const {
                  updatePassword: { user },
                } = updatePasswordResponse.data;
                const { roleId } = user || { roleId: "" };
                if ([1, 4, 5].includes(roleId) || practices.length || isGlobalAdmin || isPortalAdmin || practices.length > 1) {
                  console.log("here admin");
                  this.props.history.push("/");
                } else {
                  console.log("here practice");
                  this.props.history.push(`/${practices[0].practiceId}`);
                }

                // if (isGlobalAdmin || isPortalAdmin || practices.length > 1) {
                //   this.props.history.push('/')
                // } else {
                //   this.props.history.push(`/${practices[0].practiceId}`)
                // }
              } else {
                this.props.history.push("/login");
              }
            } else {
              this.props.history.push("/login");
            }
          } else {
            this.setState({ loading: false });

            form.setFields({
              confirmPassword: {
                value: null,
                errors: [new Error("Please confirm your password.")],
              },
            });
          }
        } else {
          this.setState({ loading: false });

          form.setFields({
            password: {
              value: password,
              errors: [new Error("Please enter a valid password.")],
            },
            confirmPassword: {
              value: null,
            },
          });
        }
      } else {
        console.log(err);
      }
    });
  }

  handlePasswordFeedback = (rule, value, callback) => {
    const { form } = this.props;

    if (value) {
      this.setState({
        input: true,
        value,
      });
    } else {
      this.setState({ input: false });
    }

    form.validateFields(["confirm"], { force: true });
    callback();
  };

  handleConfirmNewPassword = (rule, value, callback) => {
    const { form } = this.props;
    /* check for matching password confirmation as user types */
    if (value && value !== form.getFieldValue("password")) {
      callback("Passwords do not match.");
    } else {
      callback();
    }
  };

  passHandler = () => {
    this.setState({ ...this.state, isShowPass: !this.state.isShowPass });
  };
  passConfirmHandler = () => {
    this.setState({ ...this.state, isConfirmShowPass: !this.state.isConfirmShowPass });
  };

  render() {
    const {
      data: { loading, error, checkToken = null },
      form: { getFieldDecorator },
    } = this.props;
    const { input, value } = this.state;

    if (loading) {
      return (
        <PublicContent className="stretch-content">
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </PublicContent>
      );
    }

    if (error) {
      return (
        <PublicContent className="stretch-content">
          <div className="public-error-message">
            <p className="message">This invitation request is not valid.</p>
            <p>Please contact your practice administrator for assistance.</p>
          </div>
        </PublicContent>
      );
    }

    const { user } = checkToken;

    return (
      <PublicContent>
        <div className="public-form-wrapper">
          <div className="public-message">
            <p
              style={{
                color: "rgba(0, 0, 0, 0.85)",
                fontSize: "16px",
                paddingBottom: "8px",
              }}>
              Welcome {user.firstName}!
            </p>
            <p>Let&apos;s complete your account setup by creating a password.</p>
          </div>
          <FormWrapper className="public-form">
            <Form layout="vertical" onSubmit={this.handleSubmit}>
              <FormItem label="Email Address">
                {getFieldDecorator("email", {
                  initialValue: user.email,
                  rules: [
                    {
                      message: "Email is required",
                      required: true,
                    },
                  ],
                })(<Input disabled />)}
              </FormItem>
              <PasswordInputWrapper>
                <PasswordFeedbackWrapper input={input} value={value} />
              </PasswordInputWrapper>
              <FormItem className="show-required" label="Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      message: "Password is required",
                      required: true,
                      validator: this.handlePasswordFeedback,
                    },
                  ],
                })(<Input type={this.state.isShowPass ? "text" : "password"} />)}
                <div className="pw-eye-icons" onClick={() => this.passHandler()}>
                  <i className={this.state.isShowPass ? "pw-view-icon" : "pw-off-view-icon"}></i>
                </div>
              </FormItem>
              <FormItem className="show-required" label="Confirm Password">
                {getFieldDecorator("confirmPassword", {
                  rules: [
                    {
                      required: true,
                      validator: this.handleConfirmNewPassword,
                    },
                  ],
                })(<Input type={this.state.isConfirmShowPass ? "text" : "password"} />)}
                <div className="pw-eye-icons" onClick={() => this.passConfirmHandler()}>
                  <i className={this.state.isConfirmShowPass ? "pw-view-icon" : "pw-off-view-icon"}></i>
                </div>
              </FormItem>
              <FormItem>
                <Button htmlType="submit" loading={this.state.loading} type="primary">
                  Create My Account
                </Button>
              </FormItem>
            </Form>
          </FormWrapper>
        </div>
      </PublicContent>
    );
  }
}

const checkConfirmToken = gql`
  query checkToken($token: String!, $tokenType: String!) {
    checkToken(token: $token, tokenType: $tokenType) {
      ok
      user {
        id
        email
        firstName
        isGlobalAdmin
        isPracticeAdmin
        isPortalAdmin
      }
      errors {
        message
      }
    }
  }
`;

const updatePasswordMutation = gql`
  mutation updatePassword($id: Int!, $accountConfirmToken: String, $accountConfirmTokenExpires: String, $email: String!, $password: String, $status: String!) {
    updatePassword(id: $id, accountConfirmToken: $accountConfirmToken, accountConfirmTokenExpires: $accountConfirmTokenExpires, email: $email, password: $password, status: $status) {
      ok
      user {
        id
        email
        firstName
        isGlobalAdmin
        isPracticeAdmin
        isPortalAdmin
        roleId
      }
      errors {
        message
      }
    }
  }
`;

const loginUserMutation = gql`
  mutation($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
      }
    }
  }
`;

export default compose(
  graphql(checkConfirmToken, {
    fetchPolicy: "network-only",
    options: (props) => ({
      variables: {
        token: props.match.params.token,
        tokenType: "accountConfirmToken",
      },
    }),
  }),
  graphql(loginUserMutation, { name: "loginUser" }),
  graphql(updatePasswordMutation, { name: "updatePassword" })
)(Form.create()(UsersAccountConfirm));
