import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Icon, Modal, notification, Spin } from "antd";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import PracticeForm from "../form";
import TitlebarWrapper from "../../../components/titlebar/index.style";
import TitleBar from "../../../components/titlebar/titlebar";
import { AccountContext } from "../../../context/AccountContext";

const { confirm } = Modal;

class PracticesEdit extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removePractice = this.removePractice.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    const userContext = this.context;

    this.setState({
      loading: true,
    });

    delete values.practiceId;
    const variables = {
      ...values,
      id: this.props.data.getPractice.id,
      portalId: parseInt(this.props.currentPortalId),
    };

    let account = userContext.account;
    if (account.practiceid == variables.id) {
      account.practicename = variables.name;
      userContext.setAccount(account);
    }

    this.props
      .editpractice({ variables })
      .then(({ data }) => {
        if (data.editPractice.ok && !data.editPractice.errors) {
          this.handleSuccess(`${data.editPractice.practice.name} has been successfully updated.`);
          if (this.props.me && this.props.me.rolecode == "PRCTO") userContext.setLogoUpdate(variables.siteLogo);
          return;
        }

        this.handleError(data.editPractice.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDeleteConfirm() {
    confirm({
      title: "Delete Practice",
      content: `Are you sure you want to permanently delete ${this.props.data.getPractice.name}? This action cannot be undone.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",
      onOk: this.removePractice,
      width: "680px",
    });
  }

  removePractice() {
    this.props
      .deletePractice({ variables: { id: this.props.data.getPractice.id } })
      .then(({ data }) => {
        if (data.deletePractice.ok && !data.deletePractice.errors) {
          this.handleSuccess(`${this.props.data.getPractice.name} has been successfully deleted.`, "deleted");
          return;
        }

        this.handleError(data.deletePractice.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === "deleted" ? "Practice Deleted Successfully" : "Practice Updated Successfully",
      //description,
    });

    this.props.history.push("/practices");
    return;
    if (this.props.currentPractice && action !== "deleted") {
      this.setState({ redirectTo: `/${this.props.data.getPractice.practiceId}/practices` });
    } else {
      this.setState({ redirectTo: `/practices` });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    const {
      data: { loading, error, getPractice },
    } = this.props;
    const userContext = this.context;
    const { account } = userContext;
    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title="Edit Practice" subTitle="" />
            {/* <TitlebarWrapper>
              <div className="title-bar-wrapper">
                <div className="title-wrapper">
                  <div className="title-text">Edit Practice</div>
                  <div className="sub-title-text">{getPractice && getPractice.name}</div>
                </div>
              </div>
            </TitlebarWrapper> */}
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title="Edit Practice" subTitle={account && (account.accountType == 1 ? account.portalname : `${getPractice && getPractice.name} | ${account.portalname}`)} />
            {/* <TitlebarWrapper>
              <div className="title-bar-wrapper">
                <div className="title-wrapper">
                  <div className="title-text">Edit Practice</div>
                  <div className="sub-title-text">{getPractice && getPractice.name}</div>
                </div>
              </div>
            </TitlebarWrapper> */}
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - practice didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <TitleBar title="Edit Practice" subTitle={account && (account.accountType == 1 ? account.portalname : `${getPractice && getPractice.name} | ${account.portalname}`)} />
          {/* <TitlebarWrapper>
            <div className="title-bar-wrapper">
              <div className="title-wrapper">
                <div className="title-text">Edit Practice</div>
                <div className="sub-title-text">{getPractice && getPractice.name}</div>
              </div>
            </div>
          </TitlebarWrapper> */}

          {/* <Titlebar currentPractice={getPractice && getPractice.name} displayText="Edit Practice" /> */}
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <PracticeForm
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
              practice={getPractice}
              toggleLoading={this.toggleLoading}
              gblUserRole={this.props.gblUserRole}
              portalId={parseInt(this.props.match.params.poid)}
              {...this.props}
            />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

PracticesEdit.propTypes = {
  editpractice: PropTypes.func.isRequired,
  deletePractice: PropTypes.func.isRequired,
};

const getPracticeQuery = gql`
  query($id: Int!) {
    getPractice(id: $id) {
      id
      name
      notes
      practiceId
      siteLogo
      siteLogoS3Token
      status
      email
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      assessmentNotification
    }
  }
`;

const editpracticeMutation = gql`
  mutation editPractice(
    $id: Int!
    $name: String
    $notes: String
    $practiceId: String
    $portalId: Int!
    $siteLogo: String
    $siteLogoS3Token: String
    $status: String!
    $primaryContactAddress: String
    $primaryContactZipCode: String
    $primaryContactCity: String
    $primaryContactCountry: String
    $primaryContactState: String
    $primaryContactSuite: String
    $primaryContactEmail: String
    $primaryContactFirstName: String
    $primaryContactLastName: String
    $email: String
    $assessmentNotification: Boolean
    $adminId: Int
  ) {
    editPractice(
      id: $id
      name: $name
      notes: $notes
      practiceId: $practiceId
      siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      status: $status
      email: $email
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
      portalId: $portalId
      assessmentNotification: $assessmentNotification
      adminId: $adminId
    ) {
      practice {
        name
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

const deletePracticeMutation = gql`
  mutation deletePractice($id: Int!) {
    deletePractice(id: $id) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPracticeQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editpracticeMutation, { name: "editpractice" }),
  graphql(deletePracticeMutation, { name: "deletePractice" })
)(PracticesEdit);
