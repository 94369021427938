import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { Icon, Spin } from 'antd';
import { Table } from '../../../elements';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import { FormatPhoneNumber } from '../../../helpers/formatters';
import moment from "moment";

class TeamsClients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPractice: this.props.currentPractice
    };
  }

  render() {
    const { data: { loading, error, getTeam } } = this.props;
    const { currentPractice } = this.state;

    const columns = [
      {
        dataIndex: 'firstName',
        render: (text, client) => (
          <Link
            className={client.status === 'Inactive' ? 'inactive' : null}
            to={`/${currentPractice}/clients/view/${client.id}`}
          >
            {client.firstName} {client.lastName}
          </Link>
        ),
        sorter: true,
        title: 'Client',
      },
      {
        dataIndex: 'dateCollected',
        render: (text, row) => {
          if(!row.dateCollected) return "n/a"
          return moment.utc(row.dateCollected).local().format("MM/DD/YYYY");
        },
        sorter: true,
        title: 'Last Assessment Date',
      },
      {
        dataIndex: 'city',
        render: (text, row) => (row.city && row.state ? `${row.city}, ${row.state}` : ''),
        sorter: true,
        title: 'Location',
      },
      {
        dataIndex: 'email',
        sorter: true,
        title: 'Email',
      },
      {
        dataIndex: 'phoneNumber',
        render: text => FormatPhoneNumber(text),
        sorter: true,
        title: 'Phone',
      },
    ];

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - user didn&apos;t load.</p>
        </div>
      );
    }

    /* set-up dynamic button bar & routes */

    return (
      <div className='unirifi-costomer-layout-wrapper'>
        <div className='unitifi-subheader-bar-wrapper'>
          <Titlebar
            currentPractice={currentPractice}
            displayText={`Teams: ${getTeam.name} - ${getTeam.clients.length}`}
          />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={getTeam.clients}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No clients found.' }}
              onChange={this.handleTableChange}
              pagination={ false }
              rowKey={c => (c.id)}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getTeamQuery = gql`
  query($id: ID!, $practiceId: String! ) {
    getTeam(id: $id, practiceId: $practiceId){
      id
      name
      clients{
        id
        city
        email
        firstName
        lastName
        phoneNumber
        practiceName
        dateCollected
        state
      }
    }
  }
`;

export default graphql(getTeamQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(props.match.params.id),
      practiceId: props.currentPractice
    },
  }),
})(TeamsClients);
