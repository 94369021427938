import React from "react";
import { Redirect } from "react-router";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { Avatar, Badge, Dropdown, Icon, Menu, notification, Popover, Card, Divider, Button } from "antd";
import { format } from "date-fns";
import IntlMessages from "../../helpers/intlMessages";
import { UserMenuWrapper, UserCardWrapper } from "./index.style";
import { AccountContext } from "../../context/AccountContext";
import _ from "lodash";
import Notification from "./notification";
import NotificationModal from "./notificationModal";
import { Modal } from "react-bootstrap";
// import { Alert } from 'antd';

function GetInitials(name) {
  const nameArray = name.split(" ");
  const initialsArray = [];

  nameArray.forEach((i) => initialsArray.push(i.charAt(0).toUpperCase()));

  return initialsArray.join("");
}

class UserMenu extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      logout: false,
      visible: false,
      isNotificationShow: new URLSearchParams(props.location.search).get("showNotification"),
      isPopoverVisible: false,
    };
    this.handleUserTempClick = this.handleUserTempClick.bind(this);
    this.handleUserLogout = this.handleUserLogout.bind(this);
    this.handleUserProfile = this.handleUserProfile.bind(this);
    this.updateNotifications = this.updateNotifications.bind(this);
  }

  handleUserTempClick() {}

  handleUserLogout() {
    const userContext = this.context;
    userContext.setAccount(null);
    userContext.setProfile(null);
    userContext.setUserPermission([]);
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    this.setState({
      logout: true,
    });
  }

  handleUserProfile() {
    this.props.history.push("/sun-life-insurance/users");
  }

  handleVisibleChange = (flag) => {
    this.setState({ visible: flag });
  };

  updateNotifications(options) {
    const { clearAll, clientId, practiceId } = options;
    const variables = {
      practiceId,
    };

    if (!clearAll) {
      variables.clientId = clientId;
    }

    this.props
      .updateNotifications({ variables })
      .then(({ data }) => {
        if (data.updateNotifications.ok && !data.updateNotifications.errors) {
          this.props.data.refetch();

          return;
        }

        this.handleError(data.updateNotifications.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleError(errors) {
    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }
  setNotificationShowHandler = (type) => {
    this.setState({ isNotificationShow: !this.state.isNotificationShow });
  };
  handlePopoverVisibleChange = () => {
    this.setState({ isPopoverVisible: !this.state.isPopoverVisible });
  };

  render() {
    const {
      user,
      data: { loading, me },
    } = this.props;

    const { logout } = this.state;
    const userContext = this.context;
    const { account } = userContext;
    let roleName = "";
    if (me) {
      if (me.rolecode == "CMPYO") {
        roleName = "Company Admin";
      }
      if (me.rolecode == "CMPYM") {
        roleName = "Company member";
      }
      if (me.rolecode == "PRCTO") {
        roleName = "Practice Admin";
      }
      if (me.rolecode == "PRCTM") {
        roleName = "Practice Member";
      }
      if (me.rolecode == "ADVSO") {
        roleName = "Advisor Admin";
      }
      if (me.rolecode == "ADVSM") {
        roleName = "Advisor";
      }
    }
    let name = null;

    if (logout) {
      return <Redirect to="/login" />;
    }

    if (loading) {
      return (
        <UserMenuWrapper>
          <div className="user-menu">
            <Icon type="loading" />
          </div>
        </UserMenuWrapper>
      );
    }

    if (me) {
      name = account && account.firstName && account.lastName ? `${account.firstName} ${account.lastName}` : me.firstName && me.lastName ? `${me.firstName} ${me.lastName}` : "My Account";
      const { customerStripeId } = me;
      const notificationMenuContent = (
        <></>
        // <Menu className="user-notification-menu">
        //   {me.notifications.clientDetails.map((client) => (
        //     <Menu.Item key={client.id}>
        //       <div className="menu-item-wrapper">
        //         <Link
        //           to={`/${client.practiceId}/clients/view/${client.clientId}`}
        //           onClick={() => {
        //             localStorage.setItem("current_practice", client.practiceId);
        //             this.setState({ visible: false });
        //           }}>
        //           <div>{client.name}</div>
        //           <div className="note">
        //             <span style={{ color: client.transferredClient ? "#faad14" : "#008000" }}>{client.transferredClient ? "Transferred Client" : "New Assessment"}</span> - {format(client.dateCollected, "M/D/YYYY")}
        //           </div>
        //         </Link>
        //         <div>
        //           <Icon onClick={() => this.updateNotifications({ clientId: client.id, ...client })} type="close" />
        //         </div>
        //       </div>
        //     </Menu.Item>
        //   ))}
        //   {me.notifications.clientDetails.length > 0 ? (
        //     <Menu.Item onClick={() => this.updateNotifications({ clearAll: true })}>
        //       <span>Clear All Notifications</span>
        //     </Menu.Item>
        //   ) : null}
        // </Menu>
      );

      // Dropdown content (profile)
      // const userMenuContent = (
      // <Menu>
      //   <Menu.Item>

      //     <Row gutter={16}>
      //       <Col className="gutter-row" span={8}>
      //       <div className="gutter-box">col-6</div>
      //     </Col>
      //   </Row>
      //     <Link to={`/users/account/${me.id}`}>
      //       <div>
      //         <Avatar size={64} icon="user" />
      //       </div>
      //       <IntlMessages id="topbar.userMenuMyAccount" />
      //     </Link>
      //   </Menu.Item>
      //   <Menu.Item onClick={this.handleUserLogout}>
      //     <IntlMessages id="topbar.userMenuLogout" />
      //   </Menu.Item>
      // </Menu>
      // );
      const profileContent = (
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <Avatar size={64} style={{ margin: "auto", display: "block", backgroundColor: "rgba(101,122,133,.3)" }}>
            {GetInitials(name)}
          </Avatar>
          <div className="profile-wrapper" style={{ textAlign: "center" }}>
            <Link to={me.rolecode == "SPR" ? "#" : "/settings/account"} onClick={() => this.handlePopoverVisibleChange()}>
              <h6>{name}</h6>
              {/* <p>Admin Title</p> */}
            </Link>
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <UserCardWrapper>
            {/* {customerStripeId && (
              <Button className={"profile-link"} type="danger" block style={{ boxShadow: "none", backgroundColor: "transparent", borderColor: "transparent", color: "black" }}>
                <Link to={"/settings"}>
                  <Icon type="setting" /> <IntlMessages id="topbar.userMenuProfile" />
                </Link>
              </Button>
            )} */}

            <Button className={"profile-link"} type="danger" block onClick={this.handleUserLogout} style={{ boxShadow: "none", backgroundColor: "transparent", borderColor: "transparent", color: "black" }}>
              <Icon type="logout" /> <IntlMessages id="topbar.userMenuLogout" />
            </Button>
          </UserCardWrapper>
        </Card>
      );

      return (
        <UserMenuWrapper>
          <Notification {...this.props} setNotificationShowHandler={this.setNotificationShowHandler} />
          <Modal show={this.state.isNotificationShow} onHide={() => this.setState({ isNotificationShow: false })} centered className="unitifi-modal-wrapper notify-modal notify-modal-height">
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">ALL NOTIFICATIONS</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <NotificationModal {...this.props} />
            </Modal.Body>
          </Modal>
          {/* {me.notifications.clientDetails.length > 0 ? (
            <Dropdown overlay={notificationMenuContent} placement="bottomRight" onVisibleChange={this.handleVisibleChange} visible={this.state.visible} trigger={["click"]}>
              <div className="user-menu notification">
                <Badge count={me.notifications.count}>
                  <Icon type="bell" />
                </Badge>
              </div>
            </Dropdown>
          ) : (
            <Dropdown
              overlay={
                <Menu className="user-notification-menu">
                  <Menu.Item>
                    <div className="menu-item-wrapper" style={{ cursor: "default", fontSize: "14px", justifyContent: "center" }}>
                      No New Notifications
                    </div>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}>
              <div className="user-menu">
                <Icon type="bell" />
              </div>
            </Dropdown>
          )} */}
          <Popover placement="bottomRight" content={profileContent} trigger="click" visible={this.state.isPopoverVisible} onVisibleChange={this.handlePopoverVisibleChange}>
            <div className="user-menu">
              <div className="user-detail">
                <h6>{account && account.firstName && account.lastName ? `${account.firstName} ${account.lastName}` : `${me.firstName} ${me.lastName}`}</h6>
                <small>{roleName}</small>
              </div>
              <div className="user-menu-dropdown-link">
                <Avatar
                  style={{
                    backgroundColor: "rgba(101,122,133,.3)",
                    fontWeight: "600",
                  }}>
                  {GetInitials(name)}
                </Avatar>
              </div>
            </div>
          </Popover>

          {/****
            <Dropdown
              overlay={userMenuContent}
              placement="bottomRight"
              trigger={['click']}
            >
              <div className="user-menu">
                <div className="user-menu-dropdown-link">
                  <Avatar
                    style={{
                      backgroundColor: 'rgba(101,122,133,.3)',
                      fontWeight: '600',
                    }}
                  >
                    {GetInitials(name)}
                  </Avatar>
                </div>
              </div>
            </Dropdown>
           */}
        </UserMenuWrapper>
      );
    }

    // return <Redirect to="/login" />;
  }
}

const currentUserQuery = gql`
  query {
    me {
      id
      firstName
      lastName
      permissions
      portalid
      practiceId
      customerStripeId
      roleId
      roleCode
      firstLogin
      currentpracticeid
      practiceid
      isPracticeAdmin
      isPortalAdmin
      isAdvisorAdmin
      accountType
      notifications {
        clientDetails {
          id
          clientId
          dateCollected
          name
          practiceId
          transferredClient
        }
        count
      }
    }
  }
`;

const updateNotificationsMutation = gql`
  mutation updateNotifications($clientId: Int, $practiceId: String) {
    updateNotifications(clientId: $clientId, practiceId: $practiceId) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(graphql(updateNotificationsMutation, { name: "updateNotifications" }))(UserMenu);
