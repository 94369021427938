import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Icon, Modal, notification, Spin } from "antd";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { Titlebar } from "../../components";
import LayoutContent from "../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper";
import CompanyForm from "./form";
import { AccountContext } from "../../context/AccountContext";
import desktopLogo from "../../assets/img/unitifi-logo.png";

class CompanyEdit extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    const userContext = this.context;

    this.setState({
      loading: true,
    });

    delete values.practiceId;
    const variables = {
      ...values,
      id: this.props.currentPortalId,
    };

    this.props
      .editportal({ variables })
      .then(({ data }) => {
        if (data.editPortal.ok) {
          userContext.setLogoUpdate(variables.siteLogo ? variables.siteLogo : desktopLogo);
          let account = userContext.account;
          account.portalname = variables.name;
          account.firstName = variables.primaryContactFirstName;
          account.lastName = variables.primaryContactLastName;
          userContext.setAccount(account);
          this.handleSuccess(`company successfully updated.`);
          return;
        }

        this.handleError(data.editPortal.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === "deleted" ? "Practice Deleted Successfully" : "Company Updated Successfully",
      //description,
    });

    this.props.history.push("/company");
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: err.message,
        // description: err.message,
      });
    });
  }

  render() {
    const {
      data: { loading, error, getPortal },
    } = this.props;
    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice={this.props.currentPractice} displayText="Edit Company" />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice={this.props.currentPractice} displayText="Edit Company" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - company didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <Titlebar currentPractice={this.props.currentPractice} displayText="Edit Company" />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <CompanyForm loading={this.state.loading} onDelete={this.handleDeleteConfirm} onSubmit={this.handleSubmit} portal={getPortal} toggleLoading={this.toggleLoading} gblUserRole={this.props.gblUserRole} {...this.props} />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getPortal = gql`
  query($id: Int) {
    getPortal(id: $id) {
      id
      name
      email
      slug
      description
      status
      siteLogo
      address
      siteLogoS3Token
      primaryContactAddress
      primaryContactZipCode
      primaryContactCity
      primaryContactCountry
      primaryContactState
      primaryContactSuite
      primaryContactEmail
      primaryContactFirstName
      primaryContactLastName
      total_clients
      total_practice
      team_size
      assessmentNotification
      notes
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

const portalEdit = gql`
  mutation editPortal(
    $id: Int!
    $status: String
    $siteLogo: String
    $siteLogoS3Token: String
    $primaryContactAddress: String
    $primaryContactZipCode: String
    $primaryContactCity: String
    $primaryContactCountry: String
    $primaryContactState: String
    $primaryContactSuite: String
    $primaryContactEmail: String
    $primaryContactFirstName: String
    $primaryContactLastName: String
    $assessmentNotification: Boolean
    $notes: String
    $name: String
  ) {
    editPortal(
      id: $id
      status: $status
      siteLogo: $siteLogo
      siteLogoS3Token: $siteLogoS3Token
      primaryContactAddress: $primaryContactAddress
      primaryContactZipCode: $primaryContactZipCode
      primaryContactCity: $primaryContactCity
      primaryContactCountry: $primaryContactCountry
      primaryContactState: $primaryContactState
      primaryContactSuite: $primaryContactSuite
      primaryContactEmail: $primaryContactEmail
      primaryContactFirstName: $primaryContactFirstName
      primaryContactLastName: $primaryContactLastName
      assessmentNotification: $assessmentNotification
      notes: $notes
      name: $name
    ) {
      ok
      portal {
        id
        name
        slug
        description
      }
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPortal, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.currentPortalId),
      },
    }),
  }),
  graphql(portalEdit, { name: "editportal" })
)(CompanyEdit);
