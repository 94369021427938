import React from 'react';
import { Alert, Button, Form, Input, Select, Badge } from 'antd';
import PropTypes from 'prop-types';
import { FormWrapper } from '../../../components/form';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

// const cleanPracticeId = value => value
//   .trim()
//   .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
//   .replace(/\s{2,}/g, ' ')
//   .replace(/[^a-zA-Z0-9]/g, '-')
//   .replace(/_+$/, '')
//   .toLowerCase();

class AdvisorTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      siteLogo: this.props.practice && this.props.practice.siteLogo
        ? this.props.practice.siteLogo
        : null,
      siteLogoS3Token: this.props.practice && this.props.practice.siteLogoS3Token
        ? this.props.practice.siteLogoS3Token
        : null,
      clientAssessmentTitle: "",
      clientAssessmentBody: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
      }

      if (!err) {
        this.props.onSubmit({
          ...values,
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <h2>
            {this.props.practice.name}
          </h2>
          <br />
          <h3>Client Assessment Message </h3>
          <FormItem label="Subject Line">
            {getFieldDecorator('clientAssessmentTitle', {
              initialValue: this.props.advisor.clientAssessmentTitle || this.props.practice.clientAssessmentTitle,
            })(<Input/>)}
          </FormItem>
          <FormItem label="Assessment Invite Body: (1000 character limit)" style={{marginBottom: '0.5rem'}}>
            {getFieldDecorator('clientAssessmentBody', {
              initialValue: this.props.advisor.clientAssessmentBody || this.props.practice.clientAssessmentBody,
            })(<TextArea rows={10} />)}
          </FormItem>
          <Alert type="info" message="Params: [[CLIENT_FIRST_NAME]], [[PRACTICE_NAME]], [[SYS_GENERATED_LINK]], [[ADVISOR_FIRST_NAME]], [[ADVISOR_LAST_NAME]]" />
          <br />
          <FormItem
            className="input-small"
            label="Assessment Completed Notification:"
          >
            {getFieldDecorator('assessmentNotification', {
              initialValue: this.props.advisor.assessmentNotification,
            })(
              <Select
                placeholder="Notification"
              >
                <Option value={true}>
                  <Badge status="success" text="Yes, send me notification" />
                </Option>
                <Option value={false}>
                  <Badge status="error" text="No, don't send me notification" />
                </Option>
              </Select>)}
          </FormItem>
          <br />
          <div className="form-toolbar">
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={this.handleSubmit}
             >
             Save Templates
             </Button>
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

AdvisorTemplateForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  practice: PropTypes.object.isRequired,
  advisor: PropTypes.object.isRequired,
};

export default Form.create()(AdvisorTemplateForm);
