import moment from 'moment';

const dateAssessment = [

  {
    dateRange: {
      endDate: null,
      startDate: null,
    },
    label: 'Select All',
    key:1,
  },
  {
    dateRange: {
      endDate: moment()
        .utc()
        .format('YYYY-MM-DD'),
      startDate: moment()
        .utc()
        .subtract(3, 'days')
        .format('YYYY-MM-DD'),
    },
    label: '≤ 3 days',
    key:2,
  },
  {
    dateRange: {
      endDate: moment()
      .utc()
      .subtract(4, 'days')
      .format('YYYY-MM-DD'),
      startDate: moment()
      .utc()
      .subtract(7, 'days')
      .format('YYYY-MM-DD'),
    },
    label: '4-7 days', key:3,
  },
  {
    dateRange: {
      endDate: moment()
      .utc()
      .subtract(8, 'days')
      .format('YYYY-MM-DD'),
      startDate: moment()
      .utc()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    },
    label: '8-30 days', key:4,
  },
  {
    dateRange: {
      endDate: moment()
      .utc()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
      startDate: null,
    },
    label: '> 30 days', key:5,
  },
  
];

export default dateAssessment;
