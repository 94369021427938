import React, { useState } from "react";
import { Button, Form, Icon, Input, Radio, Spin, Modal, notification } from "antd";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormWrapper } from "../form";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { LowerCasePassCheck, NumberPassCheck, SpacialCharPassCheck, UpperCasePassCheck } from "../../helpers/formatters";
import IntlMessages from "../../helpers/intlMessages";
import Info from "../../assets/img/info-blue.svg";
const FormItem = Form.Item;

const UpdatePassword = (props) => {
  const { form, setPasswordShow } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const [passwordShow, setPasswordDisplay] = useState({
    pass: true,
    new: true,
    retype: true,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        let variables = { password: values.password, currentPassword: values.currentPassword };

        let result = await client.mutate({
          mutation: gql`
            mutation($password: String!, $currentPassword: String!) {
              passwordUpdate(password: $password, currentPassword: $currentPassword) {
                ok
                error
                message
              }
            }
          `,
          variables: variables,
        });
        setLoading(false);
        if (result && result.data.passwordUpdate.ok) {
          notification.success({ message: result.data.passwordUpdate.message });
        } else {
          // notification.error({ message: result.data.passwordUpdate.error });
          form.setFields({
            currentPassword: {
              value: values.currentPassword,
              errors: [new Error(result.data.passwordUpdate.error)],
            },
          });
          return;
        }
        setPasswordShow(false);
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== getFieldValue("password")) {
      callback("New Password and Re-Type Password not match");
    } else {
      callback();
    }
  };

  const passwordRequirementHandler = (rule, value, callback) => {
    if (value) {
      if (value.length < 8 || !UpperCasePassCheck.test(value) || !LowerCasePassCheck.test(value) || !SpacialCharPassCheck.test(value) || !NumberPassCheck.test(value)) {
        callback("The password you've entered is invalid.");
      } else callback();
    } else callback();
  };

  const passwordHandler = (type) => {
    setPasswordDisplay({ ...passwordShow, [type]: !passwordShow[type] });
  };

  const pass = getFieldValue("password");
  const passwordValidatorPop = (
    <Popover className="popoverModal" id="popover-basic">
      {/* <Popover.Header>Password Requiremends</Popover.Header> */}
      <Popover.Body>
        <div className="tooltip-title mb-2 text-center">Password Requirements</div>
        <div className={pass ? `input-password-feedback-signup ${pass.length >= 8 ? "success" : "error"}` : "input-password-feedback-signup"}>
          <Icon type={pass ? `${pass.length >= 8 ? "check" : "close"}-circle` : "exclamation-circle"} />
          <IntlMessages id="passwordFeedback.length" />
        </div>
        <div className={pass ? `input-password-feedback-signup ${UpperCasePassCheck.test(pass) ? "success" : "error"} ` : "input-password-feedback-signup"}>
          <Icon type={pass ? `${UpperCasePassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
          <IntlMessages id="passwordFeedback.uppercase" />
        </div>
        <div className={pass ? `input-password-feedback-signup ${LowerCasePassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
          <Icon type={pass ? `${LowerCasePassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
          <IntlMessages id="passwordFeedback.lowercase" />
        </div>
        <div className={pass ? `input-password-feedback-signup ${SpacialCharPassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
          <Icon type={pass ? `${SpacialCharPassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
          <IntlMessages id="passwordFeedback.specialCharacter" />
        </div>
        <div className={pass ? `input-password-feedback-signup ${NumberPassCheck.test(pass) ? "success" : "error"}` : "input-password-feedback-signup"}>
          <Icon type={pass ? `${NumberPassCheck.test(pass) ? "check" : "close"}-circle` : "exclamation-circle"} />
          <IntlMessages id="passwordFeedback.number" />
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="public-form-wrapper">
      <FormWrapper className="public-form">
        <div className="unitifi-login-header-bar">
          <h5>Update Password</h5>
        </div>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <FormItem label="Current Password">
            {getFieldDecorator("currentPassword", {
              rules: [
                {
                  message: "Current Password is required",
                  required: true,
                },
              ],
            })(
              <div className={`unitifi-pw-hide-show ${passwordShow.pass ? "hide-pw" : "show-pw"}`}>
                <Input type={passwordShow.pass ? "password" : "text"} />
                <i onClick={() => passwordHandler("pass")}>
                  {/* show icon */}
                  <svg id="img" className="show-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M0 7.5C0 5.25 3.75 0 10.5 0S21 5.25 21 7.5 17.25 15 10.5 15 0 9.75 0 7.5Zm10.5 4.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm2.7-4.75a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z"
                    />
                  </svg>
                  {/* show icon */}
                  {/* hide show icon */}
                  <svg id="img" className="hide-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19">
                    <path
                      fill="currentColor"
                      fill-rule="nonzero"
                      d="M11 4c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C9.74 4.13 10.35 4 11 4ZM1 1.27l2.28 2.28.46.46A11.804 11.804 0 0 0 0 9c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L18.73 19 20 17.73 2.27 0 1 1.27ZM6.53 6.8l1.55 1.55C8.03 8.56 8 8.78 8 9c0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2Zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01Z"
                    />
                  </svg>
                  {/* hide show icon */}
                </i>
              </div>
            )}
          </FormItem>
          <FormItem
            className="remove-icon"
            label={
              <p className="mb-0">
                New Password <span class="text-danger">*</span>
                <OverlayTrigger trigger="hover" rootClose placement="top" overlay={passwordValidatorPop}>
                  <i>
                    <img src={Info} />
                  </i>
                </OverlayTrigger>
              </p>
            }>
            {getFieldDecorator("password", {
              rules: [
                {
                  message: "Password is required",
                  required: true,
                },
                {
                  validator: passwordRequirementHandler,
                },
              ],
            })(
              <div className={`unitifi-pw-hide-show ${passwordShow.new ? "hide-pw" : "show-pw"}`}>
                <Input type={passwordShow.new ? "password" : "text"} />
                <i onClick={() => passwordHandler("new")}>
                  {/* show icon */}
                  <svg id="img" className="show-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M0 7.5C0 5.25 3.75 0 10.5 0S21 5.25 21 7.5 17.25 15 10.5 15 0 9.75 0 7.5Zm10.5 4.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm2.7-4.75a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z"
                    />
                  </svg>
                  {/* show icon */}
                  {/* hide show icon */}
                  <svg id="img" className="hide-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19">
                    <path
                      fill="currentColor"
                      fill-rule="nonzero"
                      d="M11 4c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C9.74 4.13 10.35 4 11 4ZM1 1.27l2.28 2.28.46.46A11.804 11.804 0 0 0 0 9c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L18.73 19 20 17.73 2.27 0 1 1.27ZM6.53 6.8l1.55 1.55C8.03 8.56 8 8.78 8 9c0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2Zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01Z"
                    />
                  </svg>
                  {/* hide show icon */}
                </i>
              </div>
            )}
          </FormItem>

          <FormItem label="Re-Type New Password ">
            {getFieldDecorator("confirmPassword", {
              rules: [
                {
                  message: "Re-Type New Password is required",
                  required: true,
                },
                {
                  validator: compareToFirstPassword,
                },
              ],
            })(
              <div className={`unitifi-pw-hide-show ${passwordShow.retype ? "hide-pw" : "show-pw"}`}>
                <Input type={passwordShow.retype ? "password" : "text"} />
                <i onClick={() => passwordHandler("retype")}>
                  {/* show icon */}
                  <svg id="img" className="show-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M0 7.5C0 5.25 3.75 0 10.5 0S21 5.25 21 7.5 17.25 15 10.5 15 0 9.75 0 7.5Zm10.5 4.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm2.7-4.75a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z"
                    />
                  </svg>
                  {/* show icon */}
                  {/* hide show icon */}
                  <svg id="img" className="hide-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19">
                    <path
                      fill="currentColor"
                      fill-rule="nonzero"
                      d="M11 4c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C9.74 4.13 10.35 4 11 4ZM1 1.27l2.28 2.28.46.46A11.804 11.804 0 0 0 0 9c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L18.73 19 20 17.73 2.27 0 1 1.27ZM6.53 6.8l1.55 1.55C8.03 8.56 8 8.78 8 9c0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2Zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01Z"
                    />
                  </svg>
                  {/* hide show icon */}
                </i>
              </div>
            )}
          </FormItem>
          <div className="modal-footer-bnts-wrapper">
            <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={loading} className="unitifi-login-btn">
              Save
            </Button>
            <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setPasswordShow(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default Form.create()(UpdatePassword);
