import React, { useCallback, useContext, useState } from "react";
import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, Modal, message, Icon } from "antd";
import { Tab, Tabs, OverlayTrigger, Popover } from "react-bootstrap";
import { FormWrapper } from "../form";
import rightIcon from "../../assets/img/promo-right.png";
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import debounce from "lodash/debounce";
import moment from "moment";
import { convertCurrency } from "../../helpers/formatters";

const FormItem = Form.Item;
const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;

const PaymentMethod = (props) => {
  const { planData, form, mutate, history, location, user, planList, setSelectedPlan } = props;
  const client = useApolloClient();
  const { getFieldProps, getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const cardElement = elements.getElement(CardNumberElement);
        const { token, error } = await stripe.createToken(cardElement, {
          address_zip: values.zipcode,
        });

        if (!token) {
          setLoading(false);
          message.error("Invalid Card Detail");
          return;
        }
        if (planData.discountError) {
          setLoading(false);
          return;
        }
        let variables = {
          token: token.id,
          user_id: parseInt(user.id),
          email: user.email,
          first_name: values.firstname,
          last_name: values.lastname,
          plan_price: planData.plan_price,
          team_size: parseInt(planData.teamSize),
          zipcode: values.zipcode,
          totalAmount: planData.totalAmount,
        };
        if (values.discountcode) {
          variables.discountcode = values.discountcode;
          variables.totalPayAmount = planData.totalPayAmount;
        }

        let result = await client.mutate({
          mutation: gql`
            mutation($token: String!, $user_id: Int!, $email: String!, $first_name: String!, $last_name: String!, $plan_price: String!, $team_size: Int!, $discountcode: String, $zipcode: String, $totalAmount: Float, $totalPayAmount: Float) {
              createCustomerAndSubscription(
                token: $token
                user_id: $user_id
                email: $email
                first_name: $first_name
                last_name: $last_name
                plan_price: $plan_price
                team_size: $team_size
                discountcode: $discountcode
                zipcode: $zipcode
                totalAmount: $totalAmount
                totalPayAmount: $totalPayAmount
              ) {
                status
                message
                token
                refreshToken
                errors {
                  message
                  path
                }
              }
            }
          `,
          variables: variables,
        });

        if (result.data.createCustomerAndSubscription.status == "true") {
          message.success(result.data.createCustomerAndSubscription.message);
          localStorage.setItem("token", result.data.createCustomerAndSubscription.token);
          localStorage.setItem("refreshToken", result.data.createCustomerAndSubscription.refreshToken);
          {
            /* DASHBOARD */
          }
          history.push("/news-center");
        } else if (result.data.createCustomerAndSubscription.status == "422") {
          result.data.createCustomerAndSubscription.errors.forEach((element) => {
            message.error(element.message);
          });
        } else message.error(result.data.createCustomerAndSubscription.message);

        setLoading(false);
      }
    });
  };

  const couponCodeHandler = async (input) => {
    setLoadingCode(true);
    let result = await client.query({
      query: gql`
        query($couponCode: String) {
          checkCouponCode(couponCode: $couponCode) {
            ok
            result
            message
          }
        }
      `,
      fetchPolicy: "network-only",
      variables: {
        couponCode: input.trim(),
      },
    });

    if (result && result.data.checkCouponCode.ok) {
      result = result.data.checkCouponCode.result;

      if (result && result.coupon) {
        let plans = planData;
        let resultCode = result;
        result = result.coupon;
        if (resultCode.max_redemptions) {
          if (resultCode.times_redeemed >= resultCode.max_redemptions) {
            setSelectedPlan({ ...planData, discountError: "This promotion code has expired", discountAmount: null, discountPer: null, totalPayAmount: null });
            setLoadingCode(false);
            return;
          }
        }
        if (resultCode.expires_at) {
          let now = moment();
          let expire = moment.unix(resultCode.expires_at);

          let compareDate = now.isAfter(expire, "day");
          if (compareDate) {
            setSelectedPlan({ ...planData, discountError: "This promotion code has expired", discountAmount: null, discountPer: null, totalPayAmount: null });
            setLoadingCode(false);
            return;
          }
        }

        if (resultCode.restrictions.minimum_amount) {
          let minimumAmount = resultCode.restrictions.minimum_amount;
          minimumAmount = minimumAmount / 100;

          if (plans.totalAmount < minimumAmount) {
            setSelectedPlan({ ...planData, discountError: `A minimum of $${minimumAmount} is required for this promotion code`, discountAmount: null, discountPer: null, totalPayAmount: null });
            setLoadingCode(false);
            return;
          }
        }

        if (result.amount_off) {
          let amount = result.amount_off / 100;
          if (plans.totalAmount - amount < 0) {
            setLoadingCode(false);
            return;
          }
          plans.discountAmount = amount;
          plans.totalPayAmount = plans.totalAmount - amount;
        } else {
          let discountAmount = (plans.totalAmount * result.percent_off) / 100;
          plans.discountAmount = discountAmount;
          plans.discountPer = result.percent_off;
          plans.totalPayAmount = plans.totalAmount - discountAmount;
        }
        plans.discountError = null;
        setSelectedPlan(plans);
      } else {
        setSelectedPlan({ ...planData, discountAmount: null, discountPer: null, totalPayAmount: null, discountError: `No such promotion code: ${input.trim()}` });
      }
    } else {
      setSelectedPlan({ ...planData, discountAmount: null, discountPer: null, totalPayAmount: null, discountError: null });
    }
    setLoadingCode(false);
  };

  // const disableHandler = (input) => {
  //   if (input.length == 0) return;
  //   if (loading) return;
  //   setLoadingCode(true);
  // };

  const disableHandler = useCallback(
    debounce((inputText, callback) => {
      couponCodeHandler(inputText);
    }, 800),

    []
  );
  return (
    <>
      <div className="uni-payment-method-subheader">
        <h4 className="unitifi-payment-title">Payment Method</h4>
        <p>Enter information below to complete transaction.</p>
      </div>
      <ul>
        <li>
          <span className="text-highlight">{planData.teamSize}</span> team member{planData.teamSize > 1 && "s"}
        </li>
        <li>
          Transaction total of
          <span className={`${planData.totalPayAmount != null ? "text-highlight strikeAmt" : "text-highlight"}`}> ${convertCurrency(planData.totalAmount)}</span>
          {planData.totalPayAmount != null && <span className="text-highlight">${convertCurrency(planData.totalPayAmount)}</span>} billed {planData.plan_type == "2" ? "annually" : "monthly"}
        </li>
        <li>
          Next payment <span className="text-highlight">{planData.nextPaymentDate}</span>
        </li>
      </ul>
      <div className="unitifi-payment-card-wrapper">
        <FormWrapper>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <FormItem label="First Name">
              {getFieldDecorator("firstname", {
                initialValue: user.firstName,
                rules: [
                  {
                    message: "First Name is required",
                    required: true,
                  },
                ],
              })(<Input autoCorrect="off" autoCapitalize="none" placeholder="First Name" />)}
            </FormItem>

            <FormItem label="Last Name">
              {getFieldDecorator("lastname", {
                initialValue: user.lastName,
                rules: [
                  {
                    message: "Last Name is required.",
                    required: true,
                  },
                ],
              })(<Input autoCorrect="off" autoCapitalize="none" placeholder="Last Name" />)}
            </FormItem>

            <FormItem label="Card Number">
              {getFieldDecorator("cardnumber", {
                rules: [
                  {
                    message: "Card Number is required.",
                    required: true,
                  },
                ],
              })(
                <CardNumberElement
                  className="text-center"
                  options={{
                    style: {
                      base: {
                        fontSize: "14px",
                        color: "#23272c",
                        fontFamily: "Lato",
                        "::placeholder": {
                          color: "#A3A3A3",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              )}
            </FormItem>

            <Row gutter={16}>
              <Col span={8} className="gutter-row">
                <FormItem label="Security Code" className="mb-0">
                  {getFieldDecorator("cardcsv", {
                    rules: [
                      {
                        message: "Card CVC is required.",
                        required: true,
                      },
                    ],
                  })(
                    <CardCvcElement
                      options={{
                        style: {
                          base: {
                            fontSize: "14px",
                            color: "#23272c",
                            fontFamily: "Lato",
                            "::placeholder": {
                              color: "#A3A3A3",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  )}
                  {/* <Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="CVC" /> */}
                </FormItem>
              </Col>
              <Col span={8} className="gutter-row">
                <FormItem label="Expiration" className="mb-0">
                  {getFieldDecorator("cardexp", {
                    rules: [
                      {
                        message: "Card expiration is required.",
                        required: true,
                      },
                    ],
                  })(
                    <CardExpiryElement
                      options={{
                        style: {
                          base: {
                            fontSize: "14px",
                            color: "#23272c",
                            fontFamily: "Lato",
                            "::placeholder": {
                              color: "#A3A3A3",
                            },
                          },
                          invalid: {
                            color: "#9e2146",
                          },
                        },
                      }}
                    />
                  )}
                  {/* <Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="MM/YY" /> */}
                </FormItem>
              </Col>
              <Col span={8} className="gutter-row">
                <FormItem label="Postal Code/ZIP Code" className="mb-0">
                  {getFieldDecorator("zipcode", {
                    rules: [
                      {
                        message: "Postal Code/ZIP Code is required.",
                        required: true,
                      },
                    ],
                  })(<Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="Code" />)}
                </FormItem>
              </Col>
            </Row>

            <FormItem label="Discount code">
              {getFieldDecorator("discountcode", {
                rules: [
                  {
                    required: false,
                  },
                ],
              })(
                <Row gutter={16}>
                  <Col className="gutter-row" span={23}>
                    <Input className="mb-2" autoCorrect="off" autoCapitalize="none" placeholder="Discount Code" onChange={(input) => disableHandler(input.target.value)} />
                    {planData.totalPayAmount != null && (
                      <div class="d-flex align-items-center">
                        <img src={rightIcon} />
                        <p className="ms-2 mb-0">
                          Promo Code Applied: {planData.discountPer && `${planData.discountPer}%`} ($
                          {convertCurrency(planData.discountAmount)}) off per billing cycle!
                        </p>
                      </div>
                    )}
                    {planData.discountError && (
                      <div class="d-flex align-items-center">
                        <p className="ms-2 mb-0 text-danger">{planData.discountError}</p>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </FormItem>
            <Button block type="primary" size="large" htmlType="submit" disabled={loading || loadingCode} loading={loading} className="unitifi-login-btn">
              Complete
            </Button>
          </Form>
        </FormWrapper>
      </div>
    </>
  );
};

export default Form.create()(PaymentMethod);
