import styled from 'styled-components';
import { palette } from 'styled-theme';

const UploadWrapper = styled.div`
  .ant-upload.ant-upload-select-picture-card {
    height: 80px;
    margin-bottom: 0;
    margin-right: 0;
    width: 190px;

    &:hover {
      border-color: ${palette('primary', 0)};
    }

    .anticon {
      font-size: 32px;
    }

    .preview {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .anticon {
        color: ${palette('base', 0)};
        left: 50px;
        position: absolute;
        top: 40px;
      }

      img {
        height: 60px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .img-preview-wrapper{
    margin-bottom: 0;
    margin-right: 0;
    position:relative;
    .preview{
      width: auto;
      height: 90px;
      img{
        height:100%;
        width:100%;
        object-fit:contain;
      }
    }
  }
  .icon-wrapper{
    img{
      cursor:pointer;
      position:absolute;
      top: 5px;
      right:-10px;
      background:#181818;
      border-color:#EA3E3E;
      border-radius:50px;
    }
  }
`;

export default UploadWrapper;
