import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Icon, Modal, notification, Spin } from "antd";
import { Redirect } from "react-router";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import AdvisorForm from "../form/advisorsForm";
import TitlebarWrapper from "../../../components/titlebar/index.style";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";

const { confirm } = Modal;

class AdvisorEdit extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : `/${this.props.currentPractice}/advisors`,
      currentPractice: this.props.currentPractice,
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removeAdvisor = this.removeAdvisor.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.data.getAdvisor.id,
      practiceId: this.props.currentPractice,
    };

    this.props
      .editAdvisor({ variables })
      .then(({ data }) => {
        if (data.editAdvisor.ok && !data.editAdvisor.errors) {
          this.handleSuccess(`${data.editAdvisor.advisor.firstName} ${data.editAdvisor.advisor.lastName} has been successfully updated.`);
          return;
        }

        this.handleError(data.editAdvisor.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDeleteConfirm() {
    confirm({
      title: "Delete Advisor",
      content: `Are you sure you want to permanently delete ${this.props.data.getAdvisor.firstName} ${this.props.data.getAdvisor.lastName}? This action cannot be undone.`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",
      onOk: this.removeAdvisor,
      width: "680px",
    });
  }

  removeAdvisor() {
    this.props
      .deleteAdvisor({ variables: { id: this.props.data.getAdvisor.id } })
      .then(({ data }) => {
        if (data.deleteAdvisor.ok && !data.deleteAdvisor.errors) {
          this.handleSuccess(`${this.props.data.getAdvisor.firstName} ${this.props.data.getAdvisor.lastName} has been successfully deleted.`, "deleted");
          return;
        }

        this.handleError(data.deleteAdvisor.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === "deleted" ? "Advisor Deleted Successfully" : "Advisor Updated Successfully",
      //description,
    });
    this.props.history.push(`/advisors`);
    // this.setState({ redirectTo: this.state.cancelUrl ? this.state.cancelUrl : `/${this.state.currentPractice}/advisors` });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    const {
      data: { loading, error, getAdvisor },
    } = this.props;

    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitlebarWrapper>
              <div className="title-bar-wrapper">
                <div className="title-wrapper">
                  <div className="title-text">Edit Advisor</div>
                  <div className="sub-title-text">{getAdvisor && getAdvisor.practiceId}</div>
                </div>
              </div>
            </TitlebarWrapper>
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice={this.props.currentPractice} displayText="Edit Advisor" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - advisor didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const userContext = this.context;
    const { account } = userContext;

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          {/* <TitlebarWrapper>
            <div className="title-bar-wrapper">
              <div className="title-wrapper">
                <div className="title-text">Edit Advisor</div>
                <div className="sub-title-text">{getAdvisor && getAdvisor.practiceId}</div>
              </div>
            </div>
          </TitlebarWrapper> */}
          <TitleBar title="Edit Advisor" subTitle={account && (account.accountType == 1 ? getAdvisor.practiceId : `${account.practicename} | ${account.portalname}`)} />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <AdvisorForm me={this.props.me} currentPractice={this.props.currentPractice} cancelUrl="/advisors" loading={this.state.loading} onDelete={this.handleDeleteConfirm} onSubmit={this.handleSubmit} advisor={getAdvisor} {...this.props} />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getAdvisorQuery = gql`
  query($id: Int!) {
    getAdvisor(id: $id) {
      id
      address
      city
      email
      firstName
      lastName
      practiceId
      phoneNumber
      phone_code
      state
      status
      zipCode
      country
      assessmentNotification
      roleId
      supports
      code
      isClientResultSentEmail
    }
  }
`;

const editAdvisorMutation = gql`
  mutation editAdvisor(
    $id: Int!
    $address: String
    $city: String
    $country: String
    $email: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $phone_code: String
    $practiceId: String
    $practice: Int
    $roleId: Int
    $state: String
    $status: String
    $zipCode: String
    $assessmentNotification: Boolean
    $isClientResultSentEmail: Boolean
  ) {
    editAdvisor(
      id: $id
      address: $address
      country: $country
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      phone_code: $phone_code
      practiceId: $practiceId
      practice: $practice
      roleId: $roleId
      state: $state
      status: $status
      zipCode: $zipCode
      isClientResultSentEmail: $isClientResultSentEmail
      assessmentNotification: $assessmentNotification
    ) {
      advisor {
        firstName
        lastName
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

const deleteAdvisorMutation = gql`
  mutation deleteAdvisor($id: Int!) {
    deleteAdvisor(id: $id) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getAdvisorQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editAdvisorMutation, { name: "editAdvisor" }),
  graphql(deleteAdvisorMutation, { name: "deleteAdvisor" })
)(AdvisorEdit);
