import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Form, Icon, Input, Select, Spin, AutoComplete } from "antd";
import PropTypes from "prop-types";
import { AddButtons, EditButtons, FormWrapper } from "../../../components/form";
import StateList from "../../../helpers/statesDataList";

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;

class ClientForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // onSelect(args) {
  //   console.log('hello world');
  //   for(let a of args){
  //     console.log(a);
  //   }

  // }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      data: { loading, error, getAllAdvisorsForForm },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - client didn&apos;t load.</p>
        </div>
      );
    }

    // const { practices } = getAllPracticesForForm;
    const { advisors } = getAllAdvisorsForForm;

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          {this.props.gblUserRole < 3 ? (
            <FormItem label="Advisor">
              {getFieldDecorator("advisorId", {
                initialValue: this.props.client ? this.props.client.advisorId : undefined,
                rules: [
                  {
                    message: "A client advisor is required.",
                    required: true,
                  },
                ],
              })(
                <Select filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} optionFilterProp="children" placeholder="Advisor" showSearch>
                  {(advisors || []).map((advisor) => (
                    <Option key={advisor.id} value={advisor.id}>
                      {advisor.firstName} {advisor.lastName}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          ) : null}
          <FormItem label="Client Email">
            {getFieldDecorator("email", {
              initialValue: this.props.client ? this.props.client.email : null,
              rules: [
                {
                  message: "A client email is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="Client Email" />)}
          </FormItem>
          <FormItem className="input-small" label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: this.props.client ? this.props.client.firstName : null,
              rules: [
                {
                  message: "A client first name is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="First Name" />)}
          </FormItem>
          <FormItem className="input-small" label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: this.props.client ? this.props.client.lastName : null,
              rules: [
                {
                  message: "A client last name is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="Last Name" />)}
          </FormItem>
          <FormItem className="input-small" label="Address">
            {getFieldDecorator("address", {
              initialValue: this.props.client ? this.props.client.address : null,
            })(<Input placeholder="Address" />)}
          </FormItem>
          <FormItem className="input-small" label="City">
            {getFieldDecorator("city", {
              initialValue: this.props.client ? this.props.client.city : null,
            })(<Input placeholder="City" />)}
          </FormItem>

          <FormItem className="input-xsmall" label="State">
            {getFieldDecorator("state", {
              initialValue: this.props.user && this.props.user.state ? this.props.user.state : undefined,
            })(
              <AutoComplete placeholder="Select a State" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {(StateList || []).map((state) => (
                  <AutoCompleteOption key={state.abbr} value={state.abbr}>
                    {state.name}
                  </AutoCompleteOption>
                ))}
              </AutoComplete>
            )}
          </FormItem>
          <FormItem className="input-xsmall" label="Zip Code">
            {getFieldDecorator("zipCode", {
              initialValue: this.props.client ? this.props.client.zipCode : null,
            })(<Input placeholder="Zip Code" />)}
          </FormItem>
          <FormItem className="input-phone__wrapper" label="Phone Number">
            <InputGroup>
              {getFieldDecorator("phoneNumber", {
                initialValue: this.props.client ? this.props.client.phoneNumber : null,
              })(
                <div className="input-phone__input">
                  {getFieldDecorator("p1", {
                    initialValue: this.props.client && this.props.client.phoneNumber ? this.props.client.phoneNumber.substring(0, 3) : null,
                  })(<Input maxLength={3} placeholder="867" />)}
                  <span>&#8211;</span>
                  {getFieldDecorator("p2", {
                    initialValue: this.props.client && this.props.client.phoneNumber ? this.props.client.phoneNumber.substring(3, 6) : null,
                  })(<Input maxLength={3} placeholder="530" />)}
                  <span>&#8211;</span>
                  {getFieldDecorator("p3", {
                    initialValue: this.props.client && this.props.client.phoneNumber ? this.props.client.phoneNumber.substring(6, 10) : null,
                  })(<Input maxLength={4} placeholder="9999" />)}
                </div>
              )}
            </InputGroup>
          </FormItem>
          <div className="form-toolbar">
            {this.props.client ? (
              <EditButtons
                cancelUrl={this.props.cancelUrl}
                loading={this.props.loading}
                onDelete={this.props.onDelete}
                onSubmit={this.handleSubmit}
                rights={["superAdmin", "createpracticeAdm", "createpracticeSup", "createPortalAdm", "createPortalSup"]}
              />
            ) : (
              <AddButtons
                cancelUrl={this.props.cancelUrl}
                createAnother={this.props.createAnother}
                loading={this.props.loading}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={["superAdmin", "createpracticeAdm", "createpracticeSup", "createPortalAdm", "createPortalSup"]}
              />
            )}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

ClientForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

ClientForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
};

const getAllAdvisorsForFormQuery = gql`
  query($practiceId: String) {
    getAllAdvisorsForForm(practiceId: $practiceId) {
      advisors {
        id
        firstName
        lastName
        practiceId
      }
    }
  }
`;

export default graphql(getAllAdvisorsForFormQuery, {
  options: (props) => ({
    fetchPolicy: "network-only",
    variables: {
      practiceId: props.currentPractice,
    },
  }),
})(Form.create()(ClientForm));
