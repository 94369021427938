import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, Modal, message } from "antd";
import React, { useState } from "react";
import logo from "../../../assets/img/unitifi.svg";
import PublicContent from "../../../layouts/public";
import SignupWrapper from "./index.style";
import { FormWrapper } from "../../../components/form";
import { NavLink } from "react-router-dom";

// images
import signupbg from "../../../assets/img/sign-up-bg.png";
import logoimg from "../../../assets/img/unitifi.svg";

const { Title, Text, Paragraph } = Typography;
const { Header, Content } = Layout;

const slugGenerator = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/_+$/, "")
    .toLowerCase();

const Signup_new = (props) => {
  const recaptchaRef = React.createRef();
  const [state, setState] = useState({
    visible: false,
    accept: false,
    verify: false,
  });

  const [error, setError] = useState({
    email: false,
  });

  const { form, mutate, history } = props;
  const { getFieldProps, getFieldDecorator } = form;

  const handleOk = () => {
    setState({ ...state, visible: false, accept: true });
  };

  const handleCancel = () => {
    setState({ ...state, visible: false, accept: false });
  };

  const onChecked = () => {
    setState({ ...state, visible: true });
  };

  return (
    <SignupWrapper>
      <div className="unitfi-signup-main">
        <div className="unitfi-signup-wrapper">
          <div className="unitfi-signup-img-div">
            <img src={signupbg} />
          </div>
          <div className="unitfi-signup-content-div">
            <NavLink to="/" className="logo-img-link">
              <img src={logoimg} />
            </NavLink>
            <NavLink to="/signup_account_type" className="unitfi-btn-wrapper">
              Create An Account{" "}
            </NavLink>
            <NavLink to="/login" className="unitfi-btn-wrapper white-bg-btn">
              Sign In{" "}
            </NavLink>
          </div>
        </div>
      </div>
      {/* </Content> */}
      {/* </Layout> */}
    </SignupWrapper>
  );
};
export default Form.create()(Signup_new);
