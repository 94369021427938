import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Table, Icon, Spin } from 'antd';
import { ButtonBar, Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import TeamsViewWrapper from './index.style';

class TeamsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPractice: this.props.currentPractice
    };
  }

  render() {
    const { data: { loading, error, getTeam } } = this.props;
    const { currentPractice } = this.state;

    const columns = [
      {
        dataIndex: 'name',
        render: (text, row) => (
          <span>
            {row.firstName}&nbsp;{row.lastName}
          </span>
        ),
        title: 'Members Name',
      },
      {
        dataIndex: 'email',
        title: 'Email',
      },
      {
        dataIndex: 'status',
        title: 'Status',
      },
    ];

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - user didn&apos;t load.</p>
        </div>
      );
    }

    /* set-up dynamic button bar & routes */

    return (
      <TeamsViewWrapper>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText={`Team: ${getTeam.name}`}
        />
        <div className="button-bar-wrapper">
          <ButtonBar
            icon="edit"
            link={`/${currentPractice}/teams/edit/${getTeam.id}`}
            text="Edit"
          />
        </div>
        <LayoutContentWrapper>
          <div>
            Team Members:
          </div>
          <br />
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={getTeam.advisors}
              pagination={false}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No members found.' }}
              rowKey={c => (c.id)}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </TeamsViewWrapper>
    );
  }
}

const getTeamQuery = gql`
  query($id: ID!, $practiceId: String! ) {
    getTeam(id: $id, practiceId: $practiceId){
      id
      name
      practice{
        id
        practiceId
      }
      advisors{
        id
        firstName
        lastName
        email
        status
      }
    }
  }
`;

export default graphql(getTeamQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      id: Number(props.match.params.id),
      practiceId: props.currentPractice
    },
  }),
})(TeamsView);
