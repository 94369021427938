import { dropRight, tail } from 'lodash';

/* returns default open keys for Sidebar component */
const OpenKeys = (pathName) => {
  const menuKeys = ['advisors', 'assessments', 'clients', 'practices', 'users', 'teams','portals'];
  const split = tail(pathName.split('/'));
  // console.log(split);

  /* if in site, exclude siteId */
  if (menuKeys.includes(split[1])) {
    split.shift();
  }

  if (split.includes(split[0])) {
    return [split[0]];
  }

  // if (split.includes('advisors')) {
  //   return ['advisors'];
  // }

  // if (split.includes('clients')) {
  //   return ['clients'];
  // }

  // if (split.includes('practices')) {
  //   return ['practices'];
  // }

  // if (split.includes('users')) {
  //   return ['users'];
  // }

  /** define key top-level menu key
   * convert if menu key does not match route
   * ex. - regions (route) is submenu item of locations (menu key) */
  if (split.length > 1 && split.length < 4) {
    return tail(split);
  }

  return tail(dropRight(split));
};

export default OpenKeys;
