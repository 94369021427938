import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Upload } from 'antd';
import { maxSize, validDocTypes, validMediaTypes } from '../../../config';
import UploadWrapper from './index.style';

const { Dragger } = Upload;
/**
 * processes a file upload and updates the UI
 */
class UploadDraggable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      loading: false,
      uploadError: false,
    };

    this.beforeUpload = this.beforeUpload.bind(this);
    this.stageUpload = this.stageUpload.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading) {
      this.setState({ loading: true });
    } else if (!nextProps.loading && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  beforeUpload(file) {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    let validUpload;
    let errorMessage = 'Invalid File Type';
    if (this.props.uploadType === 'Document') {
      validUpload = validDocTypes.includes(fileExtension);
    } else if (this.props.uploadType === 'Media') {
      validUpload = validMediaTypes.includes(fileExtension);
    } else if (file.size > maxSize) {
      validUpload = false;
      errorMessage = 'File cannot exceed 500MB';
    }

    if (!validUpload) {
      this.setState({ errorMessage, uploadError: true });
    }

    return validUpload;
  }

  stageUpload({ file }) {
    this.props.handleFileParams(file);
  }

  render() {
    const { loading, uploadError } = this.state;
    return (
      <UploadWrapper>
        <Dragger
          beforeUpload={this.beforeUpload}
          customRequest={this.stageUpload}
          disabled={loading}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <Icon type={loading ? 'loading' : 'inbox'} />
          </p>
          {!uploadError && !loading && <p className="ant-upload-text">Click or drag file to upload</p>}
          {uploadError && !loading && <p className="ant-upload-text error">{this.state.errorMessage}</p>}
          {loading && <p className="ant-upload-text">Uploading...</p>}
        </Dragger>
      </UploadWrapper>
    );
  }
}

UploadDraggable.propTypes = {
  handleFileParams: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  uploadType: PropTypes.string.isRequired,
};

export default UploadDraggable;
