import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Layout, Menu, Icon } from "antd";
import { Logo } from "../../components";
import SidebarWrapper from "./index.style";
import _, { tail } from "lodash";
import { AccountContext } from "../../context/AccountContext";
import { useQuery } from "react-apollo";
import MenuComp from "./component/menu";
import { useContext } from "react";

const { Sider } = Layout;

const rootSubmenuKeys = ["teams", "advisors", "template", "clients", "practices", "companies", "users"];

const SideBar = (props) => {
  const { gblUserIsGlobalAdmin, gblUserIsPracticeAdmin, gblUserIsPortalAdmin, showPracticeList, gblUserRole, collapsed, isMobile, openKeys, selectedKeys, location, currentPortalId, currentPractice, menuToggle } = props;
  // console.log("currentPortalId", currentPortalId);
  // console.log("currentPractice", currentPractice);
  const accountContext = useContext(AccountContext);
  const [state, setState] = useState([]);
  // const { loading, error, data, refetch } = useQuery(
  //   gql`
  //     {
  //       getAllPracticeIds {
  //         practices {
  //           practiceId
  //         }
  //       }
  //     }
  //   `,
  //   {
  //     fetchPolicy: "network-only",
  //     notifyOnNetworkStatusChange: true,
  //   }
  // );

  const { loading: logoLoading, data: logoData, refetch: portalRefetch } = useQuery(getPortalDetailsQuery, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { id: currentPortalId },
  });

  // useEffect(() => {
  //   if (data) {
  //     setState(data.getAllPracticeIds.practices.map((items) => items.practiceId));
  //     accountContext.setCurrentPractices(data.getAllPracticeIds.practices.map((items) => items.practiceId));
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (accountContext.logoUpdate) portalRefetch();
  // }, [accountContext.logoUpdate]);

  // const currentPractice = localStorage.getItem("current_practice");

  const menuItems = [
    {
      key: "dashboard",
      path: {
        admin: `/`,
        normal: `/${currentPractice}`,
      },
      label: "Dasboard",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "home",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "companies",
      path: {
        admin: `/companies`,
      },
      label: "Companies",
      permission: [],
      roles: [1, 4, 5],
      icon: "apartment",
      type: ["admin"],
      disabled: false,
    },
    {
      key: "practices",
      path: {
        admin: `/practices`,
        normal: `/${currentPractice}/practices`,
      },
      label: "Practices",
      permission: [],
      roles: [1, 4, 5, 2, 6],
      icon: "appstore",
      type: ["admin", "normal"],
      disabled: currentPractice ? false : tail(props.location.pathname.split("/")).length > 1,
    },
    {
      key: "advisors",
      path: {
        admin: `/advisors`,
        normal: `/${currentPractice}/advisors`,
      },
      label: "Advisors",
      permission: [],
      roles: [1, 2, 4, 5, 6],
      icon: "user",
      type: ["normal"],
      disabled: false,
    },
    {
      key: "template",
      path: {
        admin: `/template`,
        normal: `/${currentPractice}/advisors/template`,
      },
      label: "Assessment Template",
      permission: [],
      roles: [3],
      icon: "mail",
      type: ["normal"],
      disabled: false,
    },
    {
      key: "clients",
      path: {
        admin: `/clients`,
        normal: `/${currentPractice}/clients`,
      },
      label: "Clients",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "user",
      type: ["normal"],
      disabled: false,
    },
    {
      key: "teams",
      path: {
        admin: `/teams`,
        normal: `/${currentPractice}/teams`,
      },
      label: "Teams",
      permission: [],
      roles: [1, 2, 4],
      icon: "team",
      type: ["normal"],
      disabled: false,
    },
    {
      key: "users",
      className: "oka-menu-sidebar__divider",
      path: {
        admin: `/users`,
        normal: `/${currentPractice}/users`,
      },
      label: "Users",
      permission: [],
      roles: [1, 2, 4, 5, 6],
      icon: "user",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "return",
      path: {
        normal: "/practices",
      },
      label: "Return to Practice List",
      permission: [],
      roles: [1, 4, 5],
      icon: "caret-left",
      type: ["normal"],
      disabled: showPracticeList ? true : false,
      reset: true,
    },
    {
      key: "returnMain",
      path: {
        normal: "/",
      },
      label: "Main",
      permission: [],
      roles: [1, 4, 5],
      icon: "caret-left",
      type: ["normal"],
      disabled: false,
      reset: true,
    },
  ];

  const settingsItem = [
    {
      key: "general",
      path: {
        admin: `/settings/general`,
        normal: `/settings/general`,
      },
      label: "General",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "solution",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "account",
      path: {
        admin: `/settings/account`,
        normal: `/settings/account`,
      },
      label: "Account",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "file-protect",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "subscription",
      className: "oka-menu-sidebar__divider",
      path: {
        admin: `/settings/subscription`,
        normal: `/settings/subscription`,
      },
      label: "Subscription",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "credit-card",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "return",
      path: {
        admin: `/`,
        normal: `/${currentPractice}`,
      },
      label: "Back",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "caret-left",
      type: ["admin", "normal"],
      disabled: false,
    },
  ];
  const optionsItem = [
    {
      key: "profile",
      path: {
        admin: `/settings/general`,
        normal: `/settings/general`,
      },
      label: "Profile",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "solution",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "account",
      path: {
        admin: `/settings/account`,
        normal: `/settings/account`,
      },
      label: "Settings",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "file-protect",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "faq",
      className: "oka-menu-sidebar__divider",
      path: {
        admin: `/settings/subscription`,
        normal: `/settings/subscription`,
      },
      label: "FAQ",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "credit-card",
      type: ["admin", "normal"],
      disabled: false,
    },
    {
      key: "logout",
      path: {
        admin: `/`,
        normal: `/${currentPractice}`,
      },
      label: "Logout",
      permission: [],
      roles: [1, 2, 3, 4, 5, 6],
      icon: "caret-left",
      type: ["admin", "normal"],
      disabled: false,
    },
  ];

  // if (loading || error) {
  //   return (
  //     <SidebarWrapper>
  //       <Sider collapsed={collapsed} collapsible trigger={null} width={256} style={{ background: "white", borderRight: "1px solid rgba(238,242,244,1)" }} />
  //     </SidebarWrapper>
  //   );
  // }

  return (
    <SidebarWrapper>
      <Sider collapsed={collapsed} collapsible collapsedWidth={0} trigger={null} width={256} style={{ background: "white", borderRight: "1px solid rgba(238,242,244,1)" }}>
        {isMobile ? (
          <div className="logo" onClick={() => menuToggle()}>
            {accountContext.logoUpdate && <Logo practiceId={currentPractice} logoData={logoData} />}
          </div>
        ) : null}
        <MenuComp
          {...props}
          menuItems={openKeys.includes("settings") ? settingsItem : menuItems}
          optionsItem={optionsItem}
          gblUserRole={gblUserRole}
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          type={[1, 4, 5].includes(gblUserRole) && currentPractice === "null" ? "admin" : "normal"}
          location={location}
          currentPortalId={currentPortalId}
          currentPractice={currentPractice}
        />
      </Sider>
    </SidebarWrapper>
  );
};

export default SideBar;

const getPortalDetailsQuery = gql`
  query($id: Int) {
    getPortal(id: $id) {
      name
      siteLogo
    }
  }
`;
