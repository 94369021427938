import styled from 'styled-components';
// import { palette } from 'styled-theme';

const MenuWrapper = styled.div`
  .menuItem {
    border-color : transparent
  }
 .ant-menu-item-selected  > .menuItem {
    background: #F2F3F5!important
  }
  
   
    .topMenu{
      display:flex;
      align-items:center;
      justify-content:space-between;
      .ant-menu-item{
        a{
            text-decoration:none;
            .overViewIcon{
              //margin-left:12px;
              //margin-bottom:8px;
            }
            .news{
              display:block;
              font-size:15px;
              font-weight:700;
              line-height:17px;
              color:#181818;
            }
          }
      }
    }
   
  
`;

export default MenuWrapper;
