export default (decisionMakingStyle, plScore) => {
  // convert plScore to scale
  const x =
    plScore >= 1
      ? 100 - plScore // logical
      : plScore * -1 + 100; // passionate

  // default to 'Balanced' decision making style
  let obj = {
    fill: "#1072b7",
    x,
    y: 5,
    stroke: "#1072b7",
  };

  if (decisionMakingStyle === "Highly Passionate") {
    obj = {
      fill: "#589bcb",
      x,
      y: 5,
      stroke: "#589bcb",
    };
  }

  if (decisionMakingStyle === "Moderately Passionate") {
    obj = {
      fill: "#589bcb",
      x,
      y: 5,
      stroke: "#589bcb",
    };
  }

  if (decisionMakingStyle === "Somewhat Passionate") {
    obj = {
      fill: "#589bcb",
      x,
      y: 5,
      stroke: "#589bcb",
    };
  }

  if (decisionMakingStyle === "Somewhat Logical") {
    obj = {
      fill: "#054471",
      x,
      y: 5,
      stroke: "#054471",
    };
  }

  if (decisionMakingStyle === "Moderately Logical") {
    obj = {
      fill: "#054471",
      x,
      y: 5,
      stroke: "#054471",
    };
  }

  if (decisionMakingStyle === "Highly Logical") {
    obj = {
      fill: "#054471",
      x,
      y: 5,
      stroke: "#054471",
    };
  }

  return obj;
};
