import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import {Badge, Button, Icon, Spin} from 'antd';
import { Table } from '../../../elements';
import { ButtonBar, Titlebar } from '../../../components';
import GenerateBadge from '../../../helpers/generateBadge';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';


class PortalsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {

    const {
      data: { loading, error, getUserPortalPractices = null },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            displayText="Portal"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            displayText="Portal"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - portal didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const { portal, practices } = getUserPortalPractices;
    const { id, name, description } = portal;
    
    const columns = [
      {
        dataIndex: 'name',
        render: (text, row) => <Button type={'link'} disabled={row.status === 'Inactive' } onClick={()=>{this.props.history.push(`/${row.practiceId}`);localStorage.setItem('current_practice',row.practiceId);localStorage.setItem('current_practice_id',row.id)}}>{text}</Button>,
        sorter: true,
        title: 'Practice Name',
      },
      {
        dataIndex: 'status',
        render: text => <Badge status={GenerateBadge(text)} text={text} />,
        sorter: true,
        title: 'Status',
      },
      {
            title: 'Company',
            sorter: true,
            render: text => <span>{name}</span>
      },
      {
        className: 'action-column',
        render: practice => <Link to={`/practices/edit/${id}/${practice.id}`}><Icon type="edit" /></Link>,
      },
    ];

    return (
      <div>
        <Titlebar
          displayText={name}
          subtitle={description}
        />
        <div className="button-bar-wrapper">
          <ButtonBar
            icon="plus"
            link={`/practices/add/${id}`}
            text="Add New"
          />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={practices}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No practices found.' }}
              pagination={false}
              rowKey="id"
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getPortalQuery = gql`
  query (
    $userId: Int!
    $slug: String!
  ){
    getUserPortalPractices(
      userId: $userId
      slug: $slug
    ){
      portal {
        id
        name
        slug
        email
        primaryContactEmail
        description
      }
      practices {
        id
        name
        practiceId
        status
      }
    }
  }
`;

export default graphql(getPortalQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      slug: props.match.params.slug,
      userId: props.gblUserId,
    },
  }),
})(PortalsView);
