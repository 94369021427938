import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TeamsAdd, TeamsEdit, TeamsList, TeamsView, TeamsClients } from '../../../containers/teams';

class TeamRoutes extends React.Component {
  render() {
    const {
      gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice,
      gblUserIsPortalAdmin
    } = this.props;

    return (
      <Switch>
        {/* pratice routes - catch trailing slashes & send to right route */}
        <Redirect
          exact
          from="/:practiceId/teams/"
          to="/:practiceId/teams"
          strict
        />
        <Redirect
          exact
          from="/:practiceId/teams/add/"
          to="/:practiceId/teams/add"
          strict
        />
        <Redirect
          exact
          from="/:practiceId/teams/edit/:id/"
          to="/:practiceId/teams/edit/:id"
          strict
        />
        <redirect
          exact
          from="/:practiceid/teams/view/:id/"
          to="/:practiceid/teams/view/:id"
          strict
        />
        <redirect
          exact
          from="/:practiceid/teams/clients/:id/"
          to="/:practiceid/teams/clients/:id"
          strict
        />
        {/* practice routes */}
        <Route
          exact
          path="/:practiceId/teams"
          render={props => (
            <TeamsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/teams/add"
          render={props => (
            <TeamsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/teams/edit/:id"
          render={props => (
            <TeamsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/teams/view/:id"
          render={props => (
            <TeamsView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/teams/clients/:id"
          render={props => (
            <TeamsClients
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect
          exact
          from="/clients/"
          to="/clients"
          strict
        />
        <Redirect
          exact
          from="/clients/add/"
          to="/clients/add"
          strict
        />
        <Redirect
          exact
          from="/clients/edit/:id/"
          to="/clients/edit/:id"
          strict
        />
        <Redirect
          exact
          from="/clients/view/:id/"
          to="/clients/view/:id"
          strict
        />
        {/* admin routes */}
        <Route
          exact
          path="/clients"
          render={props => (
            <TeamsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/add"
          render={props => (
            <TeamsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/edit/:id"
          render={props => (
            <TeamsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/view/:id"
          render={props => (
            <TeamsView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
      </Switch>
    );
  }
}

export default TeamRoutes;
