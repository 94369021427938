import React from "react";
import { Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ButtonsWrapper from "./index.style";
import CustomButton from "../../../commons/CustomButton";

function AddButtons(props) {
  return (
    <ButtonsWrapper>
      <CustomButton style={{ marginRight: 10 }} rights={props.rights} loading={props.loading} disabled={props.loading} onClick={props.onSubmit} text="Add New" type={"primary"}>
        Save
      </CustomButton>
      {/*<Button*/}
      {/*className="form-button"*/}
      {/*loading={props.loading}*/}
      {/*onClick={props.onSubmit}*/}
      {/*type="primary"*/}
      {/*>*/}
      {/*Create*/}
      {/*</Button>*/}
      <div className="form-button">
        <Link to={{ pathname: props.cancelUrl, defaultActiveKey: props.cancelState }}>
          <Button>Cancel {props.loading}</Button>
        </Link>
      </div>
      {/* <div className="form-button">
                <Checkbox checked={props.createAnother} onChange={props.onCreateAnother}>
                    Create another
                </Checkbox>
            </div> */}
    </ButtonsWrapper>
  );
}

AddButtons.defaultProps = {
  cancelState: null,
  rights: [],
};

AddButtons.propTypes = {
  cancelUrl: PropTypes.string.isRequired,
  cancelState: PropTypes.string,
  createAnother: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rights: PropTypes.any,
};

export default AddButtons;
