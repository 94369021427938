
import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import ButtonsWrapper from './index.style';

const FilterButtons = props => (
  <ButtonsWrapper>
    <div className="form-button">
      <Button onClick={props.onClear}>
        {props.onClearText ? props.onClearText : 'Clear'}
      </Button>
    </div>
    <Button
      className="form-button"
      onClick={props.onApply}
      type="primary"
    >
      {props.onApplyText ? props.onApplyText : 'Apply'}
    </Button>
  </ButtonsWrapper>
);

FilterButtons.defaultProps = {
  onApplyText: null,
  onClearText: null,
};

FilterButtons.propTypes = {
  onApply: PropTypes.func.isRequired,
  onApplyText: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  onClearText: PropTypes.string,
};

export default FilterButtons;
