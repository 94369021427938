import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdvisorsAdd, AdvisorsEdit, AdvisorsList, AdvisorsForm } from "../../../containers/advisors";
import { UsersView } from "../../../containers/users";

class AdvisorRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice, gblUserIsPortalAdmin, currentPortalId, me } = this.props;
    // console.log("gblUserIsGlobalAdmin", gblUserIsGlobalAdmin);
    if (!currentPortalId && !currentPractice) return <Redirect to="/settings/account" strict />;

    return (
      <Switch>
        {/* pratice routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/:practiceId/advisors/" to="/:practiceId/advisors" strict />
        <Redirect exact from="/:practiceId/advisors/add/" to="/:practiceId/advisors/add" strict />
        <Redirect exact from="/:practiceId/advisors/edit/:id/" to="/:practiceId/advisors/edit/:id" strict />
        <Redirect exact from="/:practiceId/advisors/view/:id/" to="/:practiceId/advisors/view/:id" strict />
        <Redirect exact from="/:practiceId/advisors/template/" to="/:practiceId/advisors/template" strict />
        {/* practice routes */}
        <Route
          exact
          // path="/:practiceId/advisors"
          path="/advisors"
          render={(props) => (
            <AdvisorsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/advisors/add"
          render={(props) => (
            <AdvisorsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/advisors/edit/:id"
          render={(props) => (
            <AdvisorsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/advisors/view/:id"
          render={(props) => (
            <UsersView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              currentPortalId={currentPortalId}
              gblUserRole={gblUserRole}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/advisors/template"
          render={(props) => (
            <AdvisorsForm
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              currentPortalId={currentPortalId}
              gblUserRole={gblUserRole}
              me={me}
            />
          )}
        />
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/advisors/" to="/advisors" strict />
        <Redirect exact from="/advisors/add/" to="/advisors/add" strict />
        <Redirect exact from="/advisors/edit/:id/" to="/advisors/edit/:id" strict />
        <Redirect exact from="/advisors/view/:id/" to="/advisors/view/:id" strict />
        {/* admin routes */}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/advisors"
            render={(props) => (
              <AdvisorsList
                {...props}
                currentPractice={currentPractice}
                gblUserId={gblUserId}
                gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                gblUserRole={gblUserRole}
              />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/advisors/add"
            render={(props) => (
              <AdvisorsAdd
                {...props}
                currentPractice={currentPractice}
                gblUserId={gblUserId}
                gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                gblUserRole={gblUserRole}
              />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/advisors/edit/:id"
            render={(props) => (
              <AdvisorsEdit
                {...props}
                currentPractice={currentPractice}
                gblUserId={gblUserId}
                gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                gblUserRole={gblUserRole}
              />
            )}
          />
        ) : null}
        {gblUserIsGlobalAdmin ? (
          <Route
            exact
            path="/advisors/view/:id"
            render={(props) => (
              <UsersView
                {...props}
                currentPractice={currentPractice}
                gblUserId={gblUserId}
                gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
                gblUserIsPortalAdmin={gblUserIsPortalAdmin}
                gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
                gblUserRole={gblUserRole}
              />
            )}
          />
        ) : null}
      </Switch>
    );
  }
}

export default AdvisorRoutes;
