import { head, tail,last } from 'lodash';

/* returns current practice, ignores common paths like users, practices & profile */
const CurrentPractice = (pathName) => {
  const split = tail(pathName.split('/'));
  //console.log("split",split)
  // if(split.length > 1){
  //   return head(split);
  // }else {
    return head(split);

};

export default CurrentPractice;
