import React from "react";
import { Redirect } from "react-router-dom";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { BackTop, Button, Collapse, Dropdown, Icon, Menu, notification, Spin, Col, Row, Typography, PageHeader } from "antd";
import axios from "axios";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { apiUrlExport, assessmentUrl } from "../../../config";
import { PassionLogicMap, SelfReportedMap, Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import ClientViewWrapper from "./index.style";

const { Panel } = Collapse;
const { Title } = Typography;

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  overflow: "hidden",
};

const customHeaderStyle = {
  color: "rgba(0,0,0,.65)",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "401(k)";
  }

  return "UCIT";
};

class ClientView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copySuccess: false,
      loading: false,
      pdfLoading: false,
    };

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.downloadAssessment = this.downloadAssessment.bind(this);
    this.sendClientAssessment = this.sendClientAssessment.bind(this);
  }

  copyToClipboard = (e, assUrl) => {
    navigator.clipboard.writeText(assUrl);
    this.setState({ copySuccess: true });
  };

  downloadAssessment(type) {
    this.setState({ pdfLoading: true });

    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const {
      currentPractice,
      data: {
        viewClient: { id, firstName, lastName },
      },
    } = this.props;

    axios
      .get(`${apiUrlExport}/${id}&${currentPractice}&${type}&${token}&${refreshToken}`, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        this.setState({ pdfLoading: false });

        saveAs(blob, `${firstName}-${lastName}-${GenerateReportType(type)}-${format(new Date(), "M-D-YYYY")}`);
      });
  }

  sendClientAssessment(assUrl) {
    this.setState({ loading: true });

    const { id, advisorId, practiceId } = this.props.data.viewClient;

    const variables = {
      assessmentUrl: assUrl,
      advisorId,
      clientId: id,
      practiceId,
    };

    this.props
      .sendClientAssessment({ variables })
      .then(({ data }) => {
        if (data.sendClientAssessment.ok && !data.sendClientAssessment.errors) {
          this.handleSuccess(`The assesment has been successfully sent to ${this.props.data.viewClient.email}.`);
          return;
        }

        this.handleError(data.sendClientAssessment.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description) {
    this.setState({ loading: false });

    notification.success({
      message: "Assessment Sent Successfully",
      //description,
    });

    this.props.data.refetch();
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    const {
      data: { loading, error, viewClient = null },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - clients didn&apos;t load.</p>
        </div>
      );
    }

    if (!viewClient) {
      return <Redirect to="/" />;
    }

    const { assessmentToken, dateSent, email, firstName, insights, lastName } = viewClient;

    if (!insights) {
      const newClient = this.props.location.state && this.props.location.state.newClient;

      return (
        <div>
          <Titlebar currentPractice={this.props.currentPractice} displayText={`${firstName} ${lastName}`} viewClient />
          <LayoutContentWrapper>
            <LayoutContent>
              <div style={{ marginBottom: "24px" }}>
                <p>{newClient ? `${firstName.trim()}'s assessment is ready.` : `${firstName.trim()} has not completed the assessment.`}</p>
              </div>
              <div style={{ marginBottom: "24px" }}>
                <p style={{ marginBottom: "8px" }}>
                  Send {firstName} an email with the assessment link to {email}.
                </p>
                <Button type="primary" loading={this.state.loading} onClick={() => this.sendClientAssessment(`${assessmentToken}`)}>
                  {dateSent ? "Resend" : "Send"} Assessment Link
                </Button>
                {dateSent && (
                  <p style={{ fontSize: "12px", marginTop: "8px" }}>
                    <i>
                      *Last sent {format(dateSent, "M/D/YYYY")} at {format(dateSent, "h:mm a")}
                    </i>
                  </p>
                )}
              </div>
              {/* only show copy to clipboard if broswe supports */}
              {document.queryCommandSupported("copy") && (
                <div>
                  <p style={{ marginBottom: "8px" }}>Copy the link for {firstName}&#39;s assessment to share.</p>
                  <Button type="primary" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentUrl}/${assessmentToken}`)}>
                    {this.state.copySuccess ? "Copied To Clipboard" : "Copy Assessment Link"}
                  </Button>
                </div>
              )}
            </LayoutContent>
          </LayoutContentWrapper>
        </div>
      );
    }

    return (
      <div>
        <BackTop />

        <Titlebar currentPractice={this.props.currentPractice} displayText={`${firstName} ${lastName}`} viewClient />
        <PageHeader
          ghost={false}
          subTitle="Assessment Actions:"
          class={{ paddingTop: 0 }}
          extra={[
            <Button type="primary" loading={this.state.loading} onClick={() => this.sendClientAssessment(`${assessmentUrl}/${assessmentToken}`)}>
              Resend Assessment Link
            </Button>,
            <Button type="primary" disabled={this.state.copySuccess} onClick={(e) => this.copyToClipboard(e, `${assessmentUrl}/${assessmentToken}`)}>
              {this.state.copySuccess ? "Copied To Clipboard" : "Copy Assessment Link"}
            </Button>,
          ]}></PageHeader>

        <Row>
          <Col span={23} style={{ textAlign: "center" }}>
            <Title style={{ margin: "1rem 0 0 0" }}>
              <span style={{ color: "rgba(0, 0, 0, 0.65)" }}>UCIT</span>
            </Title>
            Unitifi Consumer Insight Tool
          </Col>
          <Col span={1}>
            <div className="button-bar-wrapper has-dropdown download">
              <Dropdown
                overlayClassName="dropdown-download"
                overlay={
                  <Menu onClick={(e) => this.downloadAssessment(e.key)}>
                    <Menu.Item disabled>For Advisor</Menu.Item>
                    <Menu.Item key="401k">401(k)</Menu.Item>
                    <Menu.Item key="snapshot">Snapshot</Menu.Item>
                    <Menu.Item key="ucita">UCIT</Menu.Item>
                    <Menu.Divider />
                    <Menu.Item disabled>For Client</Menu.Item>
                    <Menu.Item key="ucitc">UCIT</Menu.Item>
                  </Menu>
                }
                trigger={["click"]}>
                <Button type="primary" icon="download" loading={this.state.pdfLoading} size="large">
                  Download
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>

        <ClientViewWrapper>
          <div className="main-chart-wrapper">
            {/* left bar / 3 divs */}
            <div className="left-col">
              <div className="item">
                <div className="heading">Scores</div>
                <div className="details-wrapper">
                  <div className="score-item-wrapper">
                    <div className="score-item">
                      <div className="risk-behavior-score">{insights.score.riskBehaviorScore}</div>
                      <div className="score-label">Risk Behavior</div>
                    </div>
                    <div className="slash">/</div>
                    <div className="score-item">
                      <div className="self-reported-score">{insights.score.selfReportedScore}</div>
                      <div className="score-label">Self-Reported</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Financial Personality</div>
                <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                  <div className="label">{insights.personality.behavior}</div>
                  <div className="callout">{insights.personality.type}</div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Decision Making Style</div>
                <div className="details-wrapper">
                  <PassionLogicMap data={insights} firstName={firstName} />
                </div>
              </div>
            </div>

            {/* chart */}
            <div className="right-col">
              <div className="chart-legend">
                <div style={{ alignItems: "center", display: "flex", marginRight: "24px" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(0,111,186,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Risk Behavior</div>
                </div>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(255,255,255,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Self-Reported</div>
                </div>
              </div>

              <div className="chart-wrapper">
                <SelfReportedMap data={insights} />
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">Communication Center</div>
              <div className="item-wrapper-flex">
                <div className="item-flex">
                  <div className="item-flex-heading">Expectation</div>
                  <div>{insights.profile.communicationExpectation}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Age</div>
                  <div>{new Date().getFullYear() - insights.retirement.birthYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Year</div>
                  <div>{insights.retirement.retirementYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Vehicle</div>
                  <div>{insights.profile.currentRetirementVehicle}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div className="wrapper">
              <div className="heading">
                Financial Personality Defined: {insights.personality.behavior} - {insights.personality.type}
              </div>

              <div className="item-wrapper">
                <div className="item has-below">
                  <div className="item-heading">Behaviors of this Investor</div>
                  <div>
                    <ul>
                      {insights.personality.attributes.investorBehaviors.map((i) => (
                        <li key={i}>{i}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="item">
                  <div className="item-heading">Tips for the Financial Professional</div>
                  <div>
                    <ul>
                      {insights.personality.attributes.advisorTips.map((i) => (
                        <li key={i}>{i}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main-wrapper">
            <div>
              <div className="heading">Behavior Attributes Recognized</div>

              <div className="item-wrapper">
                <div className="attribute-text">
                  The following tiles identify behavior attributes recognized for {firstName}. A <div className="circle"></div> indicates a <i>strongly</i> identified attribute, while a <div className="circle min"></div> indicates an identified
                  attribute.
                </div>

                <Collapse bordered={false}>
                  {/*  defaultActiveKey={insights.tendencies.slice(0, 1).map(t => t.name)} */}
                  {insights.tendencies.map((t) => (
                    <Panel
                      header={
                        <div style={{ alignItems: "center", display: "flex", paddingBottom: "4px" }}>
                          <div
                            style={
                              t.type === "Strongly Identified"
                                ? {
                                    background: "rgba(0,111,186,1)",
                                    borderRadius: "100%",
                                    height: "16px",
                                    marginRight: "8px",
                                    width: "16px",
                                  }
                                : {
                                    background: "#6fb6e0",
                                    borderRadius: "100%",
                                    height: "16px",
                                    marginRight: "8px",
                                    width: "16px",
                                  }
                            }></div>
                          <div style={customHeaderStyle}>{t.name}</div>
                        </div>
                      }
                      key={t.name}
                      style={customPanelStyle}>
                      <div style={{ marginLeft: "28px" }}>
                        <div style={{ marginBottom: "12px" }}>
                          <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Characteristics</div>
                          <ul>
                            {t.characteristics.map((c) => (
                              <li key={c}>{c}</li>
                            ))}
                          </ul>
                        </div>

                        <div style={{ marginBottom: "24px" }}>
                          <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Results</div>
                          <ul>
                            {t.results.map((c) => (
                              <li key={c}>{c}</li>
                            ))}
                          </ul>
                        </div>

                        <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Recommendations</div>
                        <ul>
                          {t.recommendations.map((c) => (
                            <li key={c}>{c}</li>
                          ))}
                        </ul>
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </div>
          </div>
        </ClientViewWrapper>
      </div>
    );
  }
}

const viewClientQuery = gql`
  query($id: Int!, $practiceId: String!) {
    viewClient(id: $id, practiceId: $practiceId) {
      id
      address
      advisorId
      assessmentToken
      city
      dateSent
      email
      firstName
      insights
      lastName
      phoneNumber
      practiceId
      state
      submittedByClient
      viewedByAdvisor
      zipCode
    }
  }
`;

const sendClientAssessmentMutation = gql`
  mutation sendClientAssessment($advisorId: Int!, $assessmentUrl: String!, $clientId: Int!, $practiceId: Int!) {
    sendClientAssessment(advisorId: $advisorId, assessmentUrl: $assessmentUrl, clientId: $clientId, practiceId: $practiceId) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(viewClientQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
        practiceId: props.currentPractice,
      },
    }),
  }),
  graphql(sendClientAssessmentMutation, { name: "sendClientAssessment" })
)(ClientView);
