import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AdvisorRoutes, AssessmentRoutes, ClientRoutes, PracticeRoutes, UserRoutes, TeamRoutes, PortalRoutes } from "./routes";
import Dashboard from "../../containers/dashboard";
import PageNotFound from "../../containers/404";
import { UsersAdd } from "../../containers/users";
import SettingsRoute from "./routes/settings";
import NotificationList from "../../containers/notificationList";
import RetirementVehicle from "../../containers/retirementVehicle";
import Faq from "../../containers/faq";
import Search from "../../containers/search";
import Landing from "../../containers/landing";
import Wealthbox from "../../containers/wealthbox";
import SalesforceAuth from "../../containers/salesforceAuth";
import LearningCenter from "../../containers/learningCenter";
import Behavior from "../../containers/learningCenter/behavior";
import SupportRole from "../../containers/supportRole";
import SupportRoleCreate from "../../containers/supportRole/create";
import SupportRoleEdit from "../../containers/supportRole/edit";
import SupportRoleView from "../../containers/supportRole/view";

class Router extends React.Component {
  render() {
    const {
      currentPractice,
      gblUserId,
      gblUserIsGlobalAdmin,
      gblUserIsPracticeAdmin,
      gblUserIsPortalAdmin,
      gblUserRole,
      sidebarCollapsed,
      userPracticeList,
      userPortals,
      firstLogin,
      firstName,
      currentPortalId,
      user,
      me,
      history,
      location,
    } = this.props;

    if (user && !user.customerStripeId && user.accountType == 1 && user.status == "Pending") return <Redirect to="/collect-payment" />;

    if (!currentPractice && !currentPortalId && location.pathname != "/settings/account" && !location.pathname.includes("/print")) {
      return <Redirect to={`/settings/account`} />;
    }
    if (me && me.rolecode == "SPR" && (!this.props.location.pathname.includes("/advisors") && !this.props.location.pathname.includes("/faq")) && !this.props.location.pathname.includes("/search")) return <Redirect to={`/advisors`} />;

    return (
      <Switch>
        <Route
          path="/search"
          render={(props) => (
            <Search
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />

        <Route
          exact
          path="/news-center"
          render={(props) => (
            <Landing
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />
        <Route
          path="/wealthbox-authorization"
          render={(props) => (
            <Wealthbox
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />

        <Route
          path="/salesforce-authorization"
          render={(props) => (
            <SalesforceAuth
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />
        <Route
          exact
          path="/support-roles"
          render={(props) => (
            <SupportRole
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />
        <Route
          exact
          path="/support-roles/create"
          render={(props) => (
            <SupportRoleCreate
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />
        <Route
          exact
          path="/support-roles/edit/:id"
          render={(props) => (
            <SupportRoleEdit
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />
        <Route
          exact
          path="/support-roles/view/:id"
          render={(props) => (
            <SupportRoleView
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
              user={user}
              location={location}
            />
          )}
        />

        <Route
          path="/faq"
          render={(props) => (
            <Faq
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />

        <Route
          path="/learning-center"
          render={(props) => (
            <LearningCenter
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/personality-behavior"
          render={(props) => (
            <Behavior
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />

        <Route
          path="/retirement-vehicle/:type"
          render={(props) => (
            <RetirementVehicle
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />

        <Route
          path="/notifications"
          render={(props) => (
            <NotificationList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />

        <Route
          path="/settings"
          render={(props) => (
            <SettingsRoute
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />

        <Route
          path="/advisors"
          render={(props) => (
            <AdvisorRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />

        <Route
          path="/assessments"
          render={(props) => (
            <AssessmentRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />

        <Route
          path="/clients"
          render={(props) => (
            <ClientRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/"
          render={(props) => (
            <Dashboard
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          path="/company"
          render={(props) => (
            <PortalRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          path="/companies"
          render={(props) => (
            <PortalRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/practices"
          render={(props) => (
            <PracticeRoutes
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          exact
          path="/:company/practices"
          render={(props) => (
            <PracticeRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/users"
          render={(props) => (
            <UserRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/:company/users"
          render={(props) => (
            <UserRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              userPortals={userPortals}
              currentPortalId={currentPortalId}
            />
          )}
        />
        {/* practice routes */}
        <Route
          exact
          path="/:practiceId"
          render={(props) => (
            <Dashboard
              {...props}
              firstLogin={firstLogin}
              firstName={firstName}
              practiceDashboard={true}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/:practiceId/practices"
          render={(props) => (
            <PracticeRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />
        <Route
          path="/:practiceId/advisors"
          render={(props) => (
            <AdvisorRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          path="/:practiceId/clients"
          render={(props) => (
            <ClientRoutes
              {...props}
              location={location}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          path="/:practiceId/clients/advisors/:srAdvisorId"
          render={(props) => (
            <ClientRoutes
              {...props}
              location={location}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          path="/:practiceId/teams"
          render={(props) => (
            <TeamRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
              currentPortalId={currentPortalId}
            />
          )}
        />

        {/* <Route
          path="/:practiceId/client-teams"
          render={props => (
            <ClientTeamRoutes
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              userPracticeList={userPracticeList}
            />
          )}
        /> */}
        <Route component={PageNotFound} exact path="/404" />

        {/* redirect authenticated user to either practice list or practice */}
        {gblUserIsGlobalAdmin || gblUserIsPortalAdmin ? (
          <Redirect from="/" to="/" />
        ) : (
          // <Redirect from="/" to={`/${userPracticeList[0]}`} />
          //  DASHBOARD
          <Redirect from="/" to={`/`} />
        )}
        {/* 404 redirect */}
        {/* <Redirect to="/404" /> */}
      </Switch>
    );
  }
}

export default Router;
