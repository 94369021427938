import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ClientTeamsAdd, ClientTeamsEdit, ClientTeamsList, ClientTeamsView } from '../../../containers/clientTeams';

class ClientTeamRoutes extends React.Component {
  render() {
    const {
      gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice,
      gblUserIsPortalAdmin
    } = this.props;

    return (
      <Switch>
        {/* pratice routes - catch trailing slashes & send to right route */}
        <Redirect
          exact
          from="/:practiceId/client-teams/"
          to="/:practiceId/client-teams"
          strict
        />
        <Redirect
          exact
          from="/:practiceId/client-teams/add/"
          to="/:practiceId/client-teams/add"
          strict
        />
        <Redirect
          exact
          from="/:practiceId/client-teams/edit/:id/"
          to="/:practiceId/client-teams/edit/:id"
          strict
        />
        <Redirect
          exact
          from="/:practiceId/client-teams/view/:id/"
          to="/:practiceId/client-teams/view/:id"
          strict
        />
        {/* practice routes */}
        <Route
          exact
          path="/:practiceId/client-teams"
          render={props => (
            <ClientTeamsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/client-teams/add"
          render={props => (
            <ClientTeamsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/client-teams/edit/:id"
          render={props => (
            <ClientTeamsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/:practiceId/client-teams/view/:id"
          render={props => (
            <ClientTeamsView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect
          exact
          from="/clients/"
          to="/clients"
          strict
        />
        <Redirect
          exact
          from="/clients/add/"
          to="/clients/add"
          strict
        />
        <Redirect
          exact
          from="/clients/edit/:id/"
          to="/clients/edit/:id"
          strict
        />
        <Redirect
          exact
          from="/clients/view/:id/"
          to="/clients/view/:id"
          strict
        />
        {/* admin routes */}
        <Route
          exact
          path="/clients"
          render={props => (
            <ClientTeamsList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/add"
          render={props => (
            <ClientTeamsAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/edit/:id"
          render={props => (
            <ClientTeamsEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
        <Route
          exact
          path="/clients/view/:id"
          render={props => (
            <ClientTeamsView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}

              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
            />
          )}
        />
      </Switch>
    );
  }
}

export default ClientTeamRoutes;
