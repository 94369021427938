import React from "react";

export const AccountContext = React.createContext(null);
export const AccountConsumer = AccountContext.Consumer;

export const AccountProvider = (props) => {
  const [account, setAccount] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const [userPermission, setUserPermission] = React.useState([]);
  const [currentCompany, setCurrentCompany] = React.useState(null);
  const [currentPractices, setCurrentPractices] = React.useState(null);
  const [ownerPractice, setOwnerPractice] = React.useState(null);
  const [isPracticeInviteModal, setPracticeInviteModal] = React.useState(false);
  const [isInviteModal, setInviteModal] = React.useState(null);
  const [isAssessmentChange, setAssessmentChange] = React.useState(null);
  const [isAdvisorModal, setAdvisorModal] = React.useState(false);
  const [logoUpdate, setLogoUpdate] = React.useState(null);
  function saveProfile(data) {
    setProfile(data);
  }
  return (
    <AccountContext.Provider
      value={{
        account,
        setAccount,
        profile,
        setProfile,
        userPermission,
        setUserPermission,
        currentCompany,
        setCurrentCompany,
        currentPractices,
        setCurrentPractices,
        isPracticeInviteModal,
        setPracticeInviteModal,
        isAdvisorModal,
        setAdvisorModal,
        logoUpdate,
        setLogoUpdate,
        setOwnerPractice,
        ownerPractice,
        setInviteModal,
        isInviteModal,
        setAssessmentChange,
        isAssessmentChange,
      }}>
      {props.children}
    </AccountContext.Provider>
  );
};
