import styled from "styled-components";
import { palette } from "styled-theme";

const SignupWrapper = styled.div`
  width: 100%;
  background: #fff;
  .unitfi-signup-main {
    .unitfi-signup-wrapper {
      display: flex;
      align-items: center;
      .unitfi-signup-img-div {
        width: 50%;
        height: 100vh;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .unitfi-signup-content-div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo-img-link {
          width: 300px;
          height: auto;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
        .unitfi-btn-wrapper {
          min-width: 420px;
          padding: 15px;
          background: #006fba;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          text-align: center;
          border-radius: 4px;
          margin: 35px 0;
          border: 1px solid transparent;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
          &.white-bg-btn {
            border: 1px solid #006fba;
            background: transparent;
            color: #006fba;
            margin: 0;
          }
        }
      }
    }
  }
  .unitfi-owner-member-main {
    padding: 60px 0;
    .unitfi-owner-member-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo-img-link {
        width: 260px;
        height: 85px;
        overflow: hidden;
        display: inline-block;
        margin-bottom: 70px;
        @media only screen and (max-width: 1366px) {
          width: 200px;
          height: 70px;
          margin-bottom: 30px;
        }
        @media only screen and (max-width: 1199px) {
          width: 220px;
          height: 75px;
          margin-bottom: 60px;
        }
        @media only screen and (max-width: 991px) {
          width: 200px;
          height: 70px;
          margin-bottom: 55px;
        }
        @media only screen and (max-width: 767px) {
          width: 180px;
          height: 60px;
          margin-bottom: 45px;
        }
        @media only screen and (max-width: 575px) {
          width: 140px;
          height: 40px;
          margin-bottom: 35px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
      .unitfi-owner-member-select-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .unitfi-owner-member-select-div {
          display: flex;
          align-items: top;
          flex-wrap: wrap;
          height: 100%;
          .box-wrapper {
            @media only screen and (max-width: 1199px) {
              max-width: 380px;
            }
            @media only screen and (max-width: 991px) {
              margin: 0 20px;
              max-width: 300px;
            }
            @media only screen and (max-width: 767px) {
              margin: 0 0 30px;
              max-width: 100%;
            }
          }
          .unitfi-owner-member-box {
            border: 1px solid #006fba;
            padding: 50px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            // margin: 0 40px;
            // max-width: 435px;
            width: 100%;
            min-height: 450px;
            height: 100%;
            transition: all 0.3s ease-in-out;
            @media only screen and (max-width: 1199px) {
              // max-width: 380px;
            }
            @media only screen and (max-width: 991px) {
              // margin: 0 20px;
              // max-width: 300px;
              padding: 30px;
            }
            @media only screen and (max-width: 767px) {
              // margin: 0 0 30px;
              // max-width: 100%;
              padding: 20px;
              min-height: auto;
            }
            // &.member-box{
            //   margin: 0;
            // }
            &:hover {
              background: #006fba;
              h5 {
                color: #fff;
              }
              p {
                color: #fff;
              }
            }
            &.active {
              background: #006fba;
              h5 {
                color: #fff;
              }
              p {
                color: #fff;
              }
            }
            h5 {
              font-size: 22px;
              font-weight: 700;
              line-height: 32px;
              letter-spacing: 0px;
              text-align: center;
              color: #006fba;
              margin-bottom: 40px;
            }
            p {
              font-size: 22px;
              font-weight: 600;
              line-height: 32px;
              letter-spacing: 0px;
              text-align: center;
              color: #006fba;
            }
          }
        }
        .unitfi-signup-member-btn-div {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          position: relative;
          p {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 0;
            margin-bottom: 0;
          }
          .unitfi-btn-wrapper {
            min-width: 270px;
            padding: 15px;
            background: #969696;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            line-height: auto;
            text-align: center;
            border-radius: 4px;
            margin: 50px 0 0;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            height: auto;
            &:hover {
              opacity: 0.8;
            }
            &:hover {
              background: #006fba;
              color: #fff;
            }
            &.active {
              background: #006fba;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .owner-member-account-detals-wrapper {
    .ant-col {
      .box-wrapper {
        max-width: 460px;
        margin: auto;
        @media only screen and (max-width: 1199px) {
          max-width: 400px;
        }
        @media only screen and (max-width: 991px) {
        }
        @media only screen and (max-width: 767px) {
          min-height: 300px;
        }
      }
      .unitfi-owner-member-box {
        border: 1px solid #006fba;
        padding: 42px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 100%;
        transition: all 0.3s ease-in-out;
        // max-width: 460px;
        // margin: auto;
        width: 100%;
        height: calc(100% - 35px);
        margin-bottom: 35px;
        min-height: 420px;
        justify-content: center;
        @media only screen and (max-width: 1366px) {
          padding: 25px;
          min-height: 350px;
        }
        @media only screen and (max-width: 1199px) {
          padding: 35px;
          height: calc(100% - 35px);
          margin-bottom: 25px;
          min-height: 380px;
          max-width: 400px;
        }
        @media only screen and (max-width: 991px) {
          padding: 35px;
          min-height: 340px;
        }
        @media only screen and (max-width: 767px) {
          padding: 35px;
          min-height: 300px;
        }
        &:hover {
          background: #006fba;
          h5 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
        &.active {
          background: #006fba;
          h5 {
            color: #fff;
          }
          p {
            color: #fff;
          }
        }
        h5 {
          font-size: 22px;
          font-weight: 700;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: center;
          color: #006fba;
          margin-bottom: 40px;
          @media only screen and (max-width: 1199px) {
            font-size: 20px;
            margin-bottom: 30px;
          }
          @media only screen and (max-width: 991px) {
            font-size: 18px;
            margin-bottom: 20px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            margin-bottom: 12px;
          }
        }
        p {
          font-size: 22px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0px;
          text-align: center;
          color: #006fba;
          @media only screen and (max-width: 1199px) {
            font-size: 20px;
            line-height: 27px;
          }
          @media only screen and (max-width: 991px) {
            font-size: 18px;
            line-height: 24px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  .box-btns-wrapper {
    max-width: 1043px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .unitifi-copy-link-text {
      display: flex;
      margin-left: 35px;
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        margin-bottom: 10px;
      }
      p {
        color: #181818;
        font-size: 14px;
        font-weight: 400;
      }
      .link-text {
        font-size: 14px;
        font-weight: 700;
        color: #181818;
      }
    }
    .unitfi-btn-wrapper {
      min-width: 250px;
      padding: 15px;
      background: #969696;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      line-height: auto;
      text-align: center;
      border-radius: 4px;
      margin-top: 35px;
      border: 1px solid transparent;
      transition: all 0.3s ease-in-out;
      height: auto;
      &:hover {
        opacity: 0.8;
      }
      &:hover {
        background: #006fba;
        color: #fff;
      }
      &.active {
        background: #006fba;
        color: #fff;
      }
    }
  }
`;

export default SignupWrapper;
