import styled from 'styled-components';
import { palette } from 'styled-theme';

const UploadWrapper = styled.div`
  width: 100%;
  height: 168px;

  .ant-upload-text {
    &.error {
      color: ${palette('error', 0)};
    }
  }
`;

export default UploadWrapper;
