import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Icon, notification, Spin } from "antd";
import { Redirect } from "react-router";
import PropTypes from "prop-types";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import PracticeForm from "./form";
import { AccountContext } from "../../../context/AccountContext";

class PracticesEdit extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      redirectTo: null,
      pageTitle: `Edit Practice Mailer Template`,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.userContext = null;
  }

  toggleLoading(boolean) {
    this.setState({
      loading: boolean,
    });
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.data.getPractice.id,
    };

    // console.log('submit form');

    this.props
      .editpracticeMailer({ variables })
      .then(({ data }) => {
        if (data.editPracticeMailer.ok && !data.editPracticeMailer.errors) {
          this.handleSuccess(`${data.editPracticeMailer.practice.name} has been successfully updated.`);
          return;
        }

        this.handleError(data.editPracticeMailer.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: "Practice Updated Successfully",
      //description,
    });

    this.props.history.push("/practices");
    // this.setState({ redirectTo: '/practices' });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  componentDidUpdate() {
    this.userContext = this.context;
  }

  render() {
    const {
      data: { loading, error, getPractice },
    } = this.props;

    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice={this.props.currentPractice} displayText={this.state.pageTitle} />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice={this.props.currentPractice} displayText={this.state.pageTitle} />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - practice didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <Titlebar currentPractice={this.props.currentPractice} displayText={this.state.pageTitle} />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <PracticeForm loading={this.state.loading} onDelete={this.handleDeleteConfirm} onSubmit={this.handleSubmit} practice={getPractice} toggleLoading={this.toggleLoading} userContext={this.userContext} />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

PracticesEdit.propTypes = {
  editpracticeMailer: PropTypes.func.isRequired,
};

const getPracticeQuery = gql`
  query($id: Int!) {
    getPractice(id: $id) {
      id
      name
      clientAssessmentTitle
      clientAssessmentBody
      userInviteTitle
      userInviteBody
      userInviteDomain
    }
  }
`;

const editpracticeMailerMutation = gql`
  mutation editPracticeMailer($id: Int!, $clientAssessmentTitle: String, $clientAssessmentBody: String, $userInviteTitle: String, $userInviteBody: String, $userInviteDomain: String) {
    editPracticeMailer(id: $id, clientAssessmentTitle: $clientAssessmentTitle, clientAssessmentBody: $clientAssessmentBody, userInviteTitle: $userInviteTitle, userInviteBody: $userInviteBody, userInviteDomain: $userInviteDomain) {
      practice {
        name
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getPracticeQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editpracticeMailerMutation, { name: "editpracticeMailer" })
)(PracticesEdit);
