import React, { useEffect, useRef, useState } from "react";
import { Titlebar } from "../../components";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Row, Col } from "antd";
import { Modal } from "react-bootstrap";
import { Query, useLazyQuery, useQuery } from "react-apollo";
import _ from "lodash";
import gql from "graphql-tag";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Search from "../../assets/img/header-search-icon.svg";
import blockImg from "../../assets/img/dummy-img.jpg";
import { checkTruncateText, truncateText } from "../../helpers/formatters";
import VimeoPlayer from "../../components/vimeoPlayer";
import ReactPlayer from "react-player";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", color: "#000" }} onClick={onClick} />;
};

const LearningCenter = () => {
  const [carouselIndices, setCarouselIndices] = useState([0, 0, 0]);
  const [isVideoBlockModal, setVideoBlockModal] = useState(false);
  const [tutorialDetail, setTutorialDetail] = useState(null);
  const [videoTutorials, setVideoTutorials] = useState([]);
  const playerRef = useRef();
  const testimonialRef = useRef();
  const searchRef = useRef();

  const [tutorialVideoHandler, { loading: dataLoading, data: videoData, error: dataError }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getTutorialVideo(filters: $filters) {
          result
        }
      }
    `,
    {
      variables: {
        filters: {},
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  useEffect(() => {
    tutorialVideoHandler();
  }, []);

  useEffect(() => {
    if (!videoData) return;
    const result = _.get(videoData, "getTutorialVideo.result", []);
    setVideoTutorials(result);
  }, [videoData]);

  const settingTestimonial = {
    dots: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleAfterChange = (currentSlide, index) => {
    const newIndices = [...carouselIndices];
    newIndices[index] = currentSlide;
    setCarouselIndices(newIndices);
  };

  const videoHandler = (data = null) => {
    setVideoBlockModal(!isVideoBlockModal);
    setTutorialDetail(data);
  };

  const showMoreHandler = (i, ti, show) => {
    let clone = [...videoTutorials];
    clone[i].tutorials[ti].show = !show;
    setVideoTutorials(clone);
  };

  const searchHandler = (e) => {
    if (e.key === "Enter") {
      tutorialVideoHandler({ variables: { filters: { search: e.target.value } } });
      e.preventDefault();
    }
  };

  const searchHandlerBtn = () => {
    tutorialVideoHandler({ variables: { filters: { search: searchRef.current.value || "" } } });
  };

  const vimeoPlayerDataHandler = (type, data) => {};

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-learning-center-heading">
        <Titlebar displayText="Learning Center" />
        <Link to="/learning-center" className="unitifi-headLinks active">
          Self-Help Tutorials
        </Link>
        <Link to="/personality-behavior" className="unitifi-headLinks ms-5">
          Personality & Behavior Attributes
        </Link>
        <div className="unitifi-videoBlock-wrapper">
          <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between">
            <h2 className="videoBlock-title">Video Content</h2>
            <Form className="searchbox-form-wrapper">
              <div className="search-input-wrapper input-group">
                <i onClick={() => searchHandlerBtn()}>
                  <img src={Search} />
                </i>
                <svg xmlns="http://www.w3.org/2000/svg" className="" width="17" height="17" viewBox="0 0 384 512" fill="none">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                    fill="#ffffff"
                  />
                </svg>
                <Form.Control type="search" placeholder="Search Tutorials" aria-label="Search" onKeyDown={(e) => searchHandler(e)} ref={searchRef} />
                <div class="input-group-append" onClick={() => searchHandlerBtn()}>
                  <span class="input-group-text"></span>
                </div>
              </div>
            </Form>
          </div>

          {videoTutorials.length > 0 &&
            videoTutorials.map((video, i) => (
              <div class="unitifi-categoryblock-wrapper" key={video.id}>
                <h2 className="categoryBlock-title">{video.name}</h2>
                <div className="videoBlock-wrapper">
                  <Row gutter={40}>
                    <Slider ref={testimonialRef} {...settingTestimonial} afterChange={(currentSlide) => handleAfterChange(currentSlide, i)}>
                      {video.tutorials.length > 0 &&
                        video.tutorials.map((tutorial, ti) => (
                          <Col lg={8}>
                            <div className="videoBlock">
                              <a to="#" onClick={() => videoHandler(tutorial)}>
                                <img src={tutorial.vimeoThumb} />
                              </a>
                            </div>
                            <div className="videoDetail-block">
                              <h4>
                                <a to="#" onClick={() => videoHandler(tutorial)}>
                                  {tutorial.title}
                                </a>
                              </h4>
                              <div className="d-flex align-items-start align-items-xxl-end flex-column flex-xxl-row justify-content-between">
                                {/* <h6>{truncateText(tutorial.titleDescription)}</h6> */}
                                <h6 class="title-desc">{tutorial.titleDescription}</h6>
                                {checkTruncateText(tutorial.titleDescription) && (
                                  <a to="#" className="showMore" onClick={() => videoHandler(tutorial)}>
                                    Show {tutorial.show ? "less" : "more"}
                                  </a>
                                )}
                              </div>
                            </div>
                          </Col>
                        ))}
                    </Slider>
                  </Row>
                </div>
              </div>
            ))}
          {videoTutorials.length == 0 && !dataLoading && <p className="mt-3">No result found</p>}
        </div>
      </div>
      <Modal show={isVideoBlockModal} onHide={() => videoHandler()} centered className="unitifi-modal-wrapper videoContentWrapper" size="lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{tutorialDetail && tutorialDetail.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="videoDataBlock">
            <div className="videoSection">
              <ReactPlayer url={`${tutorialDetail && tutorialDetail.tutorialLink}`} controls={true} width="100%" height="500px" key={tutorialDetail && tutorialDetail.id} />
              {/* <VimeoPlayer ref={playerRef} video={tutorialDetail && tutorialDetail.vimeoId} playerDataHandler={vimeoPlayerDataHandler} autoPlay={true} controls={true} /> */}
            </div>
            <div className="videoDetail">
              <p className="videoTitle">Description</p>
              <p className="videoDescription">{tutorialDetail && tutorialDetail.titleDescription}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LearningCenter;
