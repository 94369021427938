import { Button } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { defaultStaticRanges } from "./defaultRanges";

const CalendarModal = ({ setFilterDate, filterDate }) => {
  const [selectionRange, setSelection] = useState({
    startDate: filterDate.startDate ? new Date(filterDate.startDate) : new Date(),
    endDate: filterDate.endDate ? new Date(filterDate.endDate) : new Date(),
    key: "selection",
  });
  const handleSelect = (ranges) => {
    setSelection(ranges.selection);
  };

  const applyDateFilterHandler = () => {
    setFilterDate({ startDate: moment(selectionRange.startDate).format("YYYY-MM-DD"), endDate: moment(selectionRange.endDate).format("YYYY-MM-DD") });
  };

  return (
    <div>
      <DateRangePicker staticRanges={defaultStaticRanges} inputRanges={[]} ranges={[selectionRange]} onChange={handleSelect} months={2} showSelectionPreview={true} moveRangeOnFirstSelection={false} direction="horizontal" />
      <div className="d-flex justify-content-end">
          <Button className="me-3" onClick={() => setFilterDate({ startDate: null, endDate: null })}>Clear Date Range</Button>
          <Button type="primary" onClick={() => applyDateFilterHandler()}>
            Apply Date Range
          </Button>
      </div>
    </div>
  );
};

export default CalendarModal;
