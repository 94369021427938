import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  .form-button {
    align-self: center;
    &:first-child {
      flex-grow: 0;
      margin-right: 8px;
    }
  }
`;

export default ButtonsWrapper;
