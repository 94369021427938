import React, { useState } from "react";
import { Button, Form, Icon, Input, Radio, Spin, Modal, notification } from "antd";
import { FormWrapper } from "../form";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
const FormItem = Form.Item;

const ChangeEmail = (props) => {
  const { form, setChangeEmailShow, currentLogin } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);

        let variables = { email: values.email };

        let result = await client.mutate({
          mutation: gql`
            mutation($email: String!) {
              emailChangeInvite(email: $email) {
                ok
                error
                message
              }
            }
          `,
          variables: variables,
        });

        if (result && result.data.emailChangeInvite.ok) {
          notification.success({ message: result.data.emailChangeInvite.message });
        } else {
          form.setFields({
            email: {
              value: values.email,
              errors: [new Error(result.data.emailChangeInvite.error)],
            },
          });
          setLoading(false);
          return;
          // notification.error({ message: result.data.emailChangeInvite.error });
        }

        setChangeEmailShow(false);
        setLoading(false);
      }
    });
  };

  const compareEmail = (rule, value, callback) => {

    if (value && value == currentLogin.email) {
      callback("The email address you entered is already in use.");
    } else {
      let regAx =/^([A-Z0-9._%+-])+@([A-Z0-9.-])+\.(?!con)([A-Z]{2,})$/i
      if (value && !regAx.test(value))
      callback('Please enter a valid email address');
    else  callback()
    }
  };
  return (
    <div className="public-form-wrapper">
      <FormWrapper className="public-form">
        <div className="unitifi-login-header-bar">
          <h5>Update Email</h5>
        </div>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <FormItem className="uni-password-label" label="Current Email" labelCol={{ span: 12 }}>
            <p>{currentLogin && currentLogin.email}</p>
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email", {
              rules: [
                {
                  message: "Email is required",
                  required: true,
                },
                {
                  validator: compareEmail,
                },
              ],
            })(<Input type="text" />)}
          </FormItem>

          <div className="modal-footer-bnts-wrapper">
            <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={loading} className="unitifi-login-btn">
              Save
            </Button>
            <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setChangeEmailShow(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default Form.create()(ChangeEmail);
