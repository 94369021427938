import React, { useContext, useEffect, useState } from "react";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import _ from "lodash";
import gql from "graphql-tag";
import { Table, Spin, Icon, Button } from "antd";
import { AccountContext } from "../../context/AccountContext";
import { useLazyQuery, useQuery } from "react-apollo";
import TitleBar from "../../components/titlebar/titlebar";

const SupportRoleView = (props) => {
  const [tableCount, setTableCount] = useState(0);
  const [supportListData, setSupportListData] = useState([]);
  const accountContext = useContext(AccountContext);
  const account = _.get(accountContext, "account", null);
  const [state, setState] = useState({ id: parseInt(props.match.params.id), limit: 10, loading: false, order: "ASC", orderColumn: "", page: 1, search: "", totalCount: null, sortDirection: "" });

  useEffect(() => {
    supportRoleHandler({
      variables: {
        id: parseInt(props.match.params.id),
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
    });
  }, [state.page, props.match]);

  const [supportRoleHandler, { loading, data }] = useLazyQuery(
    gql`
      query($id: Int, $limit: Int, $offset: Int, $order: String, $orderColumn: String) {
        supportAdvisorList(id: $id, limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn) {
          result
          pagination
        }
      }
    `,
    {
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
      fetchPolicy: "network-only",
    }
  );

  const { loading: logoLoading, data: supportDetail, refetch: portalRefetch } = useQuery(
    gql`
      query($id: Int) {
        supportUserDetail(id: $id) {
          result
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: { id: parseInt(props.match.params.id) },
    }
  );

  function reCall() {
    supportRoleHandler({
      variables: {
        page: state.page,
        offset: state.offset,
        limit: state.limit,
        order: state.order,
        orderColumn: state.orderColumn,
      },
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;
    if (Object.keys(sorter).length > 0 && (sorter.field != state.orderColumn || sorterOrder != state.order)) pagination.current = 1;
    let x = (pagination.current - 1) * state.limit;
    setState({
      ...state,
      offset: x,
      limit: pagination.pageSize || 10,
      page: pagination.current == state.page ? 1 : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
      [sorter.columnKey]: sorter.order,
    });

    supportRoleHandler({
      variables: {
        id: parseInt(props.match.params.id),
        limit: 10,
        page: pagination.current == state.page ? 1 : pagination.current,
        offset: x,
        limit: pagination.pageSize,
        order: sorterOrder,
        orderColumn: sorter.field,
      },
    });
  }

  const columns = [
    {
      sorter: true,
      title: "Name",
      width: 100,
      render: (data) => {
        return (
          <>
            <a href={"/" + data.practicename + "/advisors/view/" + data.id} className="practicelink-text">
              {data.username}
            </a>
          </>
        );
      },
    },
    {
      dataIndex: "email",
      className: "location-column",
      title: "Email",
      width: 100,
      sorter: true,
    },

    {
      dataIndex: "practicename",
      className: "email-column",
      title: "Practice",
      width: 100,
      sorter: true,
      render: (text, row) => {
        return <>{text}</>;
      },
    },
  ];
  let tempTableCount = _.get(data, "supportAdvisorList.pagination.totalCount", 0);
  useEffect(() => {
    if (!data) return;
    setTableCount(tempTableCount > tableCount || tableCount == undefined ? tempTableCount : tableCount);

    setSupportListData(data.supportAdvisorList.result);
  }, [data]);

  // let supportListData = _.get(data, "supportAdvisorList.result", []);

  let tablePagination = {
    current: state.page,
    defaultPageSize: 10,
    pageSize: state.limit,
    pageSizeOptions: ["10", "20", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    total: tableCount,
  };

  const countStart = (state.page - 1) * state.limit + 1;
  const countEnd = state.page * state.limit > tableCount ? tableCount : state.page * state.limit;
  const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <div className="qr-header-wrapper">
          <h4 className="edit-iconbox">
            <TitleBar
              title={supportDetail && supportDetail.supportUserDetail && supportDetail.supportUserDetail.result.length > 0 && `${supportDetail.supportUserDetail.result[0].firstName} ${supportDetail.supportUserDetail.result[0].lastName}`}
              subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)}
            />
            {/* <Button className="btnDelete" icon="edit" onClick={() => props.history.push(`/support-roles/edit/${props.match.params.id}`)} /> */}
          </h4>
        </div>
      </div>

      <LayoutContentWrapper>
        <div className="display-bar">{display}</div>
        <LayoutContent className="practiceTblWrapper">
          <Table
            columns={columns}
            loading={
              loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }
            }
            onChange={handleTableChange}
            dataSource={supportListData}
            rowKey={(record) => record.id}
            scroll={{ x: columns.length * 150 }}
            pagination={tablePagination}
          />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
};

export default SupportRoleView;
