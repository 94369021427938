import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Form, List, Checkbox, Icon, Input, Spin } from 'antd';
import PropTypes from 'prop-types';
import { AddButtons, EditButtons, FormWrapper } from '../../../components/form';

const FormItem = Form.Item;

class TeamsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAdvisors: this.props.team? this.props.team.advisors.map(v => v.id) : [],
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onAdvisorSelect= this.onAdvisorSelect.bind(this);
  }

  onAdvisorSelect(values) {
    this.setState(Object.assign({}, this.state, {selectedAdvisors: values}));
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const toSubmit = {...values, advisors: this.state.selectedAdvisors }
        this.props.onSubmit(toSubmit);
      }

    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      data: { loading, error, getAllAdvisorsForForm },
    } = this.props;

    if (loading) {
      return (
        <div className="loading-wrapper">
          <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
        </div>
      );
    }

    if (error) {
      return (
        <div className="loading-wrapper">
          <p>Whoopsie - client didn&apos;t load.</p>
        </div>
      );
    }

    // const { practices } = getAllPracticesForForm;
    const { advisors } = getAllAdvisorsForForm;

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <FormItem
            label="Team Name"
          >
            {getFieldDecorator('name', {
              initialValue: this.props.team ? this.props.team.name : '',
              rules: [{
                message: 'A team name is required.',
                required: true,
              }],
            })(<Input placeholder="Name" />)}
          </FormItem>
          <FormItem label="Members">
            <Checkbox.Group style={{ width: '100%' }} onChange={this.onAdvisorSelect} defaultValue={this.state.selectedAdvisors}>
              <List
                size="small"
                dataSource={advisors}
                renderItem={item => (
                  <List.Item>
                    <Checkbox value={item.id}>
                      {item.firstName}&nbsp;
                      {item.lastName}&nbsp;
                      ({item.email})
                    </Checkbox>
                  </List.Item>)
                }
              />
            </Checkbox.Group>
          </FormItem>
          <br />
          <div className="form-toolbar">
            { this.props.team ?
              <EditButtons
                deleteRights={'deleteTeams'}
                cancelUrl={this.props.cancelUrl}
                loading={this.props.loading}
                onDelete={this.props.onDelete}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','editTeams']}
              /> :
              <AddButtons
                cancelUrl={this.props.cancelUrl}
                createAnother={this.props.createAnother}
                loading={this.props.loading}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={['superAdmin','createTeams']}
              />}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

TeamsForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

TeamsForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
};

const getAllAdvisorsForFormQuery = gql`
  query($practiceId: String) {
    getAllAdvisorsForForm :getAllAdvisorsPerPracticesForForm(practiceId: $practiceId) {
      advisors {
        id
        firstName
        lastName
        practiceId
        email
      }
    }
  }
`;

export default graphql(getAllAdvisorsForFormQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      practiceId: props.currentPractice,
    },
  }),
})(Form.create()(TeamsForm));
