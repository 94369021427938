import React from 'react';
import {
  DiscreteColorLegend,
  RadialChart,
} from 'react-vis';
import ClientMakeupRadialWrapper from './index.style';

const AssignColor = (type) => {
  if (type === 'Protector') {
    return 'rgba(21,100,255,1)';
  }

  if (type === 'Liberator') {
    return 'rgba(97,150,255,1)';
  }

  if (type === 'Energizer') {
    return 'rgba(1,82,243,.2)';
  }

  return 'rgba(57,124,255,1)';
};

const NormalizeStyle = (style) => {
  if (['Somewhat Logical', 'Moderately Logical', 'Highly Logical'].includes(style)) {
    return 'Logical';
  }

  if (['Somewhat Passionate', 'Moderately Passionate', 'Highly Passionate'].includes(style)) {
    return 'Passionate';
  }

  return 'Balanced';
};

const ToPercent = (arr) => {
  const total = arr.reduce((a, c) => a + c.count, 0);

  return arr.map(({ count, ...props }) =>
    ({
      ...props,
      angle: Math.round((count * 100) / total),
      percent: Math.round((count * 100) / total) + '%',
    }));
};

const GenerateCounts = data => data.map(c => ({
  dateCollected: c.dateCollected,
  style: NormalizeStyle(c.insights.personality.decisionMakingStyle),
  type: c.insights.personality.type,
}))
  .reduce((a, c) => {
    /* type */
    a[c.type] = a[c.type] || { type: c.type, count: 0, styles: {}, dates: {} };
    a[c.type].count += 1;
    /* dates */
    a[c.type].dates[c.dateCollected] = a[c.type].dates[c.dateCollected] || { dateCollected: c.dateCollected, count: 0 };
    a[c.type].dates[c.dateCollected].count += 1;
    /* styles */
    a[c.type].styles[c.style] = a[c.type].styles[c.style] || { style: c.style, count: 0 };
    a[c.type].styles[c.style].count += 1;

    return a;
  }, {});

const GenerateAllCounts = data => data.map(c => ({
  dateCollected: c.dateCollected,
  style: NormalizeStyle(c.insights.personality.decisionMakingStyle),
  type: 'All',
}))
  .reduce((a, c) => {
    /* type */
    a[c.type] = a[c.type] || { type: c.type, count: 0, styles: {}, dates: {} };
    a[c.type].count += 1;
    /* dates */
    a[c.type].dates[c.dateCollected] = a[c.type].dates[c.dateCollected] || { dateCollected: c.dateCollected, count: 0 };
    a[c.type].dates[c.dateCollected].count += 1;
    /* styles */
    a[c.type].styles[c.style] = a[c.type].styles[c.style] || { style: c.style, count: 0 };
    a[c.type].styles[c.style].count += 1;

    return a;
  }, {});

const MergeDefaults = (order, items, key) => order
  .filter(o => !items.find(i => o[key] === i[key])).concat(items);

const defaults = {
  Energizer: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Energizer',
  },
  Liberator: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Liberator',
  },
  Protector: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Protector',
  },
  Observer: {
    count: 0,
    dates: {},
    styles: {},
    type: 'Observer',
  },
};

const defaultStyleOrder = ['Logical', 'Passionate', 'Balanced'];

const defaultStyles = [
  { percent: '0%', style: 'Logical' },
  { percent: '0%', style: 'Passionate' },
  { percent: '0%', style: 'Balanced' },
];

class ClientMakeupRadial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeBehavior: null,
      data: ToPercent(Object.values({
        ...defaults,
        ...GenerateCounts(this.props.data),
      }))
        .map(({
          dates, type, styles, ...properties
        }) => ({
          ...properties,
          color: AssignColor(type),
          dates: Object.values(dates),
          styles: ToPercent(Object.values(styles)),
          type,
        }))
        .sort((a, b) => a.type.localeCompare(b.type))
        .reverse(),
    };
  }

  render() {
    const { data } = this.state;

    const filteredData = data
      .map(p => (this.state.activeBehavior !== null && p.type !== this.state.activeBehavior
        ? {
          ...p,
          color: p.color,
          disabled: true,
          radius: 0.97,
        }
        : p));

    const rollupData = [
      ...data,
      ...ToPercent(Object.values({
        ...{
          All: {
            count: 0,
            dates: {},
            styles: {},
            type: 'All',
          },
        },
        ...GenerateAllCounts(this.props.data),
      }))
        .map(({
          dates, type, styles, ...properties
        }) => ({
          ...properties,
          color: AssignColor(type),
          dates: Object.values(dates),
          styles: ToPercent(Object.values(styles)),
          type,
        })),
    ];

    return (
      <ClientMakeupRadialWrapper>
        <div className="radial-chart-wrapper">
          <RadialChart
            animation
            colorDomain={[0, 100]}
            colorRange={[0, 10]}
            colorType="literal"
            data={filteredData
              .map(({
                angle, color, type: name, radius,
              }) => ({
                angle, color, name, radius,
              }))
            }
            height={400}
            onValueClick={({ name: activeBehavior }) =>
              this.setState({
                activeBehavior: this.state.activeBehavior !== activeBehavior
                  ? activeBehavior
                  : null,
              })
            }
            style={{ stroke: 'rgba(255,255,255,1)', strokeWidth: 2 }}
            width={400}
          />
          <DiscreteColorLegend
            items={filteredData
              .map(({
                color, disabled, type: title,
              }) => ({
                color, disabled, title,
              }))
            }
            onItemClick={({ title: activeBehavior }) =>
              this.setState({
                activeBehavior: this.state.activeBehavior !== activeBehavior
                  ? activeBehavior
                  : null,
              })
            }
            orientation="horizontal"
            width={266}
          />
        </div>
        <div className="dashboard-info-wrapper">
          {rollupData
            .filter(d =>
              (this.state.activeBehavior !== null
                ? d.type === this.state.activeBehavior
                : d.type !== null))
            .map((d) => {
              if (this.state.activeBehavior === null) {
                return (
                  <div className="dashboard-info-item" key={d.type}>
                    <div className="dashboard-info-item-inner">
                      <div className="dashboard-info-title">{d.type !== 'All' ? d.type : null}</div>
                      <div className="dashboard-info-detail">{d.type !== 'All' ? d.percent : null}</div>
                      {d.type === 'All' ?
                        MergeDefaults(defaultStyles, d.styles, 'style')
                          .sort((a, b) => (
                            defaultStyleOrder.indexOf(a.style) - defaultStyleOrder.indexOf(b.style)))
                          .map(a => (
                            <div
                              className={`dashboard-info-average ${a.percent === '0%' ? 'dimmed' : ''}`}
                              key={a.style}
                            >
                              {a.style} - {a.percent}
                            </div>
                          )) : null}
                    </div>
                  </div>
                );
              }

              return (
                <div className="dashboard-info-item" key={d.type}>
                  <div className="dashboard-info-item-inner">
                    <div className="dashboard-info-title">{d.type}</div>
                    <div className="dashboard-info-detail">{d.percent}</div>
                    {MergeDefaults(defaultStyles, d.styles, 'style')
                      .sort((a, b) => (
                        defaultStyleOrder.indexOf(a.style) - defaultStyleOrder.indexOf(b.style)))
                      .map(a => (
                        <div
                          className={`dashboard-info-average ${a.percent === '0%' ? 'dimmed' : ''}`}
                          key={a.style}
                        >
                          {a.style} - {a.percent}
                        </div>
                      ))}
                  </div>
                </div>
              );
            })}
        </div>
      </ClientMakeupRadialWrapper>
    );
  }
}

export default ClientMakeupRadial;
