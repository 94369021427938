import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Query, useLazyQuery, useQuery } from "react-apollo";
import _ from "lodash";
import gql from "graphql-tag";
import { practiceConfig } from "../../config";
import desktopLogo from "../../assets/img/unitifi-logo.png";
import { AccountContext } from "../../context/AccountContext";

// class Logo extends React.Component {
//   static contextType = AccountContext;

//   shouldComponentUpdate(nextProps) {
//     // only update logo on practice change
//     if (this.props.practiceId === null) {
//       return false;
//     }

//     if (this.props.practiceId === "") {
//       return false;
//     }

//     if (nextProps.practiceId === "") {
//       return false;
//     }

//     if (["advisors", "clients", "users"].includes(this.props.practiceId) && nextProps.practiceId === "practices") {
//       return false;
//     }

//     return this.props.practiceId !== nextProps.practiceId && !["advisors", "clients", "users"].includes(nextProps.practiceId) && nextProps.practiceId !== null;
//   }

//   render() {
//     const userContext = this.context;
//     console.log("userContext", userContext);
//     const LogoImg = ({ id }) => (
//       <Query query={getPortalDetailsQuery} fetchPolicy="network-only" variables={{ id }}>
//         {({ loading, error, data }) => {
//           if (loading) {
//             return null;
//           }

//           if (error) {
//             return (
//               //  DASHBOARD
//               <Link to={"/"}>
//                 <img alt={practiceConfig.practiceName} src={desktopLogo} />
//               </Link>
//             );
//           }
//           console.log("inside Query", userContext);
//           const logoAlt = data.getPortal && data.getPortal.name ? data.getPortal.name : practiceConfig.practiceName;

//           const logoSrc = userContext.logoUpdate ? userContext.logoUpdate : data.getPortal && data.getPortal.siteLogo ? data.getPortal.siteLogo : desktopLogo;
//           console.log("logoSrc");
//           return <img alt={logoAlt} src={logoSrc} />;
//         }}
//       </Query>
//     );

//     return (
//       //  DASHBOARD
//       <Link to={"/"}>
//         <LogoImg id={this.props.currentPortalId} />
//       </Link>
//     );
//   }
// }

const Logo = (props) => {
  const { currentPortalId, logoData } = props;
  const accountContext = useContext(AccountContext);

  // const { loading: logoLoading, data: logoData, refetch: clientRefetch } = useQuery(getPortalDetailsQuery, {
  //   fetchPolicy: "network-only",
  //   variables: { id: currentPortalId },
  // });

  // useEffect(() => {
  //   if (!currentPortalId) return;
  //   console.log("currentPortalId", currentPortalId);
  //   LogoHandler({ variables: { id: currentPortalId } });
  // }, [currentPortalId]);

  const logoSrc = logoData ? _.get(logoData, "getPortal.siteLogo", desktopLogo) : desktopLogo;
  const logoAlt = logoData ? _.get(logoData, "getPortal.name", "unitifi") : "";

  return <Link to={"/"}>{accountContext.logoUpdate && <img alt={logoAlt} src={accountContext.logoUpdate ? accountContext.logoUpdate : desktopLogo} />}</Link>;
};

export default Logo;
