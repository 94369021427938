import React, { useContext, useState } from "react";
import { graphql, useLazyQuery, useApolloClient, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Badge, Form, Input, notification, Select as Selectd, Alert, Row, Col, AutoComplete, Popover, Button } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AddButtons, EditButtons, FormWrapper } from "../../../components/form";
import { SiteLogo } from "../components";
import StateList from "../../../helpers/statesDataList";
import _, { flowRight as compose } from "lodash";
import Select from "react-select";
import Info from "../../../assets/img/info-blue.svg";
import { AccountContext } from "../../../context/AccountContext";
import PromptModal from "../../../components/promptModal";
const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Selectd;
const AutoCompleteOption = AutoComplete.Option;
const roleList = [];
const cleanPracticeId = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/_+$/, "")
    .toLowerCase();

class PracticeForm extends React.Component {
  static contextType = AccountContext;

  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      isPromptModalClose: false,
      countryList: [],
      stateList: [],
      cityList: [],
      memberList: [],
      siteLogo: this.props.practice && this.props.practice.siteLogo ? this.props.practice.siteLogo : null,
      siteLogoS3Token: this.props.practice && this.props.practice.siteLogoS3Token ? this.props.practice.siteLogoS3Token : null,
      newPracticeEmailRecipient: "",
      newPracticeEmailTitle: "Welcome",
      newPracticeEmailBody: `Hello,\n\nWe’re honored to have you as part of the community of financial professionals utilizing Unitifi!\n\n[[SYS_GENERATED_LINK]]\n\nOnce logged in, your dedicated Customer Success Representative will be in touch.\nWe're excited to help transform the authentic manner in which you connect with and help your clients succeed.\n\nThank you,\nUnitifi`,
    };

    this.handleUploadParams = this.handleUploadParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPracticeIdBlur = this.onPracticeIdBlur.bind(this);
    this.onPracticeIdChange = this.onPracticeIdChange.bind(this);
    //this.onPracticeNameChange = this.onPracticeNameChange.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.cityHandler = this.cityHandler.bind(this);
  }

  componentDidUpdate() {
    const {
      gblUserRole,
      practice,
      memberQuery: { getPracticeMemberList },
      data: { loading, error, getCountrylist },
    } = this.props;
    if (getCountrylist != undefined && this.state.countryList.length == 0) {
      const country = getCountrylist.countrylist.map((country) => {
        return {
          label: country.name,
          value: country.id,
        };
      });
      this.setState({ countryList: country }, () => {
        if (practice) {
          let ctry = getCountrylist.countrylist.filter((data) => data.name == practice.primaryContactCountry);
          if (ctry.length > 0) {
            ctry = ctry[0];
            this.props.form.setFields({
              primaryContactCountry: {
                value: { label: ctry.name, value: ctry.id },
              },
            });
            this.stateHandler({ value: ctry.id });
          }
        }
      });
    }
    if (getPracticeMemberList != undefined && this.state.memberList.length == 0) {
      let member = getPracticeMemberList.result.map((data) => {
        return {
          label: `${data.firstName} ${data.lastName}`,
          value: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
        };
      });
      if (member.length > 0) {
        member = [...member];
        this.setState({ memberList: member });
      }
    }
  }

  onPracticeIdBlur(event) {
    if (event.target.value === "") {
      this.setState({ practiceIdEdited: false });
    } else {
      const practiceId = cleanPracticeId(event.target.value);
      this.props.form.setFieldsValue({ practiceId });
    }
  }

  onPracticeIdChange(event) {
    const practiceId = cleanPracticeId(event.target.value);
    this.props.form.setFieldsValue({ practiceId });
  }

  //onPracticeNameChange(event) {
  //if (!this.state.practiceIdEdited && !this.props.practiceId) {
  //const practiceId = cleanPracticeId(event.target.value);
  //this.props.form.setFieldsValue({ practiceId });
  /// }
  //}

  handleUploadParams(uploadParams) {
    const { file, folder } = uploadParams;

    this.setState({
      file,
      folder,
      siteLogo: file == null ? null : this.state.siteLogo,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
        this.setState({ isPromptModalClose: true }, () => {
          this.setState({ isPromptModalClose: false });
        });
      }

      if (!err) {
        let payload = _.clone(values);
        payload.practiceId = cleanPracticeId(payload.name);
        if (payload.adminName) {
          payload.adminId = payload.adminName.value;
          payload.primaryContactFirstName = payload.adminName.firstName;
          payload.primaryContactLastName = payload.adminName.lastName;
        }
        // payload.practiceId = this.props.companyId + "-" + values.practiceId;
        if (payload.primaryContactCountry) payload.primaryContactCountry = payload.primaryContactCountry.label;
        if (payload.primaryContactCity) payload.primaryContactCity = payload.primaryContactCity.label;
        if (payload.primaryContactState) payload.primaryContactState = payload.primaryContactState.label;
        const avatarUrl = this.state.file ? await this.uploadAvatar() : null;
        this.props.onSubmit({
          ...payload,
          siteLogo: avatarUrl && avatarUrl.url ? avatarUrl.url : this.state.siteLogo,
          siteLogoS3Token: avatarUrl && avatarUrl.s3Token ? avatarUrl.s3Token : this.state.siteLogoS3Token,
        });
      }
    });
  }

  async uploadAvatar() {
    const upload = await this.props.uploadFile({
      variables: {
        file: this.state.file,
        folder: this.state.folder,
      },
    });

    const { ok } = upload.data.uploadFile;

    if (!ok) {
      notification.error({
        message: "An Error Has Occurred",
        description: "Our team has already been notified and will address the issue as quickly as possible.",
      });
    }

    const { s3Token, url } = upload.data.uploadFile.response;
    return { s3Token, url };
  }

  async cityHandler(input) {
    this.props.cityListQuery
      .refetch({ state_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getCities.cities;
        const cityData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        this.setState({ cityList: cityData }, () => {
          if (this.props.practice) {
            let ctry = cityData.filter((data) => data.label == this.props.practice.primaryContactCity);
            if (ctry.length > 0) {
              ctry = ctry[0];
              this.props.form.setFields({
                primaryContactCity: {
                  value: { label: ctry.label, value: ctry.value },
                },
              });

              this.cityHandler({ value: ctry.value });
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async stateHandler(input) {
    //this.setState({ stateList: [], cityList: [] });
    this.props.form.setFields({
      primaryContactState: {
        value: "",
      },
    });
    this.props.form.setFields({
      primaryContactCity: {
        value: "",
      },
    });
    this.props.stateListQuery
      .refetch({ country_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getStates.states;
        const stateData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        this.setState({ stateList: stateData }, () => {
          if (this.props.practice) {
            let ctry = stateData.filter((data) => data.label == this.props.practice.primaryContactState);
            if (ctry.length > 0) {
              ctry = ctry[0];
              this.props.form.setFields({
                primaryContactState: {
                  value: { label: ctry.label, value: ctry.value },
                },
              });

              this.cityHandler({ value: ctry.value });
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  memberHandler = async (e) => {
    this.props.form.setFields({
      primaryContactEmail: {
        value: e.email,
      },
    });
  };

  render() {
    const userContext = this.context;
    const { isAdvisorModal, setAdvisorModal, isPracticeInviteModal, setPracticeInviteModal } = userContext;
    const { getFieldDecorator } = this.props.form;
    const { account } = userContext;
    const addShortcut = (
      <>
        <p>Not seeing the person you’re looking for? Invite them to join Unitifi and once they’ve joined, you can name them as admin.</p>
        <div className="d-flex align-items-center justify-content-center">
          <Button className="inviteBtn" onClick={() => invitePracticeHandler()}>
            Invite Practice Member
          </Button>
          <Button className="inviteBtn advisorBtn ms-2" onClick={() => inviteAdvisorHandler()}>
            Invite Advisor
          </Button>
        </div>
      </>
    );
    const inviteAdvisorHandler = async () => {
      this.context.setAdvisorModal(true);
      this.context.setInviteModal("advisoreMember");
    };
    const invitePracticeHandler = async () => {
      this.context.setPracticeInviteModal(true);
      this.context.setInviteModal("practiceMember");
    };
    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Practice Name">
                {getFieldDecorator("name", {
                  initialValue: this.props.practice ? this.props.practice.name : null,
                  rules: [
                    {
                      message: "A practice name is required.",
                      required: true,
                    },
                  ],
                })(<Input placeholder="Name" />)}
              </FormItem>
            </Col>

            {/* <Col span={24}>
              <FormItem className="input-small" label="Practice Identifier" style={{ display: "none" }}>
                {getFieldDecorator("practiceId", {
                  initialValue: this.props.practice ? this.props.practice.practiceId : null,
                  rules: [
                    {
                      message: "A practice identifier is required.",
                      required: false,
                    },
                  ],
                })(<Input disabled onBlur={this.onPracticeIdBlur} onChange={this.onPracticeIdChange} placeholder="Practice ID" />)}
              </FormItem>
            </Col> */}
          </Row>
          {this.props.practice == null || (this.props.practice && account && this.props.practice.id !== account.practiceid) ? (
            <Row>
              <Col span={24}>
                <FormItem className="input-xsmall">
                  <div className="ant-form-item-label mb-2">
                    <label className="ant-form-item-required">Practice Admin Name</label>
                    <Popover overlayClassName="practiceAdminInfo" content={addShortcut} trigger="hover">
                      <i>
                        <img src={Info} />
                      </i>
                    </Popover>
                  </div>
                  {getFieldDecorator("adminName", {
                    // initialValue: this.props.practice ? this.props.practice.primaryContactState : null,
                    rules: [
                      {
                        message: "Admin name is required.",
                        required: this.props.practice ? false : true,
                      },
                    ],
                  })(<Select options={this.state.memberList} onChange={(e) => this.memberHandler(e)} placeholder="Select Admin" className="unitifi-select-container" classNamePrefix="unitifi-react-select" />)}
                </FormItem>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col span={24}>
              <FormItem label="Admin Email">
                {getFieldDecorator("primaryContactEmail", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactEmail : null,
                  rules: [
                    {
                      required: false,
                      message: "An Admin Email is required",
                    },
                  ],
                })(<Input readOnly />)}
              </FormItem>
            </Col>
          </Row>
          {this.props.practice && (this.props.form.getFieldValue("adminName") == undefined || this.props.form.getFieldValue("adminName").value == "") && (
            <Row gutter={16}>
              <Col span={12}>
                <FormItem label="Admin First Name">
                  {getFieldDecorator("primaryContactFirstName", {
                    initialValue: this.props.practice ? this.props.practice.primaryContactFirstName : null,
                    rules: [
                      {
                        required: true,
                        message: "An Admin First name is required.",
                      },
                    ],
                  })(<Input readOnly />)}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Admin Last Name">
                  {getFieldDecorator("primaryContactLastName", {
                    initialValue: this.props.practice ? this.props.practice.primaryContactLastName : null,
                    rules: [
                      {
                        required: true,
                        message: "An Admin Last name is required.",
                      },
                    ],
                  })(<Input readOnly />)}
                </FormItem>
              </Col>
            </Row>
          )}
          {/* ADDITIONAL INFO */}
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Street Address">
                {getFieldDecorator("primaryContactAddress", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactAddress : null,
                  rules: [
                    {
                      message: "Address is required.",
                      required: false,
                    },
                  ],
                })(<Input placeholder="Address" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Suite/Unit/Apartment (if applicable)">
                {getFieldDecorator("primaryContactSuite", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactSuite : null,
                })(<Input placeholder="Suite/Unit/Apartment (if applicable)" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Country">
                {getFieldDecorator("primaryContactCountry", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactCountry : null,
                  rules: [
                    {
                      message: "Country is required.",
                      required: false,
                    },
                  ],
                })(
                  <Select
                    options={this.state.countryList}
                    onChange={(e) => this.stateHandler(e)}
                    className="unitifi-select-container"
                    classNamePrefix="unitifi-react-select"
                    filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Postal Code/ZIP Code">
                {getFieldDecorator("primaryContactZipCode", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactZipCode : null,
                  rules: [
                    {
                      message: "Postal Code/ZIP Code is required.",
                      required: false,
                    },
                  ],
                })(<Input placeholder="Postal Code/ZIP Code" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem className="" label="State/Province/Region">
                {getFieldDecorator("primaryContactState", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactState : null,
                  rules: [
                    {
                      message: "State/Province/Region is required.",
                      required: false,
                    },
                  ],
                })(
                  <Select
                    options={this.state.stateList}
                    onChange={(e) => {
                      this.cityHandler(e);
                      this.props.form.setFields({
                        primaryContactCity: null,
                      });
                    }}
                    className="unitifi-select-container"
                    classNamePrefix="unitifi-react-select"
                    filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="City/Town">
                {getFieldDecorator("primaryContactCity", {
                  initialValue: this.props.practice ? this.props.practice.primaryContactCity : null,
                  rules: [
                    {
                      message: "City/Town is required.",
                      required: false,
                    },
                  ],
                })(<Select options={this.state.cityList} className="unitifi-select-container" classNamePrefix="unitifi-react-select" filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0} />)}
              </FormItem>
            </Col>
          </Row>

          {/* ADDITIONAL INFO */}
          <Row gutter={16}>
            <Col span={18}>
              {/* {this.props.practice ? ( */}
              <FormItem className="input-small" label="Status">
                {getFieldDecorator("status", {
                  initialValue: this.props.practice ? this.props.practice.status : "Active",
                  rules: [
                    {
                      message: "A practice status is required.",
                      required: false,
                    },
                  ],
                })(
                  <Selectd placeholder="Status">
                    <Option value="Active">
                      <Badge status="success" text="Active" />
                    </Option>
                    <Option value="Inactive">
                      <Badge status="error" text="Inactive" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
              {/* ) : null} */}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={18}>
              {/* {this.props.practice ? ( */}
              <FormItem className="input-small" label="Assessment Completed Notification">
                {getFieldDecorator("assessmentNotification", {
                  initialValue: this.props.practice ? this.props.practice.assessmentNotification : true,
                  rules: [
                    {
                      message: "A Assessment Completed Notification is required.",
                      required: false,
                    },
                  ],
                })(
                  <Selectd placeholder="Status">
                    <Option value={false}>
                      <Badge status="error" text="No, don't send me notification" />
                    </Option>
                    <Option value={true}>
                      <Badge status="success" text="Send me notification" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
              {/* ) : null} */}
            </Col>
          </Row>
          <FormItem>
            <SiteLogo handleUploadParams={this.handleUploadParams} practice={this.props.practice ? this.props.practice : null} />
          </FormItem>
          {this.props.practice === null ? (
            // new practice email
            <span>
              <h3 className="mb-2">Customize Practice Welcome Message</h3>
              <FormItem label="Subject Line">
                {getFieldDecorator("newPracticeEmailTitle", {
                  initialValue: this.state.newPracticeEmailTitle,
                  rules: [
                    {
                      message: "New Practice email subject line is required.",
                      required: false,
                    },
                  ],
                })(<Input />)}
              </FormItem>
              <FormItem label="New User Message Body: (1000 character limit)" style={{ marginBottom: "0.5rem" }}>
                {getFieldDecorator("newPracticeEmailBody", {
                  initialValue: this.state.newPracticeEmailBody,
                  rules: [
                    {
                      message: "New Practice email body is required.",
                      required: false,
                    },
                  ],
                })(<TextArea rows={10} />)}
              </FormItem>
              <Alert type="info" message="Params: [[SYS_GENERATED_LINK]]" className="mb-3" />
            </span>
          ) : (
            // show client and user email template button
            <FormItem label="Email Template">
              <Link to={`/practices/mailer/edit/${this.props.practice.id}`} style={{ marginBottom: "2rem", display: "block" }}>
                Edit Practice Email Templates
              </Link>
            </FormItem>
          )}
          <Row gutter={16}>
            <Col span={14}>
              <FormItem label="Notes">
                {getFieldDecorator("notes", {
                  initialValue: this.props.practice ? this.props.practice.notes : null,
                })(<TextArea rows={10} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="form-toolbar">
            {this.props.practice ? (
              <EditButtons deleteRights={"deletePractice"} cancelUrl="/practices" loading={this.props.loading} onDelete={this.props.onDelete} onSubmit={this.handleSubmit} rights={["superAdmin", "createpractice", "editpractice"]} />
            ) : (
              <AddButtons
                cancelUrl="/practices"
                createAnother={this.props.createAnother}
                loading={this.props.loading}
                onCreateAnother={this.props.onCreateAnother}
                onSubmit={this.handleSubmit}
                rights={["superAdmin", "createpractice", "editpractice"]}
              />
            )}
          </div>
        </Form>
        {!this.props.loading && (
          <PromptModal
            isBlocked={this.props.form.isFieldsTouched()}
            title="Leave Page?"
            content="You have unsaved changes. Are you sure you want to leave this page?"
            {...this.props}
            submitAction={this.handleSubmit}
            loading={this.props.loading}
            isPromptModalClose={this.state.isPromptModalClose}
          />
        )}
      </FormWrapper>
    );
  }
}

PracticeForm.propTypes = {
  createAnother: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onCreateAnother: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  practice: PropTypes.shape({
    name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    practiceId: PropTypes.string,
    siteLogo: PropTypes.string,
    siteLogoS3Token: PropTypes.string,
    status: PropTypes.oneOf(["Active", "Inactive"]).isRequired,
  }),
};

PracticeForm.defaultProps = {
  createAnother: false,
  onCreateAnother: null,
  onDelete: null,
  practice: null,
};

const uploadFileMutation = gql`
  mutation uploadFile($file: Upload!, $folder: String!) {
    uploadFile(file: $file, folder: $folder) {
      ok
      response {
        s3Token
        url
      }
    }
  }
`;

const countryQuery = gql`
  query {
    getCountrylist {
      countrylist {
        id
        name
        iso2
        iso3
      }
    }
  }
`;

const stateQuety = gql`
  query($country_id: String!) {
    getStates(country_id: $country_id) {
      states {
        id
        name
        state_code
      }
    }
  }
`;

const cityQuery = gql`
  query($state_id: String!) {
    getCities(state_id: $state_id) {
      cities {
        id
        name
      }
    }
  }
`;
const memberQuery = gql`
  query($portalId: Int!) {
    getPracticeMemberList(portalId: $portalId) {
      result
    }
  }
`;

export default compose(
  graphql(uploadFileMutation, { name: "uploadFile" }),
  graphql(countryQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
  }),
  graphql(stateQuety, {
    name: "stateListQuery",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        country_id: props.country_id ? props.country_id : "233",
      },
    }),
  }),
  graphql(cityQuery, {
    name: "cityListQuery",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        state_id: props.state_id,
      },
    }),
  }),
  graphql(memberQuery, {
    name: "memberQuery",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        portalId: parseInt(props.portalId),
      },
    }),
  })
)(Form.create()(PracticeForm));
