import React, { useState } from "react";
import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, Modal, message, Icon, notification } from "antd";
import { FormWrapper } from "../form";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
const FormItem = Form.Item;

const StripeCard = (props) => {
  const { form, setCardShow, currentLogin, refetch } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const { getFieldProps, getFieldDecorator, getFieldValue } = form;
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        const cardElement = elements.getElement(CardNumberElement);
        const { token, error } = await stripe.createToken(cardElement, {
          address_zip: values.zipcode,
        });

        if (!token) {
          setLoading(false);
          if (error && error.message) message.error(error.message || "Invalid Card Detail");
          else message.error("Invalid Card Detail");
          return;
        }
        let variables = { token: token.id, customerId: currentLogin.customerStripeId };

        let result = await client.mutate({
          mutation: gql`
            mutation($customerId: String!, $token: String!) {
              addCard(customerId: $customerId, token: $token) {
                ok
                message
              }
            }
          `,
          variables: variables,
        });

        if (result && result.data.addCard.ok) {
          notification.success({ message: result.data.addCard.message });
          refetch();
        } else notification.error({ message: result.data.addCard.message });

        setCardShow(false);
        setLoading(false);
      }
    });
  };

  const compareEmail = (rule, value, callback) => {
    if (value && value == currentLogin.email) {
      callback("The email address you entered is already in use.");
    } else {
      callback();
    }
  };
  return (
    <div className="public-form-wrapper">
      <FormWrapper className="public-form">
        <div className="unitifi-login-header-bar">
          <h5>Update Billing</h5>
        </div>
        <Form layout="vertical" onSubmit={handleSubmit} className="update-bill">
          <div className="unitifi-payment-card-wrapper">
            <FormWrapper>
              <FormItem label="First Name">
                {getFieldDecorator("firstname", {
                  initialValue: currentLogin.firstName,
                  rules: [
                    {
                      message: "First Name is required.",
                      required: true,
                    },
                  ],
                })(<Input autoCorrect="off" autoCapitalize="none" placeholder="First Name" />)}
              </FormItem>

              <FormItem label="Last Name">
                {getFieldDecorator("lastname", {
                  initialValue: currentLogin.lastName,
                  rules: [
                    {
                      message: "Last Name is required.",
                      required: true,
                    },
                  ],
                })(<Input autoCorrect="off" autoCapitalize="none" placeholder="Last Name" />)}
              </FormItem>

              <FormItem label="Card Number">
                {getFieldDecorator("cardnumber", {
                  rules: [
                    {
                      message: "Card Number is required.",
                      required: true,
                    },
                  ],
                })(
                  <CardNumberElement
                    className="text-center"
                    options={{
                      style: {
                        base: {
                          fontSize: "14px",
                          color: "#23272c",
                          fontFamily: "Lato",
                          "::placeholder": {
                            color: "#A3A3A3",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                    }}
                  />
                )}
              </FormItem>

              <Row gutter={16}>
                <Col span={8} className="gutter-row">
                  <FormItem label="Security Code" className="cvv mb-0">
                    {getFieldDecorator("cardcsv", {
                      rules: [
                        {
                          message: "Card CVC is required.",
                          required: true,
                        },
                      ],
                    })(
                      <CardCvcElement
                        options={{
                          style: {
                            base: {
                              fontSize: "14px",
                              color: "#23272c",
                              fontFamily: "Lato",
                              "::placeholder": {
                                color: "#A3A3A3",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    )}
                    {/* <Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="CVC" /> */}
                  </FormItem>
                </Col>
                <Col span={8} className="gutter-row">
                  <FormItem label="Expiration" className="expiry mb-0">
                    {getFieldDecorator("cardexp", {
                      rules: [
                        {
                          message: "Card expiration is required.",
                          required: true,
                        },
                      ],
                    })(
                      <CardExpiryElement
                        options={{
                          style: {
                            base: {
                              fontSize: "14px",
                              color: "#23272c",
                              fontFamily: "Lato",
                              "::placeholder": {
                                color: "#A3A3A3",
                              },
                            },
                            invalid: {
                              color: "#9e2146",
                            },
                          },
                        }}
                      />
                    )}
                    {/* <Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="MM/YY" /> */}
                  </FormItem>
                </Col>
                <Col span={8} className="gutter-row">
                  <FormItem label="Postal Code/ZIP Code" className="zipcode mb-0">
                    {getFieldDecorator("zipcode", {
                      rules: [
                        {
                          message: "Postal Code/ZIP Code is required.",
                          required: true,
                        },
                      ],
                    })(<Input className="text-center" autoCorrect="off" autoCapitalize="none" placeholder="Code" />)}
                  </FormItem>
                </Col>
              </Row>
            </FormWrapper>
          </div>

          <div className="modal-footer-bnts-wrapper mt-3">
            <Button type="primary" size="large" htmlType="submit" loading={loading} disabled={loading} className="unitifi-login-btn">
              Save
            </Button>
            <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setCardShow(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </div>
  );
};

export default Form.create()(StripeCard);
