import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import GeneralPage from "../../../containers/settings/general";
import AccountPage from "../../../containers/settings/account";
import PaymentMethod from "../../../containers/settings/subscription";
import ManageUser from "../../../containers/settings/manageUser";

class SettingsRoute extends React.Component {
  render() {
    const { gblUserId, gblUserIsPortalAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice, userPortals, currentPortalId } = this.props;
    return (
      <Switch>
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/settings" to="/settings/general" strict />

        <Redirect exact from="/settings/account/" to="/settings/account" strict />

        <Redirect exact from="/settings/subscription/" to="/settings/subscription" strict />

        {/* admin routes */}
        <Route exact path="/settings/general" render={(props) => <GeneralPage {...this.props} />} />

        <Route exact path="/settings/account" render={(props) => <AccountPage {...this.props} />} />

        <Route exact path="/settings/subscription" render={(props) => <PaymentMethod {...this.props} />} />
        <Route exact path="/settings/manage-user" render={(props) => <ManageUser {...this.props} />} />
      </Switch>
    );
  }
}

export default SettingsRoute;
