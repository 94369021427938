import styled from 'styled-components';

const UploadWrapper = styled.div`
  .upload-mobile {
    .ant-upload {
      width: 100%;

      .ant-btn {
        width: 100%;
      }
    }
  }

  .ant-upload-list {
    width: 50%;
  }
`;

export default UploadWrapper;
