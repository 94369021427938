import React from "react";
import TitlebarWrapper from "./index.style";

function TitleBar({ title, subTitle }) {
  return (
    <TitlebarWrapper>
      <div className="title-bar-wrapper">
        <div className="title-wrapper">
          <div className="title-text">{title}</div>
          <div className="sub-title-text">{subTitle}</div>
        </div>
      </div>
    </TitlebarWrapper>
  );
}

export default TitleBar;
