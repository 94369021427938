import { Result, Button } from "antd";
import _, { flowRight as compose } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";

const UpdateEmail = (props) => {
  console.log("done peopres=>>", props);
  const client = useApolloClient();

  useEffect(async () => {
    if (props.match.params && props.match.params.token) {
      let result = await client.mutate({
        mutation: gql`
          mutation($token: String!) {
            updateUserEmail(token: $token) {
              ok
              message
            }
          }
        `,
        variables: { token: props.match.params.token },
      });
      if (result.data.updateUserEmail.ok) {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("current_company");
        localStorage.removeItem("current_practice");
        localStorage.removeItem("current_practice_id");
        localStorage.removeItem("current_company_id");
      }
    }
  }, [props.match]);

  // useEffect(() => {
  // const e_team = _.get(practiceData, "getExceedTeam.exceedteam", []);

  // setExceedTeam(e_team);
  //}, [exceedteam]);
  return (
    <Result
      status="success"
      title="Email updated successfully"
      subTitle="Please login with your new email."
      extra={[
        <Button type="primary" key="console" href="/login">
          Login
        </Button>,
      ]}
    />
  );
};

export default UpdateEmail;
