import styled from 'styled-components';

const ClientMakeupRadialWrapper = styled.div`
  align-items: center;
  display: flex;
  .radial-chart-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    .rv-radial-chart__series--pie__slice {
      cursor: pointer;
    }
  }
`;

export default ClientMakeupRadialWrapper;
