import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  .form-button {
    align-self: center;
    &:first-child {
      flex-grow: 0;
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 16px;
    }
  }
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap-reverse;
    .form-button {
      &:last-child {
        margin-bottom: 32px;
        margin-left: 0;
        min-width: 100%;
      }
    }
  }
`;

export default ButtonsWrapper;
