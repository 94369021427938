import styled from 'styled-components';
import { palette } from 'styled-theme';

const UserViewWrapper = styled.div`
  .row-wrapper {
    align-items: center;
    border-bottom: 1px solid rgba(238,242,244,1);
    display: flex;
    padding: 24px 0;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    &:first-child {
      padding-top: 0;
    }
    div {
      &:first-child {
        width: calc(100% / 3 * 1);
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      &:last-child {
        width: calc(100% / 3 * 2);
        color: #969696;
        font-weight: 400;
        //font-size: 14px;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .page-select-main-wrapper{
      .ant-select{
        width:auto !important;
        &.ant-select-open{
          .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/default-chevron-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
                transform:rotate(180deg);
              }
              .anticon{
                display:none;
              }
            }
        }
        .ant-select-selection{
          background:#fff;
          width:314px !important;
          height:36px;
          border:0;
          @media(max-width:420px){
            width:250px !important;
          }
          &:hover{
            border-color:none !important;
          }
          &:focus{
            box-shadow:none !important;
            border-color:none !important;
          }
          .ant-select-selection__rendered{
            width:150px;
            .ant-select-selection-selected-value{
              font-size:16px !important;
              font-weight:600 !important;
              line-height:36px;
            }
          }
          .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/default-chevron-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
              }
              .anticon{
                display:none;
              }
            }
        }
      }
    }
   
    .row-label {
      color: ${palette('text', 0)};
      font-weight: 600;
      font-size: 15px;
    }
  }
`;

export default UserViewWrapper;
