
import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ButtonsWrapper from './index.style';
import CustomButton from "../../../commons/CustomButton";

const SaveButtons = props => (
  <ButtonsWrapper>
    <div className="form-button">
      {props.cancelOnClick ?
        <Button
          onClick={props.cancelOnClick}
        >
          Cancel
        </Button>
        :
        <Link to={props.cancelUrl}>
          <Button>
            Cancel
          </Button>
        </Link>}
    </div>
      <CustomButton
          rights={props.rights}
          className="form-button"
          loading={props.loading}
          onClick={props.onSubmit}
          type="primary">
          Save
      </CustomButton>
    {/*<Button*/}
      {/*className="form-button"*/}
      {/*loading={props.loading}*/}
      {/*onClick={props.onSubmit}*/}
      {/*type="primary"*/}
    {/*>*/}
      {/*Save*/}
    {/*</Button>*/}
  </ButtonsWrapper>
);

SaveButtons.defaultProps = {
  cancelOnClick: null,
  cancelUrl: null,
  rights:[]
};

SaveButtons.propTypes = {
  cancelOnClick: PropTypes.func,
  cancelUrl: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  rights:PropTypes.any
};

export default SaveButtons;
