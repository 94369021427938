import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { notification } from "antd";
import { Redirect } from "react-router";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import AdvisorForm from "../form/advisorsForm";

class AdvisorAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : `/${this.props.currentPractice}/advisors`,
      key: 1,
      loading: false,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      //phoneNumber: values.p1 && values.p2 && values.p3 ? `${values.p1}${values.p2}${values.p3}` : null,
      practiceId: this.props.currentPractice,
      roleId: 3,
      status: "Active",
    };

    this.props
      .mutate({ variables })
      .then(({ data }) => {
        if (data.createAdvisor.ok && !data.createAdvisor.errors) {
          this.handleSuccess(`${data.createAdvisor.advisor.firstName} ${data.createAdvisor.advisor.lastName}`);
          return;
        }

        this.handleError(data.createAdvisor.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(advisorName) {
    notification.success({
      message: "Advisor Created Successfully",
      //description: `${advisorName} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: `/${this.props.currentPractice}/advisors` });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  render() {
    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <Titlebar currentPractice={this.props.currentPractice} displayText="Add Advisor" />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <AdvisorForm
              currentPractice={this.props.currentPractice}
              cancelUrl={this.state.cancelUrl}
              createAnother={this.state.reset}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
              me={this.props.me}
            />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addAdvisorMutation = gql`
  mutation createAdvisor($address: String, $city: String, $email: String!, $firstName: String, $lastName: String, $phoneNumber: String, $practiceId: String, $roleId: Int, $state: String, $status: String, $zipCode: String, $supportId: [Int]) {
    createAdvisor(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      roleId: $roleId
      state: $state
      status: $status
      zipCode: $zipCode
      supportId: $supportId
    ) {
      ok
      advisor {
        firstName
        lastName
      }
      errors {
        message
        path
      }
    }
  }
`;

export default graphql(addAdvisorMutation)(AdvisorAdd);
