import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
// import { createHttpLink } from 'apollo-link-http';
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { apiUrl } from "./index";
// console.log("apiUrl", apiUrl);
const httpLink = createUploadLink({ uri: `${apiUrl}/graphql` });

const middlewareLink = setContext(() => ({
  headers: {
    "x-token": localStorage.getItem("token"),
    "x-refresh-token": localStorage.getItem("refreshToken"),
  },
}));

const afterwareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers) {
      const token = headers.get("x-token");
      const refreshToken = headers.get("x-refresh-token");

      if (token) {
        localStorage.setItem("token", token);
      }

      if (refreshToken) {
        localStorage.setItem("refreshToken", refreshToken);
      }
    }

    return response;
  })
);

const link = afterwareLink.concat(middlewareLink.concat(httpLink));

const cache = new InMemoryCache();
export default new ApolloClient({
  link,
  cache: cache,
  queryDeduplication: false,
  query: {
    fetchPolicy: "network-only",
  },
});
