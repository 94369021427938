import React from 'react';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Icon, Modal, notification, Spin } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import UserForm from '../form';

const { confirm } = Modal;

class UserEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.currentPractice ? `/${this.props.currentPractice}/users`: '' ,
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }


  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const {status} = values;

    const variables = {
      ...values,
      portalId: values.portalId ? values.portalId : this.props.userPortals[0] ? [this.props.userPortals[0].id] : null,
      status: status? status : 'Pending',
      id: this.props.data.getUser.id,
      isGlobalAdmin: values.roleId === 1,
      isPortalAdmin: [4,5].find((id)=> id == values.roleId) !== undefined,
      isPracticeAdmin: [2,6].find((id)=> id == values.roleId) !== undefined,
      phoneNumber: `${values.p1}${values.p2}${values.p3}`,
    };

    this.props.editUser({ variables })
    .then(({ data }) => {
      if (data.editUser.ok && !data.editUser.errors) {
        this.handleSuccess(`${data.editUser.user.firstName} ${data.editUser.user.lastName} has been successfully updated.`);
        return;
      }

      this.handleError(data.editUser.errors);
    }).catch((error) => {
      console.log(error);
    });
  }

  handleDeleteConfirm() {
    confirm({
      title: 'Delete User',
      content: `Are you sure you want to permanently delete ${this.props.data.getUser.firstName} ${this.props.data.getUser.lastName}? This action cannot be undone.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: 'close-circle',
      onOk: this.removeUser,
      width: '680px',
    });
  }

  removeUser() {
    const { id, practiceId } = this.props.data.getUser;

    this.props.deleteUser({ variables: { id, practiceId } })
      .then(({ data }) => {
        if (data.deleteUser.ok && !data.deleteUser.errors) {
          this.handleSuccess(`${this.props.data.getUser.firstName} ${this.props.data.getUser.lastName} has been successfully deleted.`, 'deleted');
          return;
        }

        this.handleError(data.deleteUser.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === 'deleted' ? 'User Deleted Successfully' : 'User Updated Successfully',
      //description,
    });

    const redirectTo = this.state.cancelUrl ? this.state.cancelUrl : '/users';

    this.setState({ redirectTo });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    const { data: { loading, error, getUser } } = this.props;

    if (loading) {
      return (
        <div className='unirifi-costomer-layout-wrapper'>
          <div className='unitifi-subheader-bar-wrapper'>
            <Titlebar
              currentPractice={this.props.currentPractice}
              displayText="Edit User"
            />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className='unirifi-costomer-layout-wrapper'>
          <div className='unitifi-subheader-bar-wrapper'>
            <Titlebar
              currentPractice={this.props.currentPractice}
              displayText="Edit User"
            />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - user didn&apos;t load.</p>
          </div>
        </div>
      );
    }
    return (
      <div className='unirifi-costomer-layout-wrapper'>
        <div className='unitifi-subheader-bar-wrapper'>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Edit User"
          />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <UserForm
              cancelUrl={this.state.cancelUrl}
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
              gblUserRole={this.props.gblUserRole}
              gblUserId={this.props.gblUserId}
              user={getUser}
              gblUserId={this.props.gblUserId}
              gblUserRole={this.props.gblUserRole}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getUserQuery = gql`
  query($id: Int!) {
    getUser(id: $id) {
      id
      address
      city
      email
      firstName
      lastName
      phoneNumber
      isGlobalAdmin
      isPracticeAdmin
      isPortalAdmin
      practiceId
      portalid
      roleId
      role
      state
      status
      zipCode
    }
  }
`;

const editUserMutation = gql`
  mutation editUser(
    $id: Int!
    $address: String
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $password: String
    $phoneNumber: String
    $state: String
    $status: String!
    $zipCode: String
    $isGlobalAdmin: Boolean
    $isPracticeAdmin: Boolean
    $isPortalAdmin: Boolean
    $practiceId: [Int]
    $portalId: [Int]
    $roleId: Int
  ) {
    editUser(
      id: $id
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      phoneNumber: $phoneNumber
      state: $state
      status: $status
      zipCode: $zipCode
      isGlobalAdmin: $isGlobalAdmin
      isPracticeAdmin: $isPracticeAdmin
      isPortalAdmin: $isPortalAdmin
      practiceId: $practiceId
      portalId: $portalId
      roleId: $roleId
    ) {
      user {
        firstName
        lastName
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUser(
    $id: Int!
  ) {
    deleteUser( id: $id) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getUserQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editUserMutation, { name: 'editUser' }),
  graphql(deleteUserMutation, { name: 'deleteUser' }),
)(UserEdit);
