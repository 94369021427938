import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PracticesAdd, PracticesEdit, PracticesView, PracticesList, PracticesMailer } from "../../../containers/practices";
import Dashboard from "../../../containers/dashboard";

class PracticeRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPracticeAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice, gblUserIsPortalAdmin, sidebarCollapsed, firstLogin, currentPortalId, me } = this.props;

    if (!currentPortalId && !currentPractice) return <Redirect to="/settings/account" strict />;

    return (
      <Switch>
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/practices/" to="/practices" strict />
        <Redirect exact from="/practices/add/:poid/" to="/practices/add/:poid" strict />
        <Redirect exact from="/practices/edit/:id/:poid/" to="/practices/edit/:id/:poid" strict />
        <Redirect exact from="/practices/view/:id/:poid/" to="/practices/view/:id/:poid" strict />
        <Redirect exact from="/practices/mailer/edit/:id/" to="/practices/mailer/edit/:id" strict />
        <Redirect exact from="/:company/practices/edit/:id/:poid/" to="/:company/practices/edit/:id/:poid" strict />
        <Redirect exact from="/:company/practices/view/:id/:poid/" to="/:company/practices/view/:id/:poid" strict />
        <Redirect exact from="/:company/practices/" to="/:company/practices" strict />

        {/* admin routes */}
        <Route
          exact
          path="/:company/practices"
          render={(props) => (
            <PracticesList
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              me={me}
            />
          )}
        />
        {/* {gblUserIsGlobalAdmin || gblUserIsPortalAdmin ? */}
        <Route
          exact
          path="/practices"
          render={(props) => (
            <PracticesList
              {...this.props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              me={me}
            />
          )}
        />
        {/* : null} */}
        {/* <Redirect from="/practices" to={`/${userPracticeList[0].practiceId}`} /> */}
        <Route
          exact
          path="/practices/add/:poid"
          render={(props) => (
            <PracticesAdd
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/practices/edit/:poid/:id"
          render={(props) => (
            <PracticesEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:company/practices/edit/:poid/:id"
          render={(props) => (
            <PracticesEdit
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/practices/view/:poid/:id"
          render={(props) => (
            <PracticesView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/:company/practices/view/:poid/:id"
          render={(props) => (
            <PracticesView
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/practices/mailer/edit/:id"
          render={(props) => (
            <PracticesMailer
              {...props}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserRole={gblUserRole}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
        <Route
          exact
          path="/practices/:practiceId"
          render={(props) => (
            <Dashboard
              {...props}
              firstLogin={firstLogin}
              currentPractice={currentPractice}
              gblUserId={gblUserId}
              gblUserIsGlobalAdmin={gblUserIsGlobalAdmin}
              gblUserIsPortalAdmin={gblUserIsPortalAdmin}
              gblUserIsPracticeAdmin={gblUserIsPracticeAdmin}
              gblUserRole={gblUserRole}
              sidebarCollapsed={sidebarCollapsed}
              currentPortalId={currentPortalId}
              me={me}
            />
          )}
        />
      </Switch>
    );
  }
}

export default PracticeRoutes;
