import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import { injectGlobal, ThemeProvider } from 'styled-components';
import AppLocale from './language';
import PublicRoutes from './appRouter';
import AppWrapper from './app.style';
import client from './config/apollo';
import config, { getCurrentLanguage } from './language/config';
import theme from './theme';
import { themeConfig } from './config';
import {AccountProvider} from "./context/AccountContext";
import moment  from "moment";
import 'bootstrap/dist/css/bootstrap.css'
const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

injectGlobal`${theme.globalstyle}`;

const App = () => (
  <ConfigProvider locale={currentAppLocale.antd}>
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ThemeProvider theme={theme[themeConfig.theme]}>
        <AppWrapper>
            <AccountProvider >
              <ApolloProvider client={client}>
                  <PublicRoutes />
              </ApolloProvider>
            </AccountProvider>
        </AppWrapper>
      </ThemeProvider>
    </IntlProvider>
  </ConfigProvider>
);

export default App;
