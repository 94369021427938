import React from 'react';
import { Icon } from 'antd';
import IntlMessages from '../../../../../helpers/intlMessages';
/**
 * checks the entered password value for at
 * least 1 lowercase & updates user feedback
 * as user types into new password input
 */
class CharacterLowercaseFeedbackItem extends React.Component {
  render() {
    const { input, value } = this.props;

    let feedbackType;
    let iconType;

    if (value) {
      const check = RegExp('^(?=.*[a-z].*).+$');
      feedbackType = check.test(value) ? 'success' : 'error';
      iconType = check.test(value) ? 'check' : 'close';
    }

    return (
      <div className={input ? `input-password-feedback ${feedbackType}` : 'input-password-feedback'}>
        <Icon type={input ? `${iconType}-circle` : 'exclamation-circle'} />
        <IntlMessages id="passwordFeedback.lowercase" />
      </div>
    );
  }
}

export default CharacterLowercaseFeedbackItem;
