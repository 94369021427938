import { Popconfirm, Form, Input, Select, AutoComplete, Icon, Result, Typography, Col, Row, List, Avatar, Button, Dropdown, Menu, Modal, Card, message, Tag } from "antd";
import gql from "graphql-tag";
import React, { useState, useMemo } from "react";
import { Titlebar } from "../../../components";
import { FormWrapper } from "../../../components/form";
import { graphql } from "react-apollo";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../../layouts/app/layoutContent";
import _, { flowRight as compose } from "lodash";
import moment from "moment";
import ButtonGroup from "antd/lib/button/button-group";

import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import useResponsiveFontSize from "../useResponsiveFontSize";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import StripeAccount from "./stripeAccount";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const FormItem = Form.Item;
const InputGroup = Input.Group;
const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;
const { Title, Paragraph } = Typography;

const StripeComponent = (props) => {
  const { form, setCreateState, createState, refetch, create } = props;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    form.validateFields(async (err, values) => {
      if (!err) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: values,
        });
        console.log(error, "errorss");
        if (error) {
          setLoading(false);
          message.error(error.message);
        } else {
          await create({
            variables: { paymentMethod: JSON.stringify(paymentMethod) },
          }).then(function(result) {
            if (result.data.createPaymentMethod.ok) {
              setLoading(false);
              setCreateState({ ...createState, visible: false });
              refetch();
            } else {
              setLoading(false);
              message.error("Unexpected error, please try again.");
            }
          });
        }
      } else {
        setLoading(false);
      }
    });
  };

  const handleCancel = () => {
    setCreateState({ ...createState, visible: false });
  };

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={16}>
        <Col span={24}>
          <FormItem className="input-small" label="Cardholder Name">
            {getFieldDecorator("name", {
              initialValue: "",
              rules: [
                {
                  message: "A user first name is required.",
                  required: true,
                },
              ],
            })(<Input placeholder="First Name" />)}
          </FormItem>
        </Col>
        <Col span={24}>
          <Card>
            <CardElement
              options={options}
              onReady={() => {
                // console.log("CardElement [ready]");
              }}
              onChange={(event) => {
                // console.log("CardElement [change]", event);
              }}
              onBlur={() => {
                // console.log("CardElement [blur]");
              }}
              onFocus={() => {
                // console.log("CardElement [focus]");
              }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 10 }}>
        <Col span={24}>
          <Button loading={loading} style={{ marginLeft: 10, float: "right" }} type="primary" disabled={!stripe} htmlType="submit">
            Submit
          </Button>

          <Button type={"danger"} style={{ float: "right" }} onClick={() => handleCancel()}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
const CreateModal = (props) => {
  const { form, setCreateState, createState } = props;
  const { visible, confirmLoading } = createState;
  return (
    <Modal
      title="Add a card"
      visible={visible}
      // onOk={handleSubmit}
      confirmLoading={confirmLoading}
      // onCancel={handleCancel}
      closable={false}
      okText={"Add card"}
      footer={null}>
      <Row gutter={16}>
        <Col span={24}>
          <Elements stripe={stripePromise}>
            <StripeComponent {...props} />
          </Elements>
        </Col>
      </Row>
    </Modal>
  );
};

const PaymentMethodForm = (props) => {
  const {
    form,
    paymentMethod: { viewUserPaymentMethod, loading, refetch },
    customer: { viewCustomer, refetch: refetchCustomer },
  } = props;

  const { confirm } = Modal;

  // console.log(viewCustomer,'viewCustomer')
  // console.log(viewUserPaymentMethod,'viewUserPaymentMethod')
  async function confirmDelete(id) {
    confirm({
      title: "Do you want to delete this card?",
      onOk() {
        props
          .delete({
            variables: {
              id,
            },
          })
          .then(function(result) {
            message.success("Successfully removed.");
            refetch();
          });
      },
      onCancel() {},
    });
  }

  async function confirmDefault(id) {
    confirm({
      title: "Do you want to set it as default payment card?",
      onOk() {
        props
          .setDefault({
            variables: {
              id,
            },
          })
          .then(function(result) {
            message.success("Successfully set to default.");
            refetch();
            refetchCustomer();
          });
      },
      onCancel() {},
    });
  }

  const menu = (id) => (
    <Menu>
      <Menu.ItemGroup key="g1" title="ACTION">
        <Menu.Item key="0" onClick={() => confirmDefault(id)}>
          Set as Default
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="3" onClick={() => confirmDelete(id)}>
          Delete
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <FormWrapper>
      <Typography>
        <Title level={4} className={"form-title"}>
          Cards
        </Title>
        <Paragraph>This information is used for paying your subscription.</Paragraph>
      </Typography>

      <Row>
        <Col>
          <List
            itemLayout="horizontal"
            dataSource={viewUserPaymentMethod.paymentMethod || []}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <ButtonGroup>
                    <Button type={"danger"} onClick={() => confirmDelete(item.id)}>
                      <Icon type="delete" />
                    </Button>
                    <Dropdown overlay={() => menu(item.id)} trigger={["click"]} placement={"bottomRight"}>
                      <Button>
                        <Icon type="ellipsis" />
                      </Button>
                    </Dropdown>
                  </ButtonGroup>,
                ]}>
                <List.Item.Meta
                  className={"stripe-card-item"}
                  avatar={<Avatar shape={"square"} icon="credit-card" style={{ color: "#f56a00", backgroundColor: "#fde3cf" }} />}
                  title={
                    <>
                      {`${item.card.brand.toUpperCase()} •••• ${item.card.last4} `}&nbsp;
                      {viewCustomer ? viewCustomer.customer.invoice_settings ? viewCustomer.customer.invoice_settings.default_payment_method == item.id ? <Tag color={"blue"}>Default</Tag> : "" : "" : ""}
                    </>
                  }
                  description={`Expires: ${moment(`${item.card.exp_year}-${item.card.exp_month}-01`).format("MMM  YYYY")}`}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>

      <div className="form-toolbar"></div>
    </FormWrapper>
  );
};

const AccountPage = (props) => {
  const {
    form,
    paymentMethod: { viewUserPaymentMethod, loading, refetch },
  } = props;

  const [createState, setCreateState] = useState({
    visible: false,
    confirmLoading: false,
  });

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <Titlebar displayText="Subscription" />
        <div className="button-bar-wrapper">
          <Button type={"primary"} onClick={() => setCreateState({ visible: true })}>
            <Icon type="plus" /> Add
          </Button>
        </div>
      </div>
      <div className="subscription-layout">
        <Row gutter={16}>
          <Col span={12}>
            <LayoutContentWrapper className="margin-remove-div">
              <LayoutContent>
                <StripeAccount cards={viewUserPaymentMethod} />
              </LayoutContent>
            </LayoutContentWrapper>
          </Col>
          <Col span={12}>
            <LayoutContentWrapper className="margin-remove-div">
              <LayoutContent>
                {loading && <Result icon={antIcon} title="Loading ..." />}
                {!loading && <PaymentMethodForm {...props} />}
              </LayoutContent>
            </LayoutContentWrapper>
          </Col>
        </Row>
      </div>
      {createState.visible && <CreateModal {...props} createState={createState} refetch={refetch} setCreateState={setCreateState} />}
    </div>
  );
  // return
};

const paymentMethodCreate = gql`
  mutation($paymentMethod: String) {
    createPaymentMethod(paymentMethod: $paymentMethod) {
      ok
    }
  }
`;
const queryPaymentMethod = gql`
  query {
    viewUserPaymentMethod {
      paymentMethod {
        #billing_details
        card {
          brand
          #checks
          country
          exp_month
          exp_year
          funding
          generated_from
          last4
          #networks
          #three_d_secure_usage
          wallet
        }
        created
        customer
        id
        livemode
        object
        type
      }
    }
  }
`;
const stripeCustomer = gql`
  query {
    viewCustomer {
      customer {
        id
        address
        balance
        currency
        default_source
        delinquent
        description
        email
        discount
        created
        invoice_settings {
          custom_fields
          default_payment_method
          footer
        }
      }
    }
  }
`;

const paymentMethodRemove = gql`
  mutation($id: String) {
    removePaymentMethod(id: $id) {
      ok
    }
  }
`;
const paymentMethodSetDefault = gql`
  mutation($id: String) {
    setDefaultPaymentMethod(id: $id) {
      ok
    }
  }
`;
export default compose(
  graphql(paymentMethodCreate, {
    name: "create",
  }),
  graphql(paymentMethodRemove, {
    name: "delete",
  }),
  graphql(queryPaymentMethod, {
    name: "paymentMethod",
  }),
  graphql(paymentMethodSetDefault, {
    name: "setDefault",
  }),
  graphql(stripeCustomer, {
    name: "customer",
  })
)(Form.create()(AccountPage));
