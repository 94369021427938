import { /* camelCase, dropRight, */ tail } from 'lodash';

/* returns selected keys for Sidebar component */
const SelectedKeys = (pathName) => {
  const menuKeys = ['advisors', 'assessments', 'clients', 'practices', 'users', 'companies', 'teams', 'settings'];

  if (menuKeys.some(key => pathName.includes(key))) {
    const split = tail(pathName.split('/'));

    if (split.includes('advisors')) {
      return ['advisors'];
    }

    if (split.includes('assessments')) {
      return ['assessments'];
    }

    if (split.includes('clients')) {
      return ['clients'];
    }

    if (split.includes('practices')) {
      return ['practices'];
    }

    if (split.includes('companies')) {
      return ['companies'];
    }

    if (split.includes('users')) {
      return ['users'];
    }

    if (split.includes('teams')) {
      return ['teams'];
    }

    if (split.includes('settings')) {
      if (split.includes('general')) {
        return ['general'];
      }
      if (split.includes('account')) {
        return ['account'];
      }
      if (split.includes('subscription')) {
        return ['subscription'];
      }
    }

    /* if in site, exclude siteId */
    // if (menuKeys.includes(split[1])) {
    //   split.shift();
    // }

    // /* if editing, return selected key as top level
    //   ex. - practicesEdit returns practice */
    // if (
    //   ([split[0], 'edit'].every(r => split.includes(r))) ||
    //   ([split[0], 'view'].every(r => split.includes(r)))
    // ) {
    //   return [split[0]];
    // }

    /* returns practices */
    // return [camelCase(split)];

    /** define key top-level menu key
     * convert if menu key does not match route
     * ex. - route is submenu item of different menu key */
    // if (split.length > 1 && split.length < 4) {
    //   return tail(split);
    // }

    // return tail(dropRight(split));
  }

  /* default to dashboard */
  return ['dashboard'];
};

export default SelectedKeys;
