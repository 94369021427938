import React from 'react';
import { Button, Drawer, Form, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;

const personalityOptions = [
  'Protector',
  'Observer',
  'Liberator',
  'Energizer',
];

const decisionMakingStyleOptions = [
  'Logical',
  'Passionate',
  'Balanced',
];

const retirementOptions = [
  '401(k) at work',
  'IRA',
  'IRA and 401(k)',
  'No Retirement Account Yet',
];

const DashboardFilter = (props) => {
  const {
    applyFilters,
    applyFilterButtonDisabled,
    clearFilters,
    data: { advisors },
    filterCount,
    form: { getFieldDecorator, resetFields },
    onClose,
    onSelectChange,
    visible,
  } = props;

  const clearAndResetFilters = () => {
    clearFilters();
    resetFields();
  };

  const practices = advisors
    .reduce((acc, cur) => [
      ...acc
        .filter(obj => obj.practiceId !== cur.practiceId),
      ({ practiceId: cur.practiceId, practiceName: cur.practiceName }),
    ], [])
    .sort((a, b) => a.practiceId.localeCompare(b.practiceId));

  return (
    <Drawer
      className="dashboard-filter-sidebar"
      maskStyle={{ background: 'transparent' }}
      onClose={onClose}
      placement="right"
      title="Filters"
      visible={visible}
    >
      <Form>
        <FormItem label="Behavior">
          {getFieldDecorator('behavior')(
            <Select
              mode="multiple"
              onChange={value => onSelectChange('behavior', value.length > 0 ? value : null)}
              placeholder="All"
            >
              {(personalityOptions || []).map(p => (
                <Option key={p} value={p}>{p}</Option>
              ))}
            </Select>)}
        </FormItem>
        <FormItem label="Decision Making Style">
          {getFieldDecorator('personality')(
            <Select
              mode="multiple"
              onChange={value => onSelectChange('personality', value.length > 0 ? value : null)}
              placeholder="All"
            >
              {(decisionMakingStyleOptions || []).map(d => (
                <Option key={d} value={d}>{d}</Option>
              ))}
            </Select>)}
        </FormItem>
        <FormItem label="Retirement Vehicle">
          {getFieldDecorator('currentRetirementVehicle')(
            <Select
              mode="multiple"
              onChange={value => onSelectChange('currentRetirementVehicle', value.length > 0 ? value : null)}
              placeholder="All"
            >
              {(retirementOptions || []).map(r => (
                <Option key={r} value={r}>{r}</Option>
              ))}
            </Select>)}
        </FormItem>
        <FormItem label="Practice">
          {getFieldDecorator('practiceId')(
            <Select
              mode="multiple"
              onChange={value => onSelectChange('practiceId', value.length > 0 ? value : null)}
              placeholder="All"
            >
              {(practices || []).map(practice => (
                <Option
                  key={practice.practiceId}
                  value={practice.practiceId}
                >
                  {practice.practiceName}
                </Option>
              ))}
            </Select>)}
        </FormItem>
        <FormItem label="Advisor">
          {getFieldDecorator('advisorId')(
            <Select
              mode="multiple"
              onChange={value => onSelectChange('advisorId', value.length > 0 ? value : null)}
              placeholder="All"
            >
              {(advisors || [])
                .sort((a, b) => a.firstName.localeCompare(b.firstName))
                .map(advisor => (
                  <Option
                    key={advisor.id}
                    value={advisor.id}
                  >
                    {advisor.firstName} {advisor.lastName}
                  </Option>
                ))}
            </Select>)}
        </FormItem>
        <div className="button-wrapper">
          <Button
            disabled={filterCount < 1}
            onClick={clearAndResetFilters}
          >
            Clear
          </Button>
          <Button
            disabled={applyFilterButtonDisabled}
            onClick={applyFilters}
            type="primary"
          >
            Apply
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default Form.create()(DashboardFilter);
