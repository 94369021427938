import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Upload } from 'antd';
import _ from 'lodash';
import { maxSize, validDocTypes, validMediaTypes } from '../../../config';
import UploadWrapper from './index.style';
/**
 * processes a file upload and updates the UI
 */
class UploadFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: !_.isEmpty(this.props.fileList) ? this.props.fileList.map((attachment) => {
        return {
          uid: _.uniqueId(),
          name: attachment.fileName,
          status: 'done',
          url: attachment.url,
        };
      }) : [],
      icon: 'upload',
      loading: false,
    };

    this.onRemove = this.onRemove.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.stageUpload = this.stageUpload.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading) {
      this.setState({ loading: true });
    } else if (!nextProps.loading && this.state.loading) {
      this.setState({ loading: false });
    }
  }

  onRemove(file) {
    const { fileList } = this.state;
    const index = _.findIndex(fileList, { uid: file.uid });

    if (index !== -1) {
      fileList.splice(index, 1);
      this.setState({ fileList });
      this.props.handleFileParams(null, fileList);
    }
  }

  beforeUpload(file) {
    const fileExtension = file.name.toLowerCase().split('.').pop();
    let validUpload;
    let errorMessage = 'Invalid File Type';

    if (this.props.uploadType === 'Document') {
      validUpload = validDocTypes.includes(fileExtension);
    } else if (this.props.uploadType === 'Media') {
      validUpload = validMediaTypes.includes(fileExtension);
    } else if (this.props.uploadType === 'MultiLevel') {
      validUpload = fileExtension === 'csv';
    } else if (file.size > maxSize) {
      validUpload = false;
      errorMessage = 'File cannot exceed 500MB';
    }
    if (!validUpload) {
      this.props.handleError(errorMessage);
    }

    return validUpload;
  }

  stageUpload({ file }) {
    const { fileList } = this.state;
    fileList.push(file);

    this.setState({
      icon: 'loading',
      fileList,
    });

    this.props.handleFileParams(file, fileList);

    this.setState({
      icon: 'upload',
    });
  }

  render() {
    const { fileList, icon, loading } = this.state;
    const {
      buttonLabel,
      className,
      showUploadList,
      uploadType,
    } = this.props;

    return (
      <UploadWrapper>
        <Upload
          beforeUpload={this.beforeUpload}
          className={className || ''}
          customRequest={this.stageUpload}
          defaultFileList={fileList}
          fileList={fileList}
          onRemove={this.onRemove}
          showUploadList={showUploadList}
        >
          <Button disabled={(['Media', 'MultiLevel'].includes(uploadType) && fileList.length > 0) || loading}>
            <Icon type={icon} /> {buttonLabel}
          </Button>
        </Upload>
      </UploadWrapper>
    );
  }
}

UploadFile.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  handleError: PropTypes.func.isRequired,
  handleFileParams: PropTypes.func.isRequired,
  showUploadList: PropTypes.bool.isRequired,
};

export default UploadFile;
