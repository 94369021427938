import { Button, Result, notification } from "antd";
import React, { useEffect } from "react";
import unitifiLogo from "../../assets/img/Unitifi-Primary.svg";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
const SalesforceAuth = (props) => {
  const { location, history } = props;
  const [salesforceConnect, { data: salesforceData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($code: String!) {
      salesforceCallback(code: $code) {
        ok
        message
      }
    }
  `);

  useEffect(() => {
    if (!salesforceData) return;
    if (salesforceData.salesforceCallback.ok == false) return notification.error({ message: salesforceData.salesforceCallback.message });

    notification.success({ message: salesforceData.salesforceCallback.message });
    history.push("/settings/account");
  }, [salesforceData]);

  const submitHandler = () => {
    let code = null;
    if (location.search) {
      let splitGo = location.search.split("?code=");
      if (splitGo.length > 1) code = splitGo[1];
    }
    if (!code) return notification.error({ message: "Invalid URL" });
    salesforceConnect({ variables: { code: decodeURIComponent(code) } });
  };

  const cancelHandler = () => {
    history.push("/settings/account");
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Result
        icon={<img src={unitifiLogo} width={400} />}
        title="Authorize Salesforce CRM"
        extra={
          <>
            <Button type="primary" onClick={() => submitHandler()} loading={loadingSubmit} disabled={loadingSubmit}>
              Allow
            </Button>{" "}
            <Button onClick={() => cancelHandler()}>Cancel</Button>
          </>
        }
      />
    </div>
  );
};

export default SalesforceAuth;
