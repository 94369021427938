import { Button, Result, notification } from "antd";
import React, { useEffect, useState } from "react";
import unitifiLogo from "../../assets/img/Unitifi-Primary.svg";

import axios from "axios";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { apiUrl } from "../../config";
const Wealthbox = (props) => {
  console.log("props", props);
  const { location, history, me } = props;
  const [loadingBox, setLoadingBox] = useState(false);

  // const [wealthboxConnect, { data: wealthboxData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
  //   mutation($code: String!) {
  //     wealthboxConnect(code: $code) {
  //       ok
  //       message
  //       status
  //     }
  //   }
  // `);

  useEffect(() => {
    // console.log("wealthboxData", wealthboxData);
    // if (!wealthboxData) {
    //   if (loadingBox) setLoadingBox(false);
    //   return;
    // }
    // if (wealthboxData.wealthboxConnect.ok == false) {
    //   setLoadingBox(false);
    //   notification.error({ message: wealthboxData.wealthboxConnect.message });
    //   return;
    // }
    // console.log("wealthboxData", wealthboxData);
    // if (wealthboxData.wealthboxConnect.status == "201") {
    //   submitHandler();
    //   return;
    // }
    // notification.success({ message: wealthboxData.wealthboxConnect.message });
    // history.push("/settings/account");
  }, []);

  const submitHandler = async () => {
    let code = null;
    if (location.search) {
      let splitGo = location.search.split("?code=");
      if (splitGo.length > 1) code = splitGo[1];
    }
    if (!code) return notification.error({ message: "Invalid URL" });
    setLoadingBox(true);
    const result = await axios.post(
      `${apiUrl}/wealthbox-connect`,
      { code: code },
      {
        headers: {
          "x-token": localStorage.getItem("token"),
          "x-refresh-token": localStorage.getItem("refreshToken"),
        },
      }
    );

    setLoadingBox(false);
    if (result && result.data && result.data.ok == false) {
      notification.error({ message: result.data.message });
      return;
    }

    if (result && result.data && result.data.ok == true) {
      notification.success({ message: result.data.message });
      history.push("/settings/account");
    }
  };

  const cancelHandler = () => {
    history.push("/settings/account");
  };

  return (
    <div style={{ marginTop: "5%" }}>
      <Result
        icon={<img src={unitifiLogo} width={400} />}
        title="Authorize Wealthbox CRM"
        extra={
          <>
            <Button type="primary" onClick={() => submitHandler()} loading={loadingBox} disabled={loadingBox}>
              Allow
            </Button>{" "}
            <Button onClick={() => cancelHandler()}>Cancel</Button>
          </>
        }
      />
    </div>
  );
};

export default Wealthbox;
