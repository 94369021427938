import React, { useCallback, useContext, useState } from "react";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import SupportRoleForm from "./form";
import TitleBar from "../../components/titlebar/titlebar";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import { notification } from "antd";
import { AccountContext } from "../../context/AccountContext";

const SupportRoleCreate = (props) => {
  const { me } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(AccountContext);
  const account = _.get(userContext, "account", null);

  const onSubmit = async (values) => {
    setLoading(true);
    let result = await client.mutate({
      mutation: gql`
        mutation($firstName: String, $email: String!, $lastName: String, $accessType: String, $groupName: String, $assignTo: JSON) {
          createSupportUser(firstName: $firstName, email: $email, lastName: $lastName, accessType: $accessType, groupName: $groupName, assignTo: $assignTo) {
            ok
            message
          }
        }
      `,
      variables: values,
    });
    if (result.data && result.data.createSupportUser && result.data.createSupportUser.ok == true) {
      notification.success({
        message: result.data.createSupportUser.message,
      });
      props.history.push("/support-roles");
      return;
    }
    setLoading(false);
    notification.error({
      message: result.data.createSupportUser.message,
    });
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <TitleBar title="Add Support Role" subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)} />
      </div>
      <LayoutContentWrapper>
        <LayoutContent>
          <SupportRoleForm {...props} onSubmit={onSubmit} loading={loading} />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
};

export default SupportRoleCreate;
