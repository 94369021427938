import { Result, Button, Typography, Layout, Spin, Icon, Row, Col, Card } from "antd";
import logo from '../../assets/img/unitifi.svg';
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import _, { flowRight as compose } from 'lodash';

const {Title, Paragraph} = Typography
const { Header, Footer, Sider, Content } = Layout;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />

const SuccessPage = (props) => {
    const { paymentIntent  , history } = props 
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);
        var id = url.searchParams.get("payment_intent");
        paymentIntent({
            variables:{
                id
            }
        }).then(function(result){
          if(result.data.verifyStripePaymentIntent.ok){
            // localStorage.setItem('token', null);
            // localStorage.setItem('refreshToken', null);
            setLoading(false)
          }
          else {
            // props.history.push('/paymentmethod')
            // setLoading(false)
          }
        }).catch(err => { 
          // props.history.push('/login')
        });
        // const update = async () => {
        //     const token = localStorage.getItem('token');
        //     /* check for token */
        //     if (token && token !== 'null') {
        //         /* catch for no user practices - send to login */
        //         const { user: { id } } = decode(token);
        //         await mutate({
        //             variables: { id },
        //         }).then(function(result){
        //             if(result.data.updatePaid.ok){
        //                 localStorage.setItem('token', null);
        //                 localStorage.setItem('refreshToken', null);
        //                 setLoading(false)
        //             }
        //         })
        //     }
        // }

        // update()
    },[])
    

    
    const InProgressForm = () => {

        return (<Result
            icon={antIcon}
            title="Please wait ..."
        />)
    }

    const FinishForm = () => {
        return (<Card>
          <Result
              status="success"
              title="Successfully Purchased Subscription."
              subTitle="You have successfully completed your Company Sign Up. An email is sent to you for your log-in credentials."
              extra={[
              <Button type="primary" key="console" onClick={()=> history.push('/login')}>
                  Done
              </Button>,
              ]}
          />
        </Card>)
       
    }

    return (
    <Layout style={{height:'100vh'}}>
    <Header style={{background:'rgba(240,242,245,1)'}}>
      <div className={'logo-signup'} style={{textAlign:'center',background:'rgba(240,242,245,1)'}}>
          <img src={logo} style={{height:'47px'}}/>
      </div>
    </Header>
    {/* <Header style={{background:'white'}}>
        <div className={'logo-signup'} style={{textAlign:'center'}}>
            <img src={logo} style={{height:'47px'}}/>
        </div>
    </Header> */}
    {/* <Spin indicator={antIcon}> */}
    <Content style={{ padding: '30px 10px 30px 10px' }}>
        <Row gutter={[{lg:8,xs:0}]}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 6 }} lg={{ span: 9, offset: 8 }} xl={{ span: 6 , offset: 9}}>
        {loading ? <InProgressForm/> : <FinishForm />}
        </Col>
        </Row>
    </Content>
    <Footer style={{ textAlign: 'center'}}>Copyright ©2021 Unitifi</Footer>
    {/* </Spin> */}
    </Layout>
    )
}


const queryPaymentIntent = gql`
  mutation(
    $id: String
  ){
    verifyStripePaymentIntent(
        id: $id
    ){
      ok
      errors {
        path
      }
    }
  }
`
;

const updateUserStatus = gql`
  mutation(
    $id: Int
  ){
    updatePaid(
        id: $id
    ){
      ok
      user{
        id
      }
      errors {
        path
      }
    }
  }
`
;
export default compose(
    graphql(queryPaymentIntent,{
        name: 'paymentIntent',
    }),
)(SuccessPage);