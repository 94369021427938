import styled from 'styled-components';
import { palette } from 'styled-theme';

const SignupWrapper = styled.div`
    width: 100%;
    background: #FFF;
    .unitfi-signup-main{
      .unitfi-signup-wrapper{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 768px) {
          flex-direction: column;
        }
        .unitfi-signup-img-div{
          width: 50%;
          height: 100vh;
          overflow: hidden;
          @media only screen and (max-width: 768px) {
            width: 100%;
            height: 50vh;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .unitfi-signup-content-div{
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 80px 18px;
          .logo-img-link{
            width: 300px;
            height: auto;
            overflow: hidden;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .unitfi-btn-wrapper{
            max-width: 420px;
            width: 100%;
            padding: 15px;
            background: #006FBA;
            color: #FFF;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            text-align: center;
            border-radius: 4px;
            margin: 35px 0;
            border: 1px solid transparent;
            transition: all 0.3s ease-in-out;
            text-decoration: none;
            display: inline-block;
            &:hover{
              opacity: 0.8;
            }
            &.white-bg-btn{
              border: 1px solid #006FBA;
              background: transparent;
              color: #006FBA;
              margin: 0;
            }
          }
        }
      }
    }
    .unitfi-owner-member-main{
      padding: 100px 0;
      height: 100vh;
      .unitfi-owner-member-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo-img-link{
            width: 300px;
            height: auto;
            overflow: hidden;
            margin-bottom: 80px;
            display: inline-block;
            img{
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .unitfi-owner-member-select-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .unitfi-owner-member-select-div{
            display: flex;
            align-items: center;
            .unitfi-owner-member-box{
              border: 1px solid #006FBA;
              padding: 50px;
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;
              margin-right: 80px;
              max-width: 435px;
              max-height: 450px;
              transition: all 0.3s ease-in-out;
              &.member-box{
                margin: 0;
              }
              &:hover{
                background: #006FBA;
                h5{
                  color: #FFF;
                }
                p{
                  color: #FFF;
                }
              }
              &.active{
                background: #006FBA;
                h5{
                  color: #FFF;
                }
                p{
                  color: #FFF;
                }
              }
              h5{
                font-size: 22px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: center;
                color: #006FBA;
                margin-bottom: 40px;
              }
              p{
                font-size: 22px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0px;
                text-align: center;
                color: #006FBA;
              }
            }
          }
          .unitfi-signup-member-btn-div{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            .unitfi-btn-wrapper{
              min-width: 270px;
              padding: 15px;
              background: #969696;
              color: #FFF;
              font-size: 16px;
              font-weight: 400;
              line-height: auto;
              text-align: center;
              border-radius: 4px;
              margin: 50px 0 0;
              border: 1px solid transparent;
              transition: all 0.3s ease-in-out;
              height: auto;
              &:hover{
                opacity: 0.8;
              }
              &:hover{
                background: #006FBA;
                color: #FFF;
              }
              &.active{
                background: #006FBA;
                color: #FFF;
              }
            }
          }
          }
          
      }
    }
`;

export default SignupWrapper;
