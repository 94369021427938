import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { Button, Form, Icon, Input, Radio, Spin, notification, Modal } from "antd";
import { practiceConfig } from "../../../config";
import { Table } from "../../../elements";
import { ButtonBar, Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import { FormatPercent } from "../../../helpers/formatters";
import CustomButton from "../../../components/commons/CustomButton";
import { AccountContext } from "../../../context/AccountContext";
import _, { flowRight as compose } from "lodash";
import { FormCheck } from "react-bootstrap";
import BehaviorList from "./behavior";
import TitleBar from "../../../components/titlebar/titlebar";

const FormItem = Form.Item;
const InputSearch = Input.Search;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { confirm } = Modal;

class AdvisorsList extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      addPulse: false,
      currentPractice: this.props.currentPractice !== "advisors" ? this.props.currentPractice : null,
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: "firstName",
      page: 1,
      search: null,
      selectedColumnData: "Counts",
      totalCount: null,
      roleId: null,
      isBehavior: false,
      behaviorMsg: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.updateColumnData = this.updateColumnData.bind(this);
    this.permissions = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      const userContext = this.context;
      this.permissions = _.get(userContext.account, "permissions", []);
      let roleId = _.get(userContext.account, "roleId");
      if (roleId) {
        this.setState({ roleId });
      }
      if (this.props.location.search != prevProps.location.search) {
        const search = new URLSearchParams(this.props.location.search).get("search");
        this.setState({ search, page: 1 });
        this.fetchData(1, this.state.limit, this.state.order, this.state.orderColumn, search, roleId);
      }
    }
  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    if (type === "clear") {
      search = null;

      this.props.form.resetFields();
    }

    this.fetchData(this.state.page, this.state.limit, this.state.order, this.state.orderColumn, search, this.state.roleId);

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? "DESC" : "ASC";
    if (sorter.field != this.state.orderColumn || sorterOrder != this.state.order) pagination.current = 1;

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
      this.state.roleId
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
    });
  }

  behaviorMsgHandler = (msg) => {
    this.setState({ behaviorMsg: msg });
  };

  fetchData(page, limit, order, orderColumn, search, roleId) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
        roleId,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        if (!fetchMoreResult) {
          return previousResult;
        }

        this.setState({
          totalCount: fetchMoreResult.getAllAdvisors.pagination.totalCount,
        });

        return fetchMoreResult;
      },
    });
  }

  updateColumnData(e) {
    this.setState({
      addPulse: true,
      selectedColumnData: e.target.value,
    });
  }

  formcheckHandler(e) {
    this.setState({ isBehavior: e.target.checked, behaviorMsg: null }, () => {
      if (this.state.isBehavior == false) this.fetchData(1, this.state.limit, this.state.order, this.state.orderColumn, "", this.state.roleId);
    });
  }
  // Are you sure you want to delete this team member?”  Then in a separate paragraph below “If you proceed, all clients assigned to ____ ____  *member name* will be assigned to ____ ______ *admin name* (the corresponding Practice Admin) until being assigned to a new Advisor
  deleteHandler(id, practiceOwnerId, practiceOwnerName, memberName) {
    confirm({
      title: "Are you sure you want to delete this team member?",
      content: `If you proceed, all clients assigned to ${memberName} will be assigned to ${practiceOwnerName}  (the corresponding Practice Admin) until being assigned to a new Advisor`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",

      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const data = await this.deleteHandlerSubmit(id, practiceOwnerId);
          resolve();
        }).catch(() => console.log("error"));
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  }

  async deleteHandlerSubmit(id, practiceOwnerId) {
    this.props
      .deleteAdvisor({ variables: { id: id, practiceOwnerId } })
      .then(({ data }) => {
        this.fetchData(this.state.page, this.state.limit, this.state.order, this.state.orderColumn, "", this.state.roleId);
        if (data.deleteAdvisor.ok && !data.deleteAdvisor.errors) {
          notification.success({
            message: "Advisor Deleted Successfully",
          });
          return true;
        }
      })
      .catch((error) => {
        this.fetchData(this.state.page, this.state.limit, this.state.order, this.state.orderColumn, "", this.state.roleId);
        return true;
        console.log(error);
      });
  }

  render() {
    const { addPulse, currentPractice, limit, page, selectedColumnData, behaviorMsg } = this.state;
    const {
      data: { loading, error, getAllAdvisors = [] },
      form: { getFieldDecorator },
      me,
    } = this.props;

    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title="Advisors" subTitle="" />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <TitleBar title="Advisors" subTitle="" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - advisors didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    let advisors = _.get(this.props.data, "getAllAdvisors.advisors", []);

    let totalCount = _.get(this.props.data, "getAllAdvisors.pagination.totalCount", 0);
    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;

    let tablePagination = {
      current: page,
      defaultPageSize: practiceConfig.rowsPerPage,
      pageSize: limit,
      pageSizeOptions: ["10", "20", "50", "100"],
      showQuickJumper: true,
      showSizeChanger: true,
      total: tableCount,
    };

    const countStart = (page - 1) * limit + 1;
    const countEnd = page * limit > tableCount ? tableCount : page * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

    let columns = [
      {
        dataIndex: "firstName",
        render: (text, advisor) => {
          let url = currentPractice ? `/${currentPractice}/advisors/view/${advisor.id}` : `/advisors/view/${advisor.id}`;
          if (me && me.rolecode == "SPR") url = `/${advisor.practiceId}/clients/advisors/${advisor.id}`;

          return (
            <Link className={advisor.status === "Inactive" ? "inactive" : null} to={url}>
              {advisor.firstName} {advisor.lastName}
            </Link>
          );
        },

        sorter: true,
        title: "Advisor",
        width: 200,
      },
      {
        dataIndex: "name",
        render: (text, advisor) => (
          <p>
            {advisor.code == "PRCTO" ? "Practice Admin" : ""}
            {advisor.code == "PRCTM" ? "Practice Member" : ""}
            {advisor.code == "ADVSM" ? "Advisor Member" : ""}
            {advisor.code == "ADVSO" ? "Advisory Admin" : ""}
            {advisor.code == "CMPYM" ? "Company Member" : ""}
            {advisor.code == "CMPYO" ? "Company Admin" : ""}
          </p>
        ),

        sorter: true,
        title: "Account Type",
        width: 200,
      },
      {
        align: "center",
        dataIndex: "assessmentStats.totalClients",
        sorter: true,
        title: "# of Clients",
      },
      {
        title: "Assessment Overview",
        className: "colOverview",
        children: [
          {
            align: "center",
            dataIndex: "assessmentStats.completedAssessments",
            sorter: true,
            title: "Completed",
          },
          {
            align: "center",
            dataIndex: "assessmentStats.uncompletedAssessments",
            sorter: true,
            title: "Uncompleted",
          },
          {
            align: "center",
            dataIndex: "assessmentStats.completionRate",
            render: (completionRate) => FormatPercent(completionRate),
            sorter: true,
            title: "Completion Rate",
          },
        ],
      },
      {
        align: "center",
        title: "View",
        className: "action-column viewAction",
        render: (advisor) => {
          if (this.permissions.includes("viewadviser")) {
            let url = `/${currentPractice}/advisors/view/${advisor.id}`;
            if (me && me.rolecode == "SPR") url = `/${advisor.practiceId}/clients/advisors/${advisor.id}`;
            return (
              <Button
                type={"link"}
                icon={"eye"}
                onClick={() => {
                  this.props.history.push(url);
                }}
              />
            );
          }
        },
      },
    ];

    if (me && me.rolecode != "SPR") {
      columns = [
        ...columns,
        {
          align: "center",
          className: "action-column viewAction",
          title: "Edit",
          render: (text, advisor) => {
            if ((this.permissions.includes("editadviser") && (advisor.code == "ADVSM" || advisor.code == "PRCTM" || advisor.code == "CMPYM" || advisor.code == "CMPYO" || advisor.code == "PRCTO")) || me.id == advisor.id)
              return (
                <CustomButton
                  rights={["superAdmin", "editadviser"]}
                  onClick={() => {
                    this.props.history.push(`/${currentPractice}/advisors/edit/${advisor.id}`);
                  }}
                  icon={"edit"}
                  type={"link"}
                />
              );
          },
        },
        {
          align: "center",
          className: "action-column viewAction",
          title: "Delete",
          //fixed: "right",
          render: (text, advisor) => {
            if (this.permissions.includes("deleteadviser") && (advisor.code == "ADVSM" || advisor.code == "PRCTM" || advisor.code == "CMPYM"))
              return <Button type={"link"} icon={"delete"} onClick={() => this.deleteHandler(advisor.id, advisor.practiceOwnerId, advisor.practiceOwnerName, advisor.firstName + " " + advisor.lastName)} />;
          },
        },
      ];
    }
    const userContext = this.context;
    const { account, setAdvisorModal } = userContext;

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          <TitleBar
            title="Advisors"
            subTitle={account && me && me.rolecode == "SPR" ? (me.groupName ? me.groupName : "Individual Advisor") : account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)}
          />
          {me && me.rolecode != "SPR" && (
            <Button type="primary" className="addnew-link-btn-wrapper" icon="plus" onClick={() => setAdvisorModal(true)}>
              Invite Advisor
            </Button>
          )}
          {currentPractice
            ? null
            : // <CustomButton
              //   rights={["superAdmin", "createAdviser"]}
              //   icon="plus"
              //   // href={"/users/add"}
              //   onClick={() => this.props.history.push(`/${currentPractice}/advisors/add`)}
              //   text="Add New"
              //   type={"primary"}>
              //   Add New
              // </CustomButton>
              null}
        </div>
        <LayoutContentWrapper>
          <div className="display-bar with-search pb-2">
            <div className="switchbox-header-bar">
              <p>{behaviorMsg ? behaviorMsg : display}</p>
              <FormCheck type="switch" id="custom-switch" label="Behavior View" onChange={(e) => this.formcheckHandler(e)} />
            </div>
          </div>
          <LayoutContent className="advisorTblWrapper">
            {this.state.isBehavior ? (
              <BehaviorList
                currentPractice={currentPractice}
                history={this.props.history}
                permissions={this.permissions}
                location={this.props.location}
                currentPortalId={this.props.currentPortalId}
                me={this.props.me}
                behaviorMsgHandler={this.behaviorMsgHandler}
              />
            ) : (
              <Table
                bordered={true}
                columns={columns}
                dataSource={advisors}
                loading={
                  this.state.loading && {
                    indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
                  }
                }
                locale={{ emptyText: "No advisors found." }}
                onChange={this.handleTableChange}
                pagination={tablePagination ? { ...tablePagination } : false}
                rowKey="id"
                // size="middle"Edit Portal
                scroll={{ x: "max-content" }}
              />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allAdvisorsQuery = gql`
  query($limit: Int, $offset: Int, $order: String, $orderColumn: String, $practiceId: String, $search: String, $roleId: Int, $portalId: Int, $roleCode: String, $accountType: Int) {
    getAllAdvisors(limit: $limit, offset: $offset, order: $order, orderColumn: $orderColumn, practiceId: $practiceId, search: $search, roleId: $roleId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType) {
      advisors {
        id
        city
        email
        firstName
        lastName
        phoneNumber
        practiceName
        state
        status
        roleId
        code
        name
        practiceId
        practiceOwnerName
        practiceOwnerId
        assessmentStats {
          completionRate
          completedAssessments
          uncompletedAssessments
          energizerCount
          energizerPercent
          liberatorCount
          liberatorPercent
          observerCount
          observerPercent
          protectorCount
          protectorPercent
          totalClients
          logicalCount
          passionateCount
          balancedCount
          logicalPercent
          passionatePercent
          balancedPercent
        }
      }
      pagination {
        totalCount
      }
    }
  }
`;

const deleteAdvisorMutation = gql`
  mutation deleteAdvisor($id: Int!, $practiceOwnerId: Int) {
    deleteAdvisor(id: $id, practiceOwnerId: $practiceOwnerId) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(allAdvisorsQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        limit: practiceConfig.rowsPerPage,
        practiceId: props.currentPractice !== "advisors" ? props.currentPractice : null,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
      },
    }),
  }),
  graphql(deleteAdvisorMutation, { name: "deleteAdvisor" })
)(Form.create()(AdvisorsList));
