import styled from 'styled-components';
import { palette } from 'styled-theme';

const PasswordInputWrapper = styled.div`
  display: flex;
  padding-bottom: 32px;
  padding-left: 12px;
  text-align: left;
  .password-feedback-wrapper {
    .input-password-feedback {
      color: ${palette('text', 2)};
      &.error {
        color: ${palette('error', 0)};
      }
      &.success {
        color: ${palette('success', 0)};
      }
    }
    i {
      margin-right: 8px;
    }
  }
`;

export default PasswordInputWrapper;
