import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Icon, Spin } from 'antd';
import { assessmentUrl } from '../../config';
import { Titlebar } from '../../components';
import LayoutContent from '../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../layouts/app/layoutWrapper';

class AssessmentsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { data: { loading, error } } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Test Assessments"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Test Assessments"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - practices didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const { data: { getAssessmentTokens: { clients } } } = this.props;

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Test Assessments"
        />
        <LayoutContentWrapper>

          <LayoutContent>
            <p style={{ fontSize: '16px', paddingBottom: '20px' }}>Remaining Test Assessment Links: {clients.length}</p>
            <ul>
              {clients.map(({ assessmentToken }) => (
                <li key={assessmentToken} style={{ padding: '4px 0' }}>
                  <a
                    href={`${assessmentUrl}/${assessmentToken}`}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {`${assessmentUrl}/${assessmentToken}`}
                  </a>
                </li>
              ))}
            </ul>
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getAssessmentsQuery = gql`
  {
    getAssessmentTokens {
      clients {
        assessmentToken
      }
    }
  }
`;

export default graphql(getAssessmentsQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
})(AssessmentsList);
