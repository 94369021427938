import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, message, Icon, notification } from "antd";
import gql from "graphql-tag";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-apollo";
import { Titlebar } from "../../components";
import { FormWrapper } from "../../components/form";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import PromptModal from "../../components/promptModal";
const InputGroup = Input.Group;
const { TextArea } = Input;

const Faq = (props) => {
  const { form, user, userPracticeList, firstName, lastName, location } = props;
  const { getFieldProps, getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [isPromptModalClose, setPromptModalClose] = useState(false);
  const contactForm = useRef();

  const [contactSave, { data: contactData, loading: loadingSubmit, error: errorSubmit }] = useMutation(gql`
    mutation($email: String!, $firstName: String, $lastName: String, $userId: Int, $companyPractice: String, $question: String) {
      contactUs(userId: $userId, firstName: $firstName, lastName: $lastName, email: $email, companyPractice: $companyPractice, question: $question) {
        ok
        error
        message
      }
    }
  `);

  useEffect(() => {
    if (!location) return;
    if (location.hash == "") return;
    window.scrollTo(0, document.body.scrollHeight);
  }, [location]);

  useEffect(() => {
    if (!contactData) return;
    setLoading(false);
    if (contactData.contactUs.ok) notification.success({ message: contactData.contactUs.message });
    else notification.error({ message: contactData.contactUs.error });
  }, [contactData]);

  useEffect(() => {
    if (isPromptModalClose) setPromptModalClose(false);
  }, [isPromptModalClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        values.userId = user.id;
        contactSave({ variables: values });
      } else setPromptModalClose(true);
    });
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <Titlebar displayText="Frequently Asked Questions" />
      </div>
      <LayoutContentWrapper className="faq-layout">
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading">Enlighten. Nurture. Unite.</h3>
          <p class="faq-info">
            We believe that when financial professionals and investors are aligned, success is inevitable! Unitifi embraces the limitless information modern science and technology affords us. Our software and tools were created to provide insights
            related the behavior of each unique investor and how they react when financial risk is applied. If your questions are not covered below, please contact us.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">What is risk tolerance?</h3>
          <p class="faq-info faq-text">
            Risk tolerance is the degree to which a capital-holder is comfortable with uncertainty regarding their financial situation. It indicates the level of psychological preparedness one holds for the possibility of loss on an investment— as
            well as the ability to activate coping mechanisms when loss occurs. While the “risk” is constituted by the variance in investment returns, outlined by financial professionals, the “tolerance” is constituted in the investor’s engagement
            with volatility in financial planning. Unitifi’s Consumer Intelligence Tool calculates one’s financial risk tolerance. Every individual who participates in any financial system is going to have a personal and particular tolerance to risk
            surrounding their finances. This cooperation between cognitive faculties, however diverse, has within it indications of the personality’s root tendencies, and this is what Unitifi exposes at its most elemental: the subject’s financial
            behavior in terms of risk tolerance.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">What is financial behavior?</h3>
          <p class="faq-info faq-text">
            Financial behavior is qualified, and in our unique case quantified, through the ways in which an individual’s personality and self-psychology influence (interrupt) their financial decision-making. One of the most prominent financial
            behaviors is risk tolerance, or one’s threshold for ambiguity regarding their financial situation. This measurable variable is what Unitifi has most primarily examined, in methodology and ideology, to maximize the utility of behavioral
            finance. Unitifi determines, with impressive accuracy— over 99%— one’s psychological reaction to financial risk, preferred regulatory channels, personal investment style, complete with the most effective means of communication. Thus, what
            Unitifi’s tools provide is a comprehensive sketch of the factors that characterize any capital-holder’s financial behavior tendencies. Unitifi was invited to present our findings on relationship based financial advising and understanding
            personal risk tolerance at the World Academy of Science, Engineering and Technology (WASET) conference in Paris, France.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">How does Unitifi track financial behavior?</h3>
          <p class="faq-info faq-text">
            Unitifi has worked with a team of behavior experts— psychologists, statisticians, and scientists— to develop a 99% accurate algorithm that can translate the neural processes latent in financial risk tolerance into useable data and
            metadata. The initial analysis is a set of 20 questions, completed in 2-7 minutes, directed at the uncovering of any individual’s financial behavior tendencies, i.e., their automatic responses to investment risk, which determines their
            financial personality placement. Based on their unique Unitifi Assessment results, the individual will fall within the exclusive Investor Type categories of Protector, Observer, Liberator, or Energizer, based on what the science
            determines is the role of their demonstrable decision-making patterns, Logical or Passionate, and procedures, Conservative, Moderate, or Aggressive, in regards to their finances. Within those associations are the top-scoring behavior
            attributes, the capital characteristics and subsequent recommendations, found in user-friendly visual fields. Our contradistinctive data-mapping capabilities are as cutting edge as the technology behind our applied software and as wholly
            original as the insights to be gleaned from relationship-based advising.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">What is the “UCIT”?</h3>
          <p class="faq-info faq-text">
            The Unitifi Consumer Intelligence Tool is what computes the statistical relationship between investment personality, tendencies, and risk tolerance of the target individual. It is statistically reliable, meaning it will accurately measure
            the investment tendency of each advisee 99% to 99.9% of the time; and, it is statistically valid, meaning it predicts what it was purposed to: investment behavior and the amount of “risk” each advisee is willing to accept (download our
            White Paper here). As a part of the UCIT assessment, respondents answer each item using a sliding scale to indicate their level of agreement or disagreement with a given statement. This sliding scale then calculates their position along a
            continuum ranging from strongly disagree, (or negative), to strongly agree (or positive), with a neutral point of zero. Based on statistical analysis, the “Personality” variable and the four distinct investor tendency types (Protector,
            Observer, Liberator, and Energizer) contained within the risk tolerance tool are empirically supported. The findings here move the understanding of how personal investors behave when risk is applied to their financial situation to beyond
            theoretical discussions.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">How can our practice increase revenue by using Unitifi?</h3>
          <p class="faq-info faq-text">
            By fostering interactions of profound understanding with your client as it relates to their financial behavior, Unitifi awards financial advisors an evident advantage to how they communicate with, guide, and administer to their clients
            based on each of their individual needs and preferences. The UCIT tool and corresponding research findings allow for a more appropriate allocation of time, resources and information to each investor on a stream-lined, data-driven basis.
            The conclusion of each client’s assessment cultivates in an easy-to-use, always handy, “coaching sheet” for the advisor. And, as a result, Unitifi can reduce (1) litigation risk, (2) E &O Insurance, (3) marketing spend, (4) and
            miscommunication failures; moreover, Unitifi can increase (1) client retention, (2) assets under management, (3) a dynamic onboarding process, (4) and, a more suitable referral process. The time, energy, and resources typically spent on
            these types of expenditures can be radically repurposed within the firm into other, less robust areas. Furthermore, as a result of Unitifi’s partnership with the Lloyd’s of London national administrator, Lockton Affinity, practices can
            realize discounts of up to 30% annually on Errors and Omissions Insurance.
          </p>
        </div>
        <div className="unitifi-faq-content-wrapper">
          <h3 className="faq-heading sub-heading">How can investors benefit from the insights provided by Unitifi?</h3>
          <p class="faq-info faq-text">
            From the first point of contact, Unitifi saves the investor time and energy in the construction of a new financial relationship. The introductory courting practices between the financial planner and those who rely on those plans become
            minimized. The process is expedited through highly efficient dialogue, built from what the science says is the the best course of communicative action for that unique client. Then, armed with the specialized knowledge Unitifi supplies,
            investors become better equipped to resist the behaviors that prevent their own financial success and to treat the tendencies that disrupt otherwise favorable outcomes in real-time. By specifying the individual’s psychological response to
            financial uncertainty, and therefore the the function of financial professionals in general, Unitifi upgrades relationship-based advising to neuro-social analysis and predictive analytics. In this way, we are working to excite and enhance
            the financial industry by reimagining the way emotion fits into the economic arena, pursuing a more fully realized, personalized level of care for any investor moving forward.
          </p>
        </div>

        <FormWrapper className="public-form faq-form">
          <div ref={contactForm}>
            <h3>Your Support Request?</h3>
            <p class="faq-info faq-text">We're here to help! Please complete the form below and we'll get back to you within 1 business day.</p>
          </div>
          <Form layout="vertical" onSubmit={handleSubmit}>
            <Row gutter={16}>
              <Col className="gutter-row" span={10}>
                <Form.Item
                  hasFeedback
                  label={"First Name"}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("firstName", {
                    initialValue: firstName,
                    rules: [
                      {
                        required: true,
                        message: "First Name is required",
                      },
                    ],
                  })(<Input placeholder="First Name" type={"text"} />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={10}>
                <Form.Item hasFeedback label={"Last Name"} style={{ color: "rgba(0, 0, 0, 0.85)" }}>
                  {getFieldDecorator("lastName", {
                    initialValue: lastName,
                    rules: [
                      {
                        required: true,
                        message: "Last Name is required",
                      },
                    ],
                  })(<Input placeholder="Last Name" type={"text"} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={10}>
                <Form.Item
                  hasFeedback
                  label={"Email Address"}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("email", {
                    initialValue: user.email,
                    rules: [
                      {
                        required: true,
                        message: "Email is required!",
                      },
                    ],
                  })(<Input placeholder="Email Address" type={"email"} />)}
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={10}>
                <Form.Item
                  hasFeedback
                  label={"Company Name or Practice Name "}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("companyPractice", {
                    initialValue: userPracticeList.length > 0 ? userPracticeList[0].name : "",
                    rules: [
                      {
                        required: true,
                        message: "Company or practice name is required!",
                      },
                    ],
                  })(<Input placeholder="Company Name or Practice Name" type={"text"} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={20}>
                <Form.Item
                  hasFeedback
                  label={"Description of Issue Encountered"}
                  style={{
                    color: "rgba(0, 0, 0, 0.85)",
                    marginBottom: "10px",
                  }}>
                  {getFieldDecorator("question", {
                    rules: [
                      {
                        required: true,
                        message: "Question is required!",
                      },
                    ],
                  })(<TextArea rows={6} placeholder="" />)}
                </Form.Item>
              </Col>
            </Row>
            <div className="modal-footer-bnts-wrapper">
              <Button type="primary" size="large" htmlType="submit" loading={loading} className="unitifi-login-btn">
                Submit
              </Button>
            </div>
          </Form>
          {!props.loading && (
            <PromptModal
              isBlocked={props.form.isFieldsTouched()}
              title="Leave Page?"
              content="You have unsaved changes. Are you sure you want to leave this page?"
              {...props}
              submitAction={handleSubmit}
              loading={props.loading}
              isPromptModalClose={isPromptModalClose}
            />
          )}
        </FormWrapper>
      </LayoutContentWrapper>
    </div>
  );
};

export default Form.create()(Faq);
