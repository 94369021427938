import styled from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  .form-button {
    align-self: center;
    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
`;

export default ButtonsWrapper;
