import React, { useCallback, useContext, useEffect, useState } from "react";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper.style";
import LayoutContent from "../../layouts/app/layoutContent";
import SupportRoleForm from "./form";
import TitleBar from "../../components/titlebar/titlebar";
import { useApolloClient, useLazyQuery, useQuery } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import { notification } from "antd";
import { AccountContext } from "../../context/AccountContext";

const SupportRoleEdit = (props) => {
  const { me } = props;
  const client = useApolloClient();
  const [loading, setLoading] = useState(false);
  const userContext = useContext(AccountContext);
  const account = _.get(userContext, "account", null);

  const { loading: logoLoading, data: supportDetail, refetch: portalRefetch } = useQuery(
    gql`
      query($id: Int) {
        supportUserDetail(id: $id) {
          result
        }
      }
    `,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      variables: { id: parseInt(props.match.params.id) },
    }
  );

  const onSubmit = async (values) => {
    setLoading(true);
    if (props.match.params.id) values.id = parseInt(props.match.params.id);
    let result = await client.mutate({
      mutation: gql`
        mutation($id: Int, $firstName: String, $email: String!, $lastName: String, $accessType: String, $groupName: String, $assignTo: JSON) {
          updateSupportUser(id: $id, firstName: $firstName, email: $email, lastName: $lastName, accessType: $accessType, groupName: $groupName, assignTo: $assignTo) {
            ok
            message
          }
        }
      `,
      variables: values,
    });
    if (result.data && result.data.updateSupportUser && result.data.updateSupportUser.ok == true) {
      notification.success({
        message: result.data.updateSupportUser.message,
      });
      props.history.push("/support-roles");
      return;
    }
    setLoading(false);
    notification.error({
      message: result.data.updateSupportUser.message,
    });
  };

  return (
    <div className="unirifi-costomer-layout-wrapper">
      <div className="unitifi-subheader-bar-wrapper">
        <TitleBar
          title={supportDetail && supportDetail.supportUserDetail && supportDetail.supportUserDetail.result.length > 0 && `Edit ${supportDetail.supportUserDetail.result[0].firstName} ${supportDetail.supportUserDetail.result[0].lastName}`}
          subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname}`)}
        />
      </div>
      <LayoutContentWrapper>
        <LayoutContent>
          <SupportRoleForm {...props} onSubmit={onSubmit} loading={loading} supportDetail={supportDetail} />
        </LayoutContent>
      </LayoutContentWrapper>
    </div>
  );
};

export default SupportRoleEdit;
