import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import {Form, Icon, Spin, Input, Badge, Button} from 'antd';
import { practiceConfig } from '../../../config';
import { Table } from '../../../elements';
import { ButtonBar, Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import CustomButton from "../../../components/commons/CustomButton";
import {AccountContext} from "../../../context/AccountContext";

const FormItem = Form.Item;
const InputSearch = Input.Search;

class PortalsList extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      limit: practiceConfig.rowsPerPage,
      loading: false,
      order: null,
      orderColumn: 'name',
      page: 1,
      search: null,
      totalCount: null,
    };

    this.fetchData = this.fetchData.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleSearch(searchParams) {
    const { type } = searchParams;
    let search = searchParams;

    //console.log(search);
    if (type === 'clear') {
      search = null;

      //TODO: not working
      this.props.form.resetFields();
    }

    this.fetchData(
      this.state.page,
      this.state.limit,
      this.state.order,
      this.state.orderColumn,
      search,
    );

    this.setState({ search });
  }

  handleTableChange(pagination, filters, sorter) {

    const sorterOrder = sorter.order === 'descend' ? `DESC` : `ASC`;

    this.fetchData(
      !pagination.current ? this.state.page : pagination.current,
      !pagination.pageSize ? this.state.limit : pagination.pageSize,
      sorter ? sorterOrder : this.state.order,
      sorter ? sorter.field : this.state.orderColumn,
      this.state.search,
    );

    this.setState({
      limit: !pagination.pageSize ? this.state.limit : pagination.pageSize,
      page: !pagination.current ? this.state.page : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field,
    });
  }

  fetchData(page, limit, order, orderColumn, search) {
    this.setState({ loading: true });

    const offset = (page - 1) * limit;
    this.props.data.fetchMore({
      variables: {
        limit,
        offset,
        order,
        orderColumn,
        search,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        this.setState({ loading: false });
        // console.log('prev', previousResult)
        // console.log('new: ', fetchMoreResult)

        if (!fetchMoreResult) {
          // console.log('No fetch more result, show prev: ', previousResult)
          return previousResult;
        }
        this.setState({
          totalCount: fetchMoreResult.getPortals.pagination.totalCount,
        });
        // console.log('show fetchmore: ', previousResult)
        return fetchMoreResult;
      },
    });
  }

  gotoCompany (company){
    const userContext = this.context;
    userContext.setCurrentCompany(company);
    this.props.history.push(`/companies/${company.slug}`)
  }

  render() {
    const { limit, page } = this.state;
    const {
      data: { loading, error, getPortals = null },
      form: { getFieldDecorator },
    } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            displayText="Companies"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }


    const { portals, pagination: { totalCount = 0 } } = getPortals;

    const tableCount = this.state.totalCount > 0 ? this.state.totalCount : totalCount;
    let tablePagination = false;

    if (tableCount > practiceConfig.rowsPerPage) {
      tablePagination = {
        current: page,
        defaultPageSize: practiceConfig.rowsPerPage,
        pageSize: limit,
        pageSizeOptions: ['10', '20', '50', '100'],
        showQuickJumper: true,
        showSizeChanger: true,
        total: tableCount,
      };
    }
    const countStart = ((page - 1) * limit) + 1;
    const countEnd = page * limit > tableCount ? tableCount : page * limit;
    const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : 'No Records';

    if (error) {
      return (
        <div>
          <Titlebar
            displayText="Companies"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - clients didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const columns = [
      {
        dataIndex: 'name',
        sorter: true,
        render: (text, row) => (
            <Button type={'link'} onClick={()=>{this.gotoCompany(row);localStorage.setItem('current_company',row.slug)}}>{row.name}</Button>
          // <Link to={`/companies/${row.slug}`}>
          //   {row.name}
          // </Link>
        ),
        title: 'Company Name',
      },
      // { title:'Status',
      //   className: 'action-column',
      //   align: 'right',
      //   sorter: true,
      //   dataIndex:'status',
      //   render: text =>{
      //     return text === 'Active'? <Badge status="success" /> : <Badge status="error" />
      //
      //   }
      // },
      { title:'Practices',
        className: 'action-column',
        align: 'right',
        render: (text, row)=> {
          return(
              <Button type={'link'} onClick={()=>this.props.history.push(`/companies/${row.slug}`)}>{row.practices}</Button>
          )
        }

      },

      { title:'Add Practice',
        className: 'action-column',
        align: 'right',
        render: (text, row)=> (
            <Button icon={'plus'} type={'link'} onClick={()=>this.props.history.push(`/practices/add/${row.id}`)}/>
        )
      },
      {
        className: 'action-column',
        fixed: 'right',
        render: (text, row)=> (
          <CustomButton
                rights={['superAdmin','editPortal']}
                icon="plus"
                // href={"/users/add"}
                onClick={()=>{this.props.history.push(`/companies/${row.slug}/edit`);localStorage.setItem('current_company',row.slug);localStorage.setItem('current_company_id',row.id)}}
                icon={'edit'}
                type={'link'}
          />
        )
      }
    ];

    return (
      <div>
        <Titlebar
          displayText="Companies"
        />
        { this.props.gblUserIsGlobalAdmin?
        <div className="button-bar-wrapper">
          <CustomButton
              rights={['superAdmin','createPortal']}
              icon="plus"
              href="/companies/add"
              text="Add New"
              type={'primary'}
          >Add New</CustomButton>

          {/*<ButtonBar*/}
            {/*icon="plus"*/}
            {/*link="/portals/add"*/}
            {/*text="Add New"*/}
          {/*/>*/}
        </div>
        : null }
        <LayoutContentWrapper>
          <div className="display-bar">
            {display}
          </div>
          <div className="search-bar-wrapper">
            <div className="search-bar-inner">
              <div className="search-bar">
                <Form>
                  <FormItem className="oka-list-search">
                    {getFieldDecorator('search')(
                      <InputSearch
                        autoComplete="off"
                        className={this.state.search ? 'has-search' : null}
                        enterButton
                        onSearch={this.handleSearch}
                        placeholder="Search for a practice..."
                        suffix={this.state.search
                          ? ( <Icon key="icon" onClick={() => this.handleSearch({ type: 'clear' })} style={{ color: '#bebebe' }} type="close-circle-o" />)
                          : <span />
                        }
                      />)}
                  </FormItem>
                </Form>
              </div>
            </div>
          </div>
          <LayoutContent>
            <Table
              columns={columns}
              dataSource={portals}
              loading={this.state.loading && {
                indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
              }}
              locale={{ emptyText: 'No clients found.' }}
              onChange={this.handleTableChange}
              pagination={tablePagination ? { ...tablePagination } : false}
              rowKey={c => (c.id + "-" + c.slug)}
              scroll={{ x: columns.length * 150 }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const allPortalsQuery = gql`
  query (
    $limit: Int
    $offset: Int
    $order: String
    $orderColumn: String
    $search: String
  ){
    getPortals(
      limit: $limit
      offset: $offset
      order: $order
      orderColumn: $orderColumn
      search: $search
    ){
      portals{
        id
        name
        slug
        status
        practices
      }
      pagination{
        totalCount
      }
    }
  }
`;

export default graphql(allPortalsQuery, {
  options: props => ({
    fetchPolicy: 'network-only',
    variables: {
      limit: practiceConfig.rowsPerPage,
    },
  }),
})(Form.create()(PortalsList));
