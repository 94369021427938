import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import ButtonBarWrapper from './index.style';

const PrimaryButton = props => (
  <Button
    disabled={props.disabled}
    ghost={props.ghost}
    icon={props.icon}
    onClick={props.onClick}
    size="large"
    type="primary"
  >
    {props.text}
  </Button>
);

const SecondaryButton = props => (
  <Button
    disabled={props.secondaryDisabled}
    icon={props.secondaryIcon}
    ghost
    onClick={props.secondaryOnClick}
    size="large"
    type="primary"
  >
    {props.secondaryText}
  </Button>
);

const PrimaryButtonWithLink = props => (
  <Link to={props.link}>
    <PrimaryButton {...props} />
  </Link>
);

const ButtonBar = props => (
  <ButtonBarWrapper>
    {props.link
      ? <PrimaryButtonWithLink {...props} />
      : <PrimaryButton {...props} />}
    {props.secondaryIcon
      ? <SecondaryButton {...props} />
      : null}
  </ButtonBarWrapper>
);

ButtonBar.defaultProps = {
  disabled: false,
  ghost: false,
  link: null,
  onClick: null,
  secondaryDisabled: false,
  secondaryIcon: null,
  secondaryOnClick: null,
  secondaryText: null,
};

ButtonBar.propTypes = {
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  secondaryDisabled: PropTypes.bool,
  secondaryIcon: PropTypes.string,
  secondaryOnClick: PropTypes.func,
  secondaryText: PropTypes.string,
};

export default ButtonBar;
