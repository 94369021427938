import React from "react";
import gql from "graphql-tag";
import _ from "lodash";
import ClientPrintWrapper from "../clients/print/index.style";
import logo from "../../assets/img/unitifi.svg";
import areteLogo from "../../assets/img/arete-logo.png";
import bernsteinLogo from "../../assets/img/bernstein-logo.png";
import { PassionLogicMap, SelfReportedMap, Titlebar } from "../../components";
import moment from "moment";
import { useLazyQuery } from "react-apollo";
import ClientViewWrapper from "../clients/view/index.style";
import { Collapse } from "antd";
import { useEffect } from "react";
import TitlebarWrapper from "../../components/titlebar/index.style";
import { personalityTypeImg } from "../../helpers/formatters";

import emailIcon from "../../assets/img/icon-envelope.svg";
import phoneIcon from "../../assets/img/icon-telephone.svg";

const { Panel } = Collapse;

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "401(k)";
  }

  return null;
};

const headerLogo = (practice) => {
  if (!practice) {
    return logo;
  }

  if (practice === "Areté Partners LLC") {
    return areteLogo;
  }

  if (practice === "Bernstein Financial Advisory") {
    return bernsteinLogo;
  }

  return logo;
};

const customHeaderStyle = {
  color: "rgba(0,0,0,.65)",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "20px",
};

const customPanelStyle = {
  border: 0,
  borderRadius: 4,
  overflow: "hidden",
};

const DownloadAssessment = (props) => {
  const logoStyle = practiceName === "Bernstein Financial Advisory" ? { height: "64px" } : { width: "100px" };

  const QUERY = gql`
    query($token: String) {
      viewClientSalesforce(token: $token) {
        firstName
        insights
        lastName
        practiceName
        dateCollected
        assessmentId
        sitelogo
        email
        phonenumber
      }
    }
  `;

  const [userList, { loading, error, data, fetchMore }] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
  });

  useEffect(() => {
    if (props.match.params.token) {
      userList({ variables: { token: props.match.params.token } });
    }
  }, [props.match.params]);

  const viewClient = _.get(data, "viewClientSalesforce", []);

  const { firstName, insights, lastName, practiceName, dateCollected, sitelogo, email, phonenumber } = viewClient;
  return (
    <ClientPrintWrapper>
      <div>
        <div
          style={{
            alignItems: "center",
            borderBottom: "1px solid rgba(238,242,244,1)",
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}>
          <div
            style={{
              color: "rgba(0,0,0,.85)",
              fontSize: "20px",
            }}>
            Unitifi Consumer Insight Tool - UCIT
            <div
              style={{
                color: "rgba(0,0,0,.65)",
                fontSize: "12px",
                lineHeight: "12px",
              }}>
              {props.match.params && props.match.params.type ? <i>{GenerateReportType(props.match.params.type)}</i> : null}
            </div>
          </div>
          <div>
            <img alt="logo" src={sitelogo ? sitelogo : logo} style={logoStyle} />
          </div>
        </div>

        {/* <Titlebar currentPractice={practiceName} displayText={`${firstName} ${lastName}`} viewClient={viewClient} forPrint /> */}
        {/* <TitlebarWrapper>
          <div className="title-bar-wrapper">
            <div className="title-wrapper for-print">
              <div className="title-text">{`${firstName} ${lastName}`}</div>
              <div className="sub-title-text">
                {practiceName}
                <div style={{ textAlign: "right" }}>{moment(viewClient.dateCollected).format("M/D/YYYY")}</div>
              </div>
            </div>
          </div>
        </TitlebarWrapper> */}

        <div className="header-client-menu-wrapper">
          <div className="header-client-left-content-data">
            <div>
              <h4 className="client-title">{`${firstName} ${lastName}`}</h4>
            </div>
            <div>
              <h6 class="cmn-title-text">
                <a href={`mailto:${email}`}>
                  <img src={emailIcon} className="me-2" />
                  {email}
                </a>
              </h6>

              <span className="cmn-title-text">
                <a href={`${phonenumber ? `tel:${phonenumber}` : "#"}`}>
                  <img src={phoneIcon} className="me-2" />
                  {phonenumber ? phonenumber : "N/A"}
                </a>
              </span>
            </div>
          </div>
          <div className="">
            {practiceName}
            <div style={{ textAlign: "right" }}>{moment(dateCollected).format("M/D/YYYY")}</div>
          </div>
        </div>

        <ClientViewWrapper style={{ padding: "24px 0 0" }}>
          <div className="main-chart-wrapper">
            {/* left bar / 3 divs */}
            <div className="left-col">
              <div className="item">
                <div className="heading">Behavior Defined</div>
                <div className="details-wrapper">
                  <div className="score-item-wrapper">
                    <div className="score-item">
                      <div className="risk-behavior-score">{insights && insights.score.riskBehaviorScore}</div>
                      <div className="score-label">Science Reported</div>
                    </div>
                    <div className="slash">/</div>
                    <div className="score-item">
                      <div className="self-reported-score">{insights && insights.score.selfReportedScore}</div>
                      <div className="score-label">Self-Reported</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Financial Personality</div>
                <div className="details-wrapper" style={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                  {/* <div className="label">{insights.personality.behavior}</div> */}
                  <div className="callout">
                    {insights && insights.personality.type}
                    <i className="ms-1">{insights && <img src={personalityTypeImg[insights.personality.type]} />}</i>
                  </div>
                </div>
              </div>

              <div className="item">
                <div className="heading">Decision Making Style</div>
                <div className="details-wrapper">{insights && <PassionLogicMap data={insights} firstName={firstName} forPrint />}</div>
              </div>
            </div>

            {/* chart */}
            <div className="right-col">
              <div className="chart-legend">
                <div style={{ alignItems: "center", display: "flex", marginRight: "24px" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(0,111,186,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Science Reported</div>
                </div>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div style={{ height: "10px", width: "10px", borderRadius: "100%", background: "rgba(255,255,255,1)", marginRight: "6px", border: "solid 1px rgba(0,111,186,1)" }}></div>
                  <div>Self-Reported</div>
                </div>
              </div>

              <div className="chart-wrapper">{insights && <SelfReportedMap data={insights} forPrint />}</div>
            </div>
          </div>

          <div className="main-wrapper content-block">
            <div className="wrapper">
              <div className="heading">Communication Center</div>
              <div className="item-wrapper-flex">
                <div className="item-flex">
                  <div className="item-flex-heading">Expectation</div>
                  <div>{insights && insights.profile.communicationExpectation}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Age</div>
                  <div>{insights && new Date().getFullYear() - insights.retirement.birthYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Year</div>
                  <div>{insights && insights.retirement.retirementYear}</div>
                </div>
                <div className="item-flex">
                  <div className="item-flex-heading">Retirement Vehicle</div>
                  <div>{insights && insights.profile.currentRetirementVehicle}</div>
                </div>
              </div>
            </div>
          </div>

          {/* do not show any extras on 401k */}
          {props.match.params && props.match.params.type === "401k" && (
            <div
              style={{
                fontSize: "16px",
                fontWeight: "600",
                padding: "12px 24px 12px",
              }}>
              Notes
            </div>
          )}

          {/* do not show Behavior Intelligence Rating on snapshot or 401k  */}
          {props.match.params && props.match.params.type !== "snapshot" && !["401k", "snapshot"].includes(props.match.params.type) && (
            <div className="main-wrapper content-block">
              <div className="wrapper">
                <div className="heading">Financial Personality Defined: {insights && insights.personality.type}</div>
                <div className="item-wrapper" style={props.match.params && props.match.params.type === "ucitc" ? { paddingBottom: 0 } : null}>
                  <div className="item has-below">
                    <div className="item-heading">Behaviors of this Investor</div>
                    <div>
                      <ul>{insights && insights.personality.attributes.investorBehaviors.map((i) => <li key={i}>{i}</li>)}</ul>
                    </div>
                  </div>

                  {props.match.params && props.match.params.type !== "ucitc" && (
                    <div className="item">
                      <div className="item-heading">Tips for the Financial Professional</div>
                      <div>
                        <ul>{insights && insights.personality.attributes.advisorTips.map((i) => <li key={i}>{i}</li>)}</ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {props.match.params && props.match.params.type !== "401k" && (
            <div className="main-wrapper content-block">
              <div>
                <div className="heading">Behavior Attributes Recognized</div>

                <div className="item-wrapper">
                  <div className="attribute-text">
                    The following tiles identify behavior attributes recognized for {firstName}. <div className="circle"></div> indicates a strongly identified attribute, while <div className="circle min"></div> indicates an identified attribute.
                  </div>

                  {props.match.params && props.match.params.type === "snapshot" ? (
                    <div style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}>
                      {insights &&
                        insights.tendencies.map((t) => (
                          <div className="snapshot-behavior" key={t.name}>
                            <div
                              style={
                                t.type === "Strongly Identified"
                                  ? {
                                      background: "rgba(0,111,186,1)",
                                      borderRadius: "100%",
                                      height: "16px",
                                      marginRight: "8px",
                                      width: "16px",
                                    }
                                  : {
                                      background: "#6fb6e0",
                                      borderRadius: "100%",
                                      height: "16px",
                                      marginRight: "8px",
                                      width: "16px",
                                    }
                              }></div>
                            <div
                              style={{
                                color: "rgba(0, 0, 0, 0.65)",
                                fontSize: "16px",
                                fontWeight: "600",
                                lineHeight: "20px",
                              }}>
                              {t.name}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <Collapse activeKey={insights && insights.tendencies.map((t) => t.name)} bordered={false}>
                      {/*  defaultActiveKey={insights.tendencies.slice(0, 1).map(t => t.name)} */}
                      {insights &&
                        insights.tendencies.map((t) => (
                          <Panel
                            className="content-block"
                            header={
                              <div style={{ alignItems: "center", display: "flex", paddingBottom: "4px" }}>
                                <div
                                  style={
                                    t.type === "Strongly Identified"
                                      ? {
                                          background: "rgba(0,111,186,1)",
                                          borderRadius: "100%",
                                          height: "16px",
                                          marginRight: "8px",
                                          width: "16px",
                                        }
                                      : {
                                          background: "#6fb6e0",
                                          borderRadius: "100%",
                                          height: "16px",
                                          marginRight: "8px",
                                          width: "16px",
                                        }
                                  }></div>
                                <div style={customHeaderStyle}>{t.name}</div>
                              </div>
                            }
                            key={t.name}
                            showArrow={false}
                            style={customPanelStyle}>
                            <div style={{ marginLeft: "28px" }}>
                              <div style={{ marginBottom: "12px" }}>
                                <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Characteristics</div>
                                <ul>
                                  {t.characteristics.map((c) => (
                                    <li key={c}>{c}</li>
                                  ))}
                                </ul>
                              </div>

                              <div style={props.match.params && props.match.params.type !== "ucitc" ? { marginBottom: "24px" } : null}>
                                <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Results</div>
                                <ul>
                                  {t.results.map((c) => (
                                    <li key={c}>{c}</li>
                                  ))}
                                </ul>
                              </div>

                              {props.match.params && props.match.params.type !== "ucitc" && (
                                <div>
                                  <div style={{ fontWeight: "600", paddingBottom: "4px" }}>Recommendations</div>
                                  <ul>
                                    {t.recommendations.map((c) => (
                                      <li key={c}>{c}</li>
                                    ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </Panel>
                        ))}
                    </Collapse>
                  )}
                </div>
              </div>
            </div>
          )}

          <footer style={{ left: "0", right: "0", fontSize: "12px", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 24px" }}>
            <div>{`${moment(dateCollected).format("MM/DD/YYYY")}`}</div>
            <div>{`${firstName} ${lastName} / ${practiceName}`}</div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ marginRight: "8px" }}>Powered by</div>
              <div>
                <img alt="logo" src={logo} style={{ height: "20px" }} />
              </div>
            </div>
          </footer>
        </ClientViewWrapper>
      </div>
    </ClientPrintWrapper>
  );
};

export default DownloadAssessment;
