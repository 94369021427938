import React from 'react';
import { Redirect } from 'react-router-dom';
import decode from 'jwt-decode';
import { Button, Card } from 'antd';
import PublicContent from '../../../layouts/public';

class Where extends React.Component {
  constructor(props) {
    super(props);

    this.state = { };

    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(practiceId) {
    this.props.history.push(`/${practiceId}`)
  }

  render() {
    try{
      const token = localStorage.getItem('token');
      const { user: { practices } } = decode(token);
      const buttons = [];
      for (let i = 0; i < practices.length; i++) {
        const v = practices[i];
        buttons.push(
          <Button
            type="primary"
            style={{margin: "2px 4px 2px 0"}}
            key={i}
            onClick={() => this.handleSelect(v.practiceId)}>
            {v.name}
          </Button>
        )
      }
      return (
        <PublicContent className="stretch-content">
          <h2>Choose a Practice </h2>
          <Card>
            {buttons}
          </Card>
        </PublicContent>
      );

    }catch(e){
      return <Redirect to="/login" />
    }
  }
}

export default Where;
