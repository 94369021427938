import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const TagsInput = (props) => {
  const { selectedTags, placeholder } = props;
  const [tags, setTags] = useState(props.tags || []);

  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (event.target.value !== "") {
      let tagVal = event.target.value;
      let lastCh = tagVal.charAt(tagVal.length - 1);
      if (lastCh == ",") tagVal = event.target.value.slice(0, -1);

      // let regAx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      let regAx = /^([A-Z0-9._%+-])+@([A-Z0-9.-])+\.(?!con)([A-Z]{2,})$/i;

      if (!/^\+\d+$/.test(tagVal) && regAx.test(tagVal) == false) {
        event.target.value = "";
        return;
      }
      setTags([...tags, tagVal]);
      selectedTags([...tags, tagVal]);
      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {tags.length > 0 &&
          tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
              <span className="tag-close-icon" onClick={() => removeTags(index)}>
                x
              </span>
            </li>
          ))}
      </ul>
      <Form.Control type="text" onKeyUp={(event) => (event.key === "," || event.key == "Enter" ? addTags(event) : null)} onBlur={(e) => addTags(e)} placeholder={placeholder} />
    </div>
  );
};

export default TagsInput;
