import styled from "styled-components";
import {
  palette
} from "styled-theme";

export const LandingDataWrapper = styled.div `
  .navbar {
    //background: #fff;
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position:absolute;
    top:0;
    right:0;
    left:0;
    z-index:5;
    @media only screen and (max-width: 480px) {
      padding: 10px;
    }
    .quickLinksWrapper{
      display:none;
      @media only screen and (max-width: 991px) {
        display:block;
      }
      .ant-select{
          &.ant-select-open{
          .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/icon-chev-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
                transform:rotate(180deg);
              }
              .anticon{
                display:none;
              }
            }
        }
        .ant-select-selection{
            background-color:transparent;
            border:0;
            &:active,:focus{
              box-shadow:none;
            }
            .ant-select-selection__rendered{
              margin-left:10px;
              .ant-select-selection__placeholder{
                font-size:16px;
                font-weight:600;
                color:#fff;
              }
            }
            .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/icon-chev-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
              }
              .anticon{
                display:none;
              }
            }
          }
        }
    }
    .navbar-brand{
      img{
        @media only screen and (max-width: 480px) {
          width:120px;
          height:36px;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      li {
        padding: 0 25px;
        .ant-select{
          &.ant-select-open{
          .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/icon-chev-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
                transform:rotate(180deg);
              }
              .anticon{
                display:none;
              }
            }
        }
          .ant-select-selection{
            background-color:transparent;
            border:0;
            &:active,:focus{
              box-shadow:none;
            }
            .ant-select-selection__rendered{
              margin-left:10px;
              .ant-select-selection__placeholder{
                font-size:16px;
                font-weight:600;
                color:#fff;
              }
            }
            .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/icon-chev-down.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
              }
              .anticon{
                display:none;
              }
            }
          }
        }
        .user-menu{
          .user-detail{
            h6{
              font-size:16px;
              font-weight:500;
              color:#fff;
              text-transform:capitalize;
            }
            p{
              font-size:14px;
              color:#fff;
              text-transform:capitalize;
              margin-bottom:0;
             }
          }
          .user-menu-dropdown-link{
            cursor:pointer;
            .ant-avatar{
              display:flex;
              align-items:center;
              justify-content:center;
              width:46px;
              height:46px;
              background:#C9C9CA !important;
            }
          }
        }
     
       
        .profileAvtar{
          display:flex;
          align-items:center;
          justify-content:center;
          width:46px;
          height:46px;
          border-radius:50px;
          background:#C9C9CA;
          color:#fff;
          img{
            width:100%;
            height:100%;
            object-fit:contain;
          }
        }
        a {
          color: #fff;
          font-family: "Avenir" !important;
          font-size: 14px;
          font-weight: 600;
          line-height: 46px;
          text-transform: Capitalize;
        }
      }
    }
    .navbar-toggler{
      background:#fff;
    }
  }

  .sidemenu-trigger-wrapper {
    align-items: center;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    color: rgba(101,122,133,1);
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    transition: all .2s;
    top: 136px;
    z-index: 6;
    cursor:pointer;
    &.collapsed {
      /* background: rgba(188,198,218,1); */
      background: rgba(102,102,102,1);
      left: 0;
      top:30%;
      @media only screen and (max-width: 575px) {
        top:38%;
      }
    }
    i {
      &.trigger {
        cursor: pointer;
        font-size: 10px;
        padding: 10px;
        transition: all .2s;
        &.collapsed {
          margin-left: -4px;
        }
      }
    }
  }

  .mobile-menu-nav {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        .ant-drawer-wrapper-body {
          padding: 20px;
          .ant-drawer-body {
            .ant-menu {
              .ant-menu-item {
                .mobile-menu-nav-link {
                  a {
                    text-decoration: none;
                    span {
                      font-family: "Avenir" !important;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 46px;
                      text-transform: uppercase;
                      color: #657a85;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .banner-wrapper {
    background: url("/img/banner-landing.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width:100%;
    height: 490px;
    position:relative;
    &::before{
      position:absolute;
      content:'';
      background:rgba(0,0,0,0.6);
      width:100%;
      height:100%;
    }
    .bannerContent {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-left: 100px;
      position:absolute;
      z-index:2;
      @media only screen and (max-width: 767px) {
        padding-left: 50px;
      }
      @media only screen and (max-width: 575px) {
        padding-left: 25px;
      }
      @media only screen and (max-width: 480px) {
        padding-left: 12px;
      }
      h2 {
        //max-width: 775px;
        width: 100%;
        font-size: 36px;
        font-weight: 600;
        line-height: 60px;
        color: #fff;
        margin-bottom: 2rem;
        @media only screen and (max-width: 480px) {
          font-size: 24px;
          line-height: 40px;
        }
      }
      .signupLink{
        .ant-btn{
              &.inviteBtn{
                background:#0358CF;
                border-color:#0358CF;
                border-radius:35px;
                width:170px;
                height:60px;
                font-size:18px;
                font-weight:600;
                line-height:32px;
                color:#fff;
              }
            }
      }
    }
  }

  .companyInfoWrapper {
    background: #fff;
    padding: 80px 0;
    .heading-title{
      font-size:22px;
      font-weight:600;
      line-height:44px;
      color:#181818;
      text-transform:uppercase;
      margin-bottom:10px;
    }
    @media only screen and (max-width: 767px) {
      padding: 45px 0;
    }
    .companyInfoBox {
        background: rgba(255, 255, 255, 0.7);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        max-width: 278px;
        width: 100%;
        height: 190px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 23px;
        margin:auto;
        cursor:pointer;
        &.cmn-info-box{
          height:149px;
        }
        .clientImg{
          margin-bottom:15px;
        }
        .cmnImg{
          margin-bottom:5px;
        }
        .compImg{
          margin-bottom:20px;
        }
        @media only screen and (max-width: 1199px) {
          margin-bottom: 1rem;
        }
        h4 {
          font-size: 26px;
          font-weight: 900;
          line-height: 40px;
          color: #1a293f;
          margin-bottom: 0;
        }
        p {
          font-size: 18px;
          font-weight: 700;
          //line-height: 45px;
          color: #1a293f;
          margin-bottom: 0;
          text-align:center;
        }
      }
  }

  .unitifiToolsWrapper {
    background: #fff;
    box-shadow: 0px 26.7864px 117.19px rgba(0, 111, 186, 0.06);
    height: 475px;
    padding: 0 120px;
    position: relative;
    @media only screen and (max-width: 1199px) {
      padding: 0 45px;
    }
    .toolsContentWrapper {
      max-width: 1250px;
      width: 100%;
      margin: 0 auto;
      .unitifiToolContent {
        padding: 50px 0;
        @media only screen and (max-width: 1199px) {
          padding: 20px 0;
        }
        h2 {
          font-size: 26px;
          font-weight: 600;
          line-height: 40px;
          color: #181818;
          margin-bottom: 18px;
        }
        p {
          max-width: 384px;
          width: 100%;
          font-size: 18px;
          line-height: 40px;
          color: #181818;
          margin-bottom: 40px;
        }

        .ant-btn {
          &.uniti-scheduleBtn {
            max-width: 253px;
            width: 100%;
            border-radius: 50px;
            background: #0358cf;
            border-color: #0358cf;
            height: 60px;
            font-size: 18px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .toolImageWrapper {
        position: absolute;
        top: -75px;
        @media only screen and (max-width: 1199px) {
          top: 75px;
        }
        @media only screen and (max-width: 575px) {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .servicesDataWrapper {

      .dashboardSectionWrapper{
        .dashboardBoxWrapper{
          background: rgba(255,255,255,0.7);
          box-shadow: 0px 4px 20px rgba(0,0,0,0.08);
          border-radius: 10px;
          height:815px;
          @media only screen and (max-width: 991px) {
            height:auto;
          }
          &.feedBox{
            padding:30px;
            overflow:auto;
          }
          .dashboardHeading{
            padding:30px;
            display:flex;
            align-items:center;
            justify-content:space-between;
            h6{
              font-size:20px;
              font-weight:600;
              color:#181818;
              margin-bottom:0;
            }
            .ant-btn{
              &.dashboardBtn{
                border-radius:70px;
                max-width:178px;
                width:100%;
                height:38px;
                font-size:16px;
                font-weight:600;
                color:#ECECEC;
                &.viewBtn{
                  max-width:113px;
                  width:100%;
                }
              }
            }
          }
          .topbar-wrapper{
            width:100%;
            *{
              color: #181818;
            }
            .top-bar{
              display:flex;
              justify-content:space-around;
            }
          }
          .dashboard-main-box-wrapper {
              border-left: none;
              border-right: none;
              display: flex;
              flex-wrap: wrap;
              display: flex;
              justify-content: space-between;
              padding:30px 15px 0 15px;
              margin-bottom:24px;
              .dashboard-info-item {
                background: rgba(255,255,255,1);
                flex-direction: column;
                display: flex;
                flex: 0 0 25%;
                padding: 0 12px;
                @media only screen and (max-width: 1199px) {
                  //flex: 0 0 33.33%;
                  padding: 6px;
                }
                @media only screen and (max-width: 991px) {
                  flex: 0 0 33.33%;
                }
                &.protector{
                  > div{
                    border-left:11px solid #D230AF;
                    &.activeProtector{
                      border-top: 1px solid #D230AF;
                      border-right: 1px solid #D230AF;
                      border-bottom: 1px solid #D230AF;
                    }
                  }
                }
                &.observer{
                  > div{
                    border-left:11px solid #30ABD2;
                    &.activeObserver{
                      border-top: 1px solid #30ABD2;
                      border-right: 1px solid #30ABD2;
                      border-bottom: 1px solid #30ABD2;
                    }
                  }
                }
                &.liberator{
                  > div{
                    border-left:11px solid #9B4BEB;
                    &.activeLiberator{
                      border-top: 1px solid #9B4BEB;
                      border-right: 1px solid #9B4BEB;
                      border-bottom: 1px solid #9B4BEB;
                    }
                  }
                }
                &.energizer{
                  > div{
                    border-left:11px solid #D2A530;
                    &.activeEnergizer{
                      border-top: 1px solid #D2A530;
                      border-right: 1px solid #D2A530;
                      border-bottom: 1px solid #D2A530;
                    }
                  }
                }
                > div{
                  background:#ECECEC;
                  border-radius:8px;
                  width: 100%;
                  height:66px;
                  padding:10px;
                }
                @media only screen and (max-width: 550px) {
                  flex: 0 0 50%;
                  &:nth-child(-n+2) {
                    border-bottom: 1px solid rgba(238,242,244,1);
                  }
                  &:nth-child(2) {
                    border-right: none;
                  }
                }
                &:last-child {
                  border-right: none;
                }
                .dashboard-info-item-inner {
                  padding: 24px;
                }
                .dashboard-info-title {
                  //color: rgba(0,111,186,1);
                  color: #181818;
                  font-size: 12px;
                  font-weight: 600;
                  cursor: pointer;
                }
                .dashboard-info-detail {
                  color: rgba(0,0,0,.65);
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 40px;
                  cursor: pointer;
                }
                .dashboard-info-average {
                  color: rgba(0,0,0,.65);
                  font-weight: 600;
                  &.dimmed {
                    color: rgba(0,0,0,.2);
                  }
                }
                &.dimmed {
                  .dashboard-info-title {
                    //color: rgba(0,0,0,.2);
                    color: #181818;
                  }
                  .dashboard-info-detail {
                    color: rgba(0,0,0,.2);
                  }
                  .dashboard-info-average {
                    color: rgba(0,0,0,.2);
                  }
                }
                .ant-skeleton-content {
                  .ant-skeleton-title {
                    margin-bottom: 2px;
                    margin-top: 14px;
                  }
                  .ant-skeleton-paragraph {
                    margin-top: 0;
                    li {
                      margin-bottom: 4px;
                      &:first-child {
                        height: 22px;
                      }
                    }
                    > li + li {
                      margin-top: 0;
                    }
                  }
                }
              }
            }

      .graph-bottom-bar{
        padding:10px 15px;
        max-width:unset;
        width:calc(100% - 60px);
        margin:15px;
        display:flex;
        align-items:center;
        justify-content:space-between;
        &.gradientBg{
          background-image: linear-gradient(to right, rgba(85,155,203,1), rgba(5,68,113,1));
        }
        @media only screen and (max-width: 575px) {
          padding:5px 10px;
        }
        span{
          display:block;
          max-width:103px;
          width:100%;
          font-size:12px;
          line-height:15px;
          color:#fff;
          text-align:center;
          @media only screen and (max-width: 575px) {
            font-size:10px;
          }
        }
      }
          .feedInfoWrapper{
             border-top:1px solid #ECECEC;
            .time-detail{
                        display:flex;
                        justify-content:flex-end;
                        color:#A2A2A3;
                      }
                      .view-data{
                          a{
                            display:flex;
                            justify-content:flex-end;
                            font-size:18px;
                            color:#006FBA;
                          }
                        }
                      .client-title{
                        font-size:18px;
                        font-weight:600;
                        line-height:20px;
                        color:#181818;
                        text-transform:capitalize;
                        margin-bottom: 5px;
                        &.style-data{
                          font-weight:400;
                          margin-bottom:12px;
                        }
                        &.retirement-data{
                          font-size: 16px;
                          font-weight:400;
                        }
                      }
                      .score-data{
                        font-size:24px;
                        font-weight:600;
                        line-height:30px;
                        color: #006FBA;
                        margin-bottom: 10px !important;
                      }
          }  
        }
      }

      .serviceinfo-btn-wrapper{
        max-width:1065px;
        width:100%;
        margin:auto;
        display:flex;
        flex-wrap:wrap;
        align-items:center;
        justify-content:space-between;
        padding:100px 0 50px;
        .ant-btn{
              &.serviceBtn{
                border-radius:35px;
                max-width:140px;
                width:100%;
                height:45px;
                font-size:18px;
                font-weight:600;
                line-height:32px;
                color:#fff;
                margin-bottom:10px;
                &.retirementBtn{
                  max-width:248px;
                }
              }
          }
      }

      .logoSlideWrapper {
        padding: 100px 0;
        @media only screen and (max-width: 575px) {
            max-width:220px;
            width:100%;
            margin:auto;
        }
        .slick-slider{
          .slick-list{
            .slick-slide{
              display:flex;
              justify-content:center;
            }
          }
        }
      }

      .newsSectionWrapper {
       padding-bottom:80px;
        h4 {
          font-size: 22px;
          font-weight: 600;
          line-height: 44px;
          color: #181818;
          text-transform: uppercase;
        }
        .newsSectionBannerBox {
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          &.leftImage{
            height:715px;
            @media only screen and (max-width: 1199px) {
              height:auto;
            }
            img{
              width:100%;
              height:100%;
              object-fit:contain;
            }
          }
          &.rightImage{
            max-width:604px;
            width:100%;
            height:342px;
            @media only screen and (max-width: 1199px) {
              max-width:100%;
              height:auto;
            }
            img{
              width:100%;
              height:100%;
              object-fit:contain;
            }
          }
          @media only screen and (max-width: 1199px) {
            margin-bottom: 1.5rem;
          }
          /* img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &.leftImage {
              height: 689px;
              @media only screen and (max-width: 1199px) {
                height: 600px;
              }
              @media only screen and (max-width: 480px) {
                height: 400px;
              }
            }
          } */
          &::before {
            position: absolute;
            content: "";
            background: #121212;
            opacity: 0.6;
            width: 100%;
            height: 100%;
          }
        }
        .newsBannerContent {
          position: absolute;
          bottom: 0;
          padding: 25px 43px;
          @media only screen and (max-width: 575px) {
            padding: 25px 18px;
          }
          &.rightBox {
            padding: 40px 45px;
            @media only screen and (max-width: 575px) {
              padding: 10px 20px;
            }
          }
          h4 {
            a{
            font-size: 24px;
            font-weight: 700;
            line-height: 40px;
            color: #fff;
            max-width: 413px;
            width: 100%;
            margin-bottom: 10px;
            text-decoration:none;
            } 
            @media only screen and (max-width: 575px) {
              font-size: 16px;
              line-height: 25px;
            }
            &.rightBoxTitle {
              max-width: 545px;
              width: 100%;
            }
          }
          p {
              a{
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                color: #fff;
                max-width: 384px;
                width: 100%;
                text-decoration:none;
              }
            @media only screen and (max-width: 575px) {
              font-size: 14px;
              line-height: 20px;
            }
            &.rightBoxInfo {
              max-width: 517px;
              width: 100%;
            }
          }
          span {
              a{
                font-size: 18px;
                font-weight: 700;
                line-height: 32px;
                color: #fff;
                text-decoration:none;
              }
            @media only screen and (max-width: 575px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
      .footerCopyright{
        display:block;
        text-align:center;
        font-size:14px;
        font-weight:600;
        line-height:46px;
        text-transform:uppercase;
        color:#657a85;
      }
  }

  .testimonialSection {
    background: #fff;
    //box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    padding-bottom: 80px;
    overflow: hidden;
    .testimonialContent {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 20px;
      h4 {
        font-size: 22px;
        font-weight: 600;
        line-height: 44px;
        color: #181818;
        text-transform: uppercase;
        margin-bottom: 14px;
      }
      .slick-slider {
        .slick-list {
          padding: 0 !important;
          overflow: visible;
          .slick-track {
            margin-left:0;
            .slick-slide {
              .reviewBoxWrapper {
                background: #ffffff;
                box-shadow: 0px 26.7864px 117.19px rgba(0, 111, 186, 0.06);
                padding: 40px;
                max-width: 937px;
                width: 100%;
                height: auto;
                margin-bottom: 45px;
                @media only screen and (max-width: 767px) {
                  padding: 25px;
                  margin-bottom: 30px;
                }
                .reviewContent {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 1.5rem;
                  @media only screen and (max-width: 480px) {
                    justify-content: space-around;
                  }
                  .profileAvtar-wrapper{
                    width:65px;
                    height:65px;
                    img{
                      width:100%;
                      height:100%;
                      border-radius:50px;
                    }
                  }
                  .titleHeading{
                    font-size:24px;
                    font-weight:600;
                    line-height:19px;
                    color:#181818;
                    margin-bottom:5px;
                    @media only screen and (max-width: 575px) {
                      font-size:20px;
                      line-height:15px;
                    }
                    @media only screen and (max-width: 480px) {
                      font-size:16px;
                      line-height:15px;
                    }
                  }
                  .companyName{
                    font-size:22px;
                    line-height:22px;
                    color:#7b7b7b;
                    margin-bottom:0;
                    @media only screen and (max-width: 575px) {
                      font-size:18px;
                      line-height:18px;
                    }
                    @media only screen and (max-width: 480px) {
                      font-size:16px;
                      line-height:15px;
                    }
                  }
                  .rating-icon {
                    .ant-rate{
                      .ant-rate-star{
                        &.ant-rate-star-full{
                          svg{
                            fill:#FF9500;
                          }  
                        }
                      }
                    }
                   }
                  p {
                    font-size: 20px;
                    line-height: 22px;
                    color:#181818;
                    margin-bottom:0;
                    @media only screen and (max-width: 767px) {
                      font-size: 15px;
                      line-height: 18px;
                    }
                  }
                }
                p {
                  max-width: 850px;
                  width: 100%;
                  font-size: 20px;
                  line-height: 28px;
                  color: #181818;
                  @media only screen and (max-width: 767px) {
                    font-size: 18px;
                    line-height: 24px;
                  }
                  @media only screen and (max-width: 575px) {
                    font-size: 14px;
                    line-height: 22px;
                  }
                }
              }
              img {
                display: unset;
              }
            }
          }
        }
      }
    }
  }

  .footerLayoutWrapper {
    background: #6a7886;
    height: auto;
    padding: 70px;
    .footer-quicklinks {
      max-width: 725px;
      width: 100%;
      margin: auto;
      &.contactInfo {
        max-width: 565px;
        width: 100%;
        margin: auto;
      }
      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media only screen and (max-width: 575px) {
          flex-direction: column;
        }
        li {
          font-family: "Avenir";
          font-size: 14px;
          font-weight: 500;
          line-height: 46px;
          color: #fff;
          text-transform: uppercase;
          a{
            font-family: "Avenir";
            font-size: 14px;
            font-weight: 500;
            line-height: 46px;
            color: #fff;
            text-transform: uppercase;
            display: inline-block;
            text-decoration: none !important;
          }
        }
      }
      span {
        display: block;
        font-family: "Avenir";
        font-size: 14px;
        font-weight: 500;
        line-height: 46px;
        text-align: center;
        color: #9aa4ad;
      }
    }
    .footer-social-links {
      max-width: 230px;
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 20px;
      .socialBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: #fff;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div `
  .menuItem {
    border-color: transparent;
    height:50px;
    line-height:50px;
  }
  .ant-menu-item-selected > .menuItem {
    background: #f2f3f5 !important;
  }
  .mobile-menu-nav-link {
    line-height:20px !important;
    height:50px !important;
    a {
      text-decoration:none;
      span {
        font-family: "Avenir" !important;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: #657a85;
        text-decoration:none;
      }
    }
    .userTitle{
          font-size:16px;
          font-weight:500;
          color:#657a85;
        }
        p{
          font-size:14px;
          color:#657a85;
        }
        .profileAvtar{
          display:flex;
          align-items:center;
          justify-content:center;
          width:46px;
          height:46px;
          border-radius:50px;
          background:#C9C9CA;
          color:#fff;
          @media only screen and (max-width: 992px) {
            width:35px;
            height:35px;
          }
          img{
            width:100%;
            height:100%;
            object-fit:contain;
          }
        }
        a {
          color: #fff;
          font-family: "Avenir" !important;
          font-size: 14px;
          font-weight: 500;
          line-height: 46px;
          text-transform: uppercase;
        }
  }
  .ant-btn {
            &.uniti-signup-btn {
              min-width: 150px;
              height: 36px;
              border-radius: 50px;
              text-transform: uppercase;
              border: 1px solid #657a85;
              font-size: 14px;
              font-weight: 500;
              background: transparent !important;
              color: #657a85 !important;
            }
          }
        
`;