import styled from 'styled-components';
import { palette } from 'styled-theme';

const UserViewWrapper = styled.div`
  .row-wrapper {
    align-items: center;
    border-bottom: 1px solid rgba(238,242,244,1);
    display: flex;
    padding: 24px 0;
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    &:first-child {
      padding-top: 0;
    }
    div {
      &:first-child {
        width: calc(100% / 3 * 1);
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
      &:last-child {
        width: calc(100% / 3 * 2);
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .row-label {
      color: ${palette('text', 0)};
    }
  }
`;

export default UserViewWrapper;
