import { Button, List } from "antd";
import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import { format } from "date-fns";
import { practiceConfig } from "../../config";
import { FormWrapper } from "../../components/form";
import UserViewWrapper from "../users/view/index.style";

const NotificationList = (props) => {
  const { notificationList, requestJoinQuery } = props;
  const [notifications, setNotifications] = useState([]);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    if (!notificationList) return;
    if (notificationList && notificationList.notificationList) {
      let data = notificationList.notificationList.data;
      setMeta({ total: data.total });
      setNotifications(data);
    }
  }, [notificationList]);

  const notificationListHandler = async (limit, offset) => {
    let result = await notificationList.fetchMore({
      variables: { limit, offset },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
  };

  const accpetRejectHandler = async (type, data) => {
    let result = await requestJoinQuery({ variables: { id: data.id, status: type } });

    if (result && result.data && result.data.requestJoinAction && result.data.requestJoinAction.ok) {
      notificationListHandler(1000, 0);
    }
  };

  return (
    <FormWrapper className="unitifi-my-account-layout-wrapper">
      <UserViewWrapper>
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta title={item.userdisplayname} description={`${item.message} - ${format(item.createdAt, "M/D/YYYY")}`} />
              {item.status == "pending" && (
                <span>
                  <Button type="primary" shape="circle" icon={"✓"} onClick={() => accpetRejectHandler("accept", item)} />
                  <Button type="danger" shape="circle" icon={"✓"} onClick={() => accpetRejectHandler("cancel", item)} />
                </span>
              )}
            </List.Item>
          )}
        />
      </UserViewWrapper>
    </FormWrapper>
  );
};

const notificationQuery = gql`
  query($limit: Int!, $offset: Int!) {
    notificationList(limit: $limit, offset: $offset) {
      data {
        id
        userId
        acceptByUserId
        portalId
        practiceId
        advisorId
        notificationType
        message
        status
        isRead
        isReadAll
        userdisplayname
        createdAt
      }
      total
      notificationTotal
      error
    }
  }
`;

const requestJoinQuery = gql`
  mutation($id: Int, $status: String) {
    requestJoinAction(id: $id, status: $status) {
      ok
      message
      error
    }
  }
`;

export default compose(
  graphql(notificationQuery, {
    name: "notificationList",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        limit: props.limit || 1000,
        offset: props.offset || 0,
      },
    }),
  }),
  graphql(requestJoinQuery, { name: "requestJoinQuery" })
)(NotificationList);
