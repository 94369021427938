import { Tabs, Radio, Table } from "antd";
import React, { useCallback, useState, useEffect, useRef } from "react";
import TimeAgo from "react-timeago";
import { Modal } from "react-bootstrap";
import Search from "../../../assets/img/search-icon.svg";
import clearSearch from "../../../assets/img/search-close.svg";
import filterIcon from "../../../assets/img/icon-filter.svg";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import { useLazyQuery } from "react-apollo";
import _ from "lodash";
import gql from "graphql-tag";
import { returnVehicle } from "../../../utils/returnVehicle";

const { TabPane } = Tabs;

const AssessmentModal = (props) => {
  const { setAssessmentOrderHandler, currentPractice, me } = props;
  const [isAssessmentFilterModal, setAssessmentFilterModal] = useState(false);
  const [assessmentViewType, setAssessmentViewType] = useState("completed");
  const [filterType, setFilterType] = useState(null);
  const [isOpenSearch, setOpenSearch] = useState(false);
  const [completeSearch, setCompleteSearch] = useState("");
  const [sentSearch, setSentSearch] = useState("");
  const [inprogressSearch, setInprogressSearch] = useState("");
  const searchRef = useRef();

  useEffect(() => {
    recenltyCompleteHandler();
  }, []);

  const [recenltyCompleteHandler, { loading: recentalyLoading, data: recentlyCompleteData }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getRecentlyCompletedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid,
          // order: assessmentOrder,
          // search: assessmentSearch,
          accountType: me.accountType,
          roleCode: me.rolecode,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const [sentViewHandler, { loading: sentViewLoading, data: sentViewCompleteData }] = useLazyQuery(
    gql`
      query($filters: JSON) {
        getSentViewedAssessment(filters: $filters) {
          clients
          pagination {
            totalCount
          }
        }
      }
    `,
    {
      variables: {
        filters: {
          practiceId: me.practiceid ? me.practiceid : null,
          advisorId: "",
          // type: assessmentViewType,
          // order: assessmentOrder,
          // search: assessmentSearch,
          portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          accountType: me.accountType,
          roleCode: me.rolecode,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (data) => {},
    }
  );

  const filterHandler = (data) => {
    setFilterType(data.target.value);
  };
  const applyFilterHandler = () => {
    if (!filterType) return;

    if (assessmentViewType == "completed") {
      recenltyCompleteHandler({
        variables: {
          filters: {
            practiceId: me.practiceid,
            order: filterType,
            search: completeSearch,
            accountType: me.accountType,
            roleCode: me.rolecode,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          },
        },
      });
    } else {
      sentViewHandler({
        variables: {
          filters: {
            practiceId: me.practiceid ? me.practiceid : null,
            advisorId: "",
            type: assessmentViewType,
            order: filterType,
            search: completeSearch,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
            accountType: me.accountType,
            roleCode: me.rolecode,
          },
        },
      });
    }

    setAssessmentFilterModal(false);
  };

  const clearFilterHandler = () => {
    setFilterType(null);
    if (assessmentViewType == "completed") {
      recenltyCompleteHandler({
        variables: {
          filters: {
            practiceId: me.practiceid,
            order: "",
            search: completeSearch,
            accountType: me.accountType,
            roleCode: me.rolecode,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          },
        },
      });
    } else {
      sentViewHandler({
        variables: {
          filters: {
            practiceId: me.practiceid ? me.practiceid : null,
            advisorId: "",
            type: assessmentViewType,
            order: "",
            search: completeSearch,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
            accountType: me.accountType,
            roleCode: me.rolecode,
          },
        },
      });
    }
    setAssessmentFilterModal(false);
  };

  const searchDebouncedHandler = useCallback(
    debounce((groupSearchText) => searchModalHandler(groupSearchText), 700),
    [assessmentViewType]
  );

  const searchHandler = (event) => {
    searchDebouncedHandler(event.target.value);
  };

  const searchModalHandler = (search) => {
    setCompleteSearch(search);
    if (assessmentViewType == "completed") {
      recenltyCompleteHandler({
        variables: {
          filters: {
            practiceId: me.practiceid,
            order: filterType,
            search: search,
            accountType: me.accountType,
            roleCode: me.rolecode,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
          },
        },
      });
    } else {
      sentViewHandler({
        variables: {
          filters: {
            practiceId: me.practiceid ? me.practiceid : null,
            advisorId: "",
            type: assessmentViewType,
            order: filterType,
            search: search,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
            accountType: me.accountType,
            roleCode: me.rolecode,
          },
        },
      });
    }
  };

  const tabChangeHandler = (e) => {
    if (searchRef.current) searchRef.current.value = "";
    setOpenSearch(false);
    setAssessmentViewType(e);
    setFilterType(null);
    if (e == "completed") recenltyCompleteHandler();
    else
      sentViewHandler({
        variables: {
          filters: {
            practiceId: me.practiceid ? me.practiceid : null,
            advisorId: "",
            type: e,
            // order: assessmentOrder,
            search: assessmentViewType == "sent" ? sentSearch : inprogressSearch,
            portalId: me.portalid.length > 0 ? me.portalid[0] : 0,
            accountType: me.accountType,
            roleCode: me.rolecode,
          },
        },
      });
  };

  const clearHandler = () => {
    searchHandler({ target: { value: "" } });
    searchRef.current.value = "";
    searchRef.current = undefined;
  };

  let recentlyCompletedAssessment = _.get(recentlyCompleteData, "getRecentlyCompletedAssessment.clients", []);
  let sentAssessment = _.get(sentViewCompleteData, "getSentViewedAssessment.clients", []);

  const recentlyComplateColumns = [
    {
      dataIndex: "name",
      title: "NAME",
      sorter: false,
    },
    {
      dataIndex: "score",
      title: "SCORE",
      sorter: false,
    },
    {
      dataIndex: "decision_making_style",
      title: "DECISION MAKING STYLE",
      sorter: false,
      className: "action-column-width",
    },
    {
      dataIndex: "retirement_vehicle",
      title: "RETIREMENT VEHICLE",
      render: (vehicle) => returnVehicle[vehicle],
      sorter: false,
      className: "action-column-width",
    },
    {
      dataIndex: "datecompleted",
      title: "COMPLETED",
      sorter: false,
      render: (data) => <TimeAgo date={data} />,
    },
    {
      className: "view-column",
      render: (data) => (
        <Link to={`/${currentPractice}/clients/view/${data.id}`}>
          <i>
            <svg id="img" className="show-pw-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 15">
              <path fill="currentColor" fill-rule="evenodd" d="M0 7.5C0 5.25 3.75 0 10.5 0S21 5.25 21 7.5 17.25 15 10.5 15 0 9.75 0 7.5Zm10.5 4.75a4.75 4.75 0 1 0 0-9.5 4.75 4.75 0 0 0 0 9.5Zm2.7-4.75a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0Z" />
            </svg>
          </i>
        </Link>
      ),
    },
  ];

  const recentlySentColumns = [
    {
      dataIndex: "name",
      title: "NAME",
      sorter: false,
    },
    // {
    //   dataIndex: "location",
    //   title: "LOCATION",
    //   sorter: false,
    //   render: (data) => (!data.includes("null") ? data : ""),
    // },
    {
      dataIndex: "email",
      title: "EMAIL",
      sorter: false,
    },
    // {
    //   dataIndex: "phone",
    //   title: "PHONE",
    //   sorter: false,
    //   render: (data) => (!data.includes("null") ? data : ""),
    // },
    {
      dataIndex: assessmentViewType == "sent" ? "dateSent" : "viewbyClient",
      title: assessmentViewType == "sent" ? "SENT" : "VIEWED",
      sorter: false,
      render: (data) => <TimeAgo date={data} />,
    },
    // {
    //   render: (data) => <p>Cancel Assessment</p>,
    // },
  ];

  return (
    <>
      <div className="client-modal-data">
        {/* <button onClick={() => setAssessmentFilterModal(true)}>Filter</button>
        <input type="text" onChange={(e) => searchHandler(e)} /> */}
        <Tabs defaultActiveKey="completed" onChange={tabChangeHandler}>
          <TabPane tab="RECENTLY COMPLETED" key="completed">
            <div className="unitify-vehicle-table-data">
              <div className="d-flex justify-content-end">
                <a onClick={() => (searchRef.current ? clearHandler() : setOpenSearch(!isOpenSearch))}>
                  <img src={searchRef.current ? clearSearch : Search} className={`${searchRef.current ? "clearIcon" : "searchIcon"}`} />
                </a>
                {isOpenSearch && <input type="text" placeholder="Search Client" ref={searchRef} onChange={(e) => searchHandler(e)} />}
                <a onClick={() => setAssessmentFilterModal(true)}>
                  <img src={filterIcon} />
                </a>
              </div>
              <Table columns={recentlyComplateColumns} loading={recentalyLoading} dataSource={recentlyCompletedAssessment} rowKey={(record) => record.id} pagination={false} scroll={{ y: 920 }} />
            </div>
            {/* {recentlyCompletedAssessment.length > 0 &&
              recentlyCompletedAssessment.map((recently) => (
                <div key={recently.id} className="tab-data-content">
                  <div className="d-flex justify-content-end">
                    <span className="me-3">
                      <a onChange={(e) => searchHandler(e)}>
                        <img src={Search} />
                      </a>
                    </span>
                    <span>
                      <a onClick={() => setAssessmentFilterModal(true)}>
                        <img src={filterIcon} />
                      </a>
                    </span>
                  </div>
                  <p className="time-detail">
                    <TimeAgo date={recently.dateCompleted} />
                  </p>
                  <p className="client-title">{recently.name}</p>
                  <p className="score-data">{recently.score}</p>
                  <p className="client-title style-data">{recently.decision_making_style}</p>
                  <p className="client-title retirement-data">{recently.retirement_vehicle}</p>
                  <p class="view-data">
                    <a href="javascript:void();">View</a>
                  </p>
                </div>
              ))} */}
          </TabPane>
          <TabPane tab="RECENTLY SENT" key="sent">
            <div className="unitify-vehicle-table-data">
              <div className="d-flex justify-content-end">
                <a onClick={() => (searchRef.current ? clearHandler() : setOpenSearch(!isOpenSearch))}>
                  <img src={searchRef.current ? clearSearch : Search} className={`${searchRef.current ? "clearIcon" : "searchIcon"}`} />
                </a>
                {isOpenSearch && <input type="text" placeholder="Search Client" ref={searchRef} onChange={(e) => searchHandler(e)} />}
                <a onClick={() => setAssessmentFilterModal(true)}>
                  <img src={filterIcon} />
                </a>
              </div>
              <Table columns={recentlySentColumns} loading={sentViewLoading} dataSource={sentAssessment} rowKey={(record) => record.id} pagination={false} scroll={{ y: 920 }} />
            </div>
            {/* {sentAssessment.length > 0 &&
              sentAssessment.map((sent) => (
                <div key={sent.id} className="tab-data-content">
                  <p class="time-detail">
                    <TimeAgo date={sent.sentDate} />
                  </p>
                  <p className="client-title">{sent.name}</p>
                  <p className="score-data">{sent.location}</p>
                  <p className="client-title style-data">{sent.email}</p>
                  <p className="client-title retirement-data">{sent.phone}</p>
                  <p class="view-data">
                    <a href="javascript:void();">View</a>
                  </p>
                </div>
              ))} */}
          </TabPane>
          <TabPane tab="IN PROGRESS" key="viewed">
            <div className="unitify-vehicle-table-data">
              <div className="d-flex justify-content-end">
                <a onClick={() => (searchRef.current ? clearHandler() : setOpenSearch(!isOpenSearch))}>
                  <img src={searchRef.current ? clearSearch : Search} className={`${searchRef.current ? "clearIcon" : "searchIcon"}`} />
                </a>
                {isOpenSearch && <input type="text" placeholder="Search Client" ref={searchRef} onChange={(e) => searchHandler(e)} />}
                <a onClick={() => setAssessmentFilterModal(true)}>
                  <img src={filterIcon} />
                </a>
              </div>
              <Table columns={recentlySentColumns} loading={sentViewLoading} dataSource={sentAssessment} rowKey={(record) => record.id} pagination={false} scroll={{ y: 920 }} />
            </div>
            {/* {sentAssessment.length > 0 &&
              sentAssessment.map((sent) => (
                <div key={sent.id} className="tab-data-content">
                  <p class="time-detail">
                    <TimeAgo date={sent.viewbyClient} />
                  </p>
                  <p className="client-title">{sent.name}</p>
                  <p className="score-data">{sent.location}</p>
                  <p className="client-title style-data">{sent.email}</p>
                  <p className="client-title retirement-data">{sent.phone}</p>
                  <p class="view-data">
                    <a href="javascript:void();">View</a>
                  </p>
                </div>
              ))} */}
          </TabPane>
        </Tabs>
      </div>
      <Modal show={isAssessmentFilterModal} onHide={() => setAssessmentFilterModal(false)} centered className="unitifi-modal-wrapper filter-modal" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Filter By</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Radio.Group name="radiogroup" onChange={filterHandler} value={filterType}>
            <Radio value="DESC" className="filterBy">
              Newest to Oldest
            </Radio>
            <Radio value="ASC" className="filterBy">
              Oldest to Newest
            </Radio>
          </Radio.Group>
          <div className="modal-btn-wrapper">
            <button className="clearBtn" onClick={() => clearFilterHandler()}>
              Clear Filter
            </button>
            <button className="filterBtn" type="primary" onClick={() => applyFilterHandler()}>
              Apply Filter
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssessmentModal;
