import { Button, List, Tabs, Icon, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import moment from "moment";

const { TabPane } = Tabs;

const NotificationModal = (props) => {
  const { requestJoinQuery } = props;
  const { loading, error, notificationList } = props.notificationList;
  const [notifications, setNotifications] = useState([]);
  const [meta, setMeta] = useState(null);
  const [notificationType, setNotificationType] = useState("request");

  useEffect(() => {
    if (!notificationList) return;
    if (notificationList) {
      let data = notificationList.data;
      if (data) {
        setMeta({ total: data.total });
        setNotifications(data);
      }
    }
  }, [notificationList, notificationType]);

  const notificationListHandler = async (limit, offset, type) => {
    let result = await props.notificationList.fetchMore({
      variables: { limit, offset, type, isAll: 1 },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return fetchMoreResult;
      },
    });
  };

  const accpetRejectHandler = async (type, data) => {
    let result = await requestJoinQuery({ variables: { id: data.id, status: type } });

    if (result && result.data && result.data.requestJoinAction && result.data.requestJoinAction.ok) {
      notificationListHandler(1000, 0, notificationType);
    }
  };

  const tabChangeHandler = (e) => {
    setNotifications([]);
    setTimeout(() => {
      setNotificationType(e);
    }, 2000);

    notificationListHandler(10000, 0, e);
  };

  return (
    <div className="modal-tab-wrapper">
      <Tabs defaultActiveKey="request" onChange={tabChangeHandler} tabBarStyle={{ display: (props.data.me && props.data.me.rolecode === "SPR") ? 'none' : 'block' }}>
        <TabPane tab="REQUESTS" key="request">
          {loading ? (
            <center style={{ marginTop: "40%" }}>
              <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />
            </center>
          ) : ( <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item, index) => {
              return (
                <List.Item key={index}>
                  <List.Item.Meta title={item.userdisplayname} description={`${item.message} - ${moment(item.createdAt).format("MM/DD/YYYY")}`} />
                  {item.status == "pending" && item.notificationType != "assessment" && item.notificationType != "bounceAssessment" && item.notificationType != "clientAssign" && (
                    <span className="d-flex">
                      <Button className="btnCancel me-3" shape="circle" onClick={() => accpetRejectHandler("cancel", item)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </Button>
                      <Button className="btnSuccess" shape="circle" onClick={() => accpetRejectHandler("accept", item)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M8.6 20 .8 12.2l1.4-1.4 6.4 6.5L21.8 4l1.4 1.4z" />
                        </svg>
                      </Button>
                    </span>
                  )}
                </List.Item>
              );
            }}
          />)}
         
        </TabPane>
        <TabPane tab="ASSESSMENTS" key="assessment">
        {loading ? (
            <center style={{ marginTop: "40%" }}>
              <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />
            </center>
          ) : (
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item, index) => {
              return (
                <List.Item key={index}>
                  <List.Item.Meta title={item.userdisplayname} description={`${item.message} - ${moment(item.createdAt).format("MM/DD/YYYY")}`} />
                </List.Item>
              );
            }}
          />)}
          
        </TabPane>
        <TabPane tab="ALL NOTIFICATIONS" key="all">
        {loading ? (
            <center style={{ marginTop: "40%" }}>
              <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />
            </center>
          ) : (
            <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item, index) => {
              return (
                <List.Item key={index}>
                  <List.Item.Meta title={item.userdisplayname} description={`${item.message} - ${moment(item.createdAt).format("MM/DD/YYYY")}`} />
                  {item.status == "pending" && item.notificationType != "assessment" && item.notificationType != "bounceAssessment" && item.notificationType != "clientAssign" && (
                    <span className="d-flex">
                      <Button className="btnCancel me-3" shape="circle" shape="circle" onClick={() => accpetRejectHandler("cancel", item)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </Button>
                      <Button className="btnSuccess" shape="circle" onClick={() => accpetRejectHandler("accept", item)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fill="currentColor" d="M8.6 20 .8 12.2l1.4-1.4 6.4 6.5L21.8 4l1.4 1.4z" />
                        </svg>
                      </Button>
                    </span>
                  )}
                </List.Item>
              );
            }}
          />
          )}
          
        </TabPane>
      </Tabs>
    </div>
  );
};

const notificationQuery = gql`
  query($limit: Int!, $offset: Int!, $type: String, $isAll: Int, $roleCode: String, $accountType: Int, $portalId: Int, $practiceId: Int) {
    notificationList(limit: $limit, offset: $offset, type: $type, isAll: $isAll, portalId: $portalId, practiceId: $practiceId, roleCode: $roleCode, accountType: $accountType) {
      data {
        id
        userId
        acceptByUserId
        portalId
        practiceId
        advisorId
        notificationType
        message
        status
        isRead
        isReadAll
        userdisplayname
        createdAt
      }
      total
      notificationTotal
      error
    }
  }
`;

const requestJoinQuery = gql`
  mutation($id: Int, $status: String) {
    requestJoinAction(id: $id, status: $status) {
      ok
      message
      error
    }
  }
`;

export default compose(
  graphql(notificationQuery, {
    name: "notificationList",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        limit: props.limit || 1000,
        offset: props.offset || 0,
        type: (props.data.me && props.data.me.rolecode == "SPR") ? "assessment" : "request",
        isAll: 1,
        roleCode: props.data.me ? props.data.me.rolecode : "",
        accountType: props.data.me ? props.data.me.accountType : 0,
        portalId: props.currentPortalId,
        practiceId: props.data.me ? props.data.me.practiceid : 0,
      },
    }),
  }),
  graphql(requestJoinQuery, { name: "requestJoinQuery" })
)(NotificationModal);
