import CurrentPractice from './currentPractice';
import DefaultTitle from './defaultTitle';
import OpenKeys from './openKeys';
import SelectedKeys from './selectedKeys';

const RouteConverter = pathName => ({
  currentPractice: CurrentPractice(pathName),
  defaultTitle: DefaultTitle(pathName),
  openKeys: OpenKeys(pathName),
  selectedKeys: SelectedKeys(pathName),
});

export default RouteConverter;
