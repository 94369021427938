import styled from 'styled-components';
import { palette } from 'styled-theme';

const SidebarWrapper = styled.div`
  /* padding-top: 75px; */
  position: fixed;
  /* @media only screen and (max-width: 767px) { */
  @media only screen and (max-width: 1620px) {
    padding-top: 0;
  }

        /* sidebar menu divider start */
  .oka-menu-sidebar__divider {
    box-shadow: inset 0 -8px 0 0 rgba(238,242,244,1);

    &.ant-menu-item {
      height: 52px!important;
    }

    /* &.ant-menu-submenu {
      padding-bottom: 8px;
    }

    &.ant-menu-submenu-open {
      padding-bottom: 0;
    } */
  }

  .return-item {
    padding-left: 16px!important;
    text-align: center;
    a {
      span {
        font-size: 12px;
      }
    }
  }

  /* .ant-menu-inline {
    .ant-menu-item {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .ant-menu-inline-collapsed {
    .oka-menu-sidebar__divider {
      &.ant-menu-submenu-open {
        padding-bottom: 8px;
      }
    }
  } */
  /* sidebar menu divider end */

  .ant-layout-sider {
    /* box-shadow: 2px 0 6px rgba(0,21,41,.35); */
    background: /*rgba(249,250,252,1)*/ rgba(240,242,245,1);
    color: rgba(101,122,133,1);
    min-height: 100vh;

  .ant-menu {
    /* background: rgba(249,250,252,1);
    background: rgba(240,242,245,1); */
    background: rgba(255,255,255,1);
    color: rgba(0,111,186,1);

    font-weight: 500;
    /* letter-spacing: 0.4px; */

    &.ant-menu-inline {
        border-right: none;
      }

    .ant-menu-item {
      /* font-size: 13px; */
      height: 32px;
      line-height: 32px;

      .anticon svg {
        stroke: rgba(101,122,133,1);
        /* stroke-width: 30px; */
        transition: all .1s;
      }

        &> a {
          color: rgba(101,122,133,1);
          transition: all .1s;
          span {
            transition: all .1s;
          }
          i {
            font-size: 16px;
            transition: all .1s;
          }

          &:hover {

            color: rgba(0,111,186,1);

            .anticon svg {
              stroke: rgba(0,111,186,1);
              transition: all .1s;
            }

            span {

              color: rgba(0,111,186,1);
            }
            i {

              color: rgba(0,111,186,1);
            }
          }

        }

      .menu-item-bullet {
      display: inline-block;
      height: 10px;
      width: 12px;
    }
    .menu-item-bullet-inner {
        background: transparent;
        border-radius: 100%;
        height: 4px;
        margin:2px;
        width: 4px;
        transition: all .1s;
      }

      &.ant-menu-item-selected {
        background: none;
        &:after {
          display: none;
        }
        .menu-item-bullet-inner {
          background: rgba(0,111,186,1);
        }

        &> a {
          i {
            transition: all .1s;
            color: rgba(0,111,186,1);
          }
          .anticon svg {
            transition: all .1s;
              stroke: rgba(0,111,186,1);
            }
          span {
            transition: all .1s;
            color: rgba(0,111,186,1);
          }
        }
      }
    }

    .menu-label-wrapper {
      color: rgba(101,122,133,1);
      cursor: default;
      font-size: 10px;
      line-height: 14px;
      padding: 14px 0 10px 12px;
      text-transform: uppercase;
    }

    /* .ant-menu-item {
        margin-bottom: 8px;
        margin-top: 8px;

        &> a {
          color: rgba(101,122,133,1);
          &:hover {
            color: rgba(0,0,0,.85);
          }
        }
      }

      .ant-menu-submenu-title {
        color: rgba(101,122,133,1);
        &:hover {
          color: rgba(0,0,0,.85);
        }

        .ant-menu-submenu-arrow {
          opacity: 1;
          &:after,
          &:before {
            background: rgba(101,122,133,1);
          }
          &:hover {
              background: rgba(0,0,0,.85);
            }
        }
      }

      .ant-menu-submenu-selected {
        color: rgba(101,122,133,1);

        .ant-menu-submenu-arrow {
          &:after,
          &:before {
            background: rgba(101,122,133,1);
          }
        }
      }



    }
    .ant-menu-item-selected {
      background: none;
      &> a {
        color: rgba(0,0,0,.85);
      }
    } */
  }


  }
  .logo {
    align-items: center;
    background: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(238,242,244,1);
    display: flex;
    height: 64px;
    /* justify-content: center; */
    padding-left: 24px;
    padding: 18px 15px;
    height: auto;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    img {
      height: 48px;
      object-fit: contain;
    }
  }
  .ant-layout-sider-children{
    height: 99vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .ant-menu{
      padding: 18px !important;
      position: relative;
      &:before{
        border-bottom: 0.68px dashed #657A85;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        content: '';
        width: calc(100% - 36px);
        margin: auto;
      }
      form{
        display: flex;
        align-items: center;
        border-bottom: 0.68px dashed #657A85;
        margin-bottom: 25px;
        padding-bottom: 0;
        .search-input-wrapper{
          display: flex;
          align-items: center;
          margin-right: 0;
          position: relative;
          width: 100%;
          i{
            position: absolute;
            left: 5px;
            width: 18px;
            height: 18px;
            display: flex;
          }
          input{
            width: 100%;
            margin-right: 0;
            max-width: 100%;
            outline: none;
            box-shadow: none;
            padding: 10px 5px;
            padding-left: 30px;
            border: none;
            color: #181818;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      &:after{
        content: unset !important;
      }
      .ant-menu-item{
        height: auto !important;
        line-height: normal;
        margin: 0;
        padding: 0px !important;
        width: calc(100% - 30px);
        position: relative;
        overflow: visible;
        &:first-child{
          &:before{
            content: unset;
          }
        }
        &:before{
          position: absolute;
          content: '';
          width: 4px;
          height: 100%;
          background:  ${palette('primary', 12)};
          right: -50px;
          z-index: 1;
          opacity: 0;
          border-radius: 10px;
        }
        &.ant-menu-item-selected{
          &:before{
            opacity: 1;
          }
          .ant-btn{
            background:  ${palette('primary', 12)} !important;
            a{
              i{
                svg{
                  color: ${palette('base', 0)};
                }
              }
              span{
                color: ${palette('base', 0)};
              }
            }
          }
        }
        .menu-label-wrapper{
          padding: 0;
          margin-left: 15px;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .ant-btn{
          border: none;
          outline: none;
          box-shadow: none;
          height: auto;
          padding: 12px;
          background:  ${palette('base', 0)};
          transition: all 0.3s ease-in-out;
          width: 100%;
          a{
            display: flex;
            align-items: center;
            text-decoration: none;
            .menu-item-bullet{
              display: none;
            }
            i{
              width: 16px;
              height: 16px;
              /* display: block;
              margin-top: -3px; */
              margin-right: 10px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              svg{
                width: 100%;
                height: 100%;
                display: block;
                color: ${palette('base', 8)};
              }
            }
            span{
              display: block;
              font-size: 15px;
              font-weight: 700;
              color: ${palette('base', 8)};
            }
          }
        }
      }
    }
    .side-menu-wrapper{
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .SPR-menu-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }
  }
  .contact-footer-bar-wrapper{
    padding: 18px;
    span{
      margin-left: 15px;
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    .info-link-block{
      margin-top: 10px;
      padding-left: 15px;
      p{
        font-size: 12px;
        font-weight: 600;
        color: ${palette('base', 1)};
        a{
          font-weight: 600;
          color: ${palette('primary', 12)};
        }
      }
    }
  }
`;

export default SidebarWrapper;
