import React, { useCallback, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import gql from "graphql-tag";
import { Card, Col, Form, Input, Layout, Row, Typography, Checkbox, Button, message, Slider, InputNumber, Icon } from "antd";
import PublicContentWrapper from "../../layouts/public/index.style";
import { FormWrapper } from "../form";
import debounce from "lodash/debounce";
import axios from "axios";
import { apiUrl } from "../../config";

const SearchOrganization = ({ setCompanyModal, companyModal }) => {
  const [selectOrg, setOrg] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectInputRef = useRef();

  const organizationHandler = useCallback(
    debounce((inputText, callback) => {
      organizationApiHandler(inputText).then((options) => callback(options));
    }, 1000),

    []
  );

  const organizationApiHandler = async (input) => {
    if (input.trim().length <= 1) return;
    let result = await axios.get(`${apiUrl}/search-organization/${input}`);
    result = result.data;
    if (result.length == 0) return;

    return result.map((data) => ({
      label: data.displayname,
      value: data.id,
      utype: data.utype,
    }));
  };

  const onClear = () => {
    selectInputRef.current.clearValue();
    setOrg(null);
  };

  const orgSelectHandler = (data) => {
    setOrg(data);
    setError(false);
  };

  const submitHandler = () => {
    if (!selectOrg) {
      setError(true);
      return;
    }
    setCompanyModal({ ...companyModal, join: true, organization: false, variables: { id: parseInt(selectOrg.value), portalName: selectOrg.label, utype: selectOrg.utype } });
    // setLoading(true);
    // organizationSave({ variables: { portalId: parseInt(selectOrg.value), utype: selectOrg.utype } });
    // setLoading(false);
  };

  return (
    <PublicContentWrapper>
      <div className="search-organization-layout-wrapper">
        <FormWrapper className="public-form">
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <p>Edit Organization</p>
                <Form.Item hasFeedback style={{ color: "rgba(0, 0, 0, 0.85)", marginBottom: "10px" }}>
                  <AsyncSelect placeholder="Company/Practice Affiliation" ref={selectInputRef} loadOptions={organizationHandler} onChange={(data) => orgSelectHandler(data)} className="unitifi-select-container" classNamePrefix="unitifi-react-select" />
                </Form.Item>
                {error && <p>Please select Organization</p>}
              </Col>
            </Row>
            {selectOrg && (
              <Row gutter={16}>
                <Col className="gutter-row" span={24}>
                  <p className="organization-error-remove-wrapper">
                    {selectOrg.label} <span onClick={() => onClear()}>X</span>
                  </p>
                </Col>
              </Row>
            )}

            <div className="organization-wrapper">
              {/* <Link to="/">Skip For Now</Link> */}
              <Button block type="primary" size="large" className="unitifi-login-btn" onClick={() => submitHandler()}>
                {loading ? <Icon type="loading" /> : " Request Change"}
              </Button>
              <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => setCompanyModal({ leave: false, organization: false, join: false })}>
                Cancel
              </Button>
            </div>
          </div>
        </FormWrapper>
      </div>
    </PublicContentWrapper>
  );
};

export default SearchOrganization;
