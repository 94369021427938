import { Badge, Button, Icon, Spin, Table, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import LayoutContent from "../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../layouts/app/layoutWrapper";
import trashIcon from "../../assets/img/icon-trash.svg";
import pauseIcon from "../../assets/img/icon-pause.svg";
import playIcon from "../../assets/img/icon-play.svg";
import backIcon from "../../assets/img/icon-chevron-left.svg";
import { useLazyQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import GenerateBadge from "../../helpers/generateBadge";
import { Link } from "react-router-dom";
const { confirm } = Modal;

const ManageUser = (props) => {
  const { currentPortalId, me } = props;

  const [variables, setVariables] = useState({
    page: 1,
    search: "",
    order: "ASC",
    orderColumn: "username",
    status: "",
    billing_cycle: "",
    limit: 10,
    accountType: me.accountType,
    portalId: currentPortalId,
    roleCode: me.rolecode,
    practiceId: me.practiceid,
  });
  const [userList, { loading, error, data, fetchMore }] = useLazyQuery(QUERY, {
    variables: { filters: variables },
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
  });

  const [deleteApiHandler, { data: deleteData, error: deleteError, loading: deleteLoading }] = useMutation(DELETE_QUERY);

  useEffect(() => {
    userList();
  }, [variables]);

  useEffect(() => {
    if (!deleteData) return;
    if (deleteData.updateDeleteMangeUser.ok) {
      notification.success({ message: deleteData.updateDeleteMangeUser.message });
      userList();
    } else notification.error({ message: deleteData.updateDeleteMangeUser.message });
  }, [deleteData]);

  const columns = [
    {
      dataIndex: "username",
      sorter: true,
      title: "Member Name",
    },
    {
      dataIndex: "rolename",
      title: "Member Role",
      sorter: true,
      render: (text, advisor) => (
        <p>
          {advisor.code == "PRCTO" ? "Practice Admin" : ""}
          {advisor.code == "PRCTM" ? "Practice Member" : ""}
          {advisor.code == "ADVSM" ? "Advisor Member" : ""}
          {advisor.code == "ADVSO" ? "Advisory Admin" : ""}
          {advisor.code == "CMPYM" ? "Company Member" : ""}
          {advisor.code == "CMPYO" ? "Company Admin" : ""}
        </p>
      ),
    },
    {
      dataIndex: "status",
      title: "Status",
      sorter: true,
      render: (text) => <Badge status={GenerateBadge(text)} text={text} />,
    },

    {
      dataIndex: "practicename",
      sorter: true,
      title: "Practice",
    },
    {
      dataIndex: "email",
      sorter: true,
      title: "Member Email",
    },
    {
      className: "action-column viewColumn text-center",
      title: "Actions",
      render: (data) => {
        //return <Button type={"link"} icon={"delete"} />;
        return (
          ["CMPYO", "PRCTO", "ADVSO"].includes(data.code) == false && (
            <>
              <a href="javascript:void(0);" onClick={() => deleteHandler(data.id, "pause", data.status, data.practiceOwnerName, data.practiceOwnerId, data.username)}>
                {data && data.status == "Active" ? <img src={pauseIcon} /> : <img src={playIcon} />}

                {/* <img src={playIcon} /> */}
              </a>
              <a href="javascript:void(0);" className="ms-2" onClick={() => deleteHandler(data.id, "delete", data.status, data.practiceOwnerName, data.practiceOwnerId, data.username)}>
                <img src={trashIcon} />
              </a>
            </>
          )
        );
      },
    },
  ];

  let result = _.get(data, "manageUserSubscriptionList.result", []);
  let tableCount = _.get(data, "manageUserSubscriptionList.pagination.totalCount", 0);

  let tablePagination = {
    current: variables.page,
    defaultPageSize: variables.limit,
    pageSize: variables.limit,
    pageSizeOptions: ["10", "20", "50", "100"],
    showQuickJumper: false,
    showSizeChanger: false,
    total: tableCount,
  };

  function handleTableChange(pagination, filters, sorter) {
    const sorterOrder = sorter.order === "descend" ? `DESC` : `ASC`;

    let x = (pagination.current - 1) * variables.limit;
    setVariables({
      ...variables,
      offset: x,
      limit: pagination.pageSize,
      page: pagination.current == variables.page ? 1 : pagination.current,
      order: sorterOrder,
      orderColumn: sorter.field || "username",
      [sorter.columnKey]: sorter.order,
    });
  }

  function deleteHandler(id, type, status, practiceOwnerName, practiceOwnerId, memberName) {
    confirm({
      title: type == "delete" ? "Are you sure you want to delete this team member?" : `Are you sure you want to ${status == "Active" ? "pause" : "activate"} this team member’s subscription?`,
      content: type == "pause" && status == "Inactive" ? "" : `If you proceed, all clients assigned to ${memberName} will be assigned to ${practiceOwnerName}  (the corresponding Practice Admin) until being assigned to a new Advisor`,
      okText: "Yes",
      okType: "primary",
      cancelText: "Cancel",
      icon: "close-circle",

      onOk: () => {
        return new Promise(async (resolve, reject) => {
          const data = await deleteApiHandler({
            variables: {
              filters: {
                actionType: type,
                userId: id,
                rolecode: me.rolecode,
                accountType: me.accountType,
                portalId: me.portalId,
                practiceId: me.practiceid,
                portalId: currentPortalId,
                status,
                practiceOwnerId,
              },
            },
          });
          resolve();
        }).catch(() => console.log("error"));
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const countStart = (variables.page - 1) * variables.limit + 1;
  const countEnd = variables.page * variables.limit > tableCount ? tableCount : variables.page * variables.limit;
  const display = tableCount ? `Displaying ${countStart}-${countEnd} of ${tableCount}` : "No Records";

  return (
    <>
      <div className="unirifi-costomer-layout-wrapper">
        <div className="backLink">
          <Link to="/settings/account">
            <img src={backIcon} />
            <span>Back</span>
          </Link>
        </div>
        <LayoutContentWrapper>
          <h6 className="headingUser">Team Members</h6>
          {tableCount > 0 && <div className="displayData">{display}</div>}
          <LayoutContent>
            <Table
              bordered={true}
              columns={columns}
              dataSource={result}
              loading={
                loading && {
                  indicator: <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />,
                }
              }
              locale={{ emptyText: "No Team Members found." }}
              onChange={handleTableChange}
              pagination={tablePagination ? { ...tablePagination } : false}
              rowKey="id"
              // size="middle"Edit Portal
              scroll={{ x: "max-content" }}
            />
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    </>
  );
};

export default ManageUser;

const QUERY = gql`
  query($filters: JSON) {
    manageUserSubscriptionList(filters: $filters) {
      result
      pagination
    }
  }
`;

const DELETE_QUERY = gql`
  mutation updateDeleteMangeUser($filters: JSON) {
    updateDeleteMangeUser(filters: $filters) {
      ok
      message
    }
  }
`;
