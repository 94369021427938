import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { notification } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import UserForm from '../form';
import _ from 'lodash';

class UserAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 1,
      loading: false,
      practiceId: props.match.params.practiceId,
      redirectTo: null,
      reset: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  
  handleSubmit(values) {
    this.setState({
      loading: true,
    });
    const variables = {
      ...values,
      portalId: values.portalId ? values.portalId : this.props.userPortals[0] ? [this.props.userPortals[0].id] : [null],
      isGlobalAdmin: values.roleId === 1,
      isPortalAdmin: values.roleId === 4 || values.roleId === 5,
      isPracticeAdmin: values.roleId === 2 || values.roleId === 6,
      phoneNumber: `${values.p1}${values.p2}${values.p3}`,
    };

    this.props.mutate({ variables })
      .then(({ data }) => {
        if (data.createUser.ok && !data.createUser.errors) {
          this.handleSuccess(`${data.createUser.user.firstName} ${data.createUser.user.lastName}`);
          return;
        }

        this.handleError(data.createUser.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleReset() {
    this.setState({ reset: !this.state.reset });
  }

  handleSuccess(userName) {
    notification.success({
      message: 'User Created Successfully',
      //description: `${userName} has been successfully created.`,
    });

    if (this.state.reset === false) {
      this.setState({ redirectTo: this.props.currentPractice ? `/${this.props.currentPractice}/users` : '/users' });
    } else {
      this.setState({
        key: this.state.key + 1,
        loading: false,
      });
    }
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }



  render() {
    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Add User"
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <UserForm
              loadingButton={this.state.loading}
              currentPractice={this.props.currentPractice}
              createAnother={this.state.reset}
              key={this.state.key}
              loading={this.state.loading}
              onCreateAnother={this.handleReset}
              onSubmit={this.handleSubmit}
              userPortals={_.get(this.props,'userPortals[0]',{})}
              gblUserRole={this.props.gblUserRole}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const addUserMutation = gql`
  mutation createUser(
    $address: String
    $city: String
    $email: String!
    $firstName: String
    $isGlobalAdmin: Boolean
    $isPracticeAdmin: Boolean
    $isPortalAdmin: Boolean
    $lastName: String
    $phoneNumber: String
    $practiceId: [Int]
    $portalId: [Int]
    $roleId: Int
    $state: String
    $zipCode: String
  ){
    createUser(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      isGlobalAdmin: $isGlobalAdmin
      isPracticeAdmin: $isPracticeAdmin
      isPortalAdmin: $isPortalAdmin
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      portalId: $portalId
      roleId: $roleId
      state: $state
      zipCode: $zipCode
    ){
      ok
      user {
        firstName
        lastName
      }
      errors {
        message
        path
      }
    }
  }
`;

export default graphql(addUserMutation)(UserAdd);
