import styled from 'styled-components';

export const  UserMenuWrapper = styled.div`
  align-items: center;
  display: flex;
  .user-menu {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      cursor: pointer;
      font-size: 18px;
      // margin-right: 16px;
      margin-top: 0;
      transition: all .2s;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      &.notification {
        margin-right: 24px;
        margin-top: 12px;
        transition: all .2s;
        svg {
          font-size: 18px;
        }
      }
    }
    .user-menu-dropdown-link {
      cursor: pointer;
    }
    i {
      color: #181818;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.green-dost{
        position: relative;
        &:before{
          position: absolute;
          content: '';
          right: 0;
          left: auto;
          top: 0;
          bottom: 0;
          background: #33923C;
          width: 8px;
          height: 8px;
          right: 1px;
          border-radius: 100px;
        }
      }
    }
    .user-detail{
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      @media only screen and (max-width: 575px) {
        display: none;
      }
      h6{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
      }
      small{
        line-height: normal;
        font-size: 13px;
        color: #586063;
      }
    }
    .ant-badge{
      .ant-scroll-number{
        position:absolute;
        top:28px;
        left:8px;
      }
    }
  }
`;

export const UserCardWrapper = styled.div`
  .profile-link {
    text-align:left;
    box-shadow:none;
    background-color:transparent;
    border-color:transparent;
    color:black
    display: flex;
    align-items: center;
    a{
      display: flex;
      align-items: center;
      text-decoration: none;
      i{
        margin-right: 7px;
      }
    }
  }

  .profile-link:hover {
    background-color:rgba(0,111,186,1)!important;
    border-color:transparent;
    color:white!important
  }
  


`;
