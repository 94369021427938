import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { Form, Input, Select as Selectd, Row, Col, TreeSelect } from "antd";
import { AddButtons, FormWrapper } from "../../components/form";
import _, { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import { AccountContext } from "../../context/AccountContext";
import PromptModal from "../../components/promptModal";
const { SHOW_PARENT } = TreeSelect;
const FormItem = Form.Item;
const { Option } = Selectd;

const countTotalRecords = (arr) => {
  let count = 0;
  for (let ar of arr) {
    count += ar.children.length;
  }
  return count;
};

function SupportRoleForm(props) {
  const { loading, me, onSubmit, form, supportDetail, history, location } = props;
  const accountContext = useContext(AccountContext);
  const { account } = accountContext;
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [assignedToData, setAssignedToData] = useState([]);
  const [sortedAssignedToData, setSortedAssignedToData] = useState([]);
  const [groupData, setGroupData] = useState([]);

  useEffect(() => {
    supportUserList();
  }, []);

  useEffect(() => {
    if (!account) return;
    if (location.pathname.includes("/edit/")) return;
    const totalSupportRole = localStorage.getItem("totalSupportRole");
    if (totalSupportRole) {
      const isRedirect = account && account.rolecode != "ADVSO" && account.rolecode != "ADVSM" ? parseInt(totalSupportRole) == 4 : parseInt(totalSupportRole) == 1;
      if (isRedirect) history.push("/support-roles");
    }
  }, [account]);

  useEffect(() => {
    if (!supportDetail) return;
    if (!assignedToData) return;
    if (supportDetail.supportUserDetail.result.length == 0) return;
    let result = supportDetail.supportUserDetail.result[0];
    setFieldsValue({ accessType: result.accessType });
    let obj = { firstName: result.firstName, lastName: result.lastName, email: result.email, accessType: result.accessType };
    if (result.accessType == "group") obj.groupName = result.groupName;

    setTimeout(() => {
      setFieldsValue(obj);
    }, 300);

    if (result.accessType == "group") {
      let practicesIds = result.assignTo
        .filter((data) => data.userType == 'practice')
        .map((practice) => {
          return { advisorId: practice.advisorId, practiceId: practice.practiceId, userType: practice.userType };
        });
      let advisorIds = result.assignTo
        .filter((data) => data.userType == 'company')
        .map((advisor) => {
          return { advisorId: advisor.advisorId, practiceId: advisor.practiceId, userType: advisor.userType };
        });
        
        const practData = filterGroupData("practice", practicesIds);
        const advData = filterGroupData("advisor", advisorIds);
        let assignedArrData = [...practData, ...advData];
      let totalRecords = countTotalRecords(assignedToData);
      if (assignedArrData.length == totalRecords) assignedArrData = ["0_0", ...assignedArrData];
      setGroupData(assignedArrData);

      form.setFieldsValue({ assignTo: assignedArrData });
    }
  }, [supportDetail, assignedToData]);

  const [supportUserList, { loading: userLoading, data: userListData }] = useLazyQuery(gql`
    query {
      groupUserList {
        result
      }
    }
  `);

  useEffect(() => {
    if (!userListData) return;
    let assignedData = userListData.groupUserList.result[0];
    let companyMemberUsers = assignedData.companyMemberUsers;
    let practiceUsers = assignedData.practiceUsers;
    let assignedArr = [];
    // let obj = {
    //   title: "Select all",
    //   value: `0_0`,
    //   id: 0,
    //   userType: "all",
    //   children: [],
    // };
    // assignedArr.push(obj);
    if (companyMemberUsers.length > 0) {
      for (const [key, member] of Object.entries(companyMemberUsers)) {
        if (member.users.length > 0) {
          for (const [ukey, users] of Object.entries(member.users)) {
            let userObj = {
              title: users.username,
              value: `${users.userId}_${ukey}`,
              id: users.userId,
              practiceid: member.practiceid,
              roleCode: users.roleCode,
              userType: "company",
              children: [],
              isPracice: false,
            };
            assignedArr.push(userObj);
          }
        }
      }
    }

    if (practiceUsers.length > 0) {
      for (const [pkey, practMember] of Object.entries(practiceUsers)) {
        let obj = {
          title: practMember.practicename,
          value: `${practMember.practiceid}_${pkey}`,
          id: practMember.practiceid,
          userType: "practice",
          children: [],
          isPracice: true,
        };
        if (practMember.users.length > 0) {
          for (const [mkey, users] of Object.entries(practMember.users)) {
            let userObj = {
              title: users.username,
              value: `${users.userId}_${mkey}`,
              id: users.userId,
              roleCode: users.roleCode,
              userType: "practice",
              isPracice: false,
            };
            obj.children.push(userObj);
          }
        }
        assignedArr.push(obj);
      }
    }
    let obj = [{
      title: "Select all",
      value: `0_0`,
      id: 0,
      userType: "all",
      children: assignedArr,
    }];

    setAssignedToData(obj);
    setSortedAssignedToData(obj);
  }, [userListData]);

  const filterGroupData = (type, values) => {
    return assignedToData.flatMap((item) => {
      if (type == "advisor") {
        const filteredChildren = item.children.filter((child) => values.some((val) => val.userType == child.userType && val.advisorId == child.id));
        if (filteredChildren.length > 0) return filteredChildren.map((child) => child.value);
        else {
          const filterData = values.filter((val) => val.userType == item.userType && val.advisorId == item.id);
          // console.log("filterData==>", filterData);
          if (filterData.length > 0) return [item.value];

          return [];
          // const filterData = ;
          // if (filterData.length > 0) return filterData.map((child) => child.value);
        }
        // else return [item.value];
      } else {

        const filteredPracticeChindern = item.children.flatMap((child) => child.children.filter((grandChild) => values.some((val) => val.userType == grandChild.userType && val.advisorId == grandChild.id)).map((grandChild) => grandChild.value))

        return filteredPracticeChindern;
        const userRes = values.some((val) => val.userType == item.userType && val.practiceId == (item.id.practiceid || item.id));
        if (userRes) return [item.value];
      }
      return [];
    });
  };

  function handleSubmit(event) {
    event.preventDefault();
    props.form.validateFields((err, values) => {
      let atd = assignedToData;
      if (!err) {
        if(assignedToData[0].value=="0_0"){
          atd = assignedToData[0].children;
        }
        
        const filteredData = atd.flatMap((item) => {
          const filteredChildren = item.children ? item.children.filter((child) => groupData.includes(child.value)) : [];          
          if (filteredChildren.length > 0) {
            return filteredChildren.map((child) => ({
              advisorId: child.id,
              practiceId: item.practiceid || item.id,
              userType: item.userType,
            }));
          } else if (groupData.includes(item.value) && item.value != "0_0") {
            if (item.isPracice) {
              return item.children.map((child) => ({
                advisorId: child.id,
                practiceId: item.practiceid || item.id,
                userType: item.userType,
              }));
            } else {
              return {
                advisorId: item.id,
                practiceId: item.practiceid || item.id,
                userType: item.userType,
              };

            }
            // return [
            //   {
            //     practiceId: item.id.practiceid || item.id,
            //     userType: item.userType,
            //   },
            // ];
          }
          return [];
        });

        if (values.accessType == undefined) values.accessType = "individual";
        if (values.accessType == "individual") values.assignTo = [{ advisorId: me.id }];
        else values.assignTo = filteredData;
        // console.log("values", values);
        props.onSubmit(values);
      }
    });
  }
  function getValueByPractiseId() {
    // Search through parent objects
    let data = assignedToData[0].children;
    let arr = [];
    for (let obj of data) {
        
        // if (obj.practiceid) {
            arr.push(obj.value);
            // return obj.value;  // Found in parent
        // }
        // Search through child objects if they exist
        // if (obj.children && Array.isArray(obj.children)) {
        //               //  console.log("sd",obj);

        //         const child = obj.children.find(childObj =>  childObj.roleCode);
        //     if (child) {
        //         arr.push(child.value);
        //         // return child.value;  // Found in child
        //     }
        // }
    }

    // Return null if not found
    return arr;
}
  const onChange = (value, node, event) => {
    let treeValue = value;
    let treeValue1 = value;
    if (value.includes("0_0") && !groupData.includes("0_0")) {
      treeValue1 = getValueByPractiseId();
      treeValue = ["0_0"]
    };
    // if (groupData.includes("0_0") && value.length != countTotalRecords(assignedToData)) treeValue = value.filter((data) => data != "0_0");
    // if (!value.includes("0_0") && groupData.includes("0_0")) treeValue = [];
    // console.log("treeValue", treeValue);
    form.setFieldsValue({ assignTo: treeValue });
    setGroupData(treeValue1);
  };

  const filterTreeNode = (inputValue, treeNode) => {
    const { title, children } = treeNode.props;
    const lowerTitle = title.toLowerCase();
    const lowerSearchTerm = inputValue.toLowerCase();
   
    const titleMatch = lowerTitle.includes(lowerSearchTerm);
    const childrenMatch =
    children &&
      children.some((child) =>
        child.props.title.toLowerCase().includes(inputValue.toLowerCase())
      );
    return titleMatch || childrenMatch;
  };

  const handleFilterTreeNode = (inputValue, node) => {
    const matchesNode = node.props.title.toLowerCase().includes(inputValue.toLowerCase());
    if (matchesNode) {
      // If parent node matches, skip searching in children
      return true;
    }
    if (node.props.isPracice == false) {
      const parent = assignedToData.flatMap(item => item.children || []).find(parent => parent.children.some(child => child.value == node.props.value));
      if (parent && parent.title.toLowerCase().includes(inputValue.toLowerCase())) {
        return true;
      }
    }
    return false
  };

  const sortTreeData = (data, inputValue) => {
    if (!inputValue) return data;
  
    // Helper to determine if a title matches or partially matches the input
    const getMatchType = (title) => {
      if (title.toLowerCase() === inputValue.toLowerCase()) return 0; // Exact match
      if (title.toLowerCase().startsWith(inputValue.toLowerCase())) return 1; // Partial match
      if (title.toLowerCase().includes(inputValue.toLowerCase())) return 2; // Partial match
      return 3; // No match
    };
  
    // Recursively sort nodes
    const sortedData = data
      .map((node) => {
        // Sort children if they exist
        const children = node.children ? sortTreeData(node.children, inputValue) : undefined;
        return { ...node, children };
      })
      .sort((a, b) => getMatchType(a.title) - getMatchType(b.title)); // Sort by match type
  
    return sortedData;
  };

  const handleSearch = (inputValue) => {
    if (!inputValue) {
      setSortedAssignedToData(assignedToData);
        return;
    }
    const sortedData = sortTreeData(assignedToData, inputValue);
    setSortedAssignedToData(sortedData);
  }


  const tProps = {
    treeData: sortedAssignedToData,
    value: groupData,
    onChange: onChange,
    //filterTreeNode,
    filterTreeNode:(inputValue, node) => handleFilterTreeNode(inputValue, node),
    onSearch:handleSearch,
    //treeNodeFilterProp: "title",
    //onSearch: onSearch,
    // filterTreeNode: false,
    treeCheckable: true,
    // showSearch: true,
    showCheckedStrategy: SHOW_PARENT,
    searchPlaceholder: "Please select",
    style: {
      width: "100%",
      height: "32px", 
      overflow: "hidden"
    },
    dropdownStyle: {
      //  background: "red",
    },
    maxTagCount: 2,
    maxTagPlaceholder: (omittedValues) => `+ ${omittedValues.length} more`,
    treeDefaultExpandedKeys: ['0_0']
  };

  return (
    <FormWrapper>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="First Name">
              {getFieldDecorator("firstName", {
                initialValue: props.supportRoleData ? props.supportRoleData.firstName : null,
                rules: [
                  {
                    message: "First name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="First Name" />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem className="" label="Last Name">
              {getFieldDecorator("lastName", {
                initialValue: props.supportRoleData ? props.supportRoleData.lastName : null,
                rules: [
                  {
                    message: "Last name is required.",
                    required: true,
                  },
                ],
              })(<Input placeholder="Last Name" />)}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <FormItem label="Email">
              {getFieldDecorator("email", {
                initialValue: props.supportRoleData ? props.supportRoleData.email : null,
                rules: [
                  {
                    message: "Email is required.",
                    required: true,
                  },
                  {
                    type: "email",
                    message: "Email is not valid",
                  },
                ],
              })(<Input placeholder="Email" />)}
            </FormItem>
          </Col>
        </Row>

        {account && account.rolecode != "ADVSO" && account.rolecode != "ADVSM" && (
          <Row gutter={16}>
            <Col span={12}>
              <FormItem className="" label="Access">
                {getFieldDecorator("accessType", {
                  initialValue: props.supportRoleData ? props.supportRoleData.accessType : "individual",
                  rules: [
                    {
                      message: "Access type is required",
                      required: true,
                    },
                  ],
                })(
                  <Selectd placeholder="access">
                    <Option value="individual">Individual</Option>
                    {account && account.rolecode != "ADVSO" && account.rolecode != "ADVSM" && <Option value="group">Group</Option>}
                  </Selectd>
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        {getFieldValue("accessType") == "group" && (
          <Row gutter={16}>
            <Col span={12}>
              <FormItem className="" label="Group Name">
                {getFieldDecorator("groupName", {
                  initialValue: props.supportRoleData ? props.supportRoleData.groupName : null,
                  rules: [
                    {
                      message: "Group name is required.",
                      required: true,
                    },
                  ],
                })(<Input placeholder="Group Name" />)}
              </FormItem>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <FormItem className={getFieldValue("accessType") == "group" ? "dropdown-icon-wrapper" : ""} label="Assign To">
              {getFieldDecorator("assignTo", {
                initialValue: getFieldValue("accessType") == "individual" || getFieldValue("accessType") == undefined ? me.firstName + " " + me.lastName : "",
                rules: [
                  {
                    message: "Assign to is required.",
                    required: true,
                  },
                ],
              })(
                <>
                  {(getFieldValue("accessType") == "individual" || getFieldValue("accessType") == undefined) && <Input value={me.firstName + " " + me.lastName} readOnly />}
                  {getFieldValue("accessType") == "group" && <TreeSelect placeholder="Please select" {...tProps} showSearch dropdownClassName="selected-assign-tasks"  />}
                </>
              )}
            </FormItem>
          </Col>
        </Row>
        <div className="form-toolbar">
          <AddButtons cancelUrl={"/support-roles"} createAnother={props.createAnother} loading={props.loading} onCreateAnother={props.onCreateAnother} onSubmit={handleSubmit} rights={["superAdmin", "createAdviser"]} />
        </div>
      </Form>
      {!props.loading && <PromptModal isBlocked={props.form.isFieldsTouched()} title="Leave Page?" content="You have unsaved changes. Are you sure you want to leave this page?" {...props} submitAction={handleSubmit} loading={props.loading} />}
    </FormWrapper>
  );
}

export default Form.create()(SupportRoleForm);
