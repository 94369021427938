import { Result, Button } from "antd";
import _, { flowRight as compose } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";

const Done = (props) => {
  console.log("done peopres=>>", props);

  // useEffect(() => {
  // const e_team = _.get(practiceData, "getExceedTeam.exceedteam", []);

  // setExceedTeam(e_team);
  //}, [exceedteam]);
  return (
    <Result
      status="success"
      title="This assessment has been submitted!"
      subTitle="Please check your email or contact your financial professional to review your personalized results."
      extra={[
        <Button type="primary" key="console" href={props.userInviteDomain}>
          Ok
        </Button>,
      ]}
    />
  );
};

export default Done;
