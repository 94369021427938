import React from 'react';
import PropTypes from 'prop-types';
import CharacterLengthFeedbackItem from './checks/length';
import CharacterLowercaseFeedbackItem from './checks/lowercase';
import CharacterUppercaseFeedbackItem from './checks/uppercase';
import CharacterSpecailFeedbackItem from './checks/specialCharacter';
import NumberLengthFeedbackItem from './checks/number';

const PasswordFeedbackWrapper = props => (
  <div className="password-feedback-wrapper">
    <CharacterLengthFeedbackItem input={props.input} value={props.value} />
    <CharacterUppercaseFeedbackItem input={props.input} value={props.value} />
    <CharacterLowercaseFeedbackItem input={props.input} value={props.value} />
    <CharacterSpecailFeedbackItem input={props.input} value={props.value} />
    <NumberLengthFeedbackItem input={props.input} value={props.value} />
  </div>
);

PasswordFeedbackWrapper.defaultProps = {
  value: null,
};

PasswordFeedbackWrapper.propTypes = {
  input: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

export default PasswordFeedbackWrapper;
