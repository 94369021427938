import styled from 'styled-components';
import { palette } from 'styled-theme';

const HeaderBarWrapper = styled.div`
  .ant-layout-header {
    align-items: center;
    background: ${palette('base', 3)};
    // border-bottom: 1px solid rgba(238,242,244,1);
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03);
    display: flex;
    padding: 0 24px;
    position: fixed;
    transition: all .2s;
    width: 100%;
    z-index: 100;
    height: auto;
    min-height: 75px;
    @media only screen and (max-width: 767px) {
      padding: 0 12px;
    }
    .unitifi-header-logo{
      max-width: 100%;
      width: 170px;
      //margin-left: -24px;
      display: flex;
      justify-content: center;
      a{
        //width: 120px;
        overflow: hidden;
        display: flex;
        img {
          height: 48px;
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
    .user-menu-item {
      margin-left: auto;
      display: flex;
      align-items: center;
      .search-and-select-wrapper{
        display: flex;
        align-items: center;
        position:relative;
        form{
          display: flex;
          align-items: center;
          @media only screen and (max-width: 767px) {
            display: none;
          }
          .search-input-wrapper{
            display: flex;
            align-items: center;
            margin-right: 15px;
            position: relative;
            cursor:pointer;
            
            .clear-icon{
              position:absolute;
              z-index:6;
              right:60px;
            }

            i{
              position: absolute;
              right: 15px;
              width: 18px;
              height: 18px;
              display: flex;
            }
            input{
              width: 600px;
              max-width: 100%;
              outline: none;
              box-shadow: none;
              padding: 8px;
              padding-left: 15px;
              border: none;
              color: #7E7E7E;
              font-size: 14px;
              font-weight: 400;
              @media only screen and (max-width: 1400px) {
                width: 400px;
              }
              @media only screen and (max-width: 1199px) {
                width: 310px;
              }
              @media only screen and (max-width: 991px) {
                width: 135px;
              }
            }
            &.input-group{
              .input-group-append{
                .input-group-text{
                  width:48px;
                  height:37px;
                  border-radius:0 4px 4px 0;
                  border:0;
                  background:#006FBA;
                }
              }
            }
          }
        }
        .form-select{
          color: #181818;
          font-size: 14px;
          font-weight: 600;
          border: none;
          outline: none;
          box-shadow: none;
          margin-right: 15px;
          width: 160px;
          @media only screen and (max-width: 767px) {
            width: 120px;
          }
        }
        .ant-select{
          width: 190px !important;
          margin-right: 15px;
          @media only screen and (max-width: 991px) {
            width: 135px !important;
          }
          &.ant-select-open{
          .ant-select-arrow{
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/chev-down-icon.svg");
                background-repeat:no-repeat;
                height:10px;
                width:16px;
                left:-15px;
                top:2px;
                transform:rotate(180deg);
              }
              .anticon{
                display:none;
              }
            }
        }
          @media only screen and (max-width: 767px) {
            width: 120px !important;
          }
          .ant-select-selection{
            height: 36px !important;
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
            .ant-select-selection__rendered{
              color: #181818;
              font-size: 14px;
              font-weight: 600;
              .ant-select-selection__placeholder{
                color: #181818;
                font-size: 14px;
                font-weight: 600;
                top: 56%;
              }
              .ant-select-selection-selected-value{
                line-height: 36px;
              }
            }
            .ant-select-arrow{
              color: #181818;
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:'';
                position:absolute;
                background-image:url("/img/chev-down-icon.svg");
                background-repeat:no-repeat;
                height:10px;
                width:12px;
                left:-10px;
                top:2px;
              }
              .anticon{
                display:none;
              }
            }
          }
        }
      }

        /* .ant-select-open{
          .ant-select-arrow{
              color: #181818;
              font-size: 12px;
              font-weight: bold;
              &::after{
                content:unset;
              }
              &::before{
                position:absolute;
                background-image:url("img/chev-up-icon.svg");
                background-repeat:no-repeat;
                height:10px;
                width:12px;
                left:-10px;
                top:2px;
              }
              .anticon{
                display:none;
              }
            }
        } */

       
  
      .notification{
        margin: 0 !important;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 21px;
      }
      .user-menu-dropdown-link{
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
      }
      .searchAction{
        position:absolute;
        z-index:1;
        right:219px;
        cursor:pointer;
        padding:0 12px;
        @media only screen and (max-width: 991px) {
           right:165px;
        }
        @media only screen and (max-width: 767px) {
           right:150px;
        }
        @media only screen and (max-width: 575px) {
           display:none;
        }
      }
    }
  }
  .unitifi-searchfor-client-input-wrapper{
    width: 649px;
    height: auto;
    margin-right: 14px;
    @media only screen and (max-width: 1400px) {
      width: 400px;
    }
    @media only screen and (max-width: 1199px) {
      width: 310px;
    }
    @media only screen and (max-width: 991px) {
      width: 255px;
    }
    @media only screen and (max-width: 767px) {
      width: 200px;
    }
    @media only screen and (max-width: 575px) {
      display: none;
    }
    .unitifi-react-select__control{
      min-height: auto;
      max-width: 100%;
      width: 100%;
      line-height: normal;
      padding: 0;
      background: white;
      .unitifi-react-select__value-container{
        max-width: 100%;
        outline: none;
        box-shadow: none;
        border: none;
        color: #7E7E7E;
        font-size: 14px;
        font-weight: 400;
        background: white;
        padding: 9.5px 15px;
        position: relative;
        padding-right: 55px;
        padding: 9px 15px 9.5px;
        min-height: 37px;
        &:before{
          background: #006fba;
          background-size: 17.5px;
          background-position: center;
          background-position: 14px 10px;
          position: absolute;
          content: '';
          z-index: 1;
          width: 44px;
          height: 100%;
          right: 0;
          border-radius: 0 3px 3px 0;
          cursor: pointer;
        }
        .unitifi-react-select__placeholder{
          color: #7E7E7E;
          font-size: 14px;
          font-weight: 400;
        }
        .unitifi-react-select__input-container {
          color: #7E7E7E;
          font-size: 14px;
          font-weight: 400;
        }
        input{
          color: #7E7E7E;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .unitifi-react-select__indicators{
        position: relative;
        display: none;
        .unitifi-react-select__indicator{
          width: 36px;
          height: 36px;
          padding: 8px;
          background: #006fba;
          position: relative;
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before{
            background: url("/img/search-icon.svg") no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
            content: '';
            z-index: 1;
            width: 16px;
            height: 16px;
          }
          svg{
          
            path{
              display: none;
            }
          
          }
        }
      }
      .unitifi-react-select__indicator{
        padding: 0 !important;
  
      }
    }
    .unitifi-react-select__menu{
      .unitifi-react-select__menu-list{
        * {
          font-size: 14px;
          font-weight: 400;
          line-height: normal !important;
        }
        .unitifi-react-select__menu-notice{
          line-height: normal;
        }
      }
    }
  }
`;

export default HeaderBarWrapper;
