import styled from 'styled-components'

const SubscriptionStyle = styled.div`
  .flexs-body {
    width: 100%;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .flexs-container {
    width: 1100px;
    max-width: 100%;
    margin: 0 auto;
  }

  .flexs-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .flexs-p {
    font-size: 24px;
    flex: 1 1 20rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px 20px;
    flex-direction: column;
    padding: 10px 20px;
    align-self: normal;
  }

  .flexs-card {
    flex: 1 1 20rem;
    display: flex;
    align-items: left;
    flex-wrap: wrap;
    margin: 10px 20px;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0, 4);
    padding: 10px 20px;
    align-self: normal;
    border-radius: 6px;
  }

  .flexs-card h4 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;

  }

  .flexs-card h4.price{
    display: inline-block;
    margin-top: auto;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;

  }

  .flexs-card p {
    margin-bottom: 10px;
  }

  .flexs-card .flexs-button {
    display: inline-block;
    text-align: center;
    padding: 10px 20px;
    color: #fff;
    background-color: #006fba;
    border-color: #006fba;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    line-height: 1.499;
    position: relative;
    font-weight: 400;
    white-space: nowrap;
    background-image: none;
    cursor: pointer;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }
  }
`

export default SubscriptionStyle
