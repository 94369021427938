import React from 'react';
import { Icon, Popover } from 'antd';
import {
  FlexibleXYPlot,
  MarkSeries,
  VerticalGridLines,
  XAxis,
} from 'react-vis';
import '../../../node_modules/react-vis/dist/style.css';
import MapDecisionMakingStyle from './mapDecisionMaking';
import PassionLogicMapWrapper from './index.style';

class PassionLogicMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const chartData = [];
    const { data: insights, firstName, forPrint } = this.props;
    const { personality: { decisionMakingStyle }, score: { plScore } } = insights;

    chartData.push(MapDecisionMakingStyle(decisionMakingStyle, plScore));

    const margin = { bottom: 12, left: 12, right: 12 };

    return (
      <PassionLogicMapWrapper>
        <div className="top-wrapper">
          {insights.personality.decisionMakingStyle}
          {!forPrint &&
            <Popover
              content={`This measurement represents how financial decisions are made and how best to communicate with ${firstName}.`}
              placement="top"
              trigger="hover"
            >
              <Icon type="info-circle" />
            </Popover>}
        </div>
        <div className="chart-wrapper">
          <FlexibleXYPlot
            height={100}
            margin={margin}
            {...forPrint && { width: 255 }}
            xDomain={[0, 200]}
            yDomain={[0, 10]}
          >
            <VerticalGridLines />
            <XAxis
              hideLine
              hideTicks
              on0
            />
            <MarkSeries
              animation
              data={chartData}
              fill={chartData[0].fill}
              stroke={chartData[0].fill}
            />
          </FlexibleXYPlot>
          <div className="label-wrapper gradientBg">
            <div>Logical</div>
            <div>Passionate</div>
          </div>
        </div>
      </PassionLogicMapWrapper>
    );
  }
}

export default PassionLogicMap;
