import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { UsersAdd, UsersEdit, UsersList, UsersView } from "../../../containers/users";

class UserRoutes extends React.Component {
  render() {
    const { gblUserId, gblUserIsPortalAdmin, gblUserIsGlobalAdmin, gblUserRole, currentPractice, userPortals, currentPortalId } = this.props;

    if (!currentPortalId && !currentPractice) return <Redirect to="/settings/account" strict />;

    return (
      <Switch>
        {/* admin routes - catch trailing slashes & send to right route */}
        <Redirect exact from="/:company/users/" to="/:company/users" strict />

        <Redirect exact from="/users/" to="/users" strict />
        <Redirect exact from="/users/add/" to="/users/add" strict />
        <Redirect exact from="/users/edit/:id/" to="/users/edit/:id" strict />
        <Redirect exact from="/users/view/:id/" to="/users/view/:id" strict />
        {/* admin routes */}
        <Route
          exact
          path="/:company/users"
          render={(props) => <UsersList {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} userPortals={userPortals} />}
        />
        <Route
          exact
          path="/users"
          render={(props) => <UsersList {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} userPortals={userPortals} />}
        />
        <Route
          exact
          path="/users/add"
          render={(props) => <UsersAdd {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} userPortals={userPortals} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />}
        />
        <Route
          exact
          path="/users/edit/:id"
          render={(props) => <UsersEdit {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} userPortals={userPortals} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />}
        />
        <Route
          exact
          path="/users/view/:id"
          render={(props) => <UsersView {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />}
        />
        <Route
          exact
          path="/:practiceId/users/edit/:id"
          render={(props) => <UsersEdit {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} userPortals={userPortals} />}
        />
        <Route
          exact
          path="/:practiceId/users/view/:id"
          render={(props) => <UsersView {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />}
        />

        <Route
          exact
          path="/:practiceId/users/add"
          render={(props) => <UsersAdd {...props} currentPractice={currentPractice} gblUserId={gblUserId} gblUserIsGlobalAdmin={gblUserIsGlobalAdmin} userPortals={userPortals} gblUserIsPortalAdmin={gblUserIsPortalAdmin} gblUserRole={gblUserRole} />}
        />
      </Switch>
    );
  }
}

export default UserRoutes;
