import React from "react";
import { FlexibleXYPlot, HorizontalGridLines, MarkSeries, VerticalGridLines, XAxis } from "react-vis";
import "../../../node_modules/react-vis/dist/style.css";
import SelfReportedMapWrapper from "./index.style";

class SelfReportedMapMultiple extends React.Component {
  render() {
    const { data: insights, forPrint } = this.props;
    const data = [
      {
        color: "rgba(255,255,255,1)",
        y:
          insights[0].score.plScore >= 1
            ? 100 + insights[0].score.plScore // logical
            : 100 - insights[0].score.plScore * -1, // passionate
      },
      {
        color: "rgba(0,111,186,1)",
        y:
          insights[0].score.plScore >= 1
            ? 100 + insights[0].score.plScore // logical
            : 100 - insights[0].score.plScore * -1, // passionate
      },
    ];

    data[0].x = insights[0].score.selfReportedScore;
    data[1].x = insights[0].score.riskBehaviorScore;

    const data2 = [
      {
        color: "rgba(255,255,255,1)",
        y:
          insights[1].score.plScore >= 1
            ? 100 + insights[1].score.plScore // logical
            : 100 - insights[1].score.plScore * -1, // passionate
      },
      {
        color: "#CB9120",
        y:
          insights[1].score.plScore >= 1
            ? 100 + insights[1].score.plScore // logical
            : 100 - insights[1].score.plScore * -1, // passionate
      },
    ];
    data2[0].x = insights[1].score.selfReportedScore;
    data2[1].x = insights[1].score.riskBehaviorScore;

    // let behaviorScore = insights[0].score.riskBehaviorScore > insights[1].score.riskBehaviorScore ? insights[0].score.riskBehaviorScore : insights[1].score.riskBehaviorScore;
    // let reportedScore = insights[0].score.selfReportedScore > insights[1].score.selfReportedScore ? insights[0].score.selfReportedScore : insights[1].score.selfReportedScore;
    let behaviorScore = insights[0].score.riskBehaviorScore;
    let reportedScore = insights[0].score.selfReportedScore;

    let behaviorScore2 = insights[1].score.riskBehaviorScore;
    let reportedScore2 = insights[1].score.selfReportedScore;

    return (
      <SelfReportedMapWrapper className="printChartData">
        <div className="chart">
          <FlexibleXYPlot height={507} margin={{ left: 10, right: 10 }} style={{ background: "rgba(255,255,255,1)" }} {...(forPrint && { width: 530 })} xDomain={[0, 100]} yDomain={[0, 200]}>
            <XAxis on0 />
            <HorizontalGridLines tickValues={[100]} />
            <VerticalGridLines animation style={{ stroke: "rgba(230,230,233,1)", strokeDasharray: "4" }} tickValues={[behaviorScore, reportedScore]} />
            <VerticalGridLines animation style={{ stroke: "rgba(230,230,233,1)", strokeDasharray: "4" }} tickValues={[behaviorScore2, reportedScore2]} />
            <MarkSeries
              animation
              colorType="literal"
              data={data.map((row) => ({
                color: row.color,
                x: row.x,
                y: row.y,
              }))}
              stroke="rgba(0,111,186,1)"
            />
            <MarkSeries
              animation
              colorType="literal"
              data={data2.map((row) => ({
                color: row.color,
                x: row.x,
                y: row.y,
              }))}
              stroke="#CB9120"
            />
          </FlexibleXYPlot>
          <div className="bottom-legend gradientBg">
            {/* <div>Conservative</div>
            <div>
              Moderately
              <br />
              Conservative
            </div>
            <div>Moderate</div>
            <div>
              Moderately
              <br />
              Aggressive
            </div>
            <div>Aggressive</div> */}
            <div>Personality Spectrum</div>
          </div>
        </div>
        <div className="bottom-wrapper gradientBg">
          <div>
            <span>Logical</span>
          </div>
          <div>
            <span>Passionate</span>
          </div>
        </div>
      </SelfReportedMapWrapper>
    );
  }
}

export default SelfReportedMapMultiple;
