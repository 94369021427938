import React from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { Icon, Modal, notification, Spin } from "antd";
import { Redirect } from "react-router";
import { Titlebar } from "../../../components";
import LayoutContent from "../../../layouts/app/layoutContent";
import LayoutContentWrapper from "../../../layouts/app/layoutWrapper";
import ClientForm from "../form/clientForm";
import { AccountContext } from "../../../context/AccountContext";
import TitleBar from "../../../components/titlebar/titlebar";

const { confirm } = Modal;

class ClientEdit extends React.Component {
  static contextType = AccountContext;
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect ? this.props.location.state.redirect : `/${this.props.currentPractice}/clients`,
      loading: false,
      redirectTo: null,
      advisorName: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removeClient = this.removeClient.bind(this);
    this.advisorNameHandler = this.advisorNameHandler.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    let variables = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      advisorId: values.advisorId,
      id: this.props.data.getClient.id,
      practiceId: values.advisorId ? this.props.currentPractice : null,
    };

    if (values.address) variables.address = values.address;
    if (values.zipCode) variables.zipCode = values.zipCode;

    if (values.p1 && values.p2 && values.p3) {
      variables.primaryContactPhoneNumber = `${values.p1}${values.p2}${values.p3}`;
      variables.phoneNumber = `${values.p1}${values.p2}${values.p3}`;
      variables.phone_code = values.countryCode;
    } else {
      variables.phone_code = values.countryCode;
      variables.phoneNumber = values.phoneNumber;
    }
    if (values.city) variables.city = values.city.label;
    if (values.state) variables.state = values.state.label;
    if (values.country) variables.country = values.country.label;

    this.props
      .editClient({ variables })
      .then(({ data }) => {
        if (data.editClient.ok && !data.editClient.errors) {
          this.handleSuccess(`${data.editClient.client.firstName} ${data.editClient.client.lastName} has been successfully updated.`);
          return;
        }

        this.handleError(data.editClient.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleDeleteConfirm() {
    confirm({
      title: "Delete Client",
      content: `Are you sure you want to permanently delete ${this.props.data.getClient.firstName} ${this.props.data.getClient.lastName}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      icon: "close-circle",
      onOk: this.removeClient,
      width: "680px",
    });
  }

  removeClient() {
    this.props
      .deleteClient({ variables: { id: this.props.data.getClient.id, portalId: this.props.currentPortalId } })
      .then(({ data }) => {
        if (data.deleteClient.ok && !data.deleteClient.errors) {
          this.handleSuccess(`${this.props.data.getClient.firstName} ${this.props.data.getClient.lastName} has been successfully deleted.`, "deleted");
          return;
        }

        this.handleError(data.deleteClient.errors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === "deleted" ? "Client Deleted Successfully" : "Client Updated Successfully",
      //description,
    });
    let url = `/${this.props.currentPractice}/clients`;
    if (this.props.me.rolecode == "SPR") url = `/${this.props.match.params.practiceId}/clients/advisors/${this.props.match.params.srAdvisorId}`;

    this.props.history.push(url);
    // this.setState({ redirectTo: `/${this.props.currentPractice}/clients` });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: "Error",
        description: err.message,
      });
    });
  }

  advisorNameHandler(name) {
    this.setState({ advisorName: name });
  }

  render() {
    const {
      data: { loading, error, getClient },
      me,
      currentPortalId,
    } = this.props;
    if (loading) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="Edit Client" />
          </div>
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="unirifi-costomer-layout-wrapper">
          <div className="unitifi-subheader-bar-wrapper">
            <Titlebar currentPractice="" displayText="Edit Client" />
          </div>
          <div className="loading-wrapper">
            <p>Whoopsie - client didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    const userContext = this.context;
    const { account } = userContext;

    return (
      <div className="unirifi-costomer-layout-wrapper">
        <div className="unitifi-subheader-bar-wrapper">
          {/* <Titlebar currentPractice={this.props.currentPractice} displayText="Edit Client" /> */}
          <TitleBar
            title={`Edit ${me && me.rolecode == "SPR" ? getClient.firstName + " " + getClient.lastName : "Client"} `}
            subTitle={account && (account.accountType == 1 ? account.portalname : `${account.practicename} | ${account.portalname} ${this.state.advisorName ? `| ${this.state.advisorName}` : ""}`)}
          />
        </div>
        <LayoutContentWrapper>
          <LayoutContent>
            <ClientForm
              cancelUrl={this.state.cancelUrl}
              client={getClient}
              currentPractice={this.props.currentPractice}
              gblUserRole={this.props.gblUserRole}
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              isEdit={true}
              onSubmit={this.handleSubmit}
              me={me}
              currentPortalId={currentPortalId}
              advisorNameHandler={this.advisorNameHandler}
              {...this.props}
            />
            {this.state.redirectTo !== null && <Redirect to={this.state.redirectTo} />}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getClientQuery = gql`
  query($id: Int!, $practiceId: String, $portalId: Int, $roleCode: String, $accountType: Int) {
    getClient(id: $id, practiceId: $practiceId, portalId: $portalId, roleCode: $roleCode, accountType: $accountType) {
      id
      address
      advisorId
      city
      email
      firstName
      lastName
      practiceId
      phoneNumber
      phone_code
      state
      zipCode
      country
      phone_code
      addClientToTeam
      isAssign
    }
  }
`;

const editClientMutation = gql`
  mutation editClient(
    $id: Int!
    $address: String
    $advisorId: Int
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $practiceId: String
    $state: String
    $zipCode: String
    $addClientToTeam: Boolean
    $country: String
    $phone_code: String
  ) {
    editClient(
      id: $id
      address: $address
      advisorId: $advisorId
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      state: $state
      zipCode: $zipCode
      addClientToTeam: $addClientToTeam
      country: $country
      phone_code: $phone_code
    ) {
      client {
        firstName
        lastName
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

const deleteClientMutation = gql`
  mutation deleteClient($id: Int!, $portalId: Int) {
    deleteClient(id: $id, portalId: $portalId) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getClientQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: Number(props.match.params.id),
        practiceId: props.match.params.practiceId,
        accountType: props.me.accountType,
        roleCode: props.me.rolecode,
        portalId: props.currentPortalId,
      },
    }),
  }),
  graphql(editClientMutation, { name: "editClient" }),
  graphql(deleteClientMutation, { name: "deleteClient" })
)(ClientEdit);
