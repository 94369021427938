import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Prompt } from "react-router-dom";

const PromptModal = (props) => {
  //   console.log("props", props);
  const { content, title, isBlocked, history, location, submitAction, isPromptModalClose = false } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastLocation, setLastLocation] = useState(location);
  const [shouldUnload, setShouldUnload] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    setShouldUnload(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const showModal = (nextLocation) => {
    openModal();
    setLastLocation(nextLocation);
  };

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation);
      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    closeModal();
    setConfirmedNavigation(true);
  };
  const cancelSubmit = () => {
    closeModal();
  };

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true);
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content;
      }
      if (shouldUnload) {
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", unload);

    return () => window.removeEventListener("beforeunload", unload);
  }, [isBlocked, content, shouldUnload]);

  useEffect(() => {
    if (isPromptModalClose) setIsModalOpen(false);
  }, [isPromptModalClose]);

  return (
    <>
      <Prompt when message={handleBlockedRoute} />

      <Modal show={isModalOpen} centered backdrop="static" className="unitifi-modal-wrapper company-modal">
        {/* <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
        </Modal.Header> */}
        {/*<Modal.Body>
          <>
            <h4 class="cmn-modal-text">Do you want to save the changes?</h4>
            <div className="modal-footer-bnts-wrapper btn-center-wrapper">
              <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn me-2" onClick={submitAction}>
                Save
              </Button>
              <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={() => handleConfirmNavigationClick()}>
                Cancel
              </Button>
            </div>
          </>
        </Modal.Body>*/}
        <Modal.Body>
          <>
            <h4 class="cmn-modal-text">Are you sure you want to cancel?</h4>
            <div className="modal-footer-bnts-wrapper btn-center-wrapper">
              <Button type="primary" size="large" htmlType="submit" className="unitifi-login-btn me-2" onClick={() => handleConfirmNavigationClick()}>
                Yes
              </Button>
              <Button type="" size="large" htmlType="button" className="unitifi-login-btn" onClick={cancelSubmit}>
                No
              </Button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PromptModal;
