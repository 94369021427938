import React, { useState, useForm } from "react";
import { graphql, useLazyQuery, useApolloClient, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Badge, Form, Input, notification, Select as Selectd, Alert, Row, Col, AutoComplete } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AddButtons, EditButtons, FormWrapper } from "../../components/form";
import _, { flowRight as compose } from "lodash";
import Select from "react-select";
import { SiteLogo } from "../practices/components";
import PromptModal from "../../components/promptModal";

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Selectd;
const AutoCompleteOption = AutoComplete.Option;
const roleList = [];
const cleanPracticeId = (value) =>
  value
    .trim()
    .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, "")
    .replace(/\s{2,}/g, " ")
    .replace(/[^a-zA-Z0-9]/g, "-")
    .replace(/_+$/, "")
    .toLowerCase();

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      countryList: [],
      stateList: [],
      cityList: [],
      siteLogo: this.props.portal && this.props.portal.siteLogo ? this.props.portal.siteLogo : null,
      siteLogoS3Token: this.props.portal && this.props.portal.siteLogoS3Token ? this.props.portal.siteLogoS3Token : null,
      newPracticeEmailRecipient: "",
      newPracticeEmailTitle: "Welcome",
      newPracticeEmailBody: `Hello,\n\nWe’re honored to have you as part of the community of financial professionals utilizing Unitifi!\n\n[[SYS_GENERATED_LINK]]\n\nOnce logged in, your dedicated Customer Success Representative will be in touch.\nWe're excited to help transform the authentic manner in which you connect with and help your clients succeed.\n\nThank you,\nUnitifi`,
    };

    this.handleUploadParams = this.handleUploadParams.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onPracticeIdBlur = this.onPracticeIdBlur.bind(this);
    this.onPracticeIdChange = this.onPracticeIdChange.bind(this);
    this.onPracticeNameChange = this.onPracticeNameChange.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.cityHandler = this.cityHandler.bind(this);
  }

  componentDidUpdate() {
    if (this.state.countryList.length > 0) return;
    const {
      gblUserRole,
      portal,
      data: { loading, error, getCountrylist },
    } = this.props;
    if (getCountrylist != undefined) {
      const country = getCountrylist.countrylist.map((country) => {
        return {
          label: country.name,
          value: country.id,
        };
      });
      this.setState({ countryList: country }, () => {
        if (portal) {
          let ctry = getCountrylist.countrylist.filter((data) => data.name == portal.primaryContactCountry);
          if (ctry.length > 0) {
            ctry = ctry[0];
            this.props.form.setFields({
              primaryContactCountry: {
                value: { label: ctry.name, value: ctry.id },
              },
            });
            this.stateHandler({ value: ctry.id });
          }
        }
      });
    }
  }

  onPracticeIdBlur(event) {
    if (event.target.value === "") {
      this.setState({ practiceIdEdited: false });
    } else {
      const practiceId = cleanPracticeId(event.target.value);
      this.props.form.setFieldsValue({ practiceId });
    }
  }

  onPracticeIdChange(event) {
    const practiceId = cleanPracticeId(event.target.value);
    this.props.form.setFieldsValue({ practiceId });
  }

  onPracticeNameChange(event) {
    if (!this.state.practiceIdEdited && !this.props.practiceId) {
      const practiceId = cleanPracticeId(event.target.value);
      this.props.form.setFieldsValue({ practiceId });
    }
  }

  handleUploadParams(uploadParams) {
    const { file, folder } = uploadParams;

    this.setState({
      file,
      folder,
      siteLogo: file == null ? null : this.state.siteLogo,
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
      }

      if (!err) {
        // console.log("values", values);
        let payload = _.clone(values);
        if (payload.primaryContactCountry) payload.primaryContactCountry = payload.primaryContactCountry.label;
        if (payload.primaryContactCity) payload.primaryContactCity = payload.primaryContactCity.label;
        if (payload.primaryContactState) payload.primaryContactState = payload.primaryContactState.label;

        const avatarUrl = this.state.file ? await this.uploadAvatar() : null;

        this.props.onSubmit({
          ...payload,
          siteLogo: avatarUrl && avatarUrl.url ? avatarUrl.url : this.state.siteLogo,
          siteLogoS3Token: avatarUrl && avatarUrl.s3Token ? avatarUrl.s3Token : this.state.siteLogoS3Token,
        });
      }
    });
  }

  async uploadAvatar() {
    const upload = await this.props.uploadFile({
      variables: {
        file: this.state.file,
        folder: this.state.folder,
      },
    });

    const { ok } = upload.data.uploadFile;

    if (!ok) {
      notification.error({
        message: "An Error Has Occurred",
        description: "Our team has already been notified and will address the issue as quickly as possible.",
      });
    }

    const { s3Token, url } = upload.data.uploadFile.response;
    return { s3Token, url };
  }

  async cityHandler(input) {
    this.props.cityListQuery
      .refetch({ state_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getCities.cities;
        const cityData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        this.setState({ cityList: cityData }, () => {
          if (this.props.portal) {
            let ctry = cityData.filter((data) => data.label == this.props.portal.primaryContactCity);
            if (ctry.length > 0) {
              ctry = ctry[0];
              this.props.form.setFields({
                primaryContactCity: {
                  value: { label: ctry.label, value: ctry.value },
                },
              });

              this.cityHandler({ value: ctry.value });
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async stateHandler(input) {
    this.setState({ stateList: [], cityList: [] });
    this.props.form.setFields({
      primaryContactState: {
        value: "",
      },
    });
    this.props.form.setFields({
      primaryContactCity: {
        value: "",
      },
    });
    this.props.stateListQuery
      .refetch({ country_id: input.value + "" })
      .then(({ data }) => {
        let state = data.getStates.states;
        const stateData = state.map((s) => {
          return {
            label: s.name,
            value: s.id,
          };
        });
        this.setState({ stateList: stateData }, () => {
          if (this.props.portal) {
            let ctry = stateData.filter((data) => data.label == this.props.portal.primaryContactState);
            if (ctry.length > 0) {
              ctry = ctry[0];
              this.props.form.setFields({
                primaryContactState: {
                  value: { label: ctry.label, value: ctry.value },
                },
              });

              this.cityHandler({ value: ctry.value });
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    /*useForm({
      warnWhenUnsavedChanges: true,
    });*/

    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { portal } = this.props;

    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit} onFieldsChange={() => console.log("field change")}>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Company Name">
                {getFieldDecorator("name", {
                  initialValue: portal ? portal.name : null,
                  rules: [
                    {
                      message: "Company name is required.",
                      required: true,
                    },
                  ],
                })(<Input placeholder="Company Name" type="text" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Admin First Name">
                {getFieldDecorator("primaryContactFirstName", {
                  initialValue: portal && portal.user ? portal.user.firstName || portal.primaryContactFirstName : null,
                  rules: [
                    {
                      message: "Admin first name is required.",
                      required: true,
                    },
                  ],
                })(<Input placeholder="First Name" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Admin Last Name">
                {getFieldDecorator("primaryContactLastName", {
                  initialValue: portal && portal.user ? portal.user.lastName || portal.primaryContactLastName : null,
                  rules: [
                    {
                      message: "Admin last name is required.",
                      required: true,
                    },
                  ],
                })(<Input placeholder="Last Name" type="text" />)}
              </FormItem>
            </Col>
            <Col span={24}>
              <FormItem label="Admin Email">
                {getFieldDecorator("primaryContactEmail", {
                  initialValue: portal && portal.user ? portal.user.email || portal.primaryContactEmail : null,
                  rules: [
                    {
                      message: "Email is required",
                      required: true,
                    },
                  ],
                })(<Input placeholder="Admin Email" type="email" readOnly />)}
              </FormItem>
            </Col>
          </Row>
          {/* ADDITIONAL INFO */}
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Street Address">
                {getFieldDecorator("primaryContactAddress", {
                  initialValue: portal ? portal.primaryContactAddress : null,
                  rules: [
                    {
                      message: "Address is required.",
                      required: false,
                    },
                  ],
                })(<Input placeholder="Address" />)}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Suite/Unit/Apartment (if applicable)">
                {getFieldDecorator("primaryContactSuite", {
                  initialValue: portal ? portal.primaryContactSuite : null,
                })(<Input placeholder="Suite/Unit/Apartment (if applicable)" />)}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Country">
                {getFieldDecorator("primaryContactCountry", {
                  initialValue: portal ? portal.primaryContactCountry : null,
                  rules: [
                    {
                      message: "Country is required.",
                      required: false,
                    },
                  ],
                })(
                  <Select
                    options={this.state.countryList}
                    onChange={(e) => this.stateHandler(e)}
                    className="unitifi-select-container"
                    classNamePrefix="unitifi-react-select"
                    filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Postal Code/ZIP Code">
                {getFieldDecorator("primaryContactZipCode", {
                  initialValue: portal ? portal.primaryContactZipCode : null,
                  rules: [
                    {
                      message: "Postal Code/ZIP Code is required.",
                      required: false,
                    },
                  ],
                })(<Input placeholder="Postal Code/ZIP Code" />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="State/Province/Region">
                {getFieldDecorator("primaryContactState", {
                  initialValue: portal ? portal.primaryContactState : null,
                  rules: [
                    {
                      message: "State/Province/Region is required.",
                      required: false,
                    },
                  ],
                })(
                  <Select
                    options={this.state.stateList}
                    onChange={(e) => {
                      this.cityHandler(e);
                      this.props.form.setFields({
                        primaryContactCity: null,
                      });
                    }}
                    className="unitifi-select-container"
                    classNamePrefix="unitifi-react-select"
                    filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="City/Town">
                {getFieldDecorator("primaryContactCity", {
                  initialValue: portal ? portal.primaryContactCity : null,
                  rules: [
                    {
                      message: "City/Town is required.",
                      required: false,
                    },
                  ],
                })(<Select options={this.state.cityList} className="unitifi-select-container" classNamePrefix="unitifi-react-select" filterOption={(option, value) => option.label.toLowerCase().indexOf(value.toLowerCase()) === 0} />)}
              </FormItem>
            </Col>
          </Row>

          {/* ADDITIONAL INFO */}
          <Row gutter={16}>
            <Col span={12}>
              {/* {this.props.practice ? ( */}
              <FormItem label="Status">
                {getFieldDecorator("status", {
                  initialValue: portal ? portal.status : "Active",
                  rules: [
                    {
                      message: "A practice status is required.",
                      required: false,
                    },
                  ],
                })(
                  <Selectd placeholder="Status">
                    <Option value="Active">
                      <Badge status="success" text="Active" />
                    </Option>
                    <Option value="Inactive">
                      <Badge status="error" text="Inactive" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
              {/* ) : null} */}
            </Col>
            <Col span={12}>
              {/* {this.props.practice ? ( */}
              <FormItem label="Assessment Completed Notifications">
                {getFieldDecorator("assessmentNotification", {
                  initialValue: portal ? portal.assessmentNotification || false : false,
                  rules: [
                    {
                      message: "A Assessment Completed Notifications is required.",
                      required: false,
                    },
                  ],
                })(
                  <Selectd placeholder="Status">
                    <Option value={false}>
                      <Badge status="error" text="No, don't send me notification" />
                    </Option>
                    <Option value={true}>
                      <Badge status="success" text="Send me notification" />
                    </Option>
                  </Selectd>
                )}
              </FormItem>
              {/* ) : null} */}
            </Col>
          </Row>

          <FormItem>
            <SiteLogo handleUploadParams={this.handleUploadParams} practice={portal ? portal : null} />
          </FormItem>

          {/* <FormItem label="Email Template">
            <Link to={`/company/mailer/${portal && portal.id}`} style={{ display: "block" }}>
              Edit Email Template
            </Link>
          </FormItem> */}
          <Row gutter={16}>
            <Col span={12}>
              <FormItem label="Notes">
                {getFieldDecorator("notes", {
                  initialValue: portal ? portal.notes : null,
                })(<TextArea rows={10} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="form-toolbar">
            <EditButtons deleteRights={"deletePractice"} cancelUrl="/company" loading={this.props.loading} onDelete={this.props.onDelete} onSubmit={this.handleSubmit} rights={["superAdmin", "editportal"]} />
          </div>
        </Form>
        {!this.props.loading && (
          <PromptModal isBlocked={this.props.form.isFieldsTouched()} title="Leave Page?" content="You have unsaved changes. Are you sure you want to leave this page?" {...this.props} submitAction={this.handleSubmit} loading={this.props.loading} />
        )}
      </FormWrapper>
    );
  }
}

const uploadFileMutation = gql`
  mutation uploadFile($file: Upload!, $folder: String!) {
    uploadFile(file: $file, folder: $folder) {
      ok
      response {
        s3Token
        url
      }
    }
  }
`;

const countryQuery = gql`
  query {
    getCountrylist {
      countrylist {
        id
        name
        iso2
        iso3
      }
    }
  }
`;

const stateQuety = gql`
  query($country_id: String!) {
    getStates(country_id: $country_id) {
      states {
        id
        name
        state_code
      }
    }
  }
`;

const cityQuery = gql`
  query($state_id: String!) {
    getCities(state_id: $state_id) {
      cities {
        id
        name
      }
    }
  }
`;

export default compose(
  graphql(uploadFileMutation, { name: "uploadFile" }),
  graphql(countryQuery, {
    options: (props) => ({
      fetchPolicy: "network-only",
    }),
  }),
  graphql(stateQuety, {
    name: "stateListQuery",
    options: (props) => ({
      variables: {
        country_id: props.country_id,
      },
    }),
  }),
  graphql(cityQuery, {
    name: "cityListQuery",
    options: (props) => ({
      variables: {
        state_id: props.state_id,
      },
    }),
  })
)(Form.create()(CompanyForm));
