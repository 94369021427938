import styled from 'styled-components';

const ClientPrintWrapper = styled.div`
  @media print and (min-width: 480px) {
    html, body {
      height: 29.7cm;
      width: 21cm;
    }
    footer {
      background: rgba(255,255,255,1);
      bottom: 0;
      position: fixed;
    }
    .ant-collapse {
      >.ant-collapse-item {
        >.ant-collapse-header {
          padding-left: 0!important;
        }
      }
    }
    .content-block {
      page-break-inside: avoid;
    }
    .snapshot-behavior {
      align-items: center;
      display: flex;
      width: 20%;
      &:nth-child(-n+5) {
        margin-bottom: 24px;
        margin-top: 12px;
      }
    }
  }

  .header-client-menu-wrapper{
    background:#fff;
    border-radius:4px;
    //padding:10px 16px;
    height:auto;
    margin:0 24px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    
    .header-client-left-content-data{
      max-width:350px;
      width:100%;
      display:flex;
      align-items:center;
      justify-content:space-between;
      
      .client-title{
        font-size:23px;
        font-weight:600;
        color:#006fba;
        margin-bottom:0;
      }
      .client-detail{
        font-size:14px;
        font-weight:400;
        color:#969696;
      }
      .cmn-title-text{
        a{
          font-size:14px;
          font-weight:400;
          color:#006fba;
          display:flex;
          align-items:center;
          margin-top:12px;
          margin-bottom:5px;
          text-decoration:none;
        }
      }
    }
   
  }
`;

export default ClientPrintWrapper;
