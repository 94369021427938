import styled from 'styled-components';

const LogoUploadWrapper = styled.div`
  .logo-upload-wrapper {
    .title {
        color: rgba(0,0,0,.85);
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 8px;
        font-weight: 600;
      }
    .logo-action {
      width: 200px;
      .ant-upload.ant-upload-select {
        margin-bottom: 8px;
      }
    }
    .logo-wrapper {
      margin-bottom: 8px;
      &.with-logo {
        margin-bottom: 0;
      }
      .img-upload-box-wrapper{
        display:flex;
        max-width:350px;
        width:100%;
        @media only screen and (max-width: 440px) {
          flex-direction: column;
        }
      }
      .logo-img-preview {
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        height: 70px;
        margin-bottom: 0;
        margin-right: 0;
        width: 220px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          padding: 2px;
        }
      }
    }
  }
`;

export default LogoUploadWrapper;
