import React, { useState } from "react";
import gql from "graphql-tag";
import _ from "lodash";
import { useLazyQuery } from "react-apollo";
import { Button, Dropdown, Icon, Menu, Result, Spin } from "antd";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { apiUrl } from "../../config";

const GenerateReportType = (type) => {
  if (type === "snapshot") {
    return "Snapshot";
  }

  if (type === "401k") {
    return "Retirement";
  }

  if (type == "ucita") {
    return "Advisor-UCIT";
  }
  return "UCIT";
};

const AssessmentPdf = (props) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const QUERY = gql`
    query($token: String) {
      viewClientSalesforce(token: $token) {
        firstName
        insights
        lastName
        practiceName
        dateCollected
        printDateCollected
        assessmentId
        assessmentHistory
      }
    }
  `;

  const [userList, { loading, error, data, fetchMore }] = useLazyQuery(QUERY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {},
  });

  useEffect(() => {
    if (props.match.params.token) {
      userList({ variables: { token: props.match.params.token } });
    }
  }, [props.match.params]);

  const viewClient = _.get(data, "viewClientSalesforce", []);

  const { firstName, insights, lastName, practiceName, dateCollected, printDateCollected, assessmentHistory } = viewClient;

  const downloadAssessment = async (type) => {
    setDownloadLoading(true);
    axios
      .get(`${apiUrl}/salesforce/pdf/${props.match.params.token}&${type}`, {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      })
      .then((response) => {
        setDownloadLoading(false);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        saveAs(blob, `${firstName}-${lastName}-${GenerateReportType(type)}-${printDateCollected}`);
      });
  };

  const dropdownMenuItems =
    assessmentHistory &&
    assessmentHistory.length > 1 &&
    assessmentHistory.map((insight) => {
      return (
        <Menu.Item key={`'${insight.assessmentToken}'`}>
          <a rel="noreferrer" href={`/salesforce-assessment-pdf/${insight.assessmentToken}`}>
            {moment
              .utc(insight.createdAt)
              .local()
              .format("MM-DD-YYYY")}
          </a>
        </Menu.Item>
      );
    });

  const dropdownMenu = dropdownMenuItems && <Menu defaultSelectedKeys={[`'${props.match.params.token}'`]}>{[...dropdownMenuItems]}</Menu>;

  return (
    <div style={{ marginTop: "5%" }}>
      {loading && (
        <center>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 44 }} spin />} />
        </center>
      )}
      {!loading && (
        <Result
          icon={<Icon type="file-pdf" style={{ fontSize: 44 }} />}
          title={firstName ? firstName + " " + lastName : "No Any Assessment Found"}
          subTitle={firstName ? "" : ""}
          extra={[
            firstName && (
              <div className="button-bar-wrapper has-dropdown download">
                {assessmentHistory && assessmentHistory.length > 1 && (
                  <div className="mb-2">
                    <Dropdown overlay={dropdownMenu}>
                      <Button type="primary" className="ant-dropdown-link mt-1 mt-sm-0" onClick={(e) => e.preventDefault()}>
                        Assessment History <Icon type="down" />
                      </Button>
                    </Dropdown>
                  </div>
                )}
                <Dropdown
                  overlayClassName="dropdown-download"
                  overlay={
                    <Menu onClick={(e) => downloadAssessment(e.key)}>
                      <Menu.Item disabled>For Advisor</Menu.Item>
                      <Menu.Item key="ucita">UCIT</Menu.Item>
                      <Menu.Divider />
                      <Menu.Item disabled>For Client</Menu.Item>
                      <Menu.Item key="ucitc">UCIT</Menu.Item>
                      <Menu.Item key="snapshot">Result Snapshot</Menu.Item>
                      <Menu.Item key="401k">Retirement Snapshot</Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}>
                  <Button type="primary" icon="download" loading={downloadLoading} size="large">
                    Download
                  </Button>
                </Dropdown>
              </div>
            ),
          ]}
        />
      )}
    </div>
  );
};

export default AssessmentPdf;
