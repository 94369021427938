// https://github.com/u-wave/react-vimeo
// https://stackoverflow.com/questions/37949981/call-child-method-from-parent
// https://stackoverflow.com/questions/50271093/how-to-fix-uncaught-in-promise-domexception-play-failed-because-the-user-d

import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from "react";
import Player from "@vimeo/player";

const VimeoPlayer = (props, ref) => {
  const {
    video,
    width,
    height,
    color,
    volume,
    playerDataHandler,
    autoPause = true,
    autoPlay = false,
    showByLine = true,
    controls = false,
    loop = false,
    showPortrait = false,
    showTitle = true,
    muted = true,
    background = false,
    responsive,
    dnt = false,
    speed = false,
  } = props;

  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (!video) return;
    createPlayer();
  }, [video]);

  useEffect(() => {
    if (!volume) return;
    player
      .setVolume(volume)
      .then((data) => console.log("data", data))
      .catch((err) => console.log(err));
  }, [volume]);

  useImperativeHandle(ref, () => ({
    play() {
      player.play();
    },
    pause() {
      player.pause();
    },
    getCurrentTime() {
      currentTimeHandler();
    },
    unload() {
      player.unload();
    },
  }));

  useEffect(() => {
    if (!player) return;

    player.on("ended", playerEndHandler);

    return () => {
      player.destroy().catch(function(error) {
        console.log("error", error);
      });
    };
  }, [player, video]);

  const createPlayer = () => {
    let playerObj = new Player("vimeo_player", playerOptions());
    setPlayer(playerObj);
  };

  const playerOptions = () => {
    return {
      id: video,
      autopause: autoPause,
      autoplay: autoPlay,
      byline: showByLine,
      portrait: showPortrait,
      title: showTitle,
      color,
      controls,
      loop,
      width,
      height,
      muted,
      background,
      responsive: true,
      dnt,
      speed,
    };
  };

  const currentTimeHandler = () => {
    player
      .pause()
      .then(() => {
        player
          .getCurrentTime()
          .then((seconds) => {
            playerDataHandler("currentTime", seconds);
          })
          .catch((err) => console.log("err", err));
      })
      .catch((error) => console.log(error));
  };

  const playerEndHandler = (data) => {
    // console.log("data", data);
    playerDataHandler("playerEnd", data);
  };

  return <div id="vimeo_player"></div>;
};

export default memo(forwardRef(VimeoPlayer));
