import React, { forwardRef } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function CustomInput(props, ref) {
	CustomInput.propTypes = {
		field: PropTypes.string.isRequired,
	};

	let marginBottom = props.removeMargin ? 0 : 16;

	return (
		<Div 
            style={{ marginBottom }}
        >
			{props.custom}
			
            <label htmlFor={props.field} className={'label'}>
                {props.label}
            </label>
			<Input
				ref={ref}
				autoComplete={'off'}
				{...props}
				id={props.field}
				placeholder={props.placeholder || props.label}
			/>
            {props['data-__field'].errors ? (
				<label htmlFor={props.field} className={'error'}>
					{props['data-__field'].errors[0].message}
				</label>
			) : (
				<></>
			)}
		</Div>
	);
}

export default forwardRef(CustomInput);

const Div = styled.div`
	.label {
		font-weight: bold;
		color: #222f3e;
	}

	.error {
		font-weight: bold;
		color: #d63031;
	}

	// input {
	// 	text-transform: uppercase;
	// }
`;


{/* <HISInput
				label={'Device Name'}
				{...getFieldProps('deviceName', {
					initialValue: props.selectedDevice.deviceName || '',
					rules: [
						{
							required: true,
							message: 'Device Name is required!',
						},
					],
				})}
			/> */}