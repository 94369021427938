import styled from 'styled-components';

const HeaderBarWrapper = styled.div`
  .ant-layout-header {
    align-items: center;
    background: rgba(255,255,255,1);
    border-bottom: 1px solid rgba(238,242,244,1);
    display: flex;
    padding: 0 24px;
    position: fixed;
    transition: all .2s;
    width: 100%;
    z-index: 100;
    @media only screen and (max-width: 767px) {
      padding: 0 12px;
    }
    img {
      height: 32px;
    }
    .user-menu-item {
      margin-left: auto;
    }
  }
`;

export default HeaderBarWrapper;
