import React from "react";
import { Alert, Button, Form, Input, Popover } from "antd";
import PropTypes from "prop-types";
import { FormWrapper } from "../../../components/form";
import CustomButton from "../../../components/commons/CustomButton";
import Info from "../../../assets/img/info-blue.svg";
const FormItem = Form.Item;
const { TextArea } = Input;

// const cleanPracticeId = value => value
//   .trim()
//   .replace(/[&/\\#,+()$~%.'":;@*?<>[\]{}!^`|=]/g, '')
//   .replace(/\s{2,}/g, ' ')
//   .replace(/[^a-zA-Z0-9]/g, '-')
//   .replace(/_+$/, '')
//   .toLowerCase();

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      folder: null,
      practiceIdEdited: false,
      siteLogo: this.props.company && this.props.company.siteLogo ? this.props.company.siteLogo : null,
      siteLogoS3Token: this.props.company && this.props.company.siteLogoS3Token ? this.props.company.siteLogoS3Token : null,
      clientAssessmentTitle: "Your future with us",
      clientAssessmentBody: `Hello [[CLIENT_FIRST_NAME]],\n\nAt [[PRACTICE_NAME]], we fully understand that as human beings, we each have unique values, goals and perspectives. We know that you are more than just a set of numbers based on your age, appetite for risk and economic status.\nThe assessment which you are about to take will not only help us understand your unique tolerance for risk, but it will also help us to see HOW you go about making financial decisions. This will aid us in understanding your desired communication style as well as guiding us toward portfolios that will be personalized for your goals and comfort level.\n\n[[SYS_GENERATED_LINK]]\n\nThank you in advance for taking the time to help us understand who you are as an individual. We hope you find the assessment beneficial!\n\nSincerely,\n[[ADVISOR_FIRST_NAME]][[ADVISOR_LAST_NAME]]`,
      userInviteTitle: `Subscription Confirmation & Account Summary`,
      userInviteBody: `Dear [[USER_FIRST_NAME]],\nThank you for subscribing to Unitifi!  We're thrilled to have you on board and wanted to confirm the details of your subscription as well as inform you about future plan updates!\nSubscription Details.\n- Organization Name: [[ORGANIZATION_NAME]]\n- Team Size: [[TEAM_SIZE]]\n- Billing Cycle: [[BILLING_CYCLE]]\n- Amount: [[AMOUNT]]\n- Payment Method: [[PAYMENT_METHOD]]\n- Subscription Renewal Date: [[RENEWAL_DATE]]\nPlease keep this email for your records. You can also access your subscription details and manage your account through your [[SYS_GENERATED_LINK]].\n\nTeam Subscriptions Summary\nWe understand that your team may grow over time. As a reminder, your current subscription plan includes [[TEAM_SIZE]] team members. If more team members are added beyond this current limit, your subscription will be automatically updated to reflect the additional charges and applicable volume discounts.\n\nFor each additional team member added, the subscription price will be adjusted accordingly. You will receive a separate confirmation email for each team member added, detailing the updated subscription price and the effective date of the change.\n\nIf you have any questions, concerns, or need assistance, please don't hesitate to contact our support team. We're here to help!\n\nThank you again for choosing Unitifi. We hope you find our platform valuable and enjoy the benefits it brings to your organization and clients.\n\nBest regards,\nThe Unitifi Team`,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.toggleLoading(true);

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        this.props.toggleLoading(false);
      }

      if (!err) {
        this.props.onSubmit({
          ...values,
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const addShortcut = (
      <>
        <p>Enter the website where the message recipient will be directed after completing an assessment or other company-related actions.</p>
      </>
    );
    return (
      <FormWrapper>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <h2>{this.props.company.name}</h2>
          <br />
          <h3>Client Assessment Message </h3>
          <FormItem label="Subject Line">
            {getFieldDecorator("clientAssessmentTitle", {
              initialValue: this.props.company.clientAssessmentTitle ? this.props.company.clientAssessmentTitle : this.state.clientAssessmentTitle,
            })(<Input />)}
          </FormItem>
          <FormItem label="Assessment Invite Body: (1000 character limit)" style={{ marginBottom: "0.5rem" }}>
            {getFieldDecorator("clientAssessmentBody", {
              initialValue: this.props.company.clientAssessmentBody ? this.props.company.clientAssessmentBody : this.state.clientAssessmentBody,
            })(<TextArea rows={10} />)}
          </FormItem>
          <Alert type="info" message="Params: [[CLIENT_FIRST_NAME]], [[PRACTICE_NAME]], [[SYS_GENERATED_LINK]], [[ADVISOR_FIRST_NAME]], [[ADVISOR_LAST_NAME]]" />

          <hr />
          <br />
          <h3>Subscription Confirmation Message</h3>
          <FormItem label="Subject Line">
            {getFieldDecorator("userInviteTitle", {
              initialValue: this.props.company.userInviteTitle ? this.props.company.userInviteTitle : this.state.userInviteTitle,
            })(<Input />)}
          </FormItem>
          <FormItem label="New User Message Body: (1000 character limit)" style={{ marginBottom: "0.5rem" }}>
            {getFieldDecorator("userInviteBody", {
              initialValue: this.props.company.userInviteBody ? this.props.company.userInviteBody : this.state.userInviteBody,
            })(<TextArea rows={10} />)}
          </FormItem>
          <Alert type="info" message="Params: [[USER_FIRST_NAME]], [[SYS_GENERATED_LINK]], [[ORGANIZATION_NAME]], [[TEAM_SIZE]], [[BILLING_CYCLE]], [[AMOUNT]], [[PAYMENT_METHOD]], [[RENEWAL_DATE]]" />
          
          <br />
          <div className="d-flex align-items-center">
            {this.props.userContext && this.props.userContext.account && this.props.userContext.account.permissions.includes("editportal") && (
              <Button type="primary" size="large" loading={this.props.loading} disabled={this.props.loading} onClick={this.handleSubmit}>
                Save Templates
              </Button>
            )}
            {this.props.userContext && this.props.userContext.account && this.props.userContext.account.permissions.includes("editportal") && (
              <CustomButton className="ms-3" icon="edit" rights={["editportal"]} href={`/company/edit`} text="Back to Edit Company" type={"secondary"}>
                Back to Edit Company
              </CustomButton>
            )}
          </div>
        </Form>
      </FormWrapper>
    );
  }
}

CompanyForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

CompanyForm.defaultProps = {
  practice: null,
};

export default Form.create()(CompanyForm);
// export default graphql(
//   uploadFileMutation,
//   { name: 'uploadFile' },
// )(Form.create()(CompanyForm));
