import { Button, Card, Col, Form, Icon, Layout, Result, Row, Typography } from "antd";
import gql from "graphql-tag";
import { flowRight as compose } from "lodash";
import React, { useState } from "react";
import { graphql } from "react-apollo";
import logo from "../../assets/img/unitifi.svg";
import SubscriptionStyle from "./subscriptions.style";

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const antIcon = <Icon type="loading" style={{ fontSize: 100 }} spin />;

var gutters = {};

var vgutters = {};

var colCounts = {};

[8, 16, 24, 32, 40, 48].forEach((value, i) => {
  gutters[i] = value;
});
[8, 16, 24, 32, 40, 48].forEach((value, i) => {
  vgutters[i] = value;
});
[2, 3, 4, 6, 8, 12].forEach((value, i) => {
  colCounts[i] = value;
});
const Subscriptons = (props) => {
  const {
    form,
    update,
    view: { getSubscriptions },
  } = props;
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleSubmit = async (value) => {
    setButtonLoading(true);
    // console.log(value, 'value')
    await update({
      variables: { price_id: value },
    }).then(function(result) {
      setButtonLoading(false);
      props.history.push("/paymentmethod");
    });
  };

  const [state, setState] = useState({
    gutterKey: 1,
    vgutterKey: 1,
    colCountKey: 0,
  });

  const { gutterKey, vgutterKey, colCountKey } = state;
  const cols = [];
  const colCount = colCounts[colCountKey];

  function moveDecimal(n) {
    var v = "0.00";
    if (n) {
      // var l = n.toString().length-2;
      v = n / 100;
    }

    return v;
  }

  return (
    <Layout className="layout" style={{ height: "100vh" }}>
      <Header style={{ background: "rgba(240,242,245,1)" }}>
        <div className={"logo-signup"} style={{ textAlign: "center", background: "rgba(240,242,245,1)" }}>
          <img src={logo} style={{ height: "47px" }} />
        </div>
      </Header>
      {/* <Header style={{background:'white'}}>
            <div className={'logo-signup'} style={{textAlign:'center'}}>
                <img src={logo} style={{height:'47px'}}/>
            </div>
        </Header> */}
      <Content style={{ padding: "0px 10px 0px 10px" }}>
        {!getSubscriptions && (
          <Row
            type="flex"
            justify="space-around"
            align="middle"
            //   type='flex'
            //   justify='space-around'
            //   align='middle'
            style={{ height: "100%" }}>
            <Col lg={{ span: 12 }} xs={{ span: 24 }}>
              <Row gutter={[gutters[gutterKey], vgutters[vgutterKey]]}>
                <Result icon={antIcon} title="Loading ..." />
              </Row>
            </Col>
          </Row>
        )}

        <SubscriptionStyle>
          <div className="flexs-body">
            <div className="flexs-containter">
              <div className="flexs-row" style={{ textAlign: "center" }}>
                <p className="flexs-p">Please select your subscription type.</p>
              </div>
              <div className="flexs-row">
                {getSubscriptions && (
                  <>
                    {getSubscriptions.prices.map((items, i) => {
                      const {
                        id,
                        unit_amount,
                        products: { description, name },
                      } = items || {
                        id: null,
                        unit_amount: "",
                        products: {
                          description: "",
                          name: "",
                        },
                      };
                      return (
                        <div className="flexs-card">
                          <h4>{name}</h4>
                          <p>{description}</p>
                          <h4 className="price">$ {moveDecimal(unit_amount)} </h4>

                          <button className="flexs-button" onClick={() => handleSubmit(id)}>
                            Subscribe
                          </button>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer style={{ textAlign: "center" }}>Copyright ©2021 Unitifi</Footer>
        </SubscriptionStyle>
        {/* <Row
          type='flex'
          justify='space-around'
          align='middle'
          //   type='flex'
          //   justify='space-around'
          //   align='middle'
          style={{ height: '100%' }}
        >
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Row
              gutter={[gutters[gutterKey], vgutters[vgutterKey]]}
              style={{ textAlign: 'center' }}
            >
              <Paragraph level={1}>
                Please select your subscription type
              </Paragraph>
            </Row>
            <Row gutter={[gutters[gutterKey], vgutters[vgutterKey]]}>
              {!getSubscriptions && (
                <Result icon={antIcon} title='Loading ...' />
              )}

              {getSubscriptions && (
                <>
                  {getSubscriptions.prices.map((items, i) => (
                    <Col
                      key={i.toString()}
                      lg={{ span: 24 / getSubscriptions.prices.length }}
                      md={{ span: 12 }}
                      xs={{ span: 24 }}
                    >
                      <Card title='' style={{ borderRadius: '6px' }}>
                        <Typography>
                          <Title level={3}>{items.products.name}</Title>
                          <Paragraph level={4}>
                            {items.products.description}{' '}
                          </Paragraph>
                          <Title level={4}>
                            $ {moveDecimal(items.unit_amount)}{' '}
                          </Title>
                          <Button
                            type={'primary'}
                            loading={buttonLoading}
                            onClick={() => handleSubmit(items.id)}
                          >
                            Subscribe
                          </Button>
                        </Typography>
                      </Card>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </Col>
        </Row> */}
      </Content>
    </Layout>
  );
};

const getSubscription = gql`
  query {
    getSubscriptions {
      prices {
        id
        product
        unit_amount
        unit_amount_decimal
        products {
          id
          name
          description
        }
      }
    }
  }
`;

const updateAccount = gql`
  mutation($address: String, $city: String, $email: String, $firstName: String, $lastName: String, $phoneNumber: String, $state: String, $zipCode: String, $subscriptions: String, $price_id: String) {
    editAccount(address: $address, city: $city, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, state: $state, zipCode: $zipCode, subscriptions: $subscriptions, price_id: $price_id) {
      ok
    }
  }
`;
export default compose(
  graphql(updateAccount, {
    name: "update",
  }),
  graphql(getSubscription, {
    name: "view",
  })
)(Form.create()(Subscriptons));
