import styled from 'styled-components';

const ClientPrintWrapper = styled.div`
  @media print and (min-width: 480px) {
    html, body {
      height: 29.7cm;
      width: 21cm;
    }
    footer {
      background: rgba(255,255,255,1);
      bottom: 0;
      position: fixed;
    }
    .ant-collapse {
      >.ant-collapse-item {
        >.ant-collapse-header {
          padding-left: 0!important;
        }
      }
    }
    .content-block {
      page-break-inside: avoid;
    }
    .snapshot-behavior {
      align-items: center;
      display: flex;
      width: 20%;
      &:nth-child(-n+5) {
        margin-bottom: 24px;
        margin-top: 12px;
      }
    }
  }
`;

export default ClientPrintWrapper;
