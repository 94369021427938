import React from 'react';
import gql from 'graphql-tag';
import {  graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import { Icon, Modal, notification, Spin } from 'antd';
import { Redirect } from 'react-router';
import { Titlebar } from '../../../components';
import LayoutContent from '../../../layouts/app/layoutContent';
import LayoutContentWrapper from '../../../layouts/app/layoutWrapper';
import ClientForm from '../form';

const { confirm } = Modal;

class ClientEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelUrl: this.props.location.state && this.props.location.state.redirect
        ? this.props.location.state.redirect
        : `/${this.props.currentPractice}/clients`,
      loading: false,
      redirectTo: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
    this.removeClient = this.removeClient.bind(this);
  }

  handleSubmit(values) {
    this.setState({
      loading: true,
    });

    const variables = {
      ...values,
      id: this.props.data.getClient.id,
      primaryContactPhoneNumber: `${values.p1}${values.p2}${values.p3}`,
      // only add practiceId arg when updating advisor association
      practiceId: values.advisorId ? this.props.currentPractice : null,
    };

    this.props.editClient({ variables })
      .then(({ data }) => {
        if (data.editClient.ok && !data.editClient.errors) {
          this.handleSuccess(`${data.editClient.client.firstName} ${data.editClient.client.lastName} has been successfully updated.`);
          return;
        }

        this.handleError(data.editClient.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleDeleteConfirm() {
    confirm({
      title: 'Delete Client',
      content: `Are you sure you want to permanently delete ${this.props.data.getClient.firstName} ${this.props.data.getClient.lastName}? This action cannot be undone.`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'Cancel',
      icon: 'close-circle',
      onOk: this.removeClient,
      width: '680px',
    });
  }

  removeClient() {
    this.props.deleteClient({ variables: { id: this.props.data.getClient.id } })
      .then(({ data }) => {
        if (data.deleteClient.ok && !data.deleteClient.errors) {
          this.handleSuccess(`${this.props.data.getClient.firstName} ${this.props.data.getClient.lastName} has been successfully deleted.`, 'deleted');
          return;
        }

        this.handleError(data.deleteClient.errors);
      }).catch((error) => {
        console.log(error);
      });
  }

  handleSuccess(description, action) {
    notification.success({
      message: action === 'deleted' ? 'Client Deleted Successfully' : 'Client Updated Successfully',
      //description,
    });

    this.setState({ redirectTo: `/${this.props.currentPractice}/clients` });
  }

  handleError(errors) {
    this.setState({ loading: false });

    errors.forEach((err) => {
      notification.error({
        message: 'Error',
        description: err.message,
      });
    });
  }

  render() {
    const { data: { loading, error, getClient } } = this.props;

    if (loading) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Edit Client"
          />
          <div className="loading-wrapper">
            <Spin indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />} />
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div>
          <Titlebar
            currentPractice={this.props.currentPractice}
            displayText="Edit Client"
          />
          <div className="loading-wrapper">
            <p>Whoopsie - client didn&apos;t load.</p>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Titlebar
          currentPractice={this.props.currentPractice}
          displayText="Edit Client"
        />
        <LayoutContentWrapper>
          <LayoutContent>
            <ClientForm
              cancelUrl={this.state.cancelUrl}
              client={getClient}
              currentPractice={this.props.currentPractice}
              gblUserRole={this.props.gblUserRole}
              loading={this.state.loading}
              onDelete={this.handleDeleteConfirm}
              onSubmit={this.handleSubmit}
            />
            {this.state.redirectTo !== null && (
              <Redirect to={this.state.redirectTo} />
            )}
          </LayoutContent>
        </LayoutContentWrapper>
      </div>
    );
  }
}

const getClientQuery = gql`
  query($id: Int!) {
    getClient(id: $id) {
      id
      address
      advisorId
      city
      email
      firstName
      lastName
      practiceId
      phoneNumber
      state
      zipCode
    }
  }
`;

const editClientMutation = gql`
  mutation editClient(
    $id: Int!
    $address: String
    $advisorId: Int
    $city: String
    $email: String!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $practiceId: String
    $state: String
    $zipCode: String
  ) {
    editClient(
      id: $id
      address: $address
      advisorId: $advisorId
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      practiceId: $practiceId
      state: $state
      zipCode: $zipCode
    ) {
      client {
        firstName
        lastName
      }
      ok
      errors {
        message
        path
      }
    }
  }
`;

const deleteClientMutation = gql`
  mutation deleteClient($id: Int!) {
    deleteClient(id: $id) {
      ok
      errors {
        message
        path
      }
    }
  }
`;

export default compose(
  graphql(getClientQuery, {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        id: Number(props.match.params.id),
      },
    }),
  }),
  graphql(editClientMutation, { name: 'editClient' }),
  graphql(deleteClientMutation, { name: 'deleteClient' }),
)(ClientEdit);
