import { camelCase, dropRight, tail } from 'lodash';

/* returns title used for titlebar component */
const DefaultTitle = (pathName) => {
  const split = tail(pathName.split('/'));

  if (split.length === 1) {
    return `titlebar.${split}`;
  }

  if (split.length === 2) {
    return `titlebar.${camelCase(split)}`;
  }

  if (split.length === 3) {
    return `titlebar.${camelCase(dropRight(split))}`;
  }

  return `titlebar.${camelCase(tail(dropRight(split)))}`;
};

export default DefaultTitle;
