export const clientMapData = [
  {
    id: 480,
    dateCollected: "Jul 05 2023",
    firstName: "july",
    insights: {
      score: {
        plScore: 17,
        riskBehaviorScore: 17,
        selfReportedScore: 100,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "IRA",
      },
      retirement: {
        birthYear: 1932,
        retirementAge: 43,
        retirementYear: 1975,
      },
      tendencies: [
        {
          id: 1,
          name: "Affixed",
          type: "Strongly Identified",
          results: [
            "Data and information are used to make decisions, even when it is incomplete or out of date.",
            "Price of an asset is established by the investor based on their personal opinion of what they believe the asset is worth.",
            "Often, but not always, the view of an investment's worth is based on the original purchase price of the asset.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Affixed.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Affixed.png",
          characteristics: [
            "Has difficulty changing course or thoughts about a position even when new information is given.",
            "Uses return levels to predict the percentage an asset may rise or fall.",
            "May become focused on the economic states of certain companies or countries rather than looking at the overall market.",
            "Has a hard time accepting how things fluctuate in the industry.",
            "Uses information available to them to interpret how a company is doing and base their estimates on just that instead of all information available.",
            "Values an asset based on a past point in time rather than how the value may change in the future.",
          ],
          recommendations: [
            "Use the analytics behind investment options to educate the investor on a more realistic value of the asset.",
            "Use logical research to support your course of action.",
            "Urge the investor to focus more on the current state of the investment and future trends instead of what has taken place in the past.",
            "Build in stop losses to assist the investor when decisions need to be made that contradict how they think about an asset's value.",
            "Changing the mind of an investor with Affixed tendencies is difficult. Be prepared that this may be long process that requires sound research and information.",
          ],
        },
        {
          id: 2,
          name: "Loss Avoidance",
          type: "Strongly Identified",
          results: ["A tendency that is based on emotion or passion.", "A deep and painful dislike of losing money.", "Avoiding loss is more of a focus than gaining money.", "An expectation to see returns that are twice what was invested."],
          blue_icon: "https://media.unitifi.com/site-icons/blue/loss_advantage.png",
          white_icon: "https://media.unitifi.com/site-icons/white/loss_advantage.png",
          characteristics: [
            "Holds onto losing investments too long with the hope that the loss will be regained.",
            "Trades too much due to a fear that profits will disappear.",
            "Believes that losses are not real until the investment is cashed in.",
            "When loss does occur, investments are held too long and possibly create an imbalanced portfolio.",
          ],
          recommendations: [
            "Create price protection points to avoid decisions based on an emotional reactions.",
            "Set rules or boundaries that take the emotion out the decision making process.",
            "Realize that these investors may react emotionally.",
            "Supply information that helps neutralize reactions that are based on feelings or passion.",
            "Take advantage of tax harvesting to offset the losses in one investment with the gains in another.",
            "Have a logical focus but involve emotion to influence the results you want.",
            "Explain the logical decisions you are suggesting through passionate examples and terms.",
          ],
        },
        {
          id: 11,
          name: "Readiness",
          type: "Strongly Identified",
          results: [
            "An investment tendency that relies on logic.",
            "Tries to understand complicated investment decisions by relating them to areas that are familiar or pertain to their own lives.",
            "Estimates how likely something is to happen based on previous life experiences.",
            "Believes outcomes that they can more easily imagine are that outcomes that are more likely to happen.",
            "Relies on limited or narrow experiences to make investment decisions.",
            "Can be influenced by situations that are similar to their own personal situations.",
            "Outcomes that are more easily remembered or understood are seen as being more dependable.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Readiness.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Readiness.png",
          characteristics: [
            "Tries to make things easier to understand by relating things they are comfortable with or that have meaning in their personal lives.",
            "Avoids getting involved in the research and due diligence process of investing.",
            "Will make investment decisions based on what is at the forefront of their mind.",
            "Investment decisions are made based on the life experiences of the investor(such as the industry in which they work, the area they live in, or the people they know).",
            "Uses guessing or illogical reasoning to justify their decisions.",
            "Potentially ignores investments because they cannot relate to them on a personal level.",
            "Mentally overemphasizes current events and trends.",
            "Gravitates towards investments they work in or around.",
          ],
          recommendations: [
            "Use knowledge of their personal lives to help them understand and process current investment decisions.",
            "Understand that they won't do their own due diligence and that this is an aspect that you must help them consider and value.",
            "Help steer them towards sounds investments rather than just what is comfortable.",
            "Allow Readiness investors time to think about your guidance and their decision before taking action.",
            "Ensure that complex positions or advice is explained as simply as possible.",
            "They may become easily overwhelmed with large amounts of information. Take things slowly, give explanations, and allow them time to process and ask questions.",
          ],
        },
      ],
      personality: {
        type: "Protector",
        behavior: "Conservative",
        attributes: {
          advisorTips: [
            "Take time to provide the big-picture descriptions of potential investments.",
            "Find ways to explain how the investments will deliver desired results using personal issues important to the Protector.",
            "Build trust through professionalism, expertise, and objectivity.",
          ],
          investorBehaviors: [
            "A preference to guard their wealth",
            "Taking any losses very seriously",
            "Careful and cautious decision making",
            "Difficulty taking action concerning their investments",
            "Worry and fear that wrong investment decisions will be made",
            "Purposeful avoidance of investments or actions that may be viewed as risky",
            "An over concern about the short-term performance of investments in both up and down markets but especially in down markets",
            "Concern with losing what had previously been gained",
            "Deliberate and cautious approach to both work and personal life",
            "Desire or need to take care of their current family as well as future generations",
            "Tendency to make decisions based on how they are feeling rather than using facts or logic",
            "Having gained their wealth by not risking their own capital but more often through inheritance, long term work, or employment in a large corporation",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "seven",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 479,
    dateCollected: "Jul 05 2023",
    firstName: "temp",
    insights: {
      score: {
        plScore: 19,
        riskBehaviorScore: 12,
        selfReportedScore: 100,
      },
      profile: {
        communicationExpectation: "Daily",
        currentRetirementVehicle: "401(k) at work",
      },
      retirement: {
        birthYear: 1954,
        retirementAge: 42,
        retirementYear: 1996,
      },
      tendencies: [
        {
          id: 1,
          name: "Affixed",
          type: "Strongly Identified",
          results: [
            "Data and information are used to make decisions, even when it is incomplete or out of date.",
            "Price of an asset is established by the investor based on their personal opinion of what they believe the asset is worth.",
            "Often, but not always, the view of an investment's worth is based on the original purchase price of the asset.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Affixed.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Affixed.png",
          characteristics: [
            "Has difficulty changing course or thoughts about a position even when new information is given.",
            "Uses return levels to predict the percentage an asset may rise or fall.",
            "May become focused on the economic states of certain companies or countries rather than looking at the overall market.",
            "Has a hard time accepting how things fluctuate in the industry.",
            "Uses information available to them to interpret how a company is doing and base their estimates on just that instead of all information available.",
            "Values an asset based on a past point in time rather than how the value may change in the future.",
          ],
          recommendations: [
            "Use the analytics behind investment options to educate the investor on a more realistic value of the asset.",
            "Use logical research to support your course of action.",
            "Urge the investor to focus more on the current state of the investment and future trends instead of what has taken place in the past.",
            "Build in stop losses to assist the investor when decisions need to be made that contradict how they think about an asset's value.",
            "Changing the mind of an investor with Affixed tendencies is difficult. Be prepared that this may be long process that requires sound research and information.",
          ],
        },
        {
          id: 3,
          name: "Devoted",
          type: "Strongly Identified",
          results: [
            "A tendency that centers on passionate and emotional behaviors.",
            "Places a higher value on investments they own simply because of their personal involvement with them.",
            "A way of thinking where owning an investment gives it some higher value.",
            "Often found where there is inter-generational ownership of an investment or where purchases are made based on family relationships.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Decoted.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Decoted.png",
          characteristics: [
            "When investments are inherited, they are held longer because of the family / personal connection.",
            "Likely has a lowest selling price that is larger than the maximum purchase price they would be willing to pay for the same investment.",
            "Holds investments too long to avoid transaction fees that are part of the selling process.",
            "Attached to investments because of a personal or family connection.",
            "Believes that investments passed to them from previous generations or that they have a strong emotional connection to are more valuable than they really are.",
            "Holds investments too long because it is familiar and brings a sense of comfort, causing it to be seen as having more value than it actually carries.",
          ],
          recommendations: [
            "Realize that the investor is making decisions using emotions more than logic.",
            'Ask targeted questions (such as "What if this happens?") to assist the investor in relinquishing concentrated positions.',
            "Use emotional arguments or points about what is happening in the market or industry.",
            "Present passionate questions to the investor with the intent of causing movement with the investment.",
            "Help the investor understand the evolution of the times, industries, and investments.",
          ],
        },
        {
          id: 5,
          name: "Cognitive Insight",
          type: "Strongly Identified",
          results: [
            "Behaviors are based on logic and information processing.",
            "Places sums of money in different mental groups or categories (i.e. college fund, play money, mortgage funds, etc.) and treats these groupings differently.",
            "Has a personally logical way that they have categorized their money.",
            "Makes financial decisions independently and differently according to their mental groupings.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Cognitive_insight.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Cognitive_insight.png",
          characteristics: [
            "Categorizes money into different groupings.",
            "Holds separate accounts to match up with financial goals they have mentally constructed for themselves.",
            "May hold imbalanced investment portfolios because they view the portfolio as distinct pieces rather than a cohesive group.",
            "Often views returns that come from income and those that come from capital appreciation as two very different things.",
            "Hesitant to sell investments that were profitable in the past but are not currently beneficial.",
            "Money that is matched through employment or other means(such as a retirement match) has a lower value to Cognitive Insight investors.",
          ],
          recommendations: [
            "Figuring out the logic or the way that the investor has grouped their money is key to guiding their investments. You must work to understand what their groupings and goals mean to them.",
            "Adjust your approach based on which piece of their investment portfolio is being discussed.",
            "Help them to shift their focus from income areas where principal may be inadvertently lost.",
            "They will react to different amounts of loss based on which mental grouping is experiencing the loss. Higher or lower levels of loss will be tolerated according to how the investment is perceived in their mind.",
            "Reinforce that a loss in an investment is a loss no matter how they personally view the investment.",
            "When investments overlap, be mindful of how they can impact each other.",
            "Look for ways to help the investor view their portfolio components from a big picture perspective.",
          ],
        },
        {
          id: 28,
          name: "Diagnostic",
          type: "Strongly Identified",
          results: [
            "Decisions are made using logic and information.",
            "Invests in positions because of a belief that they will perform well rather than a belief that it reflects who they are as a person.",
            "Does not let personal values influence investment decisions.",
            "The perceived status of an investment option does not influence this type of investor.",
            "Moral investing is not typically a concern.",
            "Realizes that investments do not reflect who they are as a person; instead, it reflects how they have put their money to work for them.",
          ],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Diagnostic.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Diagnostic.png",
          characteristics: [
            "Willing to invest in positions or areas regardless of their own personal belief or world view.",
            "Is more concerned with gaining money than any perceived status that may come with holding a particular investment.",
            "Sees the value in making investment decisions using research rather than personal views.",
            "Keeps personal beliefs and investing separate.",
            "Not overly concerned with morally investing.",
          ],
          recommendations: [
            "Use data and logic to help guide investment decisions.",
            "Provide sound recommendations regardless of moral issues.",
            "Know that increasing the value of the portfolio is the key over personal beliefs or judgements.",
            "Keep an open mind with this investor.",
            "Center discussions on the big picture more than individual positions or statistics.",
          ],
        },
        {
          id: 9,
          name: "Conclusion",
          type: "Strongly Identified",
          results: ["A logical tendency.", "Focus on the end result of an investment rather than exploring what created that results.", "Uses information to process events but in a limited manner."],
          blue_icon: "https://media.unitifi.com/site-icons/blue/Conclusion.png",
          white_icon: "https://media.unitifi.com/site-icons/white/Conclusion.png",
          characteristics: [
            "Attracted to investment options because of what others are doing.",
            "Focuses on individual positions or groups of assets without really understanding the associated risks.",
            "Undervalues the process used by financial professionals to follow investment guidelines and avoid undue risk.",
            "Overvalues investments based on recent or strong performance, even when position prices are high.",
            "Looks at current performance and not the research behind what would keep a portfolio sound.",
            "Takes things at face value and ignores what may be happening behind the scenes.",
          ],
          recommendations: [
            "Provide current data and research to connect with the logical side of this investor.",
            "Look at what the client has done in the past and their past results to guide your work with them moving forward.",
            "Encourage the use of a bigger picture viewpoint when financial decisions are made.",
            "Discuss the events and rationale behind specific position outcomes in a direct manner.",
            "Debrief with the investor after decisions have been made to help illustrate how outcomes were achieved.",
          ],
        },
      ],
      personality: {
        type: "Protector",
        behavior: "Conservative",
        attributes: {
          advisorTips: [
            "Take time to provide the big-picture descriptions of potential investments.",
            "Find ways to explain how the investments will deliver desired results using personal issues important to the Protector.",
            "Build trust through professionalism, expertise, and objectivity.",
          ],
          investorBehaviors: [
            "A preference to guard their wealth",
            "Taking any losses very seriously",
            "Careful and cautious decision making",
            "Difficulty taking action concerning their investments",
            "Worry and fear that wrong investment decisions will be made",
            "Purposeful avoidance of investments or actions that may be viewed as risky",
            "An over concern about the short-term performance of investments in both up and down markets but especially in down markets",
            "Concern with losing what had previously been gained",
            "Deliberate and cautious approach to both work and personal life",
            "Desire or need to take care of their current family as well as future generations",
            "Tendency to make decisions based on how they are feeling rather than using facts or logic",
            "Having gained their wealth by not risking their own capital but more often through inheritance, long term work, or employment in a large corporation",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "client",
    practice: "Admin Practice",
    practiceName: "64-null",
    __typename: "Client",
  },
  {
    id: 477,
    dateCollected: "Jul 04 2023",
    firstName: "kid",
    insights: {
      score: {
        plScore: 0,
        riskBehaviorScore: 78,
        selfReportedScore: 100,
      },
      profile: {
        communicationExpectation: "Daily",
        currentRetirementVehicle: "401(k) at work",
      },
      retirement: {
        birthYear: 1931,
        retirementAge: 41,
        retirementYear: 1972,
      },
      tendencies: [],
      personality: {
        type: "Energizer",
        behavior: "Moderately Aggressive",
        attributes: {
          advisorTips: [
            "Building a relationship with an Energizer may be difficult as they tend to trust their own way of thinking more than that of a financial professional.",
            "Financial recommendations must be supported by data and research.",
            "Strong, clear, and direct communication materials work best when advising an Energizer.",
          ],
          investorBehaviors: [
            "Researching investment options on their own as they enjoy the investment process",
            "Having previously risked their own capital to gain wealth",
            "Success in controlling the outcomes of activities outside of their investments and belief this can also be done with their investments (especially at high wealth levels)",
            "A preference for digging into the details of an investment",
            "A tendency to trade too much when not being advised",
            "A true desire for the thrill of a good investment choice",
            "Being passionate about their investment choices",
            "Optimism that their choices will do well even when the expectation is irrational",
            "Over-confidence in their ability to make independent investment decisions",
            "An ability to look past the potential random and unknown risks that are naturally part of financial investing",
            "High discomfort when money is lost or an investment does not perform as expected",
          ],
        },
        decisionMakingStyle: "Balanced",
      },
    },
    lastName: "div",
    practice: "Admin Practice",
    practiceName: "64-null",
    __typename: "Client",
  },
  {
    id: 409,
    dateCollected: "Jan 06 2023",
    firstName: "Spencer",
    insights: {
      score: {
        plScore: 23,
        riskBehaviorScore: 65,
        selfReportedScore: 59,
      },
      profile: {
        communicationExpectation: "Daily",
        currentRetirementVehicle: "Other Retirement Account",
      },
      retirement: {
        birthYear: 1981,
        retirementAge: 70,
        retirementYear: 2051,
      },
      tendencies: [
        {
          id: 6,
          name: "Discretion",
          type: "Strongly Identified",
          results: [
            "A tendency based on passion and emotion.",
            "Strays from their long-term financial goals because of a tendency to react to impulse buys/sells based emotion and desires.",
            "Persuaded easily by people and events around them.",
            "Lacks self-discipline to stick to a plan that has been created.",
          ],
          characteristics: [
            "Acts impulsively and have no difficulty changing course when passion or fear is introduced.",
            "Carries a mentality that it is better to spend today than to save for tomorrow.",
            "Fails to plan for retirement.",
            "Makes financial decisions that carry more risk than appropriate in order to make up for not investing earlier.",
            "Favors certain types of investments over others resulting in an unbalanced portfolio.",
            "Often unfamiliar with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist in generating long - term wealth.",
          ],
          recommendations: [
            "Explain how sacrificing a little bit in their daily life now can create large rewards later.",
            "Create checks and balances so that impulsive behaviors are minimized. For example, set the expectation that a conversation will be held before the investor acts on a financial decision.",
            "Appeal to the emotional side of this type of investor by relating the logical elements of investment decisions to their personal lives.",
            "Find ways to clearly show the advantages of using a financial professional rather than being left to make decisions on their own.",
            "Use hindsight and the results of their actions to help guide current decision making.",
          ],
        },
        {
          id: 16,
          name: "Dissatisfaction",
          type: "Strongly Identified",
          results: [
            "A tendency that uses passion and emotion.",
            "Investors who have lost money in the past and do not want to experience such loss again.",
            "Reluctancy to enter into markets that have experienced declines but are positioned to improve.",
            "Takes purposeful steps to avoid the emotional pain they experience from poor decision making.",
            "Has a conservative outlook with current investment choices because of past losses.",
            "Carries a fatalistic attitude, or a perspective that poor outcomes are unavoidable.",
          ],
          characteristics: [
            "Holds positions too long in order to avoid making a decision they may regret later.",
            "Hesitates to take action even during times that require aggressive steps.",
            "Uses their dissatisfaction with past investments to influence their perspective with current investments.",
            "Likes to invest in things that are high profile.",
            'Displays "group think" behavior when positions that are being selected by others are perceived as preferable.',
            "Holds growing positions too long.",
          ],
          recommendations: [
            "Provide education that connects with their emotional decision making process. For example, discuss potential lifestyle changes if action is not taken.",
            "Use their past poor decisions to walk them through the process of how sound investment decisions should be made.",
            "Demonstrate logical decision making by clearly talking through both the pros and cons of an investment.",
            "Redirect unrealistic concerns or desires to hold a position to the big picture situation.",
            "Clear statistics can be beneficial in the decision-making process.",
          ],
        },
        {
          id: 29,
          name: "Inspective",
          type: "Strongly Identified",
          results: [
            "Aims to understand what created the end result of an investment.",
            "Relies heavily on information and research to guide the investment process.",
            "Has an open mind to positions.",
            "Prefers a logically diverse portfolio.",
            "Is not concerned with fads or in-style positions.",
          ],
          characteristics: [
            "Are unconcerned with how others are investing.",
            "Tries to understand the risks associated with individual positions or groups of assets.",
            "Place value on the process used by financial professionals to follow investment guidelines.",
            "Uses research to keep a portfolio sound.",
            "Does not take things at face value and pays attention to what may be happening behind the scenes.",
            "Sees the difference between short term and long term value of an investment.",
            "Does not become overly excited with the short - term performance of an investment.",
            "Likes to know the logic behind the movement(both up and down) of a position.",
          ],
          recommendations: [
            "Supply the investor with information and research as they will use this to inform their bigger picture viewpoint.",
            "Use events and rationale behind specific position outcomes.",
            "Do not focus on trendy or in-style investments.",
            "Show long term plan versus short term movements of positions.",
            "Show value of the entire portfolio not individual position movement.",
            "Present reasons why positions and the portfolio is moving up or down.",
            "Using logic is important in communication and assisting with the decision-making process.",
          ],
        },
        {
          id: 18,
          name: "Reflection",
          type: "Identified",
          results: [
            "A logic oriented tendency.",
            "The investor thinks that predicting the market is possible.",
            "Views themselves as having a special ability to predict market changes.",
            "Uses hindsight to demonstrate their ability to foresee how the market behaved.",
          ],
          characteristics: [
            "Assumes too much risk because they believe they can predict the future.",
            "Keeps putting money into unsuccessful positions because they believe the outcome will be different.",
            "Adds more money to losing positions in an attempt to show that their past decisions were correct.",
            "Makes decisions based on a feeling rather than on facts or research.",
            "Are able to change their perception of history, especially when they have made poor investment decisions, in order to shift blame and avoid embarrassment.",
            "Gives too much credit to advisors when portfolios perform well.Similarly, gives too much fault to advisors when portfolios perform poorly.",
          ],
          recommendations: [
            "Use story telling of actual events to show how the market cannot be predicted.",
            "Help them to temper their reactions to both gains and losses in the market.",
            "Discuss market cycles and provide concrete examples.",
            "Encourage the investor to honestly self-reflect on the both good and bad investment outcomes.",
            "Education materials provided to this type of client will resonate well because of their logical nature.",
            "Take a gentle approach in discussing the impossibility of making market predictions.",
          ],
        },
        {
          id: 23,
          name: "Impersonal",
          type: "Identified",
          results: [
            "Views investments equally and does not become personally attached to assets they have held.",
            "Tends to view investments objectively rather than relying on familiarity.",
            "Values logic rather than passion for decisions and communication.",
            "If investments are inherited, their value is based on logic rather than emotion or sentiment.",
          ],
          characteristics: [
            "Have minimal personal or sentimental attachment to investments.",
            "Views transaction fees as a normal part of the investment process.",
            "Values inherited or family - related investments the same as other investment types.",
            "Willing to make changes to and part with an investment regardless of how long it has been held.",
            "Sees the value of an investment on its own merits rather than sentimental value.",
          ],
          recommendations: [
            "Uses logic more than emotion to make decisions.",
            "Research and evidence is more influential than loyalty to past or current investments.",
            "Realize emotional ties to inherited positions are likely minimal.",
            "Understand that investments are held based on logic rather than family or belief ties.",
            "Use rational explanations of transaction fees and talk through the allocation process.",
            "Know the client values a logical approach to positions held.",
          ],
        },
        {
          id: 31,
          name: "Progressive",
          type: "Identified",
          results: [
            "Is comfortable making complicated investment decisions even in areas where they have little experience.",
            "Is aware that outcomes may take place that are outside of what they could have ever imagined.",
            "Looks for confirmation outside of their own experiences when making investment decisions.",
            "Are not influenced by just their own personal life situation.",
            "Does not need personal experience in an area to feel comfortable making a decision.",
            "Able to see things at face value.",
            "Often learns things quickly and can keep up with rapidly changing information.",
            "Keeps personal biases out of the decision-making process.",
          ],
          characteristics: [
            "Prefers to be involved in the research and due diligence process of investing.",
            "Will make investment decisions based on elements and ideas they have been considering over time.",
            "Are open to learning about new industries, places, and people.",
            "Use data and facts to justify their decisions.",
            "Are not moved by current fads or trends in the market.",
            "Sees the value of investments that involve new industries or ideas.",
            "Open to learning new things and aim to benefit from new information.",
            "Does not need a personal knowledge of a position to be involved with an investment opportunity.",
          ],
          recommendations: [
            "Look for support that goes beyond their personal lives and experiences to help them understand and process current investment decisions.",
            "Anticipate that they may have researched an investment prior to and beyond the information you offer.",
            "Be aware that the investor may appreciate more advanced or detailed information about potential investments.",
            "Be prepared to answer questions that are more complex in nature.",
          ],
        },
        {
          id: 19,
          name: "Conviction",
          type: "Identified",
          results: [
            "Behaviors are centered on logic and reasoning.",
            "Experiences discomfort when different ideas are presented that conflict with their previously held beliefs.",
            "Resists changing their mind even when new information is made available.",
          ],
          characteristics: [
            "Has difficulty admitting when they have made a mistake.",
            "Holds positions longer than they should because selling is viewed as admitting defeat.",
            "Believes things will work out differently next time.",
            "Tends to value gut instincts more than facts or research.",
            "Tries to stay positive about the decisions they have made even when there is no cause.",
          ],
          recommendations: [
            "Connect data and research to the beliefs held by the investor.",
            "Keep in regular communication to reassure the investor on decisions that have been made.",
            "State all the facts available through research and documents when these are shared with the investor.",
            "Talk about both the positive and negative feelings being experienced by the investor.",
            "Communicate in a direct manner when action needs to be taken or a change needs to be made.",
            "Discuss how it is okay to be wrong and that this is a normal part of the investment process.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 4,
          name: "Consistency",
          type: "Identified",
          results: [
            "A tendency where behaviors are driven by passion.",
            "A desire for things to stay mostly the same.",
            "Hesitates to try new things or depart from what has been done in the past.",
            "Places a high value on safety and consistency when considering an investment option.",
          ],
          characteristics: [
            "Looks for investments that are safe and carry little change.",
            "Prefers investments that guarantee a sense of safety.",
            "Takes little to no action which may result in holding investments that are too cautious or, based on changing times, too risky.",
            "Holds investments too long to avoid transactions fees that are part of the selling process.",
          ],
          recommendations: [
            "Be prepared that changing the mind of a Consistency investor is difficult. Sometimes, no matter your argument is, they will resist change.",
            "Work to understand the rationale for why the investor holds the positions that they do. Use these personal and emotional connections to steer their decision making.",
            "Educate the investor on why you are giving certain pieces of guidance using both their passionate side (personal reasons) and their logical side.",
            "Discuss the lifestyle and financial goal changes that they could experience due to the type of investments they are making.",
          ],
        },
      ],
      personality: {
        type: "Liberator",
        behavior: "Moderately Aggressive",
        attributes: {
          advisorTips: [
            "Some Liberators may resist a rigid financial plan.",
            "Offer financial advice in a way that is mindful and respectful of their independent views.",
            "Engage in regular educational discussions as they will strengthen the relationship with a Liberator.",
            "Support any recommendations given with data.",
            "Clear, direct material and communication is preferred by most Liberators.",
          ],
          investorBehaviors: [
            "Having previously risked their own capital to gain wealth and are willing to do so in the future",
            "Preferring to take an active role in researching potential investments",
            "An expectation that there is a due diligence process for all investments being considered",
            "Keeping up to date on what is happening in financial markets",
            "A medium to high tolerance for risk with their financial investments",
            "Strong-willed and independent thinkers",
            "Looking at things logically and critically (rather than passionately)",
            "Making decisions using sound judgement and their own gut instincts",
            "Preferring to use their own research on an investment",
            "A dislike of information that is contrary to what they have learned or researched on their own",
            "Becoming overly involved with trying to beat the market with a result of holding concentrated positions",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "Woolley",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 408,
    dateCollected: "Dec 22 2022",
    firstName: "Russ",
    insights: {
      score: {
        plScore: -71,
        riskBehaviorScore: 85,
        selfReportedScore: 74,
      },
      profile: {
        communicationExpectation: "Quarterly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1959,
        retirementAge: 65,
        retirementYear: 2024,
      },
      tendencies: [
        {
          id: 6,
          name: "Discretion",
          type: "Strongly Identified",
          results: [
            "A tendency based on passion and emotion.",
            "Strays from their long-term financial goals because of a tendency to react to impulse buys/sells based emotion and desires.",
            "Persuaded easily by people and events around them.",
            "Lacks self-discipline to stick to a plan that has been created.",
          ],
          characteristics: [
            "Acts impulsively and have no difficulty changing course when passion or fear is introduced.",
            "Carries a mentality that it is better to spend today than to save for tomorrow.",
            "Fails to plan for retirement.",
            "Makes financial decisions that carry more risk than appropriate in order to make up for not investing earlier.",
            "Favors certain types of investments over others resulting in an unbalanced portfolio.",
            "Often unfamiliar with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist in generating long - term wealth.",
          ],
          recommendations: [
            "Explain how sacrificing a little bit in their daily life now can create large rewards later.",
            "Create checks and balances so that impulsive behaviors are minimized. For example, set the expectation that a conversation will be held before the investor acts on a financial decision.",
            "Appeal to the emotional side of this type of investor by relating the logical elements of investment decisions to their personal lives.",
            "Find ways to clearly show the advantages of using a financial professional rather than being left to make decisions on their own.",
            "Use hindsight and the results of their actions to help guide current decision making.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Strongly Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 25,
          name: "Free-Thinking",
          type: "Strongly Identified",
          results: [
            "Behaviors are based on feelings and emotion.",
            "Views all money to be used for investing as one lump sum rather than according to specific groupings.",
            "Factors that influence decisions are not entirely grounded in data and logic.",
            "Does not place sums of money in specific investment groups or categories based on goals for future.",
            "Values overall portfolio value rather than specific investment performance.",
            "Decisions are made based on the entire portfolio rather than specific positions.",
          ],
          characteristics: [
            "Views all investments as one unified goal.",
            "Does not group or categorize investments into specific goals.",
            "Views returns that come from income and those that come from capital appreciation similarly.",
            "See the benefit in selling investments that were profitable in the past but not currently beneficial.",
            "Different types of income are all viewed equally and valued the same.",
            "Considers employer match and personal investments as having the same value and not as separate types of money.",
            "Sees the value of a balanced portfolio and a cohesive group of investments.",
          ],
          recommendations: [
            "Know that decisions are based on the entire portfolio and not specific investments.",
            "Look at the entire investment package, including employer match and external investments, as one entire allocation.",
            "Do not use bucket method for investment advice.",
            "Use the entire household portfolio to discuss future decisions.",
          ],
        },
        {
          id: 19,
          name: "Conviction",
          type: "Identified",
          results: [
            "Behaviors are centered on logic and reasoning.",
            "Experiences discomfort when different ideas are presented that conflict with their previously held beliefs.",
            "Resists changing their mind even when new information is made available.",
          ],
          characteristics: [
            "Has difficulty admitting when they have made a mistake.",
            "Holds positions longer than they should because selling is viewed as admitting defeat.",
            "Believes things will work out differently next time.",
            "Tends to value gut instincts more than facts or research.",
            "Tries to stay positive about the decisions they have made even when there is no cause.",
          ],
          recommendations: [
            "Connect data and research to the beliefs held by the investor.",
            "Keep in regular communication to reassure the investor on decisions that have been made.",
            "State all the facts available through research and documents when these are shared with the investor.",
            "Talk about both the positive and negative feelings being experienced by the investor.",
            "Communicate in a direct manner when action needs to be taken or a change needs to be made.",
            "Discuss how it is okay to be wrong and that this is a normal part of the investment process.",
          ],
        },
        {
          id: 11,
          name: "Readiness",
          type: "Identified",
          results: [
            "An investment tendency that relies on logic.",
            "Tries to understand complicated investment decisions by relating them to areas that are familiar or pertain to their own lives.",
            "Estimates how likely something is to happen based on previous life experiences.",
            "Believes outcomes that they can more easily imagine are that outcomes that are more likely to happen.",
            "Relies on limited or narrow experiences to make investment decisions.",
            "Can be influenced by situations that are similar to their own personal situations.",
            "Outcomes that are more easily remembered or understood are seen as being more dependable.",
          ],
          characteristics: [
            "Tries to make things easier to understand by relating things they are comfortable with or that have meaning in their personal lives.",
            "Avoids getting involved in the research and due diligence process of investing.",
            "Will make investment decisions based on what is at the forefront of their mind.",
            "Investment decisions are made based on the life experiences of the investor(such as the industry in which they work, the area they live in, or the people they know).",
            "Uses guessing or illogical reasoning to justify their decisions.",
            "Potentially ignores investments because they cannot relate to them on a personal level.",
            "Mentally overemphasizes current events and trends.",
            "Gravitates towards investments they work in or around.",
          ],
          recommendations: [
            "Use knowledge of their personal lives to help them understand and process current investment decisions.",
            "Understand that they won't do their own due diligence and that this is an aspect that you must help them consider and value.",
            "Help steer them towards sounds investments rather than just what is comfortable.",
            "Allow Readiness investors time to think about your guidance and their decision before taking action.",
            "Ensure that complex positions or advice is explained as simply as possible.",
            "They may become easily overwhelmed with large amounts of information. Take things slowly, give explanations, and allow them time to process and ask questions.",
          ],
        },
        {
          id: 37,
          name: "Fundamental",
          type: "Identified",
          results: [
            "Makes decisions on what is presented rather than the way the information is presented.",
            "Visual materials (such as charts, resources, materials, brochures, etc.) are not as influential to this investor as the quality of information itself.",
            "Credibility of the advisor and their guidance is judged by performance rather than presentations.",
            "Details are important to the decision-making process.",
          ],
          characteristics: [
            "Logic and research to supporting decisions are more influential than the way that investment decisions are explained or presented.",
            "Are less concerned with the aesthetics of materials and the following of traditional professional protocols.",
            "Not concerned with micro - investing with sectors or industries.",
            "May consider adding risk to their portfolio even when their portfolio is performing well.",
            "Not focused on small price fluctuations but big picture impact and reasons behind volatility.",
          ],
          recommendations: [
            "Sophisticated materials and professional settings will not influence this type of investor.",
            "Stick to the details as flashy presentations are not influential.",
            "Focus on the big picture and not just micro or limited views.",
            "Keep a diversified and rebalance approach; value is understood with this investor.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 9,
          name: "Conclusion",
          type: "Identified",
          results: ["A logical tendency.", "Focus on the end result of an investment rather than exploring what created that results.", "Uses information to process events but in a limited manner."],
          characteristics: [
            "Attracted to investment options because of what others are doing.",
            "Focuses on individual positions or groups of assets without really understanding the associated risks.",
            "Undervalues the process used by financial professionals to follow investment guidelines and avoid undue risk.",
            "Overvalues investments based on recent or strong performance, even when position prices are high.",
            "Looks at current performance and not the research behind what would keep a portfolio sound.",
            "Takes things at face value and ignores what may be happening behind the scenes.",
          ],
          recommendations: [
            "Provide current data and research to connect with the logical side of this investor.",
            "Look at what the client has done in the past and their past results to guide your work with them moving forward.",
            "Encourage the use of a bigger picture viewpoint when financial decisions are made.",
            "Discuss the events and rationale behind specific position outcomes in a direct manner.",
            "Debrief with the investor after decisions have been made to help illustrate how outcomes were achieved.",
          ],
        },
      ],
      personality: {
        type: "Energizer",
        behavior: "Aggressive",
        attributes: {
          advisorTips: [
            "Building a relationship with an Energizer may be difficult as they tend to trust their own way of thinking more than that of a financial professional.",
            "Financial recommendations must be supported by data and research.",
            "Strong, clear, and direct communication materials work best when advising an Energizer.",
          ],
          investorBehaviors: [
            "Researching investment options on their own as they enjoy the investment process",
            "Having previously risked their own capital to gain wealth",
            "Success in controlling the outcomes of activities outside of their investments and belief this can also be done with their investments (especially at high wealth levels)",
            "A preference for digging into the details of an investment",
            "A tendency to trade too much when not being advised",
            "A true desire for the thrill of a good investment choice",
            "Being passionate about their investment choices",
            "Optimism that their choices will do well even when the expectation is irrational",
            "Over-confidence in their ability to make independent investment decisions",
            "An ability to look past the potential random and unknown risks that are naturally part of financial investing",
            "High discomfort when money is lost or an investment does not perform as expected",
          ],
        },
        decisionMakingStyle: "Highly Passionate",
      },
    },
    lastName: "Pallotti",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 405,
    dateCollected: "Mar 11 2022",
    firstName: "Brian",
    insights: {
      score: {
        plScore: 33,
        riskBehaviorScore: 60,
        selfReportedScore: 66,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1963,
        retirementAge: 65,
        retirementYear: 2028,
      },
      tendencies: [
        {
          id: 21,
          name: "Autonomous",
          type: "Strongly Identified",
          results: [
            "Sees the value in accurate and complete data and information to make informed decisions.",
            "Places importance on outside valuations of price rather than just their own opinion.",
            "Looks beyond the past performance of a position to estimate how it may perform in the future.",
          ],
          characteristics: [
            "Can quickly change course or thoughts about a position when new information is given.",
            "Considers at a variety of factors when evaluating the potential risk of an investment.",
            "Is able to look at an investment based on the overall market rather than focusing on the current economic state of certain companies or countries.",
            "Understands how investments can fluctuate quickly and often.",
            "Will make an effort to find information beyond what is easily available when assessing the current status or condition of a company.",
          ],
          recommendations: [
            "Support your recommendations with arguments and points that have passionate or emotional elements in addition to logical research.",
            "Use a big picture approach assists in the decision-making process.",
            "Keep in mind this client can see quick position changes as beneficial.",
            "Offer multiple examples when decisions are being made.",
            "Provide areas for self-research.",
            "Quick and decisive actions are valued with this client.",
          ],
        },
        {
          id: 29,
          name: "Inspective",
          type: "Strongly Identified",
          results: [
            "Aims to understand what created the end result of an investment.",
            "Relies heavily on information and research to guide the investment process.",
            "Has an open mind to positions.",
            "Prefers a logically diverse portfolio.",
            "Is not concerned with fads or in-style positions.",
          ],
          characteristics: [
            "Are unconcerned with how others are investing.",
            "Tries to understand the risks associated with individual positions or groups of assets.",
            "Place value on the process used by financial professionals to follow investment guidelines.",
            "Uses research to keep a portfolio sound.",
            "Does not take things at face value and pays attention to what may be happening behind the scenes.",
            "Sees the difference between short term and long term value of an investment.",
            "Does not become overly excited with the short - term performance of an investment.",
            "Likes to know the logic behind the movement(both up and down) of a position.",
          ],
          recommendations: [
            "Supply the investor with information and research as they will use this to inform their bigger picture viewpoint.",
            "Use events and rationale behind specific position outcomes.",
            "Do not focus on trendy or in-style investments.",
            "Show long term plan versus short term movements of positions.",
            "Show value of the entire portfolio not individual position movement.",
            "Present reasons why positions and the portfolio is moving up or down.",
            "Using logic is important in communication and assisting with the decision-making process.",
          ],
        },
        {
          id: 22,
          name: "Matter-of-Fact",
          type: "Strongly Identified",
          results: [
            "Does not need to experience large investment gains to view the investment in a positive way.",
            "Uses logic rather than passion when making decisions and communicating.",
            "Understands that loss is always a potential outcome whenever they make and investment.",
            "Sees the potential for gains from positions that have experienced a decline.",
          ],
          characteristics: [
            "When faced with a declining position, will quickly make decisions to sell hoping to regain the loss.",
            "For positions that are increasing in value, will tolerate fluctuations in price and focus on the long - term outcomes for positions.",
            "Understands the need to make decisions and act with positions that are declining in value.",
            "Is willing to sell rather than hold a position to its bottom.",
            "Realizes that the value of an investment decreases even through it is not realized by a sale of a position.",
          ],
          recommendations: [
            "Supply information that helps encourage action when portfolio changes are needed.",
            "Communicate using logical, fact-based arguments.",
            "Demonstrate the ability to take profits to off-set losses.",
            "Double the return is not expected when investing in higher risk investments.",
          ],
        },
        {
          id: 26,
          name: "Prudent",
          type: "Strongly Identified",
          results: [
            "Tend to follow their long-term financial goals and are not easily influenced by emotions or desires.",
            "Usually follow their personal beliefs and are not easily persuaded by people and events around them.",
            "Are self-disciplined and can stick to a plan that has been created.",
            "Do not make decisions impulsively.",
            "Sees value in logical decisions.",
          ],
          characteristics: [
            "Does not like change and uses caution when making decisions.",
            "Prefers to save for tomorrow rather than spend today.",
            "Exercises caution with financial decisions to avoid putting previous investment efforts at risk.",
            "Has some familiarity with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist with generating long - term wealth.",
            "Values a retirement plan.",
            "Realizes that quick risky investments do not make - up for poor past decisions.",
            "Sees the value of a balanced portfolio.",
            "Avoids impulsive buys.",
            "Enjoys the research behind purchases, and thinks before acting on a buy or a sell.",
            "Values sound logical advice.",
          ],
          recommendations: [
            "Provide sound research.",
            "Give time to think through decisions to buy or a sell.",
            "Supply information on the value of dollar cost averaging.",
            "Discuss how sacrifices today can result in a much better future.",
            "Rebalance and allocate plans as needed.",
            "Demonstrate knowledge of a balanced portfolio.",
            "Create a sound long term plan.",
            "Logic is a key to communication and decision making with this investor type.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Strongly Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 34,
          name: "Hypothesizer",
          type: "Strongly Identified",
          results: [
            "Relates current situations to hard numbers and verified information.",
            "Understands that if something works with one position, it doesn't always translate or work with another position.",
            "Makes new things familiar through research and learning.",
            "Assumes that much information is needed to understand a whole phenomenon.",
            "Tends to be open to new ideas, people, and experiences.",
          ],
          characteristics: [
            "Attempts to understand how successful and investment will be through research and learning.",
            "Predicts the likelihood of an outcome by using ideas, experiences, and information outside their own world view.",
            "Gathers several pieces of information from various sources to explain the wider phenomenon.",
            "Looks at a large amount of information to make an informed decision.",
            "Does not oversimplifying the decision - making process.",
          ],
          recommendations: [
            "Supply the investor with the research and data they need to feel fully informed before making an investment decision.",
            "Talk through the ideas of a position with verifiable facts and information.",
            "Remind the investor that while research is important to making a sound decision, information will not guarantee the success of an investment.",
            "Use caution when providing large amounts of information as too much information may paralyze the decision-making process.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Strongly Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 38,
          name: "Cultivated",
          type: "Strongly Identified",
          results: ["Knows that predicting how the market will behave is not possible.", "Uses hindsight as a learning experience to help guide future decisions.", "Uses logic and details to make decisions."],
          characteristics: [
            "Assumes risks with an understanding that it is impossible to predict the future of the market.",
            "Able to walk away from unsuccessful positions.",
            "Willing to acknowledge when past decisions were incorrect.",
            "Makes decisions using facts and research rather than feelings and hunches.",
            "Takes responsibility for poor decisions.",
            "Values ups and downs equally and realizes market movement is part of the investment process.",
          ],
          recommendations: [
            "Use a big picture approach when discussing investment strategies.",
            "Demonstrate the value in a diversified and balanced portfolio.",
            "Use logic and details when making presentations.",
            "This investor is seasoned and knows that some investments are successful and others are not.",
            "Selling failing positions is expected when necessary and logically presented.",
            "Mistakes happen and if one is made, admit it and accept responsibility.",
          ],
        },
        {
          id: 37,
          name: "Fundamental",
          type: "Identified",
          results: [
            "Makes decisions on what is presented rather than the way the information is presented.",
            "Visual materials (such as charts, resources, materials, brochures, etc.) are not as influential to this investor as the quality of information itself.",
            "Credibility of the advisor and their guidance is judged by performance rather than presentations.",
            "Details are important to the decision-making process.",
          ],
          characteristics: [
            "Logic and research to supporting decisions are more influential than the way that investment decisions are explained or presented.",
            "Are less concerned with the aesthetics of materials and the following of traditional professional protocols.",
            "Not concerned with micro - investing with sectors or industries.",
            "May consider adding risk to their portfolio even when their portfolio is performing well.",
            "Not focused on small price fluctuations but big picture impact and reasons behind volatility.",
          ],
          recommendations: [
            "Sophisticated materials and professional settings will not influence this type of investor.",
            "Stick to the details as flashy presentations are not influential.",
            "Focus on the big picture and not just micro or limited views.",
            "Keep a diversified and rebalance approach; value is understood with this investor.",
          ],
        },
        {
          id: 31,
          name: "Progressive",
          type: "Identified",
          results: [
            "Is comfortable making complicated investment decisions even in areas where they have little experience.",
            "Is aware that outcomes may take place that are outside of what they could have ever imagined.",
            "Looks for confirmation outside of their own experiences when making investment decisions.",
            "Are not influenced by just their own personal life situation.",
            "Does not need personal experience in an area to feel comfortable making a decision.",
            "Able to see things at face value.",
            "Often learns things quickly and can keep up with rapidly changing information.",
            "Keeps personal biases out of the decision-making process.",
          ],
          characteristics: [
            "Prefers to be involved in the research and due diligence process of investing.",
            "Will make investment decisions based on elements and ideas they have been considering over time.",
            "Are open to learning about new industries, places, and people.",
            "Use data and facts to justify their decisions.",
            "Are not moved by current fads or trends in the market.",
            "Sees the value of investments that involve new industries or ideas.",
            "Open to learning new things and aim to benefit from new information.",
            "Does not need a personal knowledge of a position to be involved with an investment opportunity.",
          ],
          recommendations: [
            "Look for support that goes beyond their personal lives and experiences to help them understand and process current investment decisions.",
            "Anticipate that they may have researched an investment prior to and beyond the information you offer.",
            "Be aware that the investor may appreciate more advanced or detailed information about potential investments.",
            "Be prepared to answer questions that are more complex in nature.",
          ],
        },
      ],
      personality: {
        type: "Liberator",
        behavior: "Moderately Aggressive",
        attributes: {
          advisorTips: [
            "Some Liberators may resist a rigid financial plan.",
            "Offer financial advice in a way that is mindful and respectful of their independent views.",
            "Engage in regular educational discussions as they will strengthen the relationship with a Liberator.",
            "Support any recommendations given with data.",
            "Clear, direct material and communication is preferred by most Liberators.",
          ],
          investorBehaviors: [
            "Having previously risked their own capital to gain wealth and are willing to do so in the future",
            "Preferring to take an active role in researching potential investments",
            "An expectation that there is a due diligence process for all investments being considered",
            "Keeping up to date on what is happening in financial markets",
            "A medium to high tolerance for risk with their financial investments",
            "Strong-willed and independent thinkers",
            "Looking at things logically and critically (rather than passionately)",
            "Making decisions using sound judgement and their own gut instincts",
            "Preferring to use their own research on an investment",
            "A dislike of information that is contrary to what they have learned or researched on their own",
            "Becoming overly involved with trying to beat the market with a result of holding concentrated positions",
          ],
        },
        decisionMakingStyle: "Moderately Logical",
      },
    },
    lastName: "Watkins",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 401,
    dateCollected: "Oct 30 2020",
    firstName: "Andrew",
    insights: {
      score: {
        plScore: 16,
        riskBehaviorScore: 41,
        selfReportedScore: 100,
      },
      profile: {
        communicationExpectation: "Quarterly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1991,
        retirementAge: 65,
        retirementYear: 2056,
      },
      tendencies: [
        {
          id: 29,
          name: "Inspective",
          type: "Strongly Identified",
          results: [
            "Aims to understand what created the end result of an investment.",
            "Relies heavily on information and research to guide the investment process.",
            "Has an open mind to positions.",
            "Prefers a logically diverse portfolio.",
            "Is not concerned with fads or in-style positions.",
          ],
          characteristics: [
            "Are unconcerned with how others are investing.",
            "Tries to understand the risks associated with individual positions or groups of assets.",
            "Place value on the process used by financial professionals to follow investment guidelines.",
            "Uses research to keep a portfolio sound.",
            "Does not take things at face value and pays attention to what may be happening behind the scenes.",
            "Sees the difference between short term and long term value of an investment.",
            "Does not become overly excited with the short - term performance of an investment.",
            "Likes to know the logic behind the movement(both up and down) of a position.",
          ],
          recommendations: [
            "Supply the investor with information and research as they will use this to inform their bigger picture viewpoint.",
            "Use events and rationale behind specific position outcomes.",
            "Do not focus on trendy or in-style investments.",
            "Show long term plan versus short term movements of positions.",
            "Show value of the entire portfolio not individual position movement.",
            "Present reasons why positions and the portfolio is moving up or down.",
            "Using logic is important in communication and assisting with the decision-making process.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Strongly Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 5,
          name: "Cognitive Insight",
          type: "Identified",
          results: [
            "Behaviors are based on logic and information processing.",
            "Places sums of money in different mental groups or categories (i.e. college fund, play money, mortgage funds, etc.) and treats these groupings differently.",
            "Has a personally logical way that they have categorized their money.",
            "Makes financial decisions independently and differently according to their mental groupings.",
          ],
          characteristics: [
            "Categorizes money into different groupings.",
            "Holds separate accounts to match up with financial goals they have mentally constructed for themselves.",
            "May hold imbalanced investment portfolios because they view the portfolio as distinct pieces rather than a cohesive group.",
            "Often views returns that come from income and those that come from capital appreciation as two very different things.",
            "Hesitant to sell investments that were profitable in the past but are not currently beneficial.",
            "Money that is matched through employment or other means(such as a retirement match) has a lower value to Cognitive Insight investors.",
          ],
          recommendations: [
            "Figuring out the logic or the way that the investor has grouped their money is key to guiding their investments. You must work to understand what their groupings and goals mean to them.",
            "Adjust your approach based on which piece of their investment portfolio is being discussed.",
            "Help them to shift their focus from income areas where principal may be inadvertently lost.",
            "They will react to different amounts of loss based on which mental grouping is experiencing the loss. Higher or lower levels of loss will be tolerated according to how the investment is perceived in their mind.",
            "Reinforce that a loss in an investment is a loss no matter how they personally view the investment.",
            "When investments overlap, be mindful of how they can impact each other.",
            "Look for ways to help the investor view their portfolio components from a big picture perspective.",
          ],
        },
        {
          id: 33,
          name: "Catalytic",
          type: "Identified",
          results: ["Uses new information to question and enhance their current views.", "Is not afraid of the future and what it may bring.", "Are willing to explore and embrace new ideas.", "Sees necessary change as positive."],
          characteristics: [
            "Does not hold positions too long.",
            "Responds to new information and takes action when needed.",
            "Willing to have their world perspective challenged.",
            "Believes that change and modernization have a place and that old ways aren't necessarily better.",
            "Comfortable with new and complex data.",
            "Enjoys opportunities to be involved in new things.",
          ],
          recommendations: [
            "This client enjoys philosophical views and discussions regarding future potential.",
            "Do not focus on the past, but show how the future holds immense possibilities.",
            "Keep in mind this client enthusiastically embraces new opportunities.",
            "Use research and passion when presenting materials, as this is valued.",
            "This client prefers to be an early adopter of new investment possibilities.",
          ],
        },
        {
          id: 19,
          name: "Conviction",
          type: "Identified",
          results: [
            "Behaviors are centered on logic and reasoning.",
            "Experiences discomfort when different ideas are presented that conflict with their previously held beliefs.",
            "Resists changing their mind even when new information is made available.",
          ],
          characteristics: [
            "Has difficulty admitting when they have made a mistake.",
            "Holds positions longer than they should because selling is viewed as admitting defeat.",
            "Believes things will work out differently next time.",
            "Tends to value gut instincts more than facts or research.",
            "Tries to stay positive about the decisions they have made even when there is no cause.",
          ],
          recommendations: [
            "Connect data and research to the beliefs held by the investor.",
            "Keep in regular communication to reassure the investor on decisions that have been made.",
            "State all the facts available through research and documents when these are shared with the investor.",
            "Talk about both the positive and negative feelings being experienced by the investor.",
            "Communicate in a direct manner when action needs to be taken or a change needs to be made.",
            "Discuss how it is okay to be wrong and that this is a normal part of the investment process.",
          ],
        },
        {
          id: 34,
          name: "Hypothesizer",
          type: "Identified",
          results: [
            "Relates current situations to hard numbers and verified information.",
            "Understands that if something works with one position, it doesn't always translate or work with another position.",
            "Makes new things familiar through research and learning.",
            "Assumes that much information is needed to understand a whole phenomenon.",
            "Tends to be open to new ideas, people, and experiences.",
          ],
          characteristics: [
            "Attempts to understand how successful and investment will be through research and learning.",
            "Predicts the likelihood of an outcome by using ideas, experiences, and information outside their own world view.",
            "Gathers several pieces of information from various sources to explain the wider phenomenon.",
            "Looks at a large amount of information to make an informed decision.",
            "Does not oversimplifying the decision - making process.",
          ],
          recommendations: [
            "Supply the investor with the research and data they need to feel fully informed before making an investment decision.",
            "Talk through the ideas of a position with verifiable facts and information.",
            "Remind the investor that while research is important to making a sound decision, information will not guarantee the success of an investment.",
            "Use caution when providing large amounts of information as too much information may paralyze the decision-making process.",
          ],
        },
        {
          id: 8,
          name: "Kinship",
          type: "Identified",
          results: [
            "Decisions are made using passion and emotion.",
            "Invests in positions because of a belief that it reflects who they are.",
            "Looks for investments that match their values, even if they are not logical or are financially risky.",
            "Uses investments to express their values and beliefs.",
            "Are attracted to a perceived sense of status that comes with certain investments.",
          ],
          characteristics: [
            "Will invest in services or products that are personally preferred without properly vetting the investment.",
            "Tends to invest in positions or areas that are familiar.",
            "Misses opportunities because the investor feels a connection to certain positions.",
            "Will invest to gain status rather than to gain money.",
          ],
          recommendations: [
            'Use direct questioning to help the investor open their way of thinking. For example, ask "Have you ever seen a company act in a direction you weren\'t anticipating."',
            "Create stop loss indicators to help create movement when values are clouding their logic.",
            "Use data and research to bring a realistic and non-biased viewpoint to the investor.",
            "Directly link their passionate tendencies to examples of logical reasoning to guide their decision making.",
            "Work to demonstrate how a good company may be a bad financial investment for them.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 1,
          name: "Affixed",
          type: "Identified",
          results: [
            "Data and information are used to make decisions, even when it is incomplete or out of date.",
            "Price of an asset is established by the investor based on their personal opinion of what they believe the asset is worth.",
            "Often, but not always, the view of an investment's worth is based on the original purchase price of the asset.",
          ],
          characteristics: [
            "Has difficulty changing course or thoughts about a position even when new information is given.",
            "Uses return levels to predict the percentage an asset may rise or fall.",
            "May become focused on the economic states of certain companies or countries rather than looking at the overall market.",
            "Has a hard time accepting how things fluctuate in the industry.",
            "Uses information available to them to interpret how a company is doing and base their estimates on just that instead of all information available.",
            "Values an asset based on a past point in time rather than how the value may change in the future.",
          ],
          recommendations: [
            "Use the analytics behind investment options to educate the investor on a more realistic value of the asset.",
            "Use logical research to support your course of action.",
            "Urge the investor to focus more on the current state of the investment and future trends instead of what has taken place in the past.",
            "Build in stop losses to assist the investor when decisions need to be made that contradict how they think about an asset's value.",
            "Changing the mind of an investor with Affixed tendencies is difficult. Be prepared that this may be long process that requires sound research and information.",
          ],
        },
      ],
      personality: {
        type: "Observer",
        behavior: "Moderate",
        attributes: {
          advisorTips: [
            "Avoid suggesting too many “hot” investment ideas as an Observer would like to do them all.",
            "Use data (examples) to support the investing advice that is provided.",
            "Provide information on the benefits of portfolio diversification and why sticking to a long-term plan is important.",
          ],
          investorBehaviors: [
            "Overestimating the amount of risk they are truly comfortable with in their investing",
            "Jumping into an investment that has been made to sound good without meaningful consideration of the risk involved",
            "A real fear of even dislike of financial investing",
            "Delaying investment decisions because they do not enjoy the investment process",
            "Accepting professional financial advice when it is given",
            "A tendency to maintain large cash balances because of a hesitancy to make financial decisions",
            "Learning about financial investment options on their own but not taking action using this knowledge",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "Beil",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 399,
    dateCollected: "Aug 28 2020",
    firstName: "Kasey",
    insights: {
      score: {
        plScore: 11,
        riskBehaviorScore: 64,
        selfReportedScore: 67,
      },
      profile: {
        communicationExpectation: "Quarterly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1984,
        retirementAge: 60,
        retirementYear: 2044,
      },
      tendencies: [
        {
          id: 26,
          name: "Prudent",
          type: "Strongly Identified",
          results: [
            "Tend to follow their long-term financial goals and are not easily influenced by emotions or desires.",
            "Usually follow their personal beliefs and are not easily persuaded by people and events around them.",
            "Are self-disciplined and can stick to a plan that has been created.",
            "Do not make decisions impulsively.",
            "Sees value in logical decisions.",
          ],
          characteristics: [
            "Does not like change and uses caution when making decisions.",
            "Prefers to save for tomorrow rather than spend today.",
            "Exercises caution with financial decisions to avoid putting previous investment efforts at risk.",
            "Has some familiarity with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist with generating long - term wealth.",
            "Values a retirement plan.",
            "Realizes that quick risky investments do not make - up for poor past decisions.",
            "Sees the value of a balanced portfolio.",
            "Avoids impulsive buys.",
            "Enjoys the research behind purchases, and thinks before acting on a buy or a sell.",
            "Values sound logical advice.",
          ],
          recommendations: [
            "Provide sound research.",
            "Give time to think through decisions to buy or a sell.",
            "Supply information on the value of dollar cost averaging.",
            "Discuss how sacrifices today can result in a much better future.",
            "Rebalance and allocate plans as needed.",
            "Demonstrate knowledge of a balanced portfolio.",
            "Create a sound long term plan.",
            "Logic is a key to communication and decision making with this investor type.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 31,
          name: "Progressive",
          type: "Identified",
          results: [
            "Is comfortable making complicated investment decisions even in areas where they have little experience.",
            "Is aware that outcomes may take place that are outside of what they could have ever imagined.",
            "Looks for confirmation outside of their own experiences when making investment decisions.",
            "Are not influenced by just their own personal life situation.",
            "Does not need personal experience in an area to feel comfortable making a decision.",
            "Able to see things at face value.",
            "Often learns things quickly and can keep up with rapidly changing information.",
            "Keeps personal biases out of the decision-making process.",
          ],
          characteristics: [
            "Prefers to be involved in the research and due diligence process of investing.",
            "Will make investment decisions based on elements and ideas they have been considering over time.",
            "Are open to learning about new industries, places, and people.",
            "Use data and facts to justify their decisions.",
            "Are not moved by current fads or trends in the market.",
            "Sees the value of investments that involve new industries or ideas.",
            "Open to learning new things and aim to benefit from new information.",
            "Does not need a personal knowledge of a position to be involved with an investment opportunity.",
          ],
          recommendations: [
            "Look for support that goes beyond their personal lives and experiences to help them understand and process current investment decisions.",
            "Anticipate that they may have researched an investment prior to and beyond the information you offer.",
            "Be aware that the investor may appreciate more advanced or detailed information about potential investments.",
            "Be prepared to answer questions that are more complex in nature.",
          ],
        },
        {
          id: 30,
          name: "Receptive",
          type: "Identified",
          results: [
            "Believes the market behaves in ways that they cannot predict or control.",
            "Realizes success in other areas of life do not mean they will also find success in the financial industry.",
            "Sees the logic and advantages of not over-controlling their investments.",
            "Will use professional assistance and not just themselves.",
          ],
          characteristics: [
            "Understands that market outcomes are beyond their control.",
            "Do not overly rely on what they know or their personal life experiences to make investment decisions.",
            "Values the opinions of others and not just their own ideas.",
            "Keeps personal beliefs out of investments.",
            "Values limit orders and other techniques but with sound reason to maximize opportunities.",
            "Does not try to over - control the investment process.",
          ],
          recommendations: [
            "Use complete and accurate information as it is highly valued by this investor type.",
            "Offer a diversified and balanced portfolio.",
            "Help show keys in not over-controlling portfolios.",
            "Show value in professional advice and partnering with this investor.",
          ],
        },
        {
          id: 29,
          name: "Inspective",
          type: "Identified",
          results: [
            "Aims to understand what created the end result of an investment.",
            "Relies heavily on information and research to guide the investment process.",
            "Has an open mind to positions.",
            "Prefers a logically diverse portfolio.",
            "Is not concerned with fads or in-style positions.",
          ],
          characteristics: [
            "Are unconcerned with how others are investing.",
            "Tries to understand the risks associated with individual positions or groups of assets.",
            "Place value on the process used by financial professionals to follow investment guidelines.",
            "Uses research to keep a portfolio sound.",
            "Does not take things at face value and pays attention to what may be happening behind the scenes.",
            "Sees the difference between short term and long term value of an investment.",
            "Does not become overly excited with the short - term performance of an investment.",
            "Likes to know the logic behind the movement(both up and down) of a position.",
          ],
          recommendations: [
            "Supply the investor with information and research as they will use this to inform their bigger picture viewpoint.",
            "Use events and rationale behind specific position outcomes.",
            "Do not focus on trendy or in-style investments.",
            "Show long term plan versus short term movements of positions.",
            "Show value of the entire portfolio not individual position movement.",
            "Present reasons why positions and the portfolio is moving up or down.",
            "Using logic is important in communication and assisting with the decision-making process.",
          ],
        },
        {
          id: 33,
          name: "Catalytic",
          type: "Identified",
          results: ["Uses new information to question and enhance their current views.", "Is not afraid of the future and what it may bring.", "Are willing to explore and embrace new ideas.", "Sees necessary change as positive."],
          characteristics: [
            "Does not hold positions too long.",
            "Responds to new information and takes action when needed.",
            "Willing to have their world perspective challenged.",
            "Believes that change and modernization have a place and that old ways aren't necessarily better.",
            "Comfortable with new and complex data.",
            "Enjoys opportunities to be involved in new things.",
          ],
          recommendations: [
            "This client enjoys philosophical views and discussions regarding future potential.",
            "Do not focus on the past, but show how the future holds immense possibilities.",
            "Keep in mind this client enthusiastically embraces new opportunities.",
            "Use research and passion when presenting materials, as this is valued.",
            "This client prefers to be an early adopter of new investment possibilities.",
          ],
        },
        {
          id: 21,
          name: "Autonomous",
          type: "Identified",
          results: [
            "Sees the value in accurate and complete data and information to make informed decisions.",
            "Places importance on outside valuations of price rather than just their own opinion.",
            "Looks beyond the past performance of a position to estimate how it may perform in the future.",
          ],
          characteristics: [
            "Can quickly change course or thoughts about a position when new information is given.",
            "Considers at a variety of factors when evaluating the potential risk of an investment.",
            "Is able to look at an investment based on the overall market rather than focusing on the current economic state of certain companies or countries.",
            "Understands how investments can fluctuate quickly and often.",
            "Will make an effort to find information beyond what is easily available when assessing the current status or condition of a company.",
          ],
          recommendations: [
            "Support your recommendations with arguments and points that have passionate or emotional elements in addition to logical research.",
            "Use a big picture approach assists in the decision-making process.",
            "Keep in mind this client can see quick position changes as beneficial.",
            "Offer multiple examples when decisions are being made.",
            "Provide areas for self-research.",
            "Quick and decisive actions are valued with this client.",
          ],
        },
        {
          id: 25,
          name: "Free-Thinking",
          type: "Identified",
          results: [
            "Behaviors are based on feelings and emotion.",
            "Views all money to be used for investing as one lump sum rather than according to specific groupings.",
            "Factors that influence decisions are not entirely grounded in data and logic.",
            "Does not place sums of money in specific investment groups or categories based on goals for future.",
            "Values overall portfolio value rather than specific investment performance.",
            "Decisions are made based on the entire portfolio rather than specific positions.",
          ],
          characteristics: [
            "Views all investments as one unified goal.",
            "Does not group or categorize investments into specific goals.",
            "Views returns that come from income and those that come from capital appreciation similarly.",
            "See the benefit in selling investments that were profitable in the past but not currently beneficial.",
            "Different types of income are all viewed equally and valued the same.",
            "Considers employer match and personal investments as having the same value and not as separate types of money.",
            "Sees the value of a balanced portfolio and a cohesive group of investments.",
          ],
          recommendations: [
            "Know that decisions are based on the entire portfolio and not specific investments.",
            "Look at the entire investment package, including employer match and external investments, as one entire allocation.",
            "Do not use bucket method for investment advice.",
            "Use the entire household portfolio to discuss future decisions.",
          ],
        },
      ],
      personality: {
        type: "Liberator",
        behavior: "Moderately Aggressive",
        attributes: {
          advisorTips: [
            "Some Liberators may resist a rigid financial plan.",
            "Offer financial advice in a way that is mindful and respectful of their independent views.",
            "Engage in regular educational discussions as they will strengthen the relationship with a Liberator.",
            "Support any recommendations given with data.",
            "Clear, direct material and communication is preferred by most Liberators.",
          ],
          investorBehaviors: [
            "Having previously risked their own capital to gain wealth and are willing to do so in the future",
            "Preferring to take an active role in researching potential investments",
            "An expectation that there is a due diligence process for all investments being considered",
            "Keeping up to date on what is happening in financial markets",
            "A medium to high tolerance for risk with their financial investments",
            "Strong-willed and independent thinkers",
            "Looking at things logically and critically (rather than passionately)",
            "Making decisions using sound judgement and their own gut instincts",
            "Preferring to use their own research on an investment",
            "A dislike of information that is contrary to what they have learned or researched on their own",
            "Becoming overly involved with trying to beat the market with a result of holding concentrated positions",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "Williams",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 398,
    dateCollected: "Jul 20 2020",
    firstName: "Chris",
    insights: {
      score: {
        plScore: 12,
        riskBehaviorScore: 24,
        selfReportedScore: 68,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "401(k) at work",
      },
      retirement: {
        birthYear: 1973,
        retirementAge: 75,
        retirementYear: 2048,
      },
      tendencies: [
        {
          id: 37,
          name: "Fundamental",
          type: "Strongly Identified",
          results: [
            "Makes decisions on what is presented rather than the way the information is presented.",
            "Visual materials (such as charts, resources, materials, brochures, etc.) are not as influential to this investor as the quality of information itself.",
            "Credibility of the advisor and their guidance is judged by performance rather than presentations.",
            "Details are important to the decision-making process.",
          ],
          characteristics: [
            "Logic and research to supporting decisions are more influential than the way that investment decisions are explained or presented.",
            "Are less concerned with the aesthetics of materials and the following of traditional professional protocols.",
            "Not concerned with micro - investing with sectors or industries.",
            "May consider adding risk to their portfolio even when their portfolio is performing well.",
            "Not focused on small price fluctuations but big picture impact and reasons behind volatility.",
          ],
          recommendations: [
            "Sophisticated materials and professional settings will not influence this type of investor.",
            "Stick to the details as flashy presentations are not influential.",
            "Focus on the big picture and not just micro or limited views.",
            "Keep a diversified and rebalance approach; value is understood with this investor.",
          ],
        },
        {
          id: 39,
          name: "Enlightened",
          type: "Strongly Identified",
          results: [
            "Is open to new ideas even when they conflict with their previously held beliefs.",
            "Is willing to change their mind when evidence and research are supplied.",
            "Focuses equally on ups and downs, and does not always justify losses or gains.",
            "Is open to suggestions, new ideas, and new experiences.",
          ],
          characteristics: [
            "Are willing to admit when they have made a mistake.",
            "Is comfortable selling a position when it has not performed well.",
            "Believes that past mistakes will assist with better decision making in the future.",
            "Tends to value facts or research more than gut instincts or intuition.",
            "Views decisions and outcomes of decisions in a realistic and honest way.",
            "Does not believe that selling is admitting defeat with a declining position.",
            "Not looking at the bright side of everything.",
          ],
          recommendations: [
            "Use logic and direct communication.",
            "Show mistakes as well as successes in the investment process.",
            "Keep an open mind in discussions and decisions.",
            "This investor has the ability to take on new and exciting positions.",
            "Presenting material that is new and varied is encouraged and embraced.",
            "A well rounded and diversified portfolio is valued.",
            "This investor is open to other countries or sectors if well understood.",
          ],
        },
        {
          id: 13,
          name: "Traditionalist",
          type: "Strongly Identified",
          results: ["A tendency that is logical in nature.", "Adjusts or tweaks new information to support their prior views", "Highly values their own beliefs while placing a lower value on new information."],
          characteristics: [
            "Holds on to positions too long.",
            "Either fails to act or slowly react to new information.",
            "Has difficulty accepting or believing things that question their world perspective.",
            "Believes that old ways are inherently better.",
            "Can be overwhelmed with new and complex data.",
            "Views involvement in new things as a burden.",
          ],
          recommendations: [
            "Be prepared to show concrete examples of the benefits of a professional financial advisor.",
            "Demonstrate how new data can achieve better outcomes.",
            "Present convincing arguments of how old ways can be honored while moving away from the past into more current financial positions.",
            "Take the emotion out of the decision process using logic and research.",
            "Encourage decisive action when a course of action is decided.",
            "Expect hesitation to be a natural response of a Traditionalist investor.",
            "Build a relationship where the investor turns to you for your professional assistance to understand and interpret complex financial information.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Strongly Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 18,
          name: "Reflection",
          type: "Strongly Identified",
          results: [
            "A logic oriented tendency.",
            "The investor thinks that predicting the market is possible.",
            "Views themselves as having a special ability to predict market changes.",
            "Uses hindsight to demonstrate their ability to foresee how the market behaved.",
          ],
          characteristics: [
            "Assumes too much risk because they believe they can predict the future.",
            "Keeps putting money into unsuccessful positions because they believe the outcome will be different.",
            "Adds more money to losing positions in an attempt to show that their past decisions were correct.",
            "Makes decisions based on a feeling rather than on facts or research.",
            "Are able to change their perception of history, especially when they have made poor investment decisions, in order to shift blame and avoid embarrassment.",
            "Gives too much credit to advisors when portfolios perform well.Similarly, gives too much fault to advisors when portfolios perform poorly.",
          ],
          recommendations: [
            "Use story telling of actual events to show how the market cannot be predicted.",
            "Help them to temper their reactions to both gains and losses in the market.",
            "Discuss market cycles and provide concrete examples.",
            "Encourage the investor to honestly self-reflect on the both good and bad investment outcomes.",
            "Education materials provided to this type of client will resonate well because of their logical nature.",
            "Take a gentle approach in discussing the impossibility of making market predictions.",
          ],
        },
        {
          id: 34,
          name: "Hypothesizer",
          type: "Strongly Identified",
          results: [
            "Relates current situations to hard numbers and verified information.",
            "Understands that if something works with one position, it doesn't always translate or work with another position.",
            "Makes new things familiar through research and learning.",
            "Assumes that much information is needed to understand a whole phenomenon.",
            "Tends to be open to new ideas, people, and experiences.",
          ],
          characteristics: [
            "Attempts to understand how successful and investment will be through research and learning.",
            "Predicts the likelihood of an outcome by using ideas, experiences, and information outside their own world view.",
            "Gathers several pieces of information from various sources to explain the wider phenomenon.",
            "Looks at a large amount of information to make an informed decision.",
            "Does not oversimplifying the decision - making process.",
          ],
          recommendations: [
            "Supply the investor with the research and data they need to feel fully informed before making an investment decision.",
            "Talk through the ideas of a position with verifiable facts and information.",
            "Remind the investor that while research is important to making a sound decision, information will not guarantee the success of an investment.",
            "Use caution when providing large amounts of information as too much information may paralyze the decision-making process.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Strongly Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 4,
          name: "Consistency",
          type: "Identified",
          results: [
            "A tendency where behaviors are driven by passion.",
            "A desire for things to stay mostly the same.",
            "Hesitates to try new things or depart from what has been done in the past.",
            "Places a high value on safety and consistency when considering an investment option.",
          ],
          characteristics: [
            "Looks for investments that are safe and carry little change.",
            "Prefers investments that guarantee a sense of safety.",
            "Takes little to no action which may result in holding investments that are too cautious or, based on changing times, too risky.",
            "Holds investments too long to avoid transactions fees that are part of the selling process.",
          ],
          recommendations: [
            "Be prepared that changing the mind of a Consistency investor is difficult. Sometimes, no matter your argument is, they will resist change.",
            "Work to understand the rationale for why the investor holds the positions that they do. Use these personal and emotional connections to steer their decision making.",
            "Educate the investor on why you are giving certain pieces of guidance using both their passionate side (personal reasons) and their logical side.",
            "Discuss the lifestyle and financial goal changes that they could experience due to the type of investments they are making.",
          ],
        },
        {
          id: 6,
          name: "Discretion",
          type: "Identified",
          results: [
            "A tendency based on passion and emotion.",
            "Strays from their long-term financial goals because of a tendency to react to impulse buys/sells based emotion and desires.",
            "Persuaded easily by people and events around them.",
            "Lacks self-discipline to stick to a plan that has been created.",
          ],
          characteristics: [
            "Acts impulsively and have no difficulty changing course when passion or fear is introduced.",
            "Carries a mentality that it is better to spend today than to save for tomorrow.",
            "Fails to plan for retirement.",
            "Makes financial decisions that carry more risk than appropriate in order to make up for not investing earlier.",
            "Favors certain types of investments over others resulting in an unbalanced portfolio.",
            "Often unfamiliar with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist in generating long - term wealth.",
          ],
          recommendations: [
            "Explain how sacrificing a little bit in their daily life now can create large rewards later.",
            "Create checks and balances so that impulsive behaviors are minimized. For example, set the expectation that a conversation will be held before the investor acts on a financial decision.",
            "Appeal to the emotional side of this type of investor by relating the logical elements of investment decisions to their personal lives.",
            "Find ways to clearly show the advantages of using a financial professional rather than being left to make decisions on their own.",
            "Use hindsight and the results of their actions to help guide current decision making.",
          ],
        },
        {
          id: 23,
          name: "Impersonal",
          type: "Identified",
          results: [
            "Views investments equally and does not become personally attached to assets they have held.",
            "Tends to view investments objectively rather than relying on familiarity.",
            "Values logic rather than passion for decisions and communication.",
            "If investments are inherited, their value is based on logic rather than emotion or sentiment.",
          ],
          characteristics: [
            "Have minimal personal or sentimental attachment to investments.",
            "Views transaction fees as a normal part of the investment process.",
            "Values inherited or family - related investments the same as other investment types.",
            "Willing to make changes to and part with an investment regardless of how long it has been held.",
            "Sees the value of an investment on its own merits rather than sentimental value.",
          ],
          recommendations: [
            "Uses logic more than emotion to make decisions.",
            "Research and evidence is more influential than loyalty to past or current investments.",
            "Realize emotional ties to inherited positions are likely minimal.",
            "Understand that investments are held based on logic rather than family or belief ties.",
            "Use rational explanations of transaction fees and talk through the allocation process.",
            "Know the client values a logical approach to positions held.",
          ],
        },
      ],
      personality: {
        type: "Protector",
        behavior: "Moderately Conservative",
        attributes: {
          advisorTips: [
            "Take time to provide the big-picture descriptions of potential investments.",
            "Find ways to explain how the investments will deliver desired results using personal issues important to the Protector.",
            "Build trust through professionalism, expertise, and objectivity.",
          ],
          investorBehaviors: [
            "A preference to guard their wealth",
            "Taking any losses very seriously",
            "Careful and cautious decision making",
            "Difficulty taking action concerning their investments",
            "Worry and fear that wrong investment decisions will be made",
            "Purposeful avoidance of investments or actions that may be viewed as risky",
            "An over concern about the short-term performance of investments in both up and down markets but especially in down markets",
            "Concern with losing what had previously been gained",
            "Deliberate and cautious approach to both work and personal life",
            "Desire or need to take care of their current family as well as future generations",
            "Tendency to make decisions based on how they are feeling rather than using facts or logic",
            "Having gained their wealth by not risking their own capital but more often through inheritance, long term work, or employment in a large corporation",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "Barsness",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 396,
    dateCollected: "Jun 16 2020",
    firstName: "Tom",
    insights: {
      score: {
        plScore: 30,
        riskBehaviorScore: 40,
        selfReportedScore: 36,
      },
      profile: {
        communicationExpectation: "Quarterly",
        currentRetirementVehicle: "401(k) at work",
      },
      retirement: {
        birthYear: 1958,
        retirementAge: 68,
        retirementYear: 2026,
      },
      tendencies: [
        {
          id: 27,
          name: "Composed",
          type: "Strongly Identified",
          results: [
            "Values the opinions of others in the decision-making process.",
            "Views professional financial advice as beneficial to their investment goals.",
            "Operates on the understanding that the future cannot be predicted.",
            "Realizes that just because they are successful in other areas of life, they need assistance with financial decisions.",
            "Understands the value of a good professional to assist in financial planning.",
            "Does not see value in day trading.",
          ],
          characteristics: [
            "Sees the value of a diversified portfolio.",
            "Realizes that predicting the outcome of investment decisions is not possible.",
            "Accepts their limited capacity to make investment decisions.",
            "Views day trading with caution.",
            "Sees the value of historic evaluation of upside and downside volatility potential.",
            "Sees the value of information to make informed decisions and evaluate poor past decisions.",
            "Able to admit when they have made a mistake and move on.",
          ],
          recommendations: [
            "Help walk through their investment track record to highlight where they have made sound and successful investments.",
            "Put strategies in place to encourage the investor to trade when appropriate.",
            "Limit the number of trades and frequency of portfolio movement.",
            "Providing specific logic-based advice instead of general advice will enhance this relationship.",
            "Strategize how to show the value of your professional approach.",
            "Educate the client on rebalancing and buy and hold techniques so it can be logically understood.",
          ],
        },
        {
          id: 29,
          name: "Inspective",
          type: "Identified",
          results: [
            "Aims to understand what created the end result of an investment.",
            "Relies heavily on information and research to guide the investment process.",
            "Has an open mind to positions.",
            "Prefers a logically diverse portfolio.",
            "Is not concerned with fads or in-style positions.",
          ],
          characteristics: [
            "Are unconcerned with how others are investing.",
            "Tries to understand the risks associated with individual positions or groups of assets.",
            "Place value on the process used by financial professionals to follow investment guidelines.",
            "Uses research to keep a portfolio sound.",
            "Does not take things at face value and pays attention to what may be happening behind the scenes.",
            "Sees the difference between short term and long term value of an investment.",
            "Does not become overly excited with the short - term performance of an investment.",
            "Likes to know the logic behind the movement(both up and down) of a position.",
          ],
          recommendations: [
            "Supply the investor with information and research as they will use this to inform their bigger picture viewpoint.",
            "Use events and rationale behind specific position outcomes.",
            "Do not focus on trendy or in-style investments.",
            "Show long term plan versus short term movements of positions.",
            "Show value of the entire portfolio not individual position movement.",
            "Present reasons why positions and the portfolio is moving up or down.",
            "Using logic is important in communication and assisting with the decision-making process.",
          ],
        },
        {
          id: 28,
          name: "Diagnostic",
          type: "Identified",
          results: [
            "Decisions are made using logic and information.",
            "Invests in positions because of a belief that they will perform well rather than a belief that it reflects who they are as a person.",
            "Does not let personal values influence investment decisions.",
            "The perceived status of an investment option does not influence this type of investor.",
            "Moral investing is not typically a concern.",
            "Realizes that investments do not reflect who they are as a person; instead, it reflects how they have put their money to work for them.",
          ],
          characteristics: [
            "Willing to invest in positions or areas regardless of their own personal belief or world view.",
            "Is more concerned with gaining money than any perceived status that may come with holding a particular investment.",
            "Sees the value in making investment decisions using research rather than personal views.",
            "Keeps personal beliefs and investing separate.",
            "Not overly concerned with morally investing.",
          ],
          recommendations: [
            "Use data and logic to help guide investment decisions.",
            "Provide sound recommendations regardless of moral issues.",
            "Know that increasing the value of the portfolio is the key over personal beliefs or judgements.",
            "Keep an open mind with this investor.",
            "Center discussions on the big picture more than individual positions or statistics.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 13,
          name: "Traditionalist",
          type: "Identified",
          results: ["A tendency that is logical in nature.", "Adjusts or tweaks new information to support their prior views", "Highly values their own beliefs while placing a lower value on new information."],
          characteristics: [
            "Holds on to positions too long.",
            "Either fails to act or slowly react to new information.",
            "Has difficulty accepting or believing things that question their world perspective.",
            "Believes that old ways are inherently better.",
            "Can be overwhelmed with new and complex data.",
            "Views involvement in new things as a burden.",
          ],
          recommendations: [
            "Be prepared to show concrete examples of the benefits of a professional financial advisor.",
            "Demonstrate how new data can achieve better outcomes.",
            "Present convincing arguments of how old ways can be honored while moving away from the past into more current financial positions.",
            "Take the emotion out of the decision process using logic and research.",
            "Encourage decisive action when a course of action is decided.",
            "Expect hesitation to be a natural response of a Traditionalist investor.",
            "Build a relationship where the investor turns to you for your professional assistance to understand and interpret complex financial information.",
          ],
        },
        {
          id: 21,
          name: "Autonomous",
          type: "Identified",
          results: [
            "Sees the value in accurate and complete data and information to make informed decisions.",
            "Places importance on outside valuations of price rather than just their own opinion.",
            "Looks beyond the past performance of a position to estimate how it may perform in the future.",
          ],
          characteristics: [
            "Can quickly change course or thoughts about a position when new information is given.",
            "Considers at a variety of factors when evaluating the potential risk of an investment.",
            "Is able to look at an investment based on the overall market rather than focusing on the current economic state of certain companies or countries.",
            "Understands how investments can fluctuate quickly and often.",
            "Will make an effort to find information beyond what is easily available when assessing the current status or condition of a company.",
          ],
          recommendations: [
            "Support your recommendations with arguments and points that have passionate or emotional elements in addition to logical research.",
            "Use a big picture approach assists in the decision-making process.",
            "Keep in mind this client can see quick position changes as beneficial.",
            "Offer multiple examples when decisions are being made.",
            "Provide areas for self-research.",
            "Quick and decisive actions are valued with this client.",
          ],
        },
        {
          id: 16,
          name: "Dissatisfaction",
          type: "Identified",
          results: [
            "A tendency that uses passion and emotion.",
            "Investors who have lost money in the past and do not want to experience such loss again.",
            "Reluctancy to enter into markets that have experienced declines but are positioned to improve.",
            "Takes purposeful steps to avoid the emotional pain they experience from poor decision making.",
            "Has a conservative outlook with current investment choices because of past losses.",
            "Carries a fatalistic attitude, or a perspective that poor outcomes are unavoidable.",
          ],
          characteristics: [
            "Holds positions too long in order to avoid making a decision they may regret later.",
            "Hesitates to take action even during times that require aggressive steps.",
            "Uses their dissatisfaction with past investments to influence their perspective with current investments.",
            "Likes to invest in things that are high profile.",
            'Displays "group think" behavior when positions that are being selected by others are perceived as preferable.',
            "Holds growing positions too long.",
          ],
          recommendations: [
            "Provide education that connects with their emotional decision making process. For example, discuss potential lifestyle changes if action is not taken.",
            "Use their past poor decisions to walk them through the process of how sound investment decisions should be made.",
            "Demonstrate logical decision making by clearly talking through both the pros and cons of an investment.",
            "Redirect unrealistic concerns or desires to hold a position to the big picture situation.",
            "Clear statistics can be beneficial in the decision-making process.",
          ],
        },
        {
          id: 2,
          name: "Loss Avoidance",
          type: "Identified",
          results: ["A tendency that is based on emotion or passion.", "A deep and painful dislike of losing money.", "Avoiding loss is more of a focus than gaining money.", "An expectation to see returns that are twice what was invested."],
          characteristics: [
            "Holds onto losing investments too long with the hope that the loss will be regained.",
            "Trades too much due to a fear that profits will disappear.",
            "Believes that losses are not real until the investment is cashed in.",
            "When loss does occur, investments are held too long and possibly create an imbalanced portfolio.",
          ],
          recommendations: [
            "Create price protection points to avoid decisions based on an emotional reactions.",
            "Set rules or boundaries that take the emotion out the decision making process.",
            "Realize that these investors may react emotionally.",
            "Supply information that helps neutralize reactions that are based on feelings or passion.",
            "Take advantage of tax harvesting to offset the losses in one investment with the gains in another.",
            "Have a logical focus but involve emotion to influence the results you want.",
            "Explain the logical decisions you are suggesting through passionate examples and terms.",
          ],
        },
        {
          id: 5,
          name: "Cognitive Insight",
          type: "Identified",
          results: [
            "Behaviors are based on logic and information processing.",
            "Places sums of money in different mental groups or categories (i.e. college fund, play money, mortgage funds, etc.) and treats these groupings differently.",
            "Has a personally logical way that they have categorized their money.",
            "Makes financial decisions independently and differently according to their mental groupings.",
          ],
          characteristics: [
            "Categorizes money into different groupings.",
            "Holds separate accounts to match up with financial goals they have mentally constructed for themselves.",
            "May hold imbalanced investment portfolios because they view the portfolio as distinct pieces rather than a cohesive group.",
            "Often views returns that come from income and those that come from capital appreciation as two very different things.",
            "Hesitant to sell investments that were profitable in the past but are not currently beneficial.",
            "Money that is matched through employment or other means(such as a retirement match) has a lower value to Cognitive Insight investors.",
          ],
          recommendations: [
            "Figuring out the logic or the way that the investor has grouped their money is key to guiding their investments. You must work to understand what their groupings and goals mean to them.",
            "Adjust your approach based on which piece of their investment portfolio is being discussed.",
            "Help them to shift their focus from income areas where principal may be inadvertently lost.",
            "They will react to different amounts of loss based on which mental grouping is experiencing the loss. Higher or lower levels of loss will be tolerated according to how the investment is perceived in their mind.",
            "Reinforce that a loss in an investment is a loss no matter how they personally view the investment.",
            "When investments overlap, be mindful of how they can impact each other.",
            "Look for ways to help the investor view their portfolio components from a big picture perspective.",
          ],
        },
        {
          id: 18,
          name: "Reflection",
          type: "Identified",
          results: [
            "A logic oriented tendency.",
            "The investor thinks that predicting the market is possible.",
            "Views themselves as having a special ability to predict market changes.",
            "Uses hindsight to demonstrate their ability to foresee how the market behaved.",
          ],
          characteristics: [
            "Assumes too much risk because they believe they can predict the future.",
            "Keeps putting money into unsuccessful positions because they believe the outcome will be different.",
            "Adds more money to losing positions in an attempt to show that their past decisions were correct.",
            "Makes decisions based on a feeling rather than on facts or research.",
            "Are able to change their perception of history, especially when they have made poor investment decisions, in order to shift blame and avoid embarrassment.",
            "Gives too much credit to advisors when portfolios perform well.Similarly, gives too much fault to advisors when portfolios perform poorly.",
          ],
          recommendations: [
            "Use story telling of actual events to show how the market cannot be predicted.",
            "Help them to temper their reactions to both gains and losses in the market.",
            "Discuss market cycles and provide concrete examples.",
            "Encourage the investor to honestly self-reflect on the both good and bad investment outcomes.",
            "Education materials provided to this type of client will resonate well because of their logical nature.",
            "Take a gentle approach in discussing the impossibility of making market predictions.",
          ],
        },
      ],
      personality: {
        type: "Observer",
        behavior: "Moderate",
        attributes: {
          advisorTips: [
            "Avoid suggesting too many “hot” investment ideas as an Observer would like to do them all.",
            "Use data (examples) to support the investing advice that is provided.",
            "Provide information on the benefits of portfolio diversification and why sticking to a long-term plan is important.",
          ],
          investorBehaviors: [
            "Overestimating the amount of risk they are truly comfortable with in their investing",
            "Jumping into an investment that has been made to sound good without meaningful consideration of the risk involved",
            "A real fear of even dislike of financial investing",
            "Delaying investment decisions because they do not enjoy the investment process",
            "Accepting professional financial advice when it is given",
            "A tendency to maintain large cash balances because of a hesitancy to make financial decisions",
            "Learning about financial investment options on their own but not taking action using this knowledge",
          ],
        },
        decisionMakingStyle: "Moderately Logical",
      },
    },
    lastName: "Zielinski",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 395,
    dateCollected: "Apr 30 2020",
    firstName: "Peyton",
    insights: {
      score: {
        plScore: -9,
        riskBehaviorScore: 63,
        selfReportedScore: 84,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1990,
        retirementAge: 80,
        retirementYear: 2070,
      },
      tendencies: [
        {
          id: 2,
          name: "Loss Avoidance",
          type: "Strongly Identified",
          results: ["A tendency that is based on emotion or passion.", "A deep and painful dislike of losing money.", "Avoiding loss is more of a focus than gaining money.", "An expectation to see returns that are twice what was invested."],
          characteristics: [
            "Holds onto losing investments too long with the hope that the loss will be regained.",
            "Trades too much due to a fear that profits will disappear.",
            "Believes that losses are not real until the investment is cashed in.",
            "When loss does occur, investments are held too long and possibly create an imbalanced portfolio.",
          ],
          recommendations: [
            "Create price protection points to avoid decisions based on an emotional reactions.",
            "Set rules or boundaries that take the emotion out the decision making process.",
            "Realize that these investors may react emotionally.",
            "Supply information that helps neutralize reactions that are based on feelings or passion.",
            "Take advantage of tax harvesting to offset the losses in one investment with the gains in another.",
            "Have a logical focus but involve emotion to influence the results you want.",
            "Explain the logical decisions you are suggesting through passionate examples and terms.",
          ],
        },
        {
          id: 4,
          name: "Consistency",
          type: "Strongly Identified",
          results: [
            "A tendency where behaviors are driven by passion.",
            "A desire for things to stay mostly the same.",
            "Hesitates to try new things or depart from what has been done in the past.",
            "Places a high value on safety and consistency when considering an investment option.",
          ],
          characteristics: [
            "Looks for investments that are safe and carry little change.",
            "Prefers investments that guarantee a sense of safety.",
            "Takes little to no action which may result in holding investments that are too cautious or, based on changing times, too risky.",
            "Holds investments too long to avoid transactions fees that are part of the selling process.",
          ],
          recommendations: [
            "Be prepared that changing the mind of a Consistency investor is difficult. Sometimes, no matter your argument is, they will resist change.",
            "Work to understand the rationale for why the investor holds the positions that they do. Use these personal and emotional connections to steer their decision making.",
            "Educate the investor on why you are giving certain pieces of guidance using both their passionate side (personal reasons) and their logical side.",
            "Discuss the lifestyle and financial goal changes that they could experience due to the type of investments they are making.",
          ],
        },
        {
          id: 37,
          name: "Fundamental",
          type: "Strongly Identified",
          results: [
            "Makes decisions on what is presented rather than the way the information is presented.",
            "Visual materials (such as charts, resources, materials, brochures, etc.) are not as influential to this investor as the quality of information itself.",
            "Credibility of the advisor and their guidance is judged by performance rather than presentations.",
            "Details are important to the decision-making process.",
          ],
          characteristics: [
            "Logic and research to supporting decisions are more influential than the way that investment decisions are explained or presented.",
            "Are less concerned with the aesthetics of materials and the following of traditional professional protocols.",
            "Not concerned with micro - investing with sectors or industries.",
            "May consider adding risk to their portfolio even when their portfolio is performing well.",
            "Not focused on small price fluctuations but big picture impact and reasons behind volatility.",
          ],
          recommendations: [
            "Sophisticated materials and professional settings will not influence this type of investor.",
            "Stick to the details as flashy presentations are not influential.",
            "Focus on the big picture and not just micro or limited views.",
            "Keep a diversified and rebalance approach; value is understood with this investor.",
          ],
        },
        {
          id: 16,
          name: "Dissatisfaction",
          type: "Strongly Identified",
          results: [
            "A tendency that uses passion and emotion.",
            "Investors who have lost money in the past and do not want to experience such loss again.",
            "Reluctancy to enter into markets that have experienced declines but are positioned to improve.",
            "Takes purposeful steps to avoid the emotional pain they experience from poor decision making.",
            "Has a conservative outlook with current investment choices because of past losses.",
            "Carries a fatalistic attitude, or a perspective that poor outcomes are unavoidable.",
          ],
          characteristics: [
            "Holds positions too long in order to avoid making a decision they may regret later.",
            "Hesitates to take action even during times that require aggressive steps.",
            "Uses their dissatisfaction with past investments to influence their perspective with current investments.",
            "Likes to invest in things that are high profile.",
            'Displays "group think" behavior when positions that are being selected by others are perceived as preferable.',
            "Holds growing positions too long.",
          ],
          recommendations: [
            "Provide education that connects with their emotional decision making process. For example, discuss potential lifestyle changes if action is not taken.",
            "Use their past poor decisions to walk them through the process of how sound investment decisions should be made.",
            "Demonstrate logical decision making by clearly talking through both the pros and cons of an investment.",
            "Redirect unrealistic concerns or desires to hold a position to the big picture situation.",
            "Clear statistics can be beneficial in the decision-making process.",
          ],
        },
        {
          id: 39,
          name: "Enlightened",
          type: "Identified",
          results: [
            "Is open to new ideas even when they conflict with their previously held beliefs.",
            "Is willing to change their mind when evidence and research are supplied.",
            "Focuses equally on ups and downs, and does not always justify losses or gains.",
            "Is open to suggestions, new ideas, and new experiences.",
          ],
          characteristics: [
            "Are willing to admit when they have made a mistake.",
            "Is comfortable selling a position when it has not performed well.",
            "Believes that past mistakes will assist with better decision making in the future.",
            "Tends to value facts or research more than gut instincts or intuition.",
            "Views decisions and outcomes of decisions in a realistic and honest way.",
            "Does not believe that selling is admitting defeat with a declining position.",
            "Not looking at the bright side of everything.",
          ],
          recommendations: [
            "Use logic and direct communication.",
            "Show mistakes as well as successes in the investment process.",
            "Keep an open mind in discussions and decisions.",
            "This investor has the ability to take on new and exciting positions.",
            "Presenting material that is new and varied is encouraged and embraced.",
            "A well rounded and diversified portfolio is valued.",
            "This investor is open to other countries or sectors if well understood.",
          ],
        },
        {
          id: 33,
          name: "Catalytic",
          type: "Identified",
          results: ["Uses new information to question and enhance their current views.", "Is not afraid of the future and what it may bring.", "Are willing to explore and embrace new ideas.", "Sees necessary change as positive."],
          characteristics: [
            "Does not hold positions too long.",
            "Responds to new information and takes action when needed.",
            "Willing to have their world perspective challenged.",
            "Believes that change and modernization have a place and that old ways aren't necessarily better.",
            "Comfortable with new and complex data.",
            "Enjoys opportunities to be involved in new things.",
          ],
          recommendations: [
            "This client enjoys philosophical views and discussions regarding future potential.",
            "Do not focus on the past, but show how the future holds immense possibilities.",
            "Keep in mind this client enthusiastically embraces new opportunities.",
            "Use research and passion when presenting materials, as this is valued.",
            "This client prefers to be an early adopter of new investment possibilities.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 3,
          name: "Devoted",
          type: "Identified",
          results: [
            "A tendency that centers on passionate and emotional behaviors.",
            "Places a higher value on investments they own simply because of their personal involvement with them.",
            "A way of thinking where owning an investment gives it some higher value.",
            "Often found where there is inter-generational ownership of an investment or where purchases are made based on family relationships.",
          ],
          characteristics: [
            "When investments are inherited, they are held longer because of the family / personal connection.",
            "Likely has a lowest selling price that is larger than the maximum purchase price they would be willing to pay for the same investment.",
            "Holds investments too long to avoid transaction fees that are part of the selling process.",
            "Attached to investments because of a personal or family connection.",
            "Believes that investments passed to them from previous generations or that they have a strong emotional connection to are more valuable than they really are.",
            "Holds investments too long because it is familiar and brings a sense of comfort, causing it to be seen as having more value than it actually carries.",
          ],
          recommendations: [
            "Realize that the investor is making decisions using emotions more than logic.",
            'Ask targeted questions (such as "What if this happens?") to assist the investor in relinquishing concentrated positions.',
            "Use emotional arguments or points about what is happening in the market or industry.",
            "Present passionate questions to the investor with the intent of causing movement with the investment.",
            "Help the investor understand the evolution of the times, industries, and investments.",
          ],
        },
        {
          id: 12,
          name: "Self Belief",
          type: "Identified",
          results: [
            "A tendency that is based in logic.",
            "Belief they have a great ability to understand financial markets and make decisions.",
            "Connects success to their own talent or foresight.",
            "Overestimates their own knowledge of investing.",
          ],
          characteristics: [
            "Relies more on their personal decision making than the abilities of a trained financial professional.",
            "Blames failures and poor decisions on things outside their control(such as bad luck).",
            "Takes on too much risk after experiencing success from a previous investment decision.",
            "Trades too much or too often because they believe they are good at it.",
            "Takes action, often unnecessarily, when they are presented with new information.",
            'Uses "a feeling" or their gut instincts when making decisions.',
            "Only listens to what they want to hear.",
            "Holds onto things too long because of concentrated positions.",
          ],
          recommendations: [
            "Be aware that the investor will congratulate themselves when decisions turn out well and look for someone to blame when they don't.",
            "Guide the investor to look at decisions with a more holistic outlook where there are both good and bad outcomes.",
            "Look for patterns in poor decision making and develop ways you can use these patterns to influence them in the future to make more appropriate decisions.",
            "Understand that decisions are not made using data or research but rather gut instincts and feelings.",
          ],
        },
        {
          id: 14,
          name: "Model",
          type: "Identified",
          results: [
            "A logical tendency.",
            "Relates current situations to an analogy or past experience.",
            "Believes that if something works with one position, it should work with all.",
            "Makes new things familiar by connecting them to things that are already known.",
            "Assumes that understanding a small piece of a phenomenon means they understand the whole phenomenon.",
          ],
          characteristics: [
            "Attempts to understand how successful an investment will be by connecting or modeling it to something familiar to them.",
            "Predicts the likelihood of an outcome by using their own ideas and past experiences.",
            "Mistakenly uses one piece of an event to explain the wider phenomenon.",
            "Tends to oversimplify decisions which can lead to mistakes.",
            "Looks at too little information to make an informed decision.",
          ],
          recommendations: [
            "Discuss how position situations may be similar but that each investment has its own unique traits that can influence the outcome.",
            "Help the investor to take a step back to see a bigger perspective.",
            "Talk through the ideas of a position in terms that is known through research and past experiences.",
            "Provide research that clearly demonstrates that each investment must be evaluated on its own merits.",
          ],
        },
      ],
      personality: {
        type: "Liberator",
        behavior: "Moderately Aggressive",
        attributes: {
          advisorTips: [
            "Some Liberators may resist a rigid financial plan.",
            "Offer financial advice in a way that is mindful and respectful of their independent views.",
            "Engage in regular educational discussions as they will strengthen the relationship with a Liberator.",
            "Support any recommendations given with data.",
            "Clear, direct material and communication is preferred by most Liberators.",
          ],
          investorBehaviors: [
            "Having previously risked their own capital to gain wealth and are willing to do so in the future",
            "Preferring to take an active role in researching potential investments",
            "An expectation that there is a due diligence process for all investments being considered",
            "Keeping up to date on what is happening in financial markets",
            "A medium to high tolerance for risk with their financial investments",
            "Strong-willed and independent thinkers",
            "Looking at things logically and critically (rather than passionately)",
            "Making decisions using sound judgement and their own gut instincts",
            "Preferring to use their own research on an investment",
            "A dislike of information that is contrary to what they have learned or researched on their own",
            "Becoming overly involved with trying to beat the market with a result of holding concentrated positions",
          ],
        },
        decisionMakingStyle: "Somewhat Passionate",
      },
    },
    lastName: "Falkenburg",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 393,
    dateCollected: "May 13 2020",
    firstName: "Janine",
    insights: {
      score: {
        plScore: 19,
        riskBehaviorScore: 21,
        selfReportedScore: 50,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "401(k) at work",
      },
      retirement: {
        birthYear: 1968,
        retirementAge: 67,
        retirementYear: 2035,
      },
      tendencies: [
        {
          id: 26,
          name: "Prudent",
          type: "Strongly Identified",
          results: [
            "Tend to follow their long-term financial goals and are not easily influenced by emotions or desires.",
            "Usually follow their personal beliefs and are not easily persuaded by people and events around them.",
            "Are self-disciplined and can stick to a plan that has been created.",
            "Do not make decisions impulsively.",
            "Sees value in logical decisions.",
          ],
          characteristics: [
            "Does not like change and uses caution when making decisions.",
            "Prefers to save for tomorrow rather than spend today.",
            "Exercises caution with financial decisions to avoid putting previous investment efforts at risk.",
            "Has some familiarity with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist with generating long - term wealth.",
            "Values a retirement plan.",
            "Realizes that quick risky investments do not make - up for poor past decisions.",
            "Sees the value of a balanced portfolio.",
            "Avoids impulsive buys.",
            "Enjoys the research behind purchases, and thinks before acting on a buy or a sell.",
            "Values sound logical advice.",
          ],
          recommendations: [
            "Provide sound research.",
            "Give time to think through decisions to buy or a sell.",
            "Supply information on the value of dollar cost averaging.",
            "Discuss how sacrifices today can result in a much better future.",
            "Rebalance and allocate plans as needed.",
            "Demonstrate knowledge of a balanced portfolio.",
            "Create a sound long term plan.",
            "Logic is a key to communication and decision making with this investor type.",
          ],
        },
        {
          id: 2,
          name: "Loss Avoidance",
          type: "Strongly Identified",
          results: ["A tendency that is based on emotion or passion.", "A deep and painful dislike of losing money.", "Avoiding loss is more of a focus than gaining money.", "An expectation to see returns that are twice what was invested."],
          characteristics: [
            "Holds onto losing investments too long with the hope that the loss will be regained.",
            "Trades too much due to a fear that profits will disappear.",
            "Believes that losses are not real until the investment is cashed in.",
            "When loss does occur, investments are held too long and possibly create an imbalanced portfolio.",
          ],
          recommendations: [
            "Create price protection points to avoid decisions based on an emotional reactions.",
            "Set rules or boundaries that take the emotion out the decision making process.",
            "Realize that these investors may react emotionally.",
            "Supply information that helps neutralize reactions that are based on feelings or passion.",
            "Take advantage of tax harvesting to offset the losses in one investment with the gains in another.",
            "Have a logical focus but involve emotion to influence the results you want.",
            "Explain the logical decisions you are suggesting through passionate examples and terms.",
          ],
        },
        {
          id: 9,
          name: "Conclusion",
          type: "Identified",
          results: ["A logical tendency.", "Focus on the end result of an investment rather than exploring what created that results.", "Uses information to process events but in a limited manner."],
          characteristics: [
            "Attracted to investment options because of what others are doing.",
            "Focuses on individual positions or groups of assets without really understanding the associated risks.",
            "Undervalues the process used by financial professionals to follow investment guidelines and avoid undue risk.",
            "Overvalues investments based on recent or strong performance, even when position prices are high.",
            "Looks at current performance and not the research behind what would keep a portfolio sound.",
            "Takes things at face value and ignores what may be happening behind the scenes.",
          ],
          recommendations: [
            "Provide current data and research to connect with the logical side of this investor.",
            "Look at what the client has done in the past and their past results to guide your work with them moving forward.",
            "Encourage the use of a bigger picture viewpoint when financial decisions are made.",
            "Discuss the events and rationale behind specific position outcomes in a direct manner.",
            "Debrief with the investor after decisions have been made to help illustrate how outcomes were achieved.",
          ],
        },
        {
          id: 31,
          name: "Progressive",
          type: "Identified",
          results: [
            "Is comfortable making complicated investment decisions even in areas where they have little experience.",
            "Is aware that outcomes may take place that are outside of what they could have ever imagined.",
            "Looks for confirmation outside of their own experiences when making investment decisions.",
            "Are not influenced by just their own personal life situation.",
            "Does not need personal experience in an area to feel comfortable making a decision.",
            "Able to see things at face value.",
            "Often learns things quickly and can keep up with rapidly changing information.",
            "Keeps personal biases out of the decision-making process.",
          ],
          characteristics: [
            "Prefers to be involved in the research and due diligence process of investing.",
            "Will make investment decisions based on elements and ideas they have been considering over time.",
            "Are open to learning about new industries, places, and people.",
            "Use data and facts to justify their decisions.",
            "Are not moved by current fads or trends in the market.",
            "Sees the value of investments that involve new industries or ideas.",
            "Open to learning new things and aim to benefit from new information.",
            "Does not need a personal knowledge of a position to be involved with an investment opportunity.",
          ],
          recommendations: [
            "Look for support that goes beyond their personal lives and experiences to help them understand and process current investment decisions.",
            "Anticipate that they may have researched an investment prior to and beyond the information you offer.",
            "Be aware that the investor may appreciate more advanced or detailed information about potential investments.",
            "Be prepared to answer questions that are more complex in nature.",
          ],
        },
        {
          id: 34,
          name: "Hypothesizer",
          type: "Identified",
          results: [
            "Relates current situations to hard numbers and verified information.",
            "Understands that if something works with one position, it doesn't always translate or work with another position.",
            "Makes new things familiar through research and learning.",
            "Assumes that much information is needed to understand a whole phenomenon.",
            "Tends to be open to new ideas, people, and experiences.",
          ],
          characteristics: [
            "Attempts to understand how successful and investment will be through research and learning.",
            "Predicts the likelihood of an outcome by using ideas, experiences, and information outside their own world view.",
            "Gathers several pieces of information from various sources to explain the wider phenomenon.",
            "Looks at a large amount of information to make an informed decision.",
            "Does not oversimplifying the decision - making process.",
          ],
          recommendations: [
            "Supply the investor with the research and data they need to feel fully informed before making an investment decision.",
            "Talk through the ideas of a position with verifiable facts and information.",
            "Remind the investor that while research is important to making a sound decision, information will not guarantee the success of an investment.",
            "Use caution when providing large amounts of information as too much information may paralyze the decision-making process.",
          ],
        },
        {
          id: 40,
          name: "Discerned",
          type: "Identified",
          results: [
            "Focus on the big picture and are not overly concerned with fads or trends in the market.",
            "Considers both past and current events.",
            "Understands that financial climates can changes easily.",
            "Uses what has happened in the past to help guide future investments.",
          ],
          characteristics: [
            "Balanced approach to considering the past, present, and future.",
            "Considers both the price of a position and the overall value of a position.",
            "Uses a wide set of information to make future predictions.",
            "Is not attracted to fads or trends in the market.",
            "Sees value in diversification.",
          ],
          recommendations: [
            "Avoid focusing on trendy investments.",
            "Use big picture concepts to discuss logical decisions.",
            "Current events and focus on what is happening now as it relates to the past is a key.  Use of this style is good for discussions and investment opportunities in a broad theme.",
            "Having the ability to relate what has happened previously and what the potential holds in the future is a key in communication.",
            "Open markets and opportunities in other countries is welcomed if understood and is logically presented in a time based perspective.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 30,
          name: "Receptive",
          type: "Identified",
          results: [
            "Believes the market behaves in ways that they cannot predict or control.",
            "Realizes success in other areas of life do not mean they will also find success in the financial industry.",
            "Sees the logic and advantages of not over-controlling their investments.",
            "Will use professional assistance and not just themselves.",
          ],
          characteristics: [
            "Understands that market outcomes are beyond their control.",
            "Do not overly rely on what they know or their personal life experiences to make investment decisions.",
            "Values the opinions of others and not just their own ideas.",
            "Keeps personal beliefs out of investments.",
            "Values limit orders and other techniques but with sound reason to maximize opportunities.",
            "Does not try to over - control the investment process.",
          ],
          recommendations: [
            "Use complete and accurate information as it is highly valued by this investor type.",
            "Offer a diversified and balanced portfolio.",
            "Help show keys in not over-controlling portfolios.",
            "Show value in professional advice and partnering with this investor.",
          ],
        },
        {
          id: 39,
          name: "Enlightened",
          type: "Identified",
          results: [
            "Is open to new ideas even when they conflict with their previously held beliefs.",
            "Is willing to change their mind when evidence and research are supplied.",
            "Focuses equally on ups and downs, and does not always justify losses or gains.",
            "Is open to suggestions, new ideas, and new experiences.",
          ],
          characteristics: [
            "Are willing to admit when they have made a mistake.",
            "Is comfortable selling a position when it has not performed well.",
            "Believes that past mistakes will assist with better decision making in the future.",
            "Tends to value facts or research more than gut instincts or intuition.",
            "Views decisions and outcomes of decisions in a realistic and honest way.",
            "Does not believe that selling is admitting defeat with a declining position.",
            "Not looking at the bright side of everything.",
          ],
          recommendations: [
            "Use logic and direct communication.",
            "Show mistakes as well as successes in the investment process.",
            "Keep an open mind in discussions and decisions.",
            "This investor has the ability to take on new and exciting positions.",
            "Presenting material that is new and varied is encouraged and embraced.",
            "A well rounded and diversified portfolio is valued.",
            "This investor is open to other countries or sectors if well understood.",
          ],
        },
      ],
      personality: {
        type: "Protector",
        behavior: "Moderately Conservative",
        attributes: {
          advisorTips: [
            "Take time to provide the big-picture descriptions of potential investments.",
            "Find ways to explain how the investments will deliver desired results using personal issues important to the Protector.",
            "Build trust through professionalism, expertise, and objectivity.",
          ],
          investorBehaviors: [
            "A preference to guard their wealth",
            "Taking any losses very seriously",
            "Careful and cautious decision making",
            "Difficulty taking action concerning their investments",
            "Worry and fear that wrong investment decisions will be made",
            "Purposeful avoidance of investments or actions that may be viewed as risky",
            "An over concern about the short-term performance of investments in both up and down markets but especially in down markets",
            "Concern with losing what had previously been gained",
            "Deliberate and cautious approach to both work and personal life",
            "Desire or need to take care of their current family as well as future generations",
            "Tendency to make decisions based on how they are feeling rather than using facts or logic",
            "Having gained their wealth by not risking their own capital but more often through inheritance, long term work, or employment in a large corporation",
          ],
        },
        decisionMakingStyle: "Somewhat Logical",
      },
    },
    lastName: "Mashburn",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 391,
    dateCollected: "Nov 27 2019",
    firstName: "Matt",
    insights: {
      score: {
        plScore: -28,
        riskBehaviorScore: 87,
        selfReportedScore: 50,
      },
      profile: {
        communicationExpectation: "Monthly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1978,
        retirementAge: 60,
        retirementYear: 2038,
      },
      tendencies: [
        {
          id: 16,
          name: "Dissatisfaction",
          type: "Strongly Identified",
          results: [
            "A tendency that uses passion and emotion.",
            "Investors who have lost money in the past and do not want to experience such loss again.",
            "Reluctancy to enter into markets that have experienced declines but are positioned to improve.",
            "Takes purposeful steps to avoid the emotional pain they experience from poor decision making.",
            "Has a conservative outlook with current investment choices because of past losses.",
            "Carries a fatalistic attitude, or a perspective that poor outcomes are unavoidable.",
          ],
          characteristics: [
            "Holds positions too long in order to avoid making a decision they may regret later.",
            "Hesitates to take action even during times that require aggressive steps.",
            "Uses their dissatisfaction with past investments to influence their perspective with current investments.",
            "Likes to invest in things that are high profile.",
            'Displays "group think" behavior when positions that are being selected by others are perceived as preferable.',
            "Holds growing positions too long.",
          ],
          recommendations: [
            "Provide education that connects with their emotional decision making process. For example, discuss potential lifestyle changes if action is not taken.",
            "Use their past poor decisions to walk them through the process of how sound investment decisions should be made.",
            "Demonstrate logical decision making by clearly talking through both the pros and cons of an investment.",
            "Redirect unrealistic concerns or desires to hold a position to the big picture situation.",
            "Clear statistics can be beneficial in the decision-making process.",
          ],
        },
        {
          id: 4,
          name: "Consistency",
          type: "Strongly Identified",
          results: [
            "A tendency where behaviors are driven by passion.",
            "A desire for things to stay mostly the same.",
            "Hesitates to try new things or depart from what has been done in the past.",
            "Places a high value on safety and consistency when considering an investment option.",
          ],
          characteristics: [
            "Looks for investments that are safe and carry little change.",
            "Prefers investments that guarantee a sense of safety.",
            "Takes little to no action which may result in holding investments that are too cautious or, based on changing times, too risky.",
            "Holds investments too long to avoid transactions fees that are part of the selling process.",
          ],
          recommendations: [
            "Be prepared that changing the mind of a Consistency investor is difficult. Sometimes, no matter your argument is, they will resist change.",
            "Work to understand the rationale for why the investor holds the positions that they do. Use these personal and emotional connections to steer their decision making.",
            "Educate the investor on why you are giving certain pieces of guidance using both their passionate side (personal reasons) and their logical side.",
            "Discuss the lifestyle and financial goal changes that they could experience due to the type of investments they are making.",
          ],
        },
        {
          id: 3,
          name: "Devoted",
          type: "Strongly Identified",
          results: [
            "A tendency that centers on passionate and emotional behaviors.",
            "Places a higher value on investments they own simply because of their personal involvement with them.",
            "A way of thinking where owning an investment gives it some higher value.",
            "Often found where there is inter-generational ownership of an investment or where purchases are made based on family relationships.",
          ],
          characteristics: [
            "When investments are inherited, they are held longer because of the family / personal connection.",
            "Likely has a lowest selling price that is larger than the maximum purchase price they would be willing to pay for the same investment.",
            "Holds investments too long to avoid transaction fees that are part of the selling process.",
            "Attached to investments because of a personal or family connection.",
            "Believes that investments passed to them from previous generations or that they have a strong emotional connection to are more valuable than they really are.",
            "Holds investments too long because it is familiar and brings a sense of comfort, causing it to be seen as having more value than it actually carries.",
          ],
          recommendations: [
            "Realize that the investor is making decisions using emotions more than logic.",
            'Ask targeted questions (such as "What if this happens?") to assist the investor in relinquishing concentrated positions.',
            "Use emotional arguments or points about what is happening in the market or industry.",
            "Present passionate questions to the investor with the intent of causing movement with the investment.",
            "Help the investor understand the evolution of the times, industries, and investments.",
          ],
        },
        {
          id: 8,
          name: "Kinship",
          type: "Strongly Identified",
          results: [
            "Decisions are made using passion and emotion.",
            "Invests in positions because of a belief that it reflects who they are.",
            "Looks for investments that match their values, even if they are not logical or are financially risky.",
            "Uses investments to express their values and beliefs.",
            "Are attracted to a perceived sense of status that comes with certain investments.",
          ],
          characteristics: [
            "Will invest in services or products that are personally preferred without properly vetting the investment.",
            "Tends to invest in positions or areas that are familiar.",
            "Misses opportunities because the investor feels a connection to certain positions.",
            "Will invest to gain status rather than to gain money.",
          ],
          recommendations: [
            'Use direct questioning to help the investor open their way of thinking. For example, ask "Have you ever seen a company act in a direction you weren\'t anticipating."',
            "Create stop loss indicators to help create movement when values are clouding their logic.",
            "Use data and research to bring a realistic and non-biased viewpoint to the investor.",
            "Directly link their passionate tendencies to examples of logical reasoning to guide their decision making.",
            "Work to demonstrate how a good company may be a bad financial investment for them.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 1,
          name: "Affixed",
          type: "Identified",
          results: [
            "Data and information are used to make decisions, even when it is incomplete or out of date.",
            "Price of an asset is established by the investor based on their personal opinion of what they believe the asset is worth.",
            "Often, but not always, the view of an investment's worth is based on the original purchase price of the asset.",
          ],
          characteristics: [
            "Has difficulty changing course or thoughts about a position even when new information is given.",
            "Uses return levels to predict the percentage an asset may rise or fall.",
            "May become focused on the economic states of certain companies or countries rather than looking at the overall market.",
            "Has a hard time accepting how things fluctuate in the industry.",
            "Uses information available to them to interpret how a company is doing and base their estimates on just that instead of all information available.",
            "Values an asset based on a past point in time rather than how the value may change in the future.",
          ],
          recommendations: [
            "Use the analytics behind investment options to educate the investor on a more realistic value of the asset.",
            "Use logical research to support your course of action.",
            "Urge the investor to focus more on the current state of the investment and future trends instead of what has taken place in the past.",
            "Build in stop losses to assist the investor when decisions need to be made that contradict how they think about an asset's value.",
            "Changing the mind of an investor with Affixed tendencies is difficult. Be prepared that this may be long process that requires sound research and information.",
          ],
        },
        {
          id: 9,
          name: "Conclusion",
          type: "Identified",
          results: ["A logical tendency.", "Focus on the end result of an investment rather than exploring what created that results.", "Uses information to process events but in a limited manner."],
          characteristics: [
            "Attracted to investment options because of what others are doing.",
            "Focuses on individual positions or groups of assets without really understanding the associated risks.",
            "Undervalues the process used by financial professionals to follow investment guidelines and avoid undue risk.",
            "Overvalues investments based on recent or strong performance, even when position prices are high.",
            "Looks at current performance and not the research behind what would keep a portfolio sound.",
            "Takes things at face value and ignores what may be happening behind the scenes.",
          ],
          recommendations: [
            "Provide current data and research to connect with the logical side of this investor.",
            "Look at what the client has done in the past and their past results to guide your work with them moving forward.",
            "Encourage the use of a bigger picture viewpoint when financial decisions are made.",
            "Discuss the events and rationale behind specific position outcomes in a direct manner.",
            "Debrief with the investor after decisions have been made to help illustrate how outcomes were achieved.",
          ],
        },
        {
          id: 37,
          name: "Fundamental",
          type: "Identified",
          results: [
            "Makes decisions on what is presented rather than the way the information is presented.",
            "Visual materials (such as charts, resources, materials, brochures, etc.) are not as influential to this investor as the quality of information itself.",
            "Credibility of the advisor and their guidance is judged by performance rather than presentations.",
            "Details are important to the decision-making process.",
          ],
          characteristics: [
            "Logic and research to supporting decisions are more influential than the way that investment decisions are explained or presented.",
            "Are less concerned with the aesthetics of materials and the following of traditional professional protocols.",
            "Not concerned with micro - investing with sectors or industries.",
            "May consider adding risk to their portfolio even when their portfolio is performing well.",
            "Not focused on small price fluctuations but big picture impact and reasons behind volatility.",
          ],
          recommendations: [
            "Sophisticated materials and professional settings will not influence this type of investor.",
            "Stick to the details as flashy presentations are not influential.",
            "Focus on the big picture and not just micro or limited views.",
            "Keep a diversified and rebalance approach; value is understood with this investor.",
          ],
        },
        {
          id: 13,
          name: "Traditionalist",
          type: "Identified",
          results: ["A tendency that is logical in nature.", "Adjusts or tweaks new information to support their prior views", "Highly values their own beliefs while placing a lower value on new information."],
          characteristics: [
            "Holds on to positions too long.",
            "Either fails to act or slowly react to new information.",
            "Has difficulty accepting or believing things that question their world perspective.",
            "Believes that old ways are inherently better.",
            "Can be overwhelmed with new and complex data.",
            "Views involvement in new things as a burden.",
          ],
          recommendations: [
            "Be prepared to show concrete examples of the benefits of a professional financial advisor.",
            "Demonstrate how new data can achieve better outcomes.",
            "Present convincing arguments of how old ways can be honored while moving away from the past into more current financial positions.",
            "Take the emotion out of the decision process using logic and research.",
            "Encourage decisive action when a course of action is decided.",
            "Expect hesitation to be a natural response of a Traditionalist investor.",
            "Build a relationship where the investor turns to you for your professional assistance to understand and interpret complex financial information.",
          ],
        },
        {
          id: 5,
          name: "Cognitive Insight",
          type: "Identified",
          results: [
            "Behaviors are based on logic and information processing.",
            "Places sums of money in different mental groups or categories (i.e. college fund, play money, mortgage funds, etc.) and treats these groupings differently.",
            "Has a personally logical way that they have categorized their money.",
            "Makes financial decisions independently and differently according to their mental groupings.",
          ],
          characteristics: [
            "Categorizes money into different groupings.",
            "Holds separate accounts to match up with financial goals they have mentally constructed for themselves.",
            "May hold imbalanced investment portfolios because they view the portfolio as distinct pieces rather than a cohesive group.",
            "Often views returns that come from income and those that come from capital appreciation as two very different things.",
            "Hesitant to sell investments that were profitable in the past but are not currently beneficial.",
            "Money that is matched through employment or other means(such as a retirement match) has a lower value to Cognitive Insight investors.",
          ],
          recommendations: [
            "Figuring out the logic or the way that the investor has grouped their money is key to guiding their investments. You must work to understand what their groupings and goals mean to them.",
            "Adjust your approach based on which piece of their investment portfolio is being discussed.",
            "Help them to shift their focus from income areas where principal may be inadvertently lost.",
            "They will react to different amounts of loss based on which mental grouping is experiencing the loss. Higher or lower levels of loss will be tolerated according to how the investment is perceived in their mind.",
            "Reinforce that a loss in an investment is a loss no matter how they personally view the investment.",
            "When investments overlap, be mindful of how they can impact each other.",
            "Look for ways to help the investor view their portfolio components from a big picture perspective.",
          ],
        },
      ],
      personality: {
        type: "Energizer",
        behavior: "Aggressive",
        attributes: {
          advisorTips: [
            "Building a relationship with an Energizer may be difficult as they tend to trust their own way of thinking more than that of a financial professional.",
            "Financial recommendations must be supported by data and research.",
            "Strong, clear, and direct communication materials work best when advising an Energizer.",
          ],
          investorBehaviors: [
            "Researching investment options on their own as they enjoy the investment process",
            "Having previously risked their own capital to gain wealth",
            "Success in controlling the outcomes of activities outside of their investments and belief this can also be done with their investments (especially at high wealth levels)",
            "A preference for digging into the details of an investment",
            "A tendency to trade too much when not being advised",
            "A true desire for the thrill of a good investment choice",
            "Being passionate about their investment choices",
            "Optimism that their choices will do well even when the expectation is irrational",
            "Over-confidence in their ability to make independent investment decisions",
            "An ability to look past the potential random and unknown risks that are naturally part of financial investing",
            "High discomfort when money is lost or an investment does not perform as expected",
          ],
        },
        decisionMakingStyle: "Somewhat Passionate",
      },
    },
    lastName: "Ruma",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
  {
    id: 390,
    dateCollected: "Nov 12 2019",
    firstName: "Malia",
    insights: {
      score: {
        plScore: 65,
        riskBehaviorScore: 46,
        selfReportedScore: 65,
      },
      profile: {
        communicationExpectation: "Quarterly",
        currentRetirementVehicle: "IRA and 401(k)",
      },
      retirement: {
        birthYear: 1965,
        retirementAge: 60,
        retirementYear: 2025,
      },
      tendencies: [
        {
          id: 20,
          name: "Present-Day",
          type: "Strongly Identified",
          results: [
            "A tendency that centers on the use of logic.",
            "A focus on what is hot right now rather than the big picture.",
            "Places more importance on recent events than those that happened in the past.",
            "A desire to have the current financial climate continue into the future and despite past history.",
          ],
          characteristics: [
            "Over - emphasis on what is happening in the current moment rather than also considering what the future may hold.",
            "Places a high value on the price of a position rather than the overall value of a position.",
            "Makes future predictions based on a narrow set of information.",
            "Gravitates towards fads or trends in the market.",
          ],
          recommendations: [
            "Connect both the past and the potential future to what is happening right now.",
            "Education is important to take advantage of this Investor type's connection to logic and information.",
            "Teach the investor about the advantages of asset allocation and diversification to the long-term balance and health of a portfolio.",
            "Use the Investor's Periodic Table of Investing to talk through a long-term balanced approach.",
            "Reinforce that the market cannot be predicted.",
            "Discuss, using historical data, how the behavior of the market is often repeated.",
          ],
        },
        {
          id: 28,
          name: "Diagnostic",
          type: "Strongly Identified",
          results: [
            "Decisions are made using logic and information.",
            "Invests in positions because of a belief that they will perform well rather than a belief that it reflects who they are as a person.",
            "Does not let personal values influence investment decisions.",
            "The perceived status of an investment option does not influence this type of investor.",
            "Moral investing is not typically a concern.",
            "Realizes that investments do not reflect who they are as a person; instead, it reflects how they have put their money to work for them.",
          ],
          characteristics: [
            "Willing to invest in positions or areas regardless of their own personal belief or world view.",
            "Is more concerned with gaining money than any perceived status that may come with holding a particular investment.",
            "Sees the value in making investment decisions using research rather than personal views.",
            "Keeps personal beliefs and investing separate.",
            "Not overly concerned with morally investing.",
          ],
          recommendations: [
            "Use data and logic to help guide investment decisions.",
            "Provide sound recommendations regardless of moral issues.",
            "Know that increasing the value of the portfolio is the key over personal beliefs or judgements.",
            "Keep an open mind with this investor.",
            "Center discussions on the big picture more than individual positions or statistics.",
          ],
        },
        {
          id: 18,
          name: "Reflection",
          type: "Strongly Identified",
          results: [
            "A logic oriented tendency.",
            "The investor thinks that predicting the market is possible.",
            "Views themselves as having a special ability to predict market changes.",
            "Uses hindsight to demonstrate their ability to foresee how the market behaved.",
          ],
          characteristics: [
            "Assumes too much risk because they believe they can predict the future.",
            "Keeps putting money into unsuccessful positions because they believe the outcome will be different.",
            "Adds more money to losing positions in an attempt to show that their past decisions were correct.",
            "Makes decisions based on a feeling rather than on facts or research.",
            "Are able to change their perception of history, especially when they have made poor investment decisions, in order to shift blame and avoid embarrassment.",
            "Gives too much credit to advisors when portfolios perform well.Similarly, gives too much fault to advisors when portfolios perform poorly.",
          ],
          recommendations: [
            "Use story telling of actual events to show how the market cannot be predicted.",
            "Help them to temper their reactions to both gains and losses in the market.",
            "Discuss market cycles and provide concrete examples.",
            "Encourage the investor to honestly self-reflect on the both good and bad investment outcomes.",
            "Education materials provided to this type of client will resonate well because of their logical nature.",
            "Take a gentle approach in discussing the impossibility of making market predictions.",
          ],
        },
        {
          id: 7,
          name: "Assertive",
          type: "Strongly Identified",
          results: [
            "A tendency that is influenced by passion.",
            "Illogically believes that they carry a great ability to understand, make decisions, and think through financial issues.",
            "Assumes they are more intelligent and have better information than they actually do.",
            "Feels as though they can figure out investments because they already hold good reasoning and judgement.",
            "Assumes that expertise or success in other areas can translate into expertise or success with investments.",
          ],
          characteristics: [
            "Overestimates their ability to make investment decisions.",
            "Believes they could excel at day trading.",
            "May ignore negative information or information that questions their abilities.",
            "Views themselves as holding superior intellect.",
            "Do not look at the impact the past has on current financial markets.",
            "Trades too much and too often because they believe they hold superior decision making abilities.",
            "Exaggerates downside risk because they do not know, do not understand, or ignore historical investment performance statistics.",
            "Will make risky decisions to compensate for poor decisions in the past.",
            "Takes on unnecessary risk by holding a portfolio that lacks diversity.",
          ],
          recommendations: [
            "Realize the investor may be overconfident in their abilities to make decisions.",
            "Help walk them through their investment track record as they often fail to keep track of their losses.",
            "Take advantage of tax harvesting to offset losses with gains.",
            "Will likely respond extremely well to education and information as it connects to their logical side.",
            "Use hedging strategies to help combat emotional ties to certain investments.",
            "Put strategies in place to curb the investor's propensity to trade too often.",
            "Often seen as a tendency that can have the most negative impact on the investment process and will need that advisor's attention to neutralize.",
          ],
        },
        {
          id: 34,
          name: "Hypothesizer",
          type: "Strongly Identified",
          results: [
            "Relates current situations to hard numbers and verified information.",
            "Understands that if something works with one position, it doesn't always translate or work with another position.",
            "Makes new things familiar through research and learning.",
            "Assumes that much information is needed to understand a whole phenomenon.",
            "Tends to be open to new ideas, people, and experiences.",
          ],
          characteristics: [
            "Attempts to understand how successful and investment will be through research and learning.",
            "Predicts the likelihood of an outcome by using ideas, experiences, and information outside their own world view.",
            "Gathers several pieces of information from various sources to explain the wider phenomenon.",
            "Looks at a large amount of information to make an informed decision.",
            "Does not oversimplifying the decision - making process.",
          ],
          recommendations: [
            "Supply the investor with the research and data they need to feel fully informed before making an investment decision.",
            "Talk through the ideas of a position with verifiable facts and information.",
            "Remind the investor that while research is important to making a sound decision, information will not guarantee the success of an investment.",
            "Use caution when providing large amounts of information as too much information may paralyze the decision-making process.",
          ],
        },
        {
          id: 4,
          name: "Consistency",
          type: "Strongly Identified",
          results: [
            "A tendency where behaviors are driven by passion.",
            "A desire for things to stay mostly the same.",
            "Hesitates to try new things or depart from what has been done in the past.",
            "Places a high value on safety and consistency when considering an investment option.",
          ],
          characteristics: [
            "Looks for investments that are safe and carry little change.",
            "Prefers investments that guarantee a sense of safety.",
            "Takes little to no action which may result in holding investments that are too cautious or, based on changing times, too risky.",
            "Holds investments too long to avoid transactions fees that are part of the selling process.",
          ],
          recommendations: [
            "Be prepared that changing the mind of a Consistency investor is difficult. Sometimes, no matter your argument is, they will resist change.",
            "Work to understand the rationale for why the investor holds the positions that they do. Use these personal and emotional connections to steer their decision making.",
            "Educate the investor on why you are giving certain pieces of guidance using both their passionate side (personal reasons) and their logical side.",
            "Discuss the lifestyle and financial goal changes that they could experience due to the type of investments they are making.",
          ],
        },
        {
          id: 5,
          name: "Cognitive Insight",
          type: "Strongly Identified",
          results: [
            "Behaviors are based on logic and information processing.",
            "Places sums of money in different mental groups or categories (i.e. college fund, play money, mortgage funds, etc.) and treats these groupings differently.",
            "Has a personally logical way that they have categorized their money.",
            "Makes financial decisions independently and differently according to their mental groupings.",
          ],
          characteristics: [
            "Categorizes money into different groupings.",
            "Holds separate accounts to match up with financial goals they have mentally constructed for themselves.",
            "May hold imbalanced investment portfolios because they view the portfolio as distinct pieces rather than a cohesive group.",
            "Often views returns that come from income and those that come from capital appreciation as two very different things.",
            "Hesitant to sell investments that were profitable in the past but are not currently beneficial.",
            "Money that is matched through employment or other means(such as a retirement match) has a lower value to Cognitive Insight investors.",
          ],
          recommendations: [
            "Figuring out the logic or the way that the investor has grouped their money is key to guiding their investments. You must work to understand what their groupings and goals mean to them.",
            "Adjust your approach based on which piece of their investment portfolio is being discussed.",
            "Help them to shift their focus from income areas where principal may be inadvertently lost.",
            "They will react to different amounts of loss based on which mental grouping is experiencing the loss. Higher or lower levels of loss will be tolerated according to how the investment is perceived in their mind.",
            "Reinforce that a loss in an investment is a loss no matter how they personally view the investment.",
            "When investments overlap, be mindful of how they can impact each other.",
            "Look for ways to help the investor view their portfolio components from a big picture perspective.",
          ],
        },
        {
          id: 23,
          name: "Impersonal",
          type: "Strongly Identified",
          results: [
            "Views investments equally and does not become personally attached to assets they have held.",
            "Tends to view investments objectively rather than relying on familiarity.",
            "Values logic rather than passion for decisions and communication.",
            "If investments are inherited, their value is based on logic rather than emotion or sentiment.",
          ],
          characteristics: [
            "Have minimal personal or sentimental attachment to investments.",
            "Views transaction fees as a normal part of the investment process.",
            "Values inherited or family - related investments the same as other investment types.",
            "Willing to make changes to and part with an investment regardless of how long it has been held.",
            "Sees the value of an investment on its own merits rather than sentimental value.",
          ],
          recommendations: [
            "Uses logic more than emotion to make decisions.",
            "Research and evidence is more influential than loyalty to past or current investments.",
            "Realize emotional ties to inherited positions are likely minimal.",
            "Understand that investments are held based on logic rather than family or belief ties.",
            "Use rational explanations of transaction fees and talk through the allocation process.",
            "Know the client values a logical approach to positions held.",
          ],
        },
        {
          id: 32,
          name: "Judicious",
          type: "Strongly Identified",
          results: [
            "Acknowledges their limitations when it comes to knowledge of and expertise with investing.",
            "Prefers a balanced allocation.",
            "Looks to the expertise of financial professionals to help inform their decision making.",
            "Sees the value of not relying only on their instincts when making decisions.",
          ],
          characteristics: [
            "Believes in the expertise and ability of trained financial professionals.",
            "Looks for ways to learn from failures and poor decisions.",
            "Keeps a sensible approach to decisions even after experiencing investment success.",
            "Sees the value of sound investing based on a plan.",
            "Listens to new information without feeling obligated to react.",
            "Use research and data when making decisions.",
            "Willing to hear all information available, even if it is bad news.",
            "Does not rely on instincts when making investment decisions.",
            "Sees the value of a diversified portfolio.",
          ],
          recommendations: [
            "Do not blame others when decisions turn out poorly.",
            "Be available and prepared to offer insight and guidance.",
            "Create and communicate the investing plan in direct and frequent ways.",
            "Communicate using logic, research, and detailed information.",
            "Keep in mind that this investor enjoys the experience of working with a trusted advisor.",
          ],
        },
        {
          id: 26,
          name: "Prudent",
          type: "Strongly Identified",
          results: [
            "Tend to follow their long-term financial goals and are not easily influenced by emotions or desires.",
            "Usually follow their personal beliefs and are not easily persuaded by people and events around them.",
            "Are self-disciplined and can stick to a plan that has been created.",
            "Do not make decisions impulsively.",
            "Sees value in logical decisions.",
          ],
          characteristics: [
            "Does not like change and uses caution when making decisions.",
            "Prefers to save for tomorrow rather than spend today.",
            "Exercises caution with financial decisions to avoid putting previous investment efforts at risk.",
            "Has some familiarity with basic financial principles(such as compounding interest, dollar cost averaging, etc.) that assist with generating long - term wealth.",
            "Values a retirement plan.",
            "Realizes that quick risky investments do not make - up for poor past decisions.",
            "Sees the value of a balanced portfolio.",
            "Avoids impulsive buys.",
            "Enjoys the research behind purchases, and thinks before acting on a buy or a sell.",
            "Values sound logical advice.",
          ],
          recommendations: [
            "Provide sound research.",
            "Give time to think through decisions to buy or a sell.",
            "Supply information on the value of dollar cost averaging.",
            "Discuss how sacrifices today can result in a much better future.",
            "Rebalance and allocate plans as needed.",
            "Demonstrate knowledge of a balanced portfolio.",
            "Create a sound long term plan.",
            "Logic is a key to communication and decision making with this investor type.",
          ],
        },
      ],
      personality: {
        type: "Observer",
        behavior: "Moderate",
        attributes: {
          advisorTips: [
            "Avoid suggesting too many “hot” investment ideas as an Observer would like to do them all.",
            "Use data (examples) to support the investing advice that is provided.",
            "Provide information on the benefits of portfolio diversification and why sticking to a long-term plan is important.",
          ],
          investorBehaviors: [
            "Overestimating the amount of risk they are truly comfortable with in their investing",
            "Jumping into an investment that has been made to sound good without meaningful consideration of the risk involved",
            "A real fear of even dislike of financial investing",
            "Delaying investment decisions because they do not enjoy the investment process",
            "Accepting professional financial advice when it is given",
            "A tendency to maintain large cash balances because of a hesitancy to make financial decisions",
            "Learning about financial investment options on their own but not taking action using this knowledge",
          ],
        },
        decisionMakingStyle: "Moderately Logical",
      },
    },
    lastName: "Schleifer",
    practice: "Legacy Company",
    practiceName: "legacy-company",
    __typename: "Client",
  },
];
